import React from 'react';
import theme from './style';
import { Stepper as MUIStepper, Step, StepLabel } from "@material-ui/core";

function getSteps() {
  return ["Skapad", "Hanterad", "Väntar", "Publicerad"];
}
function QontoStepIcon(props) {
  const classes = theme();
  const { active, completed } = props;
  return (
    <div
      className={classes.root}
    >
      {completed || active ? (
        <div className={classes.completed} />
      ) : (
          <div className={classes.circle} />
        )}
    </div>
  );
}

const Stepper = (props) => {
  const classes = theme();
  let steps = getSteps();
  return (
    <MUIStepper alternativeLabel activeStep={props.step} className={classes.stepper}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel className={classes.label} StepIconComponent={QontoStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </MUIStepper>
  );
}

export default Stepper;