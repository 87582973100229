import React from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import { setError } from '../../redux/states/statesActions';

import { useDispatch, useSelector } from 'react-redux';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const SnackBar = () => {
  const error = useSelector(state => state.states.error);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setError(null));
  }
  return (
    <React.Fragment>
      {!!error &&
        <Snackbar open={!!error} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {error.error}
          </Alert>
        </Snackbar>
      }
    </React.Fragment>
  );
}

export default SnackBar;