import React, { Fragment, memo } from 'react';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import AddCommentOutlinedIcon from '@material-ui/icons/AddCommentOutlined';
import translate from '../../../functions/applyTranslation';
import theme from './style';

const Comments = (props) => {
  const classes = theme();
  const { comments, handle, index, type, openModal } = props;
  const onClick = () => {
    if (openModal)
      openModal({ key: 'comments', index: index, type: type, comments: comments, callback: handle })
  }

  return (
    <Fragment>
      {!comments ? <AddCommentOutlinedIcon onClick={onClick} /> : <CommentOutlinedIcon onClick={onClick} />} <small>{!comments ? translate('activities-cart', 'label_add_comment') : translate('activities-cart', 'label_edit_comment')}</small>
    </Fragment>
  );
}

export default Comments;