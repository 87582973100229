import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const styles = makeStyles(theme => ({
    formWrap: {
      padding: 15,
      marginBottom: 15,
      '& .item': {
        padding: 3,
      }
    },
    header: {
      marginBottom: 10,
    },
    subHeader: {
      marginBottom: 20,
    },
    buttonWrap: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 5
    },
    forceHeight: {
      // backgroundColor: 'red'
      minHeight: 76,
    }
  }));
  return styles();
}
export default theme;

