import React from 'react';
import Media from 'react-media';
import MenuIcon from '@material-ui/icons/Menu';
import {Grid} from "@material-ui/core";
import theme from './style';
import Notifications from './notifications';
import translate from '../../../functions/applyTranslation';
import { useSelector } from 'react-redux';
import { Fragment } from 'react';
import { useHistory, matchPath } from 'react-router-dom';

const LogoComponent = (props) => {
  const classes = theme();
  const userData = useSelector(state => state.auth.userData);
  const history = useHistory();
  const {
    tabletMode,
    handleLogout,
    toggleMobileMyPages
  } = props;

  const match = matchPath(history.location.pathname, {
    path: "/activities/:id/check-list"
  });

  const handleBack = () => {
    let id = 0;
    if (match && match.params.id) {
      id = match.params.id
      history.push(`/activities/${id}/booking`)
    }
    else
      history.goBack();
  }

  const handleProfile = ()=> {
    history.push('/profile/personal-info');
  }

  return (
    <div className={classes.userInfo}>
      <Media queries = {{
        desktop: "(min-width: 541px)",
        mobile: "(max-width: 540px)"
      }}>
        {matches => (
          <>
            {matches.mobile && (
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  {/*<Notifications />*/}
                </Grid>
                <Grid>
                  <MenuIcon className={classes.mobileMenuIcon} onClick={() => toggleMobileMyPages(true)}/>
                </Grid>
              </Grid>
            )}
            {matches.desktop && (
              <>
                {!tabletMode &&
                  <Fragment>
                    {/*FIXME: if notifications is to be displayed in the future also check the textWrapper style*/}
                    {/*<Notifications />*/}
                    <div className={classes.textWrapper}>
                      <div className='user'>{userData ? userData.name : ''}</div>
                      <div className='actions'><span onClick={handleProfile}>{translate('', 'navbar_shortcut_my_pages')}</span> / <span onClick={handleLogout}>{translate('', 'navbar_shortcut_log_out')}</span></div>
                    </div>
                  </Fragment>
                }
                {tabletMode &&
                  <Fragment>
                    <div className={classes.textWrapperTablet}>
                      <div className='user'>{userData ? userData.name : ''}<br /><span onClick={handleBack}>{`< ${translate('', 'navbar_link_member_list')}`} </span></div>
                    </div>
                    <div className={classes.textWrapperTablet}>
                      <div className='actions'><span onClick={handleLogout}>{translate('', 'navbar_shortcut_log_out')}</span></div>
                    </div>
                  </Fragment>
                }
              </>
            )}
          </>
        )}
      </Media>
    </div>
  );
}
export default LogoComponent;