import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUserSettings } from '../redux/auth/authActions';
import useAPI from '../api/apiHandler';
import moment from 'moment';

const useSettingsHook = () => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.auth.userData);
    const userSettings = useSelector(state => state.auth.userSettings);
    const [setRequest] = useAPI();

    useEffect(() => {
        if ((!userSettings || moment.duration(moment.unix(userSettings.lastUpdate).diff(moment())).asMinutes > 5) && userData) {
            setRequest({
                url: `user/settings?user_id=${userData.id}`,
                method: 'GET',
            }).then(res => {
                if (res && res.data) {
                    if (res.data.length > 0) {
                        dispatch(setUserSettings({ lastUpdate: moment.unix(), settings: res.data[0] }));
                    }
                    else {
                        dispatch(setUserSettings({ lastUpdate: moment.unix(), settings: null }));
                    }
                }
            })
        }
    }, [userData]);

    const hasSetting = (key) => {
        if (userSettings && userSettings.settings) {
            const setting = userSettings.settings[key];
            return (setting || '') === '1';
        }
        return false;
    }

    const role = (userData || {}).role;

    return [role, hasSetting]
}

export default useSettingsHook;

