import {useState} from 'react';
import moment from "moment";
import useAPI from '../../../api/apiHandler';
import {useDispatch, useSelector} from 'react-redux';
import {setLoading} from '../../../redux/states/statesActions';
import usePermissionsHook from '../../../functions/permissions';
import useStatus from '../../../settings/statusList';
import translate from "../../../functions/applyTranslation";

const ActivitiesHook = () => {
  const dispatch = useDispatch();
  const [setRequest, multipleRequests] = useAPI();
  const [statusList, statusFilter, applyFilter] = useStatus();
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const [activities, setActivities] = useState([])
  const [navigationData, setSavigationData] = useState({
    totalItems: 0,
    totalPages: 1,
  });
  const auth = useSelector(state => state.auth);
  const [guides, setGuides] = useState([]);

  const [sort, setSort] = useState({column: 'event.start_time', by: 'asc'});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    startTime: !!sessionStorage.getItem("aLFilterStartTime") ? sessionStorage.getItem("aLFilterStartTime") : moment().startOf('day').unix(),
    endTime: !!sessionStorage.getItem("aLFilterEndTime") ? sessionStorage.getItem("aLFilterEndTime") : 0,
    location: !!sessionStorage.getItem("aLFilterLocation") ? sessionStorage.getItem("aLFilterLocation") : "",
    title: !!sessionStorage.getItem("aLFilterTitle") ? sessionStorage.getItem("aLFilterTitle") : "",
    guideId: !!sessionStorage.getItem("aLFilterGuideId") ? sessionStorage.getItem("aLFilterGuideId") : ""
  });
  const [checkBoxes, setCheckBoxes] = useState({
    viewSynagogue: !!sessionStorage.getItem("aLChckSynagogue") ? Number(JSON.parse(sessionStorage.getItem("aLChckSynagogue"))) : 1,
    viewMeeting: !!sessionStorage.getItem("aLChckMeeting") ? Number(JSON.parse(sessionStorage.getItem("aLChckMeeting"))) : 1,
    viewEvent: !!sessionStorage.getItem("aLChckEvent") ? Number(JSON.parse(sessionStorage.getItem("aLChckEvent"))) : 1,
    viewOld: !!sessionStorage.getItem("aLChckOld") ? Number(JSON.parse(sessionStorage.getItem("aLChckOld"))) : 0,
  });

  const [noGuideTours, setNoGuideTours] = useState({});

  const fetchActivities = (filters, view, calRange = {}) => {
    dispatch(setLoading(true))
    if (view != 'cal')
      setActivities([]);

    const {status_id, limit} = filters;
    let pageLimit = limit;
    if (view !== 'activity' && view !== "table")
      pageLimit = 999999;
    const isAdmin = auth.userData.role === "admin" || auth.userData.role === "superadmin" || auth.userData.role === "security";
    const isGuide = auth.userData.tour_guide == "1";
    const isMemberPlus = auth.userData.role === "memberplus";
    const visibility = status_id === 2 ? (hasPermission(permissions.ACTIVITIES_CREATE) ? '1' : '2') : '1,2'
    let url = `events?status_id=${applyFilter(status_id)}&visibility=${visibility}`

    if (view === "activity") {
      url += `&limit=${pageLimit}&page=${page}`;
    }
    if (status_id < 0) {
      if (view !== 'calendar') {
        url = `events?visibility=${visibility}&limit=${view === "table" ? rowsPerPage : pageLimit}&page=${view === "table" ? page : filters.page}`;
      } else {
        url = `events?visibility=${visibility}`;
      }
    }

    if (view === "table") {
      url += `&order=${sort.column} ${sort.by}&limit=${rowsPerPage}&page=${page + 1}`;

      if (checkBoxes.viewSynagogue !== 1 && checkBoxes.viewMeeting !== 1 && checkBoxes.viewEvent !== 1) {
        setActivities([]);
        setTotalItems(0);
        dispatch(setLoading(false))
        return;
      }

      if (checkBoxes.viewOld === 1 && (checkBoxes.viewSynagogue === 1 && checkBoxes.viewMeeting === 1 && checkBoxes.viewEvent === 1)) {
        url += ``
      } else if (checkBoxes.viewOld === 1) {
        url += ``
      } else {
        url += `&start_time=${filter.startTime}&end_time=${filter.endTime !== 0 ? filter.endTime : 2556143999}`
      }

      if (filter.guideId !== "") {
        url += `&tour_guide=${filter.guideId}`
      }

      if (checkBoxes.viewSynagogue === 1 && checkBoxes.viewMeeting !== 1 && checkBoxes.viewEvent !== 1) {
        url += `&type=synagogue`
      } else if (checkBoxes.viewSynagogue !== 1 && checkBoxes.viewMeeting === 1 && checkBoxes.viewEvent !== 1) {
        url += `&type=meeting`
      } else if (checkBoxes.viewSynagogue !== 1 && checkBoxes.viewMeeting !== 1 && checkBoxes.viewEvent === 1) {
        url += `&type=event`
      } else if (checkBoxes.viewSynagogue === 1 && checkBoxes.viewMeeting === 1 && checkBoxes.viewEvent !== 1) {
        url += `&type=synagogue,meeting`
      } else if (checkBoxes.viewSynagogue === 1 && checkBoxes.viewMeeting !== 1 && checkBoxes.viewEvent === 1) {
        url += `&type=synagogue,event`
      } else if (checkBoxes.viewSynagogue !== 1 && checkBoxes.viewMeeting === 1 && checkBoxes.viewEvent === 1) {
        url += `&type=meeting,event`
      }

      if (filter.endTime !== 0) {
        url += `&start_time=${filter.startTime}`;
      }
      if (filter.endTime !== 0) {
        url += `&end_time=${filter.endTime}`;
      }
      if (filter.location !== "") {
        url += `&location=${filter.location}`;
      }
      if (filter.title !== "") {
        url += `&title=${filter.title}`;
      }
    }

    /*if (view === 'activity' && status_id === 2) {
      url += `&type=event`
    }*/
    if (view === 'activity') {
      url += `&start_time=${moment(new Date()).startOf('day').unix()}&end_time=2556143999`;

      if (isAdmin) {
      } else if (isMemberPlus) {
        if (isGuide) {
          url += '&category=2,3,4,5,6,7,8,9,10,11,12,13,14'
        } else {
          url += '&type=event'
        }
      } else {
        if (!isGuide) {
          //   url += `&category=9`;
        }
      }
    } else if (view === "calendar") {
      url += `&cal=1`;
      if (calRange && calRange.startTime) {
        url += `&start_time=${calRange.startTime}`;
      } else {
        return;
      }
      if (calRange && calRange.endTime) {
        url += `&end_time=${calRange.endTime}`;
      } else {
        return;
      }
      url += `&limit=${pageLimit}&page=${page}`;
    }

    setRequest({
      url: url,
    }).then(async res => {
      if (res.data) {
        let data = res.data.content;
        setActivities(data);
        setSavigationData({
          totalPages: Number(res.data.pages),
          totalItems: Number(res.data.total)
        })

        if (view === "table") {
          setTotalItems(res.data.total);
          let _noGuideTours = {};
          data.forEach(e => {
            if (e.jfm_event.event_type == "14" && e.event.type === "synagogue" && (!e.jfm_event.tour_guide || e.jfm_event.tour_guide === "" || e.jfm_event.tour_guide == "0")) {
              _noGuideTours[e.jfm_event.id] = "0";
            }
          })
          setNoGuideTours(_noGuideTours);
        }
      }
      dispatch(setLoading(false))
    })
        .catch(() => dispatch(setLoading(false)))
  };

  const fetchAllGuides = () => {
    dispatch(setLoading(true))
    setRequest({
      url: 'users/tour_guides',
      method: 'GET'
    })
        .then(res => {
          if (res) {
            setGuides(res.data);
          }
          dispatch(setLoading(false))
        })
        .catch(() => dispatch(setLoading(false)))
  };

  const submitGuideChange = (activityId, guideId, mailContent, mailContentConfirmation) => {
    dispatch(setLoading());

    let formData = new FormData();
    formData.append("event_id", activityId);
    formData.append("user_id", guideId);
    formData.append("update_origin", "tableViewRow");
    formData.append("send_mail", true);
    formData.append("mail_content", mailContent);
    formData.append("mail_content_confirmation", mailContentConfirmation);

    return setRequest({
      url: "event/tour_guide",
      method: "POST",
      data: formData,
    })
  };

  const exportEvents = () => {
    let url = 'events/export?';

    if (checkBoxes.viewOld === 1 && (checkBoxes.viewSynagogue === 1 && checkBoxes.viewMeeting === 1 && checkBoxes.viewEvent === 1)) {
      url += ``
    } else if (checkBoxes.viewOld === 1) {
      url += ``
    } else {
      url += `&start_time=${filter.startTime}&end_time=${filter.endTime !== 0 ? filter.endTime : 2556143999}`
    }

    if (filter.guideId !== "") {
      url += `&tour_guide=${filter.guideId}`
    }

    if (checkBoxes.viewSynagogue === 1 && checkBoxes.viewMeeting !== 1 && checkBoxes.viewEvent !== 1) {
      url += `&type=synagogue`
    } else if (checkBoxes.viewSynagogue !== 1 && checkBoxes.viewMeeting === 1 && checkBoxes.viewEvent !== 1) {
      url += `&type=meeting`
    } else if (checkBoxes.viewSynagogue !== 1 && checkBoxes.viewMeeting !== 1 && checkBoxes.viewEvent === 1) {
      url += `&type=event`
    } else if (checkBoxes.viewSynagogue === 1 && checkBoxes.viewMeeting === 1 && checkBoxes.viewEvent !== 1) {
      url += `&type=synagogue,meeting`
    } else if (checkBoxes.viewSynagogue === 1 && checkBoxes.viewMeeting !== 1 && checkBoxes.viewEvent === 1) {
      url += `&type=synagogue,event`
    } else if (checkBoxes.viewSynagogue !== 1 && checkBoxes.viewMeeting === 1 && checkBoxes.viewEvent === 1) {
      url += `&type=meeting,event`
    }

    if (filter.endTime !== 0) {
      url += `&start_time=${filter.startTime}`;
    }
    if (filter.endTime !== 0) {
      url += `&end_time=${filter.endTime}`;
    }
    if (filter.location) {
      url += `&location=${filter.location}`;
    }
    if (filter.title) {
      url += `&title=${filter.title}`;
    }
    if (filter.guideId) {
      url += `&tour_guide=${filter.guideId}`;
    }

    setRequest({
      url: url,
      method: 'GET',
      responseType: 'blob', // Important to handle binary data
    })
        .then(res => {
          // Create a Blob from the response data
          const fileBlob = new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

          // Create an object URL for the Blob
          const fileURL = URL.createObjectURL(fileBlob);

          // Create an <a> element
          const a = document.createElement('a');
          a.href = fileURL;

          // Set the desired file name
          a.download = `Aktiviteter_export_${moment().format('YYYY_MM_DD')}.xlsx`; // Change this to your desired file name

          // Append the <a> element to the document body temporarily
          document.body.appendChild(a);

          // Programmatically click the <a> element to trigger the download
          a.click();

          // Remove the <a> element after the download is triggered
          document.body.removeChild(a);

          // Optional: Revoke the object URL after some time to free up memory
          setTimeout(() => {
            URL.revokeObjectURL(fileURL);
          }, 100);
        })
        .catch(error => {
        });

  }

  return [{
    data: activities,
    settings: navigationData
  }, sort,
    setSort,
    fetchActivities,
    guides,
    fetchAllGuides,
    page,
    rowsPerPage,
    totalItems,
    setPage,
    setRowsPerPage,
    filter,
    setFilter,
    checkBoxes,
    setCheckBoxes,
    noGuideTours,
    submitGuideChange,
    exportEvents
  ];
}

export default ActivitiesHook;
