import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    smallTitleWrap: {
    },
    fullWidth: {
      width: '100%'
    },
    smallTitleText: {
      fontSize: 22,
      fontWeight: 'bold',
      color: ColorSchemes.black
    },
    altText: {
      marginLeft: 5,
      color: ColorSchemes.orange,
    }
  }));
  return styles();
}
export default theme;
