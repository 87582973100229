import React from 'react';
import theme from './style';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

const SmallTitle = (props) => {
  const classes = theme();
  const {
    text,
    className,
    onClick
  } = props;

  return (<Typography onClick={onClick} className={clsx(className, classes.text)}>{text}</Typography>);
}
export default SmallTitle;