import {makeStyles} from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme'

const theme = (props) => {
  const styles = makeStyles(theme => ({
    groupItemWrap: {
      marginBottom: 20
    },
    itemWrap: {
      paddingBottom: 3,
      marginBottom: 3,
      borderBottom: `1px solid ${ColorSchemes.tableOddBg}`
    },
    item: {
      backgroundColor: '#FF90003D',
      padding: 5,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: "center"
    },
    iconAndNameWrap: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      '& .icon': {
        fill: ColorSchemes.mediumBlue,
        maxHeight: 15,
        maxWidth: 15,
        marginRight: 5
      },
      '& p': {
        fontSize: 14,
      },
      '& small': {
        fontSize: 12,
        color: ColorSchemes.lowDarkGrey,
      }
    },
    priceWrap: {
      display: "flex",
      justifyContent: "center",
      '& p': {
        fontSize: 14,
      },
    },
    actionWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: "flex-end",
      '& span': {
        margin: '0px 10px',
        fontSize: 30,
        fontWeight: 'bold'
      }
    },
    actionIcon: {
      maxWidth: 20,
      maxHeight: 20,
    },
    active: {
      cursor: 'pointer',
      fill: ColorSchemes.mediumBlue,
    },
    inactive: {
      fill: ColorSchemes.lowDarkGrey
    }
  }));
  return styles();
}
export default theme;

