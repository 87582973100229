import React, {useEffect, useState} from "react";
import Table from "@material-ui/core/Table";
import {
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Hidden,
  TablePagination, TableFooter, Dialog, Slide, DialogTitle, DialogContent, Grid, DialogActions, Tooltip, Link
} from "@material-ui/core";
import moment from "moment";
import * as XLSX from "xlsx";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EnhancedTableHead from "./EnhancedTableHead";
import ExpandRowButton from './ExpandRowButton';
import SaveAltSharpIcon from '@material-ui/icons/SaveAltSharp';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from "clsx";
import translate from "../../functions/applyTranslation";
import {Check, DatePicker, InputField, Select} from "../Inputs";
import TablePaginationActions from "./pagination";
import ColorSchemes from "../../settings/colorScheme";
import ButtonJFM from "../Button";
import useFilters from "../../views/activities-list/hooks/filter";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {useDispatch, useSelector} from "react-redux";
import {setLoading,setError} from "../../redux/states/statesActions";

const FREDIK_ID = 39;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GuideColumn = props => {
  const {activity, noGuideTours, tempGuides, guideName, exportEvents} = props;
  const userData = useSelector(state => state.auth.userData);
  const classes = useStyles();

  if (activity.jfm_event.tour_guide && activity.jfm_event.tour_guide != "0") {
    return guideName || '';
  } else {
    if (noGuideTours[activity.jfm_event.id] == "0" && !tempGuides[activity.jfm_event.id]) {
      return <div style={{display: "flex", alignItems: "center"}}><FiberManualRecordIcon style={{fill: "#ff9000", marginRight: 5}}/><span>Ej tilldelad</span>
      </div>
    } else if (noGuideTours[activity.jfm_event.id] == "0" && tempGuides[activity.jfm_event.id] == "1") {
      return <span className={classes.bookingGuideSpan}>Boka in mig</span>
    } else
      return <span>{userData.name}</span>
  }
};

const ActivitiesListTable = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [filters, setFilters] = useFilters();
  const userData = useSelector(state => state.auth.userData);

  const {
    sort,
    setSort,
    activities,
    guides,
    fetchAllGuides,
    page,
    rowsPerPage,
    totalItems,
    setPage,
    setRowsPerPage,
    filter,
    setFilter,
    view,
    fetchActivities,
    checkBoxes,
    setCheckBoxes,
    noGuideTours,
    submitGuideChange,
    exportEvents
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [tempGuides, setTempGuides] = useState({});
  const [mapExpandedRows, setMapExpandedRows] = useState([]);

  const TABLE_COLUMNS = [
    {
      id: "expand",
      name: "",
      sort: false,
      visible: true
    },
    {
      id: "jfm_event.event_start",
      name: "Datum/tid",
      sort: true,
      visible: true
    },
    {
      id: "jfm_event.event_type",
      name: "Typ",
      sort: true,
      visible: true
    },
    {
      id: "asset.name",
      name: "Lokal/tid",
      sort: true,
      visible: true
    },
    {
      id: "event.title",
      name: "Titel",
      sort: true,
      visible: true
    },
    {
      id: "organisation",
      name: "Bokad organisation/klass",
      sort: false,
      visible: true
    },
    {
      id: "Quantity",
      name: "Antal",
      sort: false,
      visible: true
    },
    // {
    //   id: "comentaries",
    //   name: "Kommentar",
    //   sort: false,
    //   visible: true
    // },
    {
      id: "jfm_event.tour_guide",
      name: "Guide",
      sort: true,
      visible: true
    },
    {
      id: "info",
      name: "",
      sort: false,
      visible: userData.role === "admin" || userData.role === "superadmin" || userData.role === "security"
    },
  ];

  const prepareMailToPayload = text => {
    let result = "";

    for (let item of text) {
      if (typeof item === "string") {
        result += item;
      } else if (item.type === "strong") {
        result += `<b>${item.props.children}</b>`;
      }else if (item.props && item.props.children) {
        result += item.props.children;
      } else if (item.type === "br") {
        result += "<br>";
      }
    }

    return result;
  }

  const getDialogText = (activity, mailTemplate) => {
    let text = translate("booking", mailTemplate);
    for (let i in text) {
      const item = text[i];
      if (typeof item === 'object') {
        if (item.props.children) {
          if (item.props.children.includes('##Program##')) {
            let updated = Object.assign({}, item, { props: { ...item.props, children: activity.program.name } });
            text[i] = updated;
          }
          if (item.props.children.includes('##BookedDay##')) {
            let updated = Object.assign({}, item, { props: { ...item.props, children: String(moment.unix(activity.jfm_event.event_start).format('DD MMM YYYY')) } });
            text[i] = updated;
          }
          if (item.props.children.includes('##startTime##')) {
            let updated = Object.assign({}, item, { props: { ...item.props, children: String(moment.unix(activity.jfm_event.event_start).format('HH:mm')) } });
            text[i] = updated;
          }
          if (item.props.children.includes('##ProgramLength##')) {
            let updated = Object.assign({}, item, { props: { ...item.props, children: Number(activity.program.duration) / 60 } });
            text[i] = updated;
          }
          if (item.props.children.includes('##guideEmail##') && userData.mail) {
            let updated = Object.assign({}, item, { props: { ...item.props, children: userData.mail } });
            text[i] = updated;
          }
        }
      }

      if (typeof item === "string") {
        if (item.includes('##guideName##') && userData.name ) {
          text[i] = text[i].replace("##guideName##", userData.name);
        }
        if (item.includes('##Program##') && activity.program.name ) {
          text[i] = text[i].replace("##Program##", activity.program.name);
        }
        if (item.includes('##startTime##')) {
          text[i] = text[i].replace("##startTime##", String(moment.unix(activity.jfm_event.event_start).format('HH:mm')));
        }
        if (item.includes('##endtime')) {
          text[i] = text[i].replace("##endtime##", String(moment.unix(activity.jfm_event.event_end).format('HH:mm')));
        }
        if (item.includes('##BookedDay##')) {
          text[i] = text[i].replace('##BookedDay##', String(moment.unix(activity.jfm_event.event_start).format('DD MMM YYYY')));
        }
        if (item.includes('##ProgramContent##') && activity.program.description) {
          text[i] = text[i].replace('##ProgramContent##', activity.program.description)
        }
        if (item.includes('##guideMobile##') && userData.phone) {
          text[i] = text[i].replace('##guideMobile##', userData.phone)
        }
        if (item.includes('##guideEmail##') && userData.mail) {
          text[i] = text[i].replace('##guideEmail##', userData.mail)
        }
        if (item.includes('##ProgramLength##')) {
          text[i] = text[i].replace('##ProgramLength##', Number(activity.program.duration) / 60)
        }
      }

    }

    return text
  }

  const tryParseString = str => {
    try {
      let obj = {};
      let o = JSON.parse(str);
      if (o && typeof o === "object") {
        obj = {
          organisation: o.organisation || "-",
          nrVisitors: o.nr_visitors || "-",
          message: o.message || "-"
        }
        return obj;
      }
    } catch (e) {
      return "-";
    }
  };

  const handleSortChange = (column, order) => {
    setSort({column: column, by: order})
  };

  const getTypeName = id => {
    if (id === "meeting") {
      return "Möte";
    } else if (id === "synagogue") {
      return "Visning"
    } else {
      return "Aktivitet"
    }
  };

  const getTitle = activity => {
    if (Object.keys(activity.program).length > 0) {
      return `Judiskt kunskapscenter, ${activity.program.name}`;
    }
    return activity.event.title;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewAllRowsClick = () => {
    handleResetFilters();
    setRowsPerPage(999999);
  };

  const handleCheckboxChange = (e, id) => {
    setCheckBoxes({...checkBoxes, [id]: e ? 1 : 0})
    switch (id) {
      case 'viewSynagogue':
        sessionStorage.setItem("aLChckSynagogue", e ? 1 : 0);
        setFilters('viewSynagogue', (e ? 1 : 0));
        break;
      case 'viewMeeting':
        sessionStorage.setItem("aLChckMeeting", e ? 1 : 0);
        setFilters('viewMeeting', (e ? 1 : 0));
        break;
      case 'viewEvent':
        sessionStorage.setItem("aLChckEvent", e ? 1 : 0);
        setFilters('viewEvent', (e ? 1 : 0));
        break;
      case 'viewOld':
        sessionStorage.setItem("aLChckOld", e ? 1 : 0);
        setFilters('viewOld', (e ? 1 : 0));
        break;
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDialogFilterChange = (e, id) => {
    if (id === "startTime" || id === "endTime") {
      setFilter({...filter, [id]: moment(e).unix()})
    } else {
      setFilter({...filter, [id]: e});
    }

    switch (id) {
      case 'startTime':
        sessionStorage.setItem("aLFilterStartTime", moment(e).unix());
        break;
      case 'endTime':
        sessionStorage.setItem("aLFilterEndTime", moment(e).unix());
        break;
      case 'location':
        sessionStorage.setItem("aLFilterLocation", e);
        break;
      case 'title':
        sessionStorage.setItem("aLFilterTitle", e);
        break;
      case 'guideId':
        sessionStorage.setItem("aLFilterGuideId", e);
        break;
    }
  };

  const createXLSX = () => {
    let data = [];

    activities.data.forEach(entry => {
      let obj = {
        "Datum/tid": `${moment.unix(entry.jfm_event.event_start).format("YYYY-MM-DD")}`,
        "Typ": getTypeName(entry.event.type),
        // "Lokal/tid": `${entry.asset.name || "-"}/${moment.unix(entry.event.start_time).format('YYYY-MM-DD HH:mm')} - ${moment.unix(entry.event.end_time).format('YYYY-MM-DD HH:mm')}`,
        "Titel": getTitle(entry),
        "Bokad organisation/klass": Object.keys(entry.program).length > 0 ? entry.booking.organisation : "-",
        "Stad": Object.keys(entry.program).length > 0 ? entry.booking.city : "-",
        "Antal": Object.keys(entry.program).length > 0 ? `${entry.booking.nr_visitors} (35)` : `${entry.event.registered_users} (${entry.event.max_ppl})`,
        "Guide": entry.jfm_event.tour_guide && entry.tour_guide ? entry.tour_guide.name : "-"
      };
      data.push(obj);
    });

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Aktivitier.xlsx")
  };

  const handleResetFilters = () => {
    setFilter({
      startTime: moment().startOf('day').unix(),
      endTime: 0,
      location: "",
      title: "",
      guideId: ""
    });

    sessionStorage.setItem("aLFilterStartTime", moment().startOf('day').unix());
    sessionStorage.setItem("aLFilterEndTime", "");
    sessionStorage.setItem("aLFilterLocation", "");
    sessionStorage.setItem("aLFilterTitle", "");
    sessionStorage.setItem("aLFilterGuideId", "");

    setCheckBoxes({
      viewSynagogue: !!sessionStorage.getItem("aLChckSynagogue") ? Number(JSON.parse(sessionStorage.getItem("aLChckSynagogue"))) : 1,
      viewMeeting: !!sessionStorage.getItem("aLChckMeeting") ? Number(JSON.parse(sessionStorage.getItem("aLChckMeeting"))) : 1,
      viewEvent: !!sessionStorage.getItem("aLChckEvent") ? Number(JSON.parse(sessionStorage.getItem("aLChckEvent"))) : 1,
      viewOld: !!sessionStorage.getItem("aLChckOld") ? Number(JSON.parse(sessionStorage.getItem("aLChckOld"))) : 0,
    })
    handleClose();
    fetchActivities(filters, view);
  };

  const handleSubmit = () => {
    fetchActivities(filters, view);
    handleClose();
  };

  const handleInfoClick = (e, activity) => {
    e.stopPropagation();
    history.push(`/activities/${activity.jfm_event.id}/booking`)
  };

  const handleGuideClick = (e, activity) => {
    e.stopPropagation();
    if (userData.tour_guide == "1" && !tempGuides[activity.jfm_event.id]) {
      setTempGuides({...tempGuides, [activity.jfm_event.id]: "1"})
    } else if (userData.tour_guide == "1" && tempGuides[activity.jfm_event.id] == "1") {

      let mailContent = prepareMailToPayload(getDialogText(activity, "booking_email"));
      let mailContentConfirmation = prepareMailToPayload(getDialogText(activity, "booking_confirmation_mail"))

      if(userData.id == FREDIK_ID){
        mailContent = prepareMailToPayload(getDialogText(activity, "booking_email_fredik"));
      }

      submitGuideChange(activity.jfm_event.id, userData.id, mailContent, mailContentConfirmation)
          .then(res => {
            if(!res){
              let _err = {
                error: translate('activities-list', 'tour_guide_already_exists'),
                err: null,
              }
              dispatch(setError(_err));
            }
            if (res) {
              dispatch(setLoading(false));
              setTempGuides({...tempGuides, [activity.jfm_event.id]: "2"})
            }
          })
          .catch(() => {
            dispatch(setLoading(false))
          })
    }
  };

  useEffect(() => {
    if(userData.role === "admin" || userData.role === "superadmin")
      fetchAllGuides();
  }, []);

  return (
      <>
        {/*Start of buttons*/}
        <Hidden xsDown>
          <div className={classes.buttonsWrap}>
            <Button
                size="small"
                color="primary"
                className={classes.buttons}
                onClick={handleViewAllRowsClick}
            >
              Visa alla rader
            </Button>
            <Button
                size="small"
                color="primary"
                variant="outlined"
                className={clsx(classes.buttons, classes.outlinedButton)}
                style={{margin: "0px  15px"}}
                startIcon={<FilterListIcon/>}
                onClick={() => setDialogOpen(true)}
            >
              Filtera lista
            </Button>
            <Button
                size="small"
                color="primary"
                variant="outlined"
                className={clsx(classes.buttons, classes.outlinedButton)}
                startIcon={<SaveAltSharpIcon/>}
                /*onClick={createXLSX}*/
                onClick={exportEvents}
            >
              Exportera
            </Button>
          </div>
        </Hidden>

        <Hidden smUp>
          <div className={classes.buttonsXsWrap}>
            <Button
                size="small"
                color="primary"
                fullWidth
                className={classes.buttons}
            >
              Visas alla rader
            </Button>
            <Button
                size="small"
                color="primary"
                variant="outlined"
                fullWidth
                className={clsx(classes.buttons, classes.outlinedButton)}
                style={{margin: "10px  0px"}}
                startIcon={<FilterListIcon/>}
            >
              Filtera lista
            </Button>
            <Button
                size="small"
                color="primary"
                variant="outlined"
                fullWidth
                className={clsx(classes.buttons, classes.outlinedButton)}
                startIcon={<SaveAltSharpIcon/>}
            >
              Exportera
            </Button>
          </div>
        </Hidden>
        {/*End of buttons*/}

        {/*Start of checkboxes*/}
        <Hidden xsDown>
          <div className={classes.checkboxesWrap}>
            <Check value={filters.viewSynagogue === 1} label="Visa visningar" className={classes.secondCheckbox}
                   onChange={e => handleCheckboxChange(e, "viewSynagogue")}/>
            <Check value={filters.viewMeeting === 1} label="Visa möten" className={classes.thirdCheckbox}
                   onChange={e => handleCheckboxChange(e, "viewMeeting")}/>
            <Check value={filters.viewEvent === 1} label="Visa Aktiviteter" className={classes.fourthCheckbox}
                   onChange={e => handleCheckboxChange(e, "viewEvent")}/>
            <Check value={filters.viewOld === 1} label="Inkludera äldre"
                   onChange={e => handleCheckboxChange(e, "viewOld")}/>
          </div>
        </Hidden>

        <Hidden smUp>
          <div className={classes.checkboxesXsWrap}>
            <div className={classes.checkboxGroup}>
              <Check value={filters.viewSynagogue === 1} label="Visa visningar"
                     onChange={e => handleCheckboxChange(e, "viewSynagogue")}/>
            </div>
            <div className={classes.checkboxGroup}>
              <Check value={filters.viewMeeting === 1} label="Visa möten"
                     onChange={e => handleCheckboxChange(e, "viewMeeting")}/>
              <Check value={filters.viewEvent === 1} label="Visa Aktiviteter"
                     onChange={e => handleCheckboxChange(e, "viewEvent")}/>
            </div>
            <div className={classes.checkboxGroup}>
              <Check value={filters.viewOld === 1} label="Inkludera äldre"
                     onChange={e => handleCheckboxChange(e, "viewOld")}/>
            </div>
          </div>
        </Hidden>
        {/*End of checkboxes*/}

        <TableContainer className={classes.tableContainer}>
          <Table aria-label="collapse table" size="small">
            <EnhancedTableHead
                sort={sort}
                onRequestSort={handleSortChange}
                cells={TABLE_COLUMNS}
            />
            <TableBody>
              {activities.data && activities.data.map(activity => (
                  <React.Fragment key={activity.jfm_event.id} >
                    <TableRow key={activity.jfm_event.id}
                              className={classes.forcePointer}
                              onClick={() => history.push(`/activities/${activity.jfm_event.id}`)}>
                      <TableCell className={classes.smallCell} onClick={(e) => {e.stopPropagation();}}>
                        {getTypeName(activity.event.type) === 'Visning' &&
                            <ExpandRowButton
                                isExpanded={mapExpandedRows.includes(activity.jfm_event.id)}
                                onClick={(e) => {
                                  const newMapExpandedRows = mapExpandedRows.includes(activity.jfm_event.id)
                                      ? mapExpandedRows.filter(id => id !== activity.jfm_event.id)
                                      : [...mapExpandedRows, activity.jfm_event.id];

                                  setMapExpandedRows(newMapExpandedRows);
                                }}
                            />
                        }
                      </TableCell>
                      <TableCell>
                        <div>
                          <Typography variant="caption">
                            {moment.unix(activity.jfm_event.event_start).format("D MMM")}
                          </Typography>
                          <br/>
                          <Typography variant="caption">
                            {`kl ${moment.unix(activity.jfm_event.event_start).format('HH:mm')} - ${moment.unix(activity.jfm_event.event_end).format('HH:mm')}`}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>{getTypeName(activity.event.type)}</TableCell>
                      <TableCell>
                        <div>
                          <Typography variant="caption">
                            {activity.asset.name || "-"}
                          </Typography>
                          <br/>
                          <Typography variant="caption">
                            {`kl ${moment.unix(activity.event.start_time).format('HH:mm')} - ${moment.unix(activity.event.end_time).format('HH:mm')}`}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell>{getTitle(activity)}</TableCell>
                      <TableCell>
                        {activity.jfm_event.event_type == "14" && activity.event.type === "synagogue" && Object.keys(activity.program).length > 0 ? activity.booking.organisation : "-"}
                      </TableCell>
                      <TableCell>
                        {activity.jfm_event.event_type == "14" && activity.event.type === "synagogue" ?
                            Object.keys(activity.program) <= 0 ?
                                `${activity.event.registered_users} (35)`
                                :
                                `${activity.booking.nr_visitors} (35)`
                            :
                            `${activity.event.registered_users} (${activity.event.max_ppl})`
                        }
                      </TableCell>
                      {/* <TableCell style={{textAlign: "center"}}>
                  {activity.jfm_event.event_type === "14" && activity.event.type === "synagogue" && Object.keys(activity.program).length > 0 ?
                    <Tooltip title={activity.booking.message}>
                      <MenuIcon/>
                    </Tooltip>
                    :
                    "-"
                  }
                </TableCell> */}
                      <TableCell onClick={e => handleGuideClick(e, activity)}
                                 style={userData.tour_guide != "1" ? {cursor: "default"} : {}}>
                        {activity.jfm_event.event_type == "14" && activity.event.type === "synagogue" ?
                            <GuideColumn activity={activity} noGuideTours={noGuideTours} tempGuides={tempGuides}
                                         guideName={activity.tour_guide.name}/>
                            :
                            "-"
                        }
                      </TableCell>
                      {(userData.role === "admin" || userData.role === "superadmin" || userData.role === "security") &&
                          <TableCell className={classes.infoCell}
                                     onClick={e => handleInfoClick(e, activity)}
                          >
                            <Tooltip title={translate('activities', 'info_tooltip')}>
                              <div className={classes.infoIconWrap}>
                                <InfoOutlinedIcon className={classes.infoIcon}/>
                              </div>
                            </Tooltip>
                          </TableCell>
                      }
                    </TableRow>
                    {mapExpandedRows && mapExpandedRows.includes(activity.jfm_event.id) && (
                        <TableRow>
                          <TableCell colSpan={1}>
                          </TableCell>
                          <TableCell className={classes.alignTop} colSpan={2}>
                            <div>
                              <Typography variant="body1">
                                {translate('activities-list', 'row_expanded_contact')}
                              </Typography>
                              <Typography variant="caption" >
                                {activity.jfm_event.alt_contact  || ""} <br/>
                                {activity.jfm_event.alt_email || ""} <br/>
                                {activity.jfm_event.alt_phone || ""}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell className={classes.alignTop} colSpan={2}>
                            <div>
                              <Typography variant="body1">
                                {translate('activities-list', 'row_expanded_organization')}
                              </Typography>
                              <Typography variant="caption" >
                                {activity.booking.organisation && (
                                    <>
                                      {activity.booking.organisation}
                                      {activity.booking.grade && (
                                          " (klass: " + activity.booking.grade + ")"
                                      )}
                                    </>
                                )}

                                {activity.booking.adress && (
                                    <>
                                      {activity.booking.organisation && <br />}
                                      {activity.booking.adress +
                                          (activity.booking.zip ? ", " + activity.booking.zip : "") +
                                          (activity.booking.city ? " " + activity.booking.city : "")}
                                    </>
                                )}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell className={classes.alignTop} colSpan={2}>
                            <div>
                              <Typography variant="body1">
                                {translate('activities-list', 'row_expanded_comment')}
                              </Typography>
                              <Typography variant="caption" >
                                {activity.booking.message || ""} <br/>
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell colSpan={2} style={{textAlign: 'center'}}>
                            <Link
                                onClick={() => history.push(`/activities/${activity.jfm_event.id}`)}
                                style={{ display: 'inline-flex', textAlign: 'center', cursor: 'pointer' }}
                            >
                              Gå till aktivitet
                              <ArrowForwardIcon></ArrowForwardIcon>
                            </Link>
                          </TableCell>
                        </TableRow>
                    )}
                  </React.Fragment>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                    /*colSpan={props.columns.length + (props.isNested ? 1 : 0)}*/
                    count={totalItems}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={translate('', 'generic_table_pagination_rows_per_page')}
                    labelDisplayedRows={({
                                           from,
                                           to,
                                           count
                                         }) => translate('', 'generic_table_pagination_totals').replace('##from##', from).replace('##to##', to).replace('##count##', count)}
                    SelectProps={{
                      inputProps: {'aria-label': 'rows per page'},
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <Dialog
            open={dialogOpen}
            TransitionComponent={Transition}
            keepMounted={false}
            onClose={handleClose}
            maxWidth='sm'
            fullWidth
        >
          <DialogTitle className={classes.dialogTitle}>
            {translate('activities', 'title_list_filters')}
            <small>{translate('activities', 'subtitle_list_filters')}</small>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <DatePicker fullWidth placeholder={translate('', 'placeholder_from')}
                            value={moment.unix(filter.startTime).format("YYYY-MM-DD")}
                            onChange={e => handleDialogFilterChange(e, "startTime")}/>
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker fullWidth placeholder={translate('', 'placeholder_to')}
                            value={filter.endTime !== 0 ? moment.unix(filter.endTime).format("YYYY-MM-DD") : undefined}
                            onChange={e => handleDialogFilterChange(e, "endTime")}/>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField fullWidth placeholder={translate('activities-list', 'place_holder_location_filter')}
                            value={filter.location}
                            onChange={e => handleDialogFilterChange(e, "location")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField fullWidth placeholder={translate('activities-list', 'place_holder_title_filter')}
                            value={filter.title}
                            onChange={e => handleDialogFilterChange(e, "title")}
                />
              </Grid>
              <Grid item xs={12}>
                <Select fullWidth
                        placeholder="Guide"
                        data={guides}
                        selected={filter.guideId}
                        onChange={(e) => {
                          handleDialogFilterChange(e, "guideId")
                        }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonJFM onClick={handleResetFilters} label={translate('', 'button_reset_filters')}/>
            <ButtonJFM onClick={handleClose} label={translate('', 'button_cancel')}/>
            <ButtonJFM onClick={handleSubmit} label={translate('', 'button_confirm')} fill/>
          </DialogActions>
        </Dialog>
      </>
  );
};

const useStyles = makeStyles(() => ({
  buttonsWrap: {
    textAlign: "right",
    marginTop: 10,
    marginBottom: 20
  },
  buttons: {
    fontSize: 12
  },
  outlinedButton: {
    borderWidth: 2,
    padding: "6px 9px",
    "&:hover": {
      borderWidth: 2,
      backgroundColor: "transparent"
    }
  },
  buttonsXsWrap: {
    marginTop: 5
  },
  checkboxesWrap: {
    textAlign: "right",
    marginBottom: 10
  },
  thirdCheckbox: {
    margin: "0px 15px"
  },
  fourthCheckbox: {
    marginRight: 15
  },
  checkboxesXsWrap: {
    marginTop: 10
  },
  checkboxGroup: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 5
  },
  secondCheckbox: {
    marginLeft: 15
  },
  tableContainer: {
    border: "1px solid #e0e0e0"
  },
  infoCell: {
    backgroundColor: "#8b8b8b",
    width: 35,
  },
  forcePointer: {
    cursor: "pointer"
  },
  infoIconWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  infoIcon: {
    fill: "white",
    width: 26,
    height: 26
  },
  dialogTitle: {
    paddingBottom: 5,
    fontSize: 18,
    lineHeight: '22px',
    '& small': {
      display: 'block',
      fontSize: 12,
      lineHeight: '14px',
      color: ColorSchemes.highDarkGrey
    }
  },
  alignTop: {
    verticalAlign: 'top'
  },
  smallCell: {
    padding: "0px 0px 0px 4px",
    textAlign: 'center'
  },
  bookingGuideSpan: {
    borderRadius: 4,
    border: '1px solid #ff9000',
    color: '#ffffff',
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    cursor: 'pointer',
    userSelect: 'none',
    backgroundColor: '#ff9000',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: '#ffb248',
    },
    padding: '5px',
  }
}))

export default ActivitiesListTable;
