import React from 'react';
import theme from './style';
import ProfileHeader from '../ProfileHeader';
import {useHistory} from 'react-router-dom';

const ProfileView = (props) => {
    const history = useHistory();

    const {
        item,
        width,
        path,
        expanded,
        handleExpanded,
        onClick
    } = props;

    const classes = theme({width: width});

    const {
        id,
        ssn,
        name,
        mail,
        role,
        active
    } = item;

    return (
        <div className={`${classes.listViewWrap} block`}>
            <ProfileHeader
                id={id}
                ssn={ssn}
                name={name}
                mail={mail}
                role={role}
                active={active === 1}
                path={path}
                expanded={expanded}
                handleExpanded={handleExpanded}
                onClick={onClick}/>
        </div>

    );
}
export default ProfileView;