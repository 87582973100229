import React, {memo, useContext} from 'react';
import theme from './style';
import {Grid,} from '@material-ui/core';
import Button from '../../components/Button';
import {ListItem, SmallTitle} from '../../components/DataDisplay';
import translate from '../../functions/applyTranslation';
import clsx from 'clsx';
import {BlogContext, Provider} from "./blogContext";
import {Fragment} from 'react';
import {momentLocalizer} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import BlogRules from '../blog-rules';
import usePermissionsHook from '../../functions/permissions';
import useSettingsHook from '../../functions/settings';
import DeleteModal from '../delete-modal';
import {useSelector} from "react-redux";

const BlogList = (props) => {
  const localizer = momentLocalizer(moment)
  const classes = theme();

  const [permissions, roles, hasPermission] = usePermissionsHook();
  const [role, hasSetting] = useSettingsHook();
  const {userData} = useSelector(state => state.auth);

  const blogContext = useContext(BlogContext);
  const {
    filters,
    blog,
    expanded,
    handleExpanded,
    handlePagination,
    handleNavigation,
    modal,
    closeModal,
    openRules,
    openDelete,
    deleteBlog,
    acceptedBlogRules,
    handleAcceptBlogRules
  } = blogContext;

  const customeEventItem = date => {
    return {
      style: {
        backgroundColor: date.color,
        border: date.color,
      },
    }
  }

  const handleDelete = () => {
    deleteBlog(modal.blog_id);
  }

  const parseBlogContent = (item) => {
    let result = item;
    // if (item.blog.content.startsWith(" ")) {
    //  result.blog.content = item.blog.content.replace(" ", "");
    // }
    result.blog.content = item.blog.content.trim();
    return result;
  }

  return (
    <>
      <DeleteModal title={translate('blog', 'label_delete_blog_title')}
                   subTitle={translate('blog', 'label_delete_blog_subtitle')} modal={modal ? modal.key : null}
                   closeModal={closeModal} callBack={handleDelete}/>
      <BlogRules modal={modal ? modal.key : null} closeModal={closeModal} acceptedBlogRules={acceptedBlogRules}
                 acceptBlogRules={handleAcceptBlogRules}/>
      <div className={classes.pageWrap}>
        <div className={classes.headerWrap}>
          <div className={classes.titleWrap}>
            <SmallTitle className={classes.title} text={translate('blog', 'title_blog_list')}/>
            {hasPermission(permissions.BLOG_CREATE) && !hasSetting('blogpost_create_not_allowed') &&
            <div className={classes.buttonGroup}>
              <Button label={translate('blog', 'button_new')} fill icon='edit'
                      onClick={() => handleNavigation({path: 'blog', action: 'new'})}/>
            </div>
            }
          </div>
          <Button onClick={openRules} label={translate('blog', 'button_read_terms')}/>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.newsList}>
              <Fragment>
                <Grid container className={classes.newsWrap}>
                  {blog.data.map(item =>
                    <Grid key={`blog_${item.blog.id}`} item xs={12} className='item'>
                      <ListItem viewType={'blog'} path='blog' handleDelete={openDelete} item={parseBlogContent(item)}
                                onClick={handleNavigation} expanded={expanded} handleExpanded={handleExpanded}
                                readOnly={role === "memberplus" && Number(item.blog.author_id) !== Number(userData.id)}/>
                    </Grid>
                  )}
                </Grid>
                <div className={clsx(classes.flexEnd, classes.paginatorLabel)}>
                  {translate('blog-list', 'label_page_counter').replace('##page##', filters.page).replace('##total##', blog.settings.totalPages)}
                </div>
                <div className={
                  filters.page !== 1 && filters.page !== blog.settings.totalPages ? classes.spaceBetween :
                    filters.page === 1 ? classes.flexEnd : classes.flexStart
                }>
                  {filters.page !== 1 &&
                  <Button label={translate('blog-list', 'button_page_back')} fill
                          onClick={(e) => handlePagination('back')}/>
                  }
                  {!!blog.settings.totalPages && filters.page !== blog.settings.totalPages &&
                  <Button label={translate('blog-list', 'button_page_next')} fill
                          onClick={(e) => handlePagination('next')}/>
                  }
                </div>
              </Fragment>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

const ApplyProvider = (props) => {
  return (<Provider>
    <BlogList {...props} />
  </Provider>)
}
export default memo(ApplyProvider);
