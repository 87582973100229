import React from 'react';
import { Grid } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import theme from './style';
import clsx from 'clsx';
const GalleryListItem = (props) => {
  const classes = theme();
  const {
    id,
    selected,
    url,
    filename,
    date,
    category,
    onSelect,
  } = props;
  const handleSelect = () => {
    if (onSelect) {
      onSelect(id)
    }
  }
 // const imgURL = `${window.location.origin}${url}`
  return (
    <div className={clsx(
      classes.itemWrapp,
      selected ? classes.selected : '')}
      onClick={handleSelect}>
      <div className={classes.staticWrap}>
        {selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
        <img src={url} alt={''} className={'image'} />
      </div>
      <Grid container className={classes.textWrap}>
        <Grid item xs={12} sm={4} className={classes.item}>{filename}</Grid>
        <Grid item xs={12} sm={4} className={classes.item}>{date}</Grid>
        <Grid item xs={12} sm={4} className={classes.item}>{category}</Grid>
      </Grid>
    </div>
  )

}

export default GalleryListItem;