import React, { useState, useEffect } from 'react';
import theme from './style';
import translate from '../../../functions/applyTranslation';
import { BigTitle } from '../../../components/DataDisplay';
import { Grid } from '@material-ui/core';

const Resume = (props) => {
  const classes = theme();
  const { className, checkedIn, notCheckedIn, checkedOut, totalBooked } = props;

  return (

    <Grid container className={classes.resume}>
      <Grid item xs={6} sm={3}>
        <Grid container>
          <Grid item xs={12} className={classes.label}>
            {translate('security', 'label_checked_in')}
          </Grid>
          <Grid item xs={12} className={classes.titleWrap}>
            <BigTitle text={checkedIn} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Grid container>
          <Grid item xs={12} className={classes.label}>
            {translate('security', 'label_not_checked_in')}
          </Grid>
          <Grid item xs={12} className={classes.titleWrap}>
            <BigTitle text={notCheckedIn} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Grid container>
          <Grid item xs={12} className={classes.label}>
            {translate('security', 'label_checked_out')}
          </Grid>
          <Grid item xs={12} className={classes.titleWrap}>
            <BigTitle text={checkedOut} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Grid container>
          <Grid item xs={12} className={classes.label}>
            {translate('security', 'label_total_booked')}
          </Grid>
          <Grid item xs={12} className={classes.titleWrap}>
            <BigTitle text={totalBooked} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default Resume;