import React, {Fragment, useEffect,} from 'react';
import theme from './style';
import {Grid, Typography, withWidth} from '@material-ui/core';
import Button from '../../components/Button';
import {Breadcrumbs, TreeView, BigTitle} from '../../components/DataDisplay';
import translations, {tryParseToHtml} from '../../functions/applyTranslation';
import useInformationHook from './hook';
import clsx from 'clsx';
import {useLocation} from "react-router-dom"
import AnchorScroll from "../../routes/AnchorScroll";

import usePermissionsHook from '../../functions/permissions';
import {makeStyles} from "@material-ui/core/styles";
import displayForMembers from "../../functions/informationMemberPermission";
import {useSelector} from "react-redux";
import translate from "../../functions/applyTranslation";

const HomeView = (props) => {
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const {width,} = props;
  let responsiveTest = (width === 'xs' || width === 'sm');
  const classes = theme({mobile: responsiveTest});
  const [menu, selected, handleSelect, breadcrumbs, handleBreadCrumbsSelect, data, handleNavigation] = useInformationHook(props);
  const styles = useStyles();
  let location = useLocation();
  const userData = useSelector(state => state.auth.userData);
  const access_token = useSelector(state => state.auth.access_token);

  return (
    <AnchorScroll location={location} data={data}>
      <Grid container>
        <Grid item xs={12} sm={3} className={classes.treeviewWrap}>
          {hasPermission(permissions.PAGES_EDIT) &&
          <div className={classes.headerWrap}>
            <Button label={translations('information', 'button_edit_menu_order')} icon='edit' onClick={() => {
              handleNavigation('menu-order')
            }}/>
          </div>
          }
          <TreeView items={menu} handleSelect={handleSelect} selected={selected}
                    hasPermission={hasPermission(permissions.PAGES_EDIT)}/>
        </Grid>
        <Grid item xs={12} sm={9} className={styles.contentGrid}>
          {(hasPermission(permissions.PAGES_CREATE) || hasPermission(permissions.PAGES_EDIT)) &&
          <div className={clsx(classes.headerWrap, classes.buttonGroup)}>
            {hasPermission(permissions.PAGES_EDIT) &&
            <Button label={translations('information', 'button_edit_page')} fill icon='edit' onClick={() => {
              handleNavigation('edit')
            }}/>
            }
            {hasPermission(permissions.PAGES_CREATE) &&
            <>
              <Button label={translations('information', 'button_new_subpage')} fill icon='add' onClick={() => {
                handleNavigation('subpage')
              }}/>
              <Button label={translations('information', 'button_new_page')} fill icon='add' onClick={() => {
                handleNavigation('new')
              }}/>
            </>
            }
          </div>
          }
          {data && userData && displayForMembers(userData.role, data) &&
          <div className={classes.contentWrap}>
            <Breadcrumbs items={breadcrumbs} onClick={handleBreadCrumbsSelect}/>
            <Fragment>
              <BigTitle text={data.page.title} className={styles.bigTitle}/>
              <div className={classes.htmlContent}>
                {tryParseToHtml(data.page.content, access_token)}
              </div>
            </Fragment>
          </div>}
          {data && !displayForMembers(userData.role, data) &&
            <Grid container justify="center" alignContent="center" className={styles.noAccessContainer}>
              <Grid item xs={12} style={{textAlign: "center"}}>
                <Typography variant="h5">{translate('information', 'access_denied_to_member_role')}</Typography>
              </Grid>
            </Grid>
          }
        </Grid>
      </Grid>
    </AnchorScroll>
  );
}

const useStyles = makeStyles(theme => ({
  bigTitle: {
    marginBottom: 10,
  },
  noAccessContainer: {
    height: "100%"
  },
  contentGrid: {
    paddingLeft: 25
  }

}));
export default withWidth()(HomeView);