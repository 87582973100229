import React from 'react';
import theme from './style';
import { SmallTitle, Accordion, Text } from '../../components/DataDisplay';
import translate from '../../functions/applyTranslation';
import moment from 'moment';

const PublishInfo = (props) => {
    const classes = theme();
    const {
        area,
        createdDate,
        createdBy,
        managedBy,
        publishedBy,
        unpublishedBy
    } = props;
    return (
      <Accordion info title={
          <SmallTitle className={classes.title} text={translate(area, 'label_information')} />
      }>
          <div>
              <Text className={classes.infoDetail} text={translate(area, 'information_label_creation_date').replace('##date##', createdDate ? moment.unix(createdDate).format('D MMMM YYYY HH:mm') : '-')} />
              <Text className={classes.infoDetail} text={translate(area, 'information_label_by').replace('##name##', createdBy ? createdBy : '-')} />
              <Text className={classes.infoDetail} text={translate(area, 'information_label_managed_by').replace('##name##', managedBy ? managedBy :'-')} />
              <Text className={classes.infoDetail} text={translate(area, 'information_label_published_by').replace('##name##', publishedBy ? publishedBy :'-')} />
              <Text className={classes.infoDetail} text={translate(area, 'information_label_unpublished_by').replace('##name##', unpublishedBy ? unpublishedBy :'-')} />
          </div>
      </Accordion>

    );
}
export default PublishInfo;