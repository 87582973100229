import React, {Fragment, memo,} from 'react';
import theme from './style';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Paper, CircularProgress} from '@material-ui/core';
import {Helmet} from "react-helmet";
import Button from '../../components/Button';
import {BigTitle, SmallTitle, Text} from '../../components/DataDisplay';
import {Label, InputField} from '../../components/Inputs';
import translate from '../../functions/applyTranslation';
import useAuth from './hook';

const AuthBankId = (props) => {
  const [data, initialize, handleNavigation, handleApplyAccount, handleSubmit, handleChange, handleCancel, invalidList, loading, checkStatus, startHostedGrandId] = useAuth(props);
  const classes = theme();
  return (
    <Fragment>
      <Helmet>
        <meta http-equiv='cache-control' content='no-store'/>
        <meta http-equiv='expires' content='0'/>
        <meta http-equiv='pragma' content='no-cache'/>
      </Helmet>
      <Paper elevation={4} className={classes.formWrap}>
        {!initialize &&
        <form onSubmit={startHostedGrandId}>
          <div className={classes.titleWrap}>
            <BigTitle text={translate('bankid_auth', 'title_bankId_auth')}/>
          </div>
          {/*<Label text={translate('bankid_auth', 'placeholder_personal_number')}>
            <InputField fullWidth name="ssn" value={data.ssn} onChange={handleChange} id='ssn'
                        invalid={invalidList.includes('ssn')}/>
          </Label>*/}
          <div className={classes.loginButtonWrap}>
            <Button disabled={loading} className={classes.loginButton} fill label={translate('login', 'button_login_hosted_bank_id')} type='submit'/>
          </div>
        </form>
        }
        {initialize &&
        <Fragment>
          <div className={classes.titleWrap}>
            <BigTitle text={translate('bankid_auth', 'title_bankId_auth')}/>
          </div>
          <div style={{textAlign: 'center'}}>
            <CircularProgress style={{width: 20, height: 20}}/>
            <Text text={translate('bankid_auth', 'label_check_phone')}/>
          </div>
          <div className={classes.loginButtonWrap}>
            <Button fill label={translate('', 'button_cancel')} onClick={handleCancel}/>
          </div>
        </Fragment>
        }
      </Paper>
      <Paper elevation={4} className={classes.formWrap}>
        <Button label={translate('bankid_auth', 'label_change_login_method_password')} onClick={handleNavigation}/>
      </Paper>
      {!initialize &&
      <Fragment>
        <Paper elevation={4} className={classes.formWrap}>
          <SmallTitle className={classes.paddingFix} text={translate('bankid_auth', 'label_help_sign_bankid_title')}/>
          <Text className={classes.help} text={translate('bankid_auth', 'label_help_sign_bankid_step1')}/>
          <Text className={classes.help} text={translate('bankid_auth', 'label_help_sign_bankid_step2')}/>
          <Text className={classes.help} text={translate('bankid_auth', 'label_help_sign_bankid_step3')}/>
        </Paper>
        {/* <Paper elevation={4} className={classes.formWrap}>
            <SmallTitle text={translate('bankid_auth', 'label_help_problems_sign_bankid_title')} />
            <Text text={translate('bankid_auth', 'label_help_problems_sign_bankid_detail')} />
            <Button label={translate('bankid_auth', 'label_help_problems_sign_bankid_list')} onClick={() => { alert('todo') }} />
          </Paper> */}
        <Paper elevation={4} className={classes.alternativeWrap}>
          <SmallTitle text={translate('login', 'label_no_account')}/>
          <Button label={translate('login', 'label_apply_account')} onClick={handleApplyAccount}/>
        </Paper>
      </Fragment>
      }

    </Fragment>
  );
}
const mapStateToProps = store => ({});
const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(memo(AuthBankId));