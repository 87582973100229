import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    checkGroup: {
      display: 'inline-flex',
      height: 22,
      alignItems: 'center',
      cursor: 'pointer',
      '& .checkIcon': {
        fill: ColorSchemes.mediumBlue,
        fontSize: 22,
        height: 22,
      },
      '&:hover':{
        '& .checkIcon': {
          fill: `${ColorSchemes.orange} !important`,
        }
      }
    },
    invalid: {
      '& .checkIcon': {
        fill: ColorSchemes.darkOrange,
        fontSize: 22,
        height: 22,
      },
    },
    disabled: {
      '& .checkIcon': {
        fill: ColorSchemes.highDarkGrey,

      },
      '&:hover':{
        '& .checkIcon': {
          fill: `${ColorSchemes.highDarkGrey} !important`,
        }
      }
    },
    text: {
      color: ColorSchemes.darkGrey,
      fontSize: 13,
      marginLeft: 5,
    },
  }));
  return styles();
}
export default theme;
