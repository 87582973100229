import React, { useState, useEffect, useContext } from "react";
import { Grid, LinearProgress, Button, ButtonGroup } from "@material-ui/core";
import moment from 'moment';
import Table from '../Table/index';
import { FilterList, Add, } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles';
import translate from '../../functions/applyTranslation';
import useTranslationHook from '../../hooks/translations';
// import Dialog from '../../components/SeachDialog'
// import SearchChips from '../../components/SearchChips'

const useStyles = makeStyles(theme => ({
  pageHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  searchIcon: {
    height: 24,
    width: 24,
    cursor: 'pointer',
  }
}));

const TranslationsTable = props => {
  // const { loading, setLoading } = useContext(AuthContext);
  //const { getTranslation, getTranslationList, lang, locListFilters, setLocListFilters } = useContext(LocalizationContext);
  moment.locale('sv');
  const classes = useStyles();
  //const [count, setCount] = useState(0);
  const [page, setPage] = useState(0); // TODO: locListFilters.page || 0);
  const [rowsPerPage, setRowsPerPage] = useState(20);// TODO: locListFilters.rowsPerPage || 20);
  const [sort, setSort] = useState({ column: 'name', by: 'asc' })// TODO: locListFilters.sort || { column: 'name', by: 'asc' })
  const [searchState, setSearchState] = useState(false)
  const [filter, setFilter] = useState({})// TODO: locListFilters.filter || {})
  //const [data, setData] = useState([]);


  const [data, count, loading, fetchData] = useTranslationHook();

  const handleChangePage = (event, newPage) => {
    //TODO:
    //setLocListFilters({ ...locListFilters, page: newPage })
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    //TODO:
    //setLocListFilters({ ...locListFilters, page: 0, rowsPerPage: parseInt(event.target.value, 10) })
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSortChange = (column, order) => {
    //TODO:
    //setLocListFilters({ ...locListFilters, sort: { column: column, by: order } })
    setSort({ column: column, by: order })
  }
  const columns = [
    //Referal Id
    {
      name: translate('translations', 'list_table_header_key'),
      functionalName: 'name',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: 'left',
    },
    //Org Id
    {
      name: translate('translations', 'list_table_header_value'),
      functionalName: 'translation',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      sortable: true,
      visible: true,
      align: 'left',
    },
    //system
    {
      name: translate('translations', 'list_table_header_system'),
      functionalName: 'system',
      type: 'text',
      filter: true,
      filterOptions: {
        col: 6,
      },
      style: { width: 150 },
      sortable: true,
      visible: true,
      align: 'left',
    },
    //Test Time
    {
      name: translate('translations', 'list_table_header_lang'),
      functionalName: 'code',
      type: 'select',
      list: [{ id: 'en_US', text: translate('', 'generic_english_string') }, { id: 'sv_SE', text: translate('', 'generic_sweedish_string') }],
      multi: true,
      filter: true,
      filterOptions: {
        col: 6,
      },
      style: { width: 100 },
      sortable: true,
      visible: true,
      align: 'left',
    },
  ]
  const generetaQueryString = () => {
    return '?language=en-US';
    let queryString = `?sort=${sort.column} ${sort.by}`;
    queryString += `&limit=${rowsPerPage}&offset=${rowsPerPage * page}`
    Object.keys(filter).forEach(f => {
      queryString += `&filter[${f}]=${filter[f]}`
    })
    return queryString;
  }
  // const fetchData = () => {
  //   //TODO
  //   //setLoading(true);
  //   // getTranslationList(generetaQueryString()).then(res => {
  //   //   if (res) {
  //   //     if (res.count) {
  //   //       setCount(Number(res.count) || 0);
  //   //     }
  //   //     setData(res.data.map(data => {
  //   //       if (data['id']) {
  //   //         data['sys_id'] = data['id'];
  //   //         delete data['id'];
  //   //       }
  //   //       return { ...data, code: data.code === 'en_US' ? translate('','generic_english_string') : translate('','generic_sweedish_string') };

  //   //     }))
  //   //   }
  //   //   //TODO:
  //   //   //setLoading(false)
  //   // }).catch(() =>  {
  //   //     //TODO:setLoading(false)
  //   // }    
  //   //)
  // }
  const clearFilter = () => {
    setFilter({});
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchData(generetaQueryString()), [filter, sort, page, rowsPerPage])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (page > 0) { setPage(0) };
    //setLocListFilters({ ...locListFilters, filter: filter }) 
  }, [filter])
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ButtonGroup size="small" color="primary" className={classes.pageHeader}>
          <Button onClick={clearFilter} > {translate('','generic_clear_filter')} </Button>
          <Button startIcon={<FilterList />} onClick={() => setSearchState(true)} > {translate('', 'generic_button_filter')} </Button>
          <Button startIcon={<Add />} onClick={() => props.history.push('translations/new')} > {translate('', 'generic_button_add')} </Button>
        </ButtonGroup>
        {/* {Object.keys(filter).length > 0 &&
          <SearchChips
            filter={filter}
            setFilter={setFilter}
            columns={columns} />
        } */}
        {/* {loading && <LinearProgress />} */}
        <Table
          rows={data}
          page={page}
          count={count}
          columns={columns}
          handleChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSortChange={handleSortChange}
          sort={sort}
          onLineClick={'/translations/'}
        />
        {/* <Dialog
          filter={filter}
          setFilter={setFilter}
          open={searchState}
          onClose={setSearchState}
          columns={columns}
        /> */}
      </Grid>
    </Grid>
  );
};

export default TranslationsTable;