import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Send';
import CheckIcon from '@material-ui/icons/Check';
const Icon = (props) => {
  const { icon } = props;
  switch (icon) {
    case 'back':
      return <ArrowBackIosIcon />
    case 'add':
      return <AddIcon />
    case 'save':
      return <SaveIcon />
    case 'edit':
      return <EditIcon />
    case 'delete':
      return <DeleteIcon />
    case 'upload':
      return <CloudUploadIcon />
    case 'print':
      return <PrintIcon />
    case 'email':
      return <EmailIcon />
    case 'check':
      return <CheckIcon />
    default:
      return false;
  }
}

export default Icon;