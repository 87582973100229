import React, { Fragment, useContext } from 'react';
import theme from './style';
import { Grid, Typography } from '@material-ui/core';
import Button from '../../components/Button';
import { SmallTitle, Text } from '../../components/DataDisplay';
import { InputField } from '../../components/Inputs';
import EventHeader from '../../components/EventHeader';
import translate from '../../functions/applyTranslation';
import canByNewTickets from '../../functions/canByNewTickets';
import { ActivitiesContext, Provider } from "./actitiesContext";
import SoldTicketsTable from './SoldTicketsTable';
import { Label, Check } from '../../components/Inputs'
import CartItems from './CartItems';
import Comments from './comments';
import AddComment from './comments/modal';
import ToTopWrap from '../../components/ToTopWrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


const ActivitiesCart = (props) => {
  const history = useHistory();
  const classes = theme();
  const activitiesContext = useContext(ActivitiesContext);
  const { data, ticketsSold, ticketsNonMember, setTicketsNonMember, ticketsMember,
    setTicketsMember, ticketUsersMember, ticketUsersNonMember, handleChangeMember,
    handleChangeNonMember, comments, setComments, agreementTC, setAgreementTC, handleCheckOut,
    handleBack, childrenStartAt, childrenEndAt, error, modal, openModal, closeModal
  } = activitiesContext;
  const userData = useSelector(state => state.auth.userData);
  if (!!data) {
    const {
      event,
      jfm_event,
      asset,
      products,
    } = data;

    if (!canByNewTickets(event, userData.role))
      history.push(`/activities/${jfm_event.id}`)

    const {
      title,
    } = event;
    const {
      event_start
    } = jfm_event;
    const {
      name,
      description
    } = asset;

    let memberTickets = products ? products.filter(item => item.pay === '1') : null;
    let nonMemberTickets = products ? products.filter(item => item.pay === '0') : null;

    let ammount = 0;
    const calculateAvailability = () => {
      //0 = Full, 1 = Low Availability, 2 = Big Availability;
      const maxPpl = Number(event.max_ppl);
      const registredUsers = Number(event.registered_users);
      const avaibality = maxPpl - registredUsers;
      if (avaibality > 0) {
        if (registredUsers / maxPpl > 0.79) {
          return 1;
        } else {
          return 2;
        }
      } else {
        return 0;
      }
    }
    const availability = calculateAvailability();
    const currency = translate('', 'label_currency_name');
    return (
      <Fragment>
        <AddComment modal={modal} closeModal={closeModal} />
        <div className={classes.pageWrap}>
          <div className={classes.buttonWrap}>
            <Button fill label={translate('activities-cart', 'button_cancel')} onClick={handleBack} />
          </div>
          <EventHeader className={classes.borderBottom} simple={true}
                       title={title}
                       date_time={event_start}
                       location={{ name: name || '', description: description || '' }}
          />
          {ticketsSold && ticketsSold.length > 0 &&
          <Fragment>
            <div className={classes.subHeaderWrap}>
              <SmallTitle text={translate('activities-cart', 'label_participant_registed_title')} />
              <Text text={translate('activities-cart', 'label_participant_registed_info')} />
            </div>
            <div className={classes.soldTicketsWrap}>
              <SoldTicketsTable data={ticketsSold} />
            </div>
          </Fragment>
          }
          <Grid container>
            <Grid item xs={12} sm={7} className={classes.productsWrap}>
              <div className='singleline'>
                <SmallTitle text={translate('activities-cart', 'label_products_title')} />
                <div className={classes.availabilityWrap}>
                  <div className={`circle ${availability === 1 ? 'lowAvailable' : availability === 2 ? 'available' : 'noAvailable'}`} />
                  {availability === 0 && translate('activities-cart', 'label_no_availability')}
                  {availability === 1 && translate('activities-cart', 'label_low_availability')}
                  {availability === 2 && translate('activities-cart', 'label_high_availability')}
                </div>
              </div>
              {userData.role !== 'member' && ticketsMember &&
              <Label text={translate('activities-cart', 'label_members_title')}>
                <CartItems members={ticketsMember} data={memberTickets} childrenStartAt={childrenStartAt} childrenEndAt={childrenEndAt} onChange={(e) => setTicketsMember(e)} />
              </Label>
              }
              {ticketsNonMember &&
              <Label text={translate('activities-cart', 'label_non_members_title')}>
                <CartItems members={ticketsNonMember} data={nonMemberTickets} childrenStartAt={childrenStartAt} childrenEndAt={childrenEndAt} onChange={(e) => setTicketsNonMember(e)} />
              </Label>
              }
              <Label text={translate('activities-cart', 'label_participant_data_title')}>
                <Text text={translate('activities-cart', 'label_participant_data_info')} />
              </Label>
            </Grid>
            <Grid item xs={12} sm={5} className={classes.resumeWrap}>
              <div className={classes.resumeWrap}>
                <div className={classes.cartWrap}>
                  <h4>{translate('activities-cart', 'label_to_pay')} </h4>
                  <p>{translate('activities-cart', 'label_payment_details')}</p>
                  {ticketsMember && Object.keys(ticketsMember).map(item => {
                    let _item = ticketsMember[item];
                    let product = products.find(item => item.id === _item.id);
                    if (_item.quantity > 0) {
                      ammount += (Number(product.amount)) * _item.quantity;
                      return <div key={_item.id} className={'row'}>
                        <p>{`${_item.quantity} x ${product.name} (${translate('activities-cart', 'label_member')})`}</p>
                        <p>{`${Number.parseFloat(Number(product.amount) * _item.quantity).toFixed(0)} ${currency}`}</p>
                      </div>
                    }
                  })}
                  {ticketsNonMember && Object.keys(ticketsNonMember).map(item => {
                    let _item = ticketsNonMember[item];
                    let product = products.find(item => item.id === _item.id);
                    if (_item.quantity > 0) {
                      ammount += (Number(product.amount)) * _item.quantity;
                      return <div key={_item.id} className={'row'}>
                        <p>{`${_item.quantity} x ${product.name} (${translate('activities-cart', 'label_non_member')})`}</p>
                        <p>{`${Number.parseFloat(Number(product.amount) * _item.quantity).toFixed(0)} ${currency}`}</p>
                      </div>
                    }
                  })}
                  <div className={'row'}>
                    <b>{translate('activities-cart', 'label_total')} </b>
                    <b>{`${Number.parseFloat(ammount).toFixed(0)} ${currency}`}</b>
                  </div>
                </div>
                <div className={classes.triangleWrap}>
                  <div className={classes.triangle} />
                </div>
              </div>
            </Grid>

            <Grid item xs={12} className={classes.productsWrap}>
              <Grid container className={classes.usersTicketWrap}>
                {ticketUsersMember.adult.map((item, index) => {
                  return <Fragment>
                    <Grid item xs={10} sm={7}>
                      <InputField value={item.name} placeholder={`${translate('activities-cart', 'label_full_name_for')} ${item.productName} (${translate('activities-cart', 'label_member')})`} fullWidth
                                  onChange={(e) => { handleChangeMember('adult', index, e, 'name') }} />
                    </Grid>
                    <Grid item xs={2} sm={5} className={classes.commentsAdd}>
                      <Comments comments={item.comments} handle={handleChangeMember} type='adult' index={index} openModal={openModal} />
                    </Grid>
                  </Fragment>
                })}
                {ticketUsersMember.child.map((item, index) => {
                  return <Fragment>
                    <Grid item xs={10} sm={7}>
                      <InputField value={item.name} placeholder={`${translate('activities-cart', 'label_full_name_for')} ${item.productName} (${translate('activities-cart', 'label_member')})`} fullWidth
                                  onChange={(e) => { handleChangeMember('child', index, e, 'name') }} />
                    </Grid>
                    <Grid item xs={2} sm={5} className={classes.commentsAdd}>
                      <Comments comments={item.comments} handle={handleChangeMember} type='child' index={index} openModal={openModal} />
                    </Grid>
                  </Fragment>
                })}
                {ticketUsersMember.group.map((item, index) => {
                  return <Fragment>
                    <Grid item xs={10} sm={7}>
                      <InputField value={item.name} placeholder={`${translate('activities-cart', 'label_full_name_for')} ${item.productName} (${translate('activities-cart', 'label_member')})`} fullWidth
                                  onChange={(e) => { handleChangeMember('group', index, e, 'name') }} />
                    </Grid>
                    <Grid item xs={2} sm={5} className={classes.commentsAdd}>
                      <Comments comments={item.comments} handle={handleChangeMember} type='group' index={index} openModal={openModal} />
                    </Grid>
                  </Fragment>
                })}

                {ticketUsersNonMember.adult.map((item, index) => {
                  return <Fragment>
                    <Grid item xs={10} sm={7}>
                      <Grid container>
                        <Grid item xs={9}>
                          <InputField value={item.name} placeholder={`${translate('activities-cart', 'label_full_name_for')} ${item.productName} (${translate('activities-cart', 'label_non_member')})`} fullWidth
                                      onChange={(e) => { handleChangeNonMember('adult', index, e, 'name') }} />
                        </Grid>
                        <Grid item xs={3}>
                          <InputField value={item.ssn} placeholder={translate('activities-cart', 'label_ssn')} fullWidth
                                      onChange={(e) => { handleChangeNonMember('adult', index, e, 'ssn') }} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} sm={5} className={classes.commentsAdd}>
                      <Comments comments={item.comments} handle={handleChangeNonMember} type='adult' index={index} openModal={openModal} />
                    </Grid>
                  </Fragment>
                })}
                {ticketUsersNonMember.child.map((item, index) => {
                  return <Fragment>
                    <Grid item xs={10} sm={7}>
                      <Grid container>
                        <Grid item xs={9}>
                          <InputField value={item.name} placeholder={`${translate('activities-cart', 'label_full_name_for')} ${item.productName} (${translate('activities-cart', 'label_non_member')})`} fullWidth
                                      onChange={(e) => { handleChangeNonMember('child', index, e, 'name') }} />
                        </Grid>
                        <Grid item xs={3}>
                          <InputField value={item.ssn} placeholder={translate('activities-cart', 'label_ssn')} fullWidth
                                      onChange={(e) => { handleChangeNonMember('child', index, e, 'ssn') }} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} sm={5} className={classes.commentsAdd}>
                      <Comments comments={item.comments} handle={handleChangeNonMember} type='child' index={index} openModal={openModal} />
                    </Grid>
                  </Fragment>
                })}
                {ticketUsersNonMember.group.map((item, index) => {
                  return <Fragment>
                    <Grid item xs={10} sm={7}>
                      <Grid container>
                        <Grid item xs={9}>
                          <InputField value={item.name} placeholder={`${translate('activities-cart', 'label_full_name_for')} ${item.productName} (${translate('activities-cart', 'label_non_member')})`} fullWidth
                                      onChange={(e) => { handleChangeNonMember('group', index, e, 'name') }} />
                        </Grid>
                        <Grid item xs={3}>
                          <InputField value={item.ssn} placeholder={translate('activities-cart', 'label_ssn')} fullWidth
                                      onChange={(e) => { handleChangeNonMember('group', index, e, 'ssn') }} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} sm={5} className={classes.commentsAdd}>
                      <Comments comments={item.comments} handle={handleChangeNonMember} type='group' index={index} openModal={openModal} />
                    </Grid>
                  </Fragment>
                })}
              </Grid>
              <Grid item xs={10} sm={7} className={classes.addToCart}>
                <div className={classes.alignFlexEnd}>
                  <Check value={agreementTC} className={classes.checkText} position='right' onChange={() => setAgreementTC(!agreementTC)} label={translate('activities-cart', 'label_accept_conditions')} />
                </div>
                <div className={classes.alignFlexEnd}>
                  <Typography className={classes.checkUnderText}>{`${translate('activities-cart', 'label_receipt_sent_to')} ${userData.name ? userData.name : ''}`}</Typography>
                </div>
                <div className={classes.alignFlexEnd}>
                  <Button color='green' fill className={classes.goToPayment} onClick={handleCheckOut} label={translate('activities-cart', 'button_go_to_payment')} />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              {error &&
              <div className={classes.error}>
                <Label className={classes.whiteText} text={translate('activities-cart', 'label_error_title')}>
                  <Typography className={classes.whiteText}>{translate('activities-cart', 'label_error_text')}</Typography>
                </Label>
              </div>
              }
              <div className={classes.disclaimer}>
                <Label className={classes.whiteText} text={translate('activities-cart', 'label_warning_title')}>
                  <Typography className={classes.whiteText}>{translate('activities-cart', 'label_warning_text')}</Typography>
                </Label>
              </div>
            </Grid>
          </Grid>
          {/* {!step ? <Cart /> : <CheckOut />} */}
          {/* <CheckOut /> */}
          <ToTopWrap />
        </div>
      </Fragment>
    );

  } else {
    return null;
  }
}

const ApplyProvider = (props) => {
  return (<Provider>
    <ActivitiesCart {...props} />
  </Provider>)
}
export default ApplyProvider;