import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    text: {
      fontSize: 13,
      color: ColorSchemes.highDarkGrey,
      width: '100%'
    },
  }));
  return styles();
}
export default theme;
