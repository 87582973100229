import React from 'react';
import { Grid } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import theme from './style';
import clsx from 'clsx';
const SearchResultListItem = (props) => {
  const classes = theme();
  const {
    id,
    title,
    area,
    areaName,
    handleNavigation
  } = props;
  const handleClick = () => {
    if (handleNavigation)
      handleNavigation(area, id);
  }
  return (
    <div className={clsx(
      classes.itemWrapp)}
      onClick={handleClick}>
      <Grid container className={classes.textWrap}>
        <Grid item xs={12} className={classes.item}>{title}<small>{areaName}</small></Grid>
      </Grid>
    </div>
  )

}

export default SearchResultListItem;