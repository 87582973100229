import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  setLoading
} from '../../redux/states/statesActions';
import {
  setLogin,
  setUserData
} from '../../redux/auth/authActions';
import useAPI from '../../api/apiHandler';
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
const AuthPasswordHook = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.states.loading);
  const [invalidList, setInvalidList] = useState([]);
  const [setRequest] = useAPI();
  const [data, setData] = useState({
    ssn: '',
    password: '',
    code: '',
  })
  const [step, setStep] = useState(1)
  const handleNavigation = () => {
    const { history } = props;
    history.push(`/${window.location.search?window.location.search:''}`);
  }
  const handleApplyAccount = () => {
    const { history } = props;
    history.push('application');
  }
  const handleRevover = () => {
    const { history } = props;
    history.push('recover-password');
  }
  const handleSubmit = (e) => {
    try { e.preventDefault() } catch (err) { };
    if(validator())
      step === 1 ? handle2FAIntialize() : handle2FAConfirmation()
  }
  const handle2FAIntialize = () => {
    dispatch(setLoading(true))
    setRequest({
      url: 'login/twofactorpass1',
      method: 'POST',
      data: data,
    }).then(res => {
      if (res) {
        setStep(2)
      }
      dispatch(setLoading(false))
    })
  }
  const handle2FAConfirmation = () => {
    dispatch(setLoading(true))
    setRequest({
      url: 'login/twofactorpass2',
      method: 'POST',
      data: data,
    }).then(async res => {
      if (res) {
        let { access_token, refresh_token, expires_in } = res.data;
        expires_in += moment().unix();
        await dispatch(setLogin({
          access_token: access_token,
          refresh_token: refresh_token,
          expires_in: expires_in,
          role: parseJwt(access_token).role,
          lastRefresh: moment().unix()
        }))
      }
      dispatch(setLoading(false))
    })
  }

  const fetchUserData = () => {
    setRequest({
      url: 'user/profile',
      method: 'GET',
    }).then(res => {
      if (res) {
        dispatch(setUserData(res.data))
      }
    })
  }

  const handleChange = (value, id) => {
    setData({ ...data, [id]: value })
  }
  const handleBack = () => {
    setStep(1)
  }

  const validator = () => {
    let invalid = [];
    Object.keys(data).forEach((key) => {
      var value = data[key];
      switch (key) {
        case 'ssn':
          if (step === 1 && (!value || value.length === 0)) {
            invalid.push('ssn')
          }
          break;
        case 'password':
          if (step === 1 && (!value || value.length === 0)) {
            invalid.push('password')
          }
          break;
        case 'code':
          if (step === 2 && (!value || value.length === 0)) {
            invalid.push('code')
          }
          break;
        default:
          return true;
      }
    });
    setInvalidList(invalid);
    return invalid.length === 0
  }

  return [data, handleNavigation, handleApplyAccount, handleRevover, handleSubmit, handleChange, step, handleBack, invalidList, loading];
}

export default AuthPasswordHook;