import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    tabWrapper: {
      width: '100%',
      display: 'inline-flex',
      '& >:first-child': {
        borderRadius: "3px 0px 0px 3px",
      },
      '& >:last-child': {
        borderRadius: "0px 3px 3px 0px",
      }
    },
    tabItem: {
      cursor: 'pointer',
      padding: 7,
      textAlign: 'center',
      flexGrow: 1,
      backgroundColor: ColorSchemes.tableOddBg,
      color: ColorSchemes.darkBlue,
      fontSize: 14,
      '&:hover': {
        backgroundColor: ColorSchemes.highLightGrey,
      }
    },
    tabItemSelected: {
      backgroundColor: ColorSchemes.green,
      color: ColorSchemes.white,
      '&:hover': {
        backgroundColor: ColorSchemes.green,
      }
    }
  }));
  return styles();
}
export default theme;
