import React, {useState} from "react";
import {Dialog, DialogTitle, DialogContent, Slide, DialogActions} from "@material-ui/core";
import theme from './style';
import translate from "../../../../functions/applyTranslation";
import {InputField, Label} from "../../index";
import Button from "../../../Button";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function AlertDialogSlide(props) {
  const { modal, functions, submitModal } = props;
  const classes = theme();
  const [data, setData] = useState({text: '',})

  const handleClose = () => {
    functions.closeModal();
  };

  const handleSubmit = () => {
    submitModal('div', data);
    setData({text: '',})
    functions.closeModal();
  }

  return(
    <div>
      <Dialog
        open={modal === 'div'}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>{translate('news-edit', 'title_editor_div_modal')}</DialogTitle>
        <DialogContent>
          <Label text={translate('news-edit', 'form_label_div')}>
            <InputField value={data.text} fullWidth onChange={(e) => { setData({ ...data, text: e }) }} />
          </Label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} label={translate('', 'button_cancel')} />
          <Button onClick={handleSubmit} label={translate('', 'button_confirm')} fill icon='save' />
        </DialogActions>
      </Dialog>
    </div>
  )
};