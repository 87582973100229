import React, { useState, useEffect } from 'react';
import theme from './style';
import { Paper } from '@material-ui/core';
import { Text } from '../../../components/DataDisplay';
import translate from '../../../functions/applyTranslation';
import { useDispatch, useSelector } from 'react-redux';

const Booking = (props) => {
    const classes = theme();
    // eslint-disable-next-line
    const [data, setData] = useState([])

    const fetchData = () => {
        if (props.data) {
            setData(props.data);
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [])

    return (
        <Paper elevation={0} className={classes.formWrap}>
            {/* TODO */}
            {(!data || data.length === 0) &&
                <div className={classes.titleWrap}>
                    <Text className={classes.title} text={translate('booking', 'label_no_data')} />
                </div>
            }
        </Paper>
    )
}
export default Booking;