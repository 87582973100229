import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme'
const theme = (props) => {
  const styles = makeStyles(theme => ({
    headerWrap: {
      borderBottom: `1px solid ${ColorSchemes.highDarkGrey}`,
      display: 'inline-flex',
      width: '100%',
      marginBottom: 10,
      padding: '10px 0px',
      alignItems: 'center'
    },
    buttonGroup: {
      flexGrow: 1,
      '& >:nth-child(1)': {
        marginRight: 10,
      }
    },
    date: {
      fontSize: 12,
      color: ColorSchemes.highDarkGrey,
      margin: 0,
    },
    titleWrap: {
      marginBottom: 20,
    },
    contentWrap: {
      marginTop: 20,
      lineHeight: '1.5em',
      fontSize: '15px',
    },
    htmlContent: {
      display: 'inline-block',
      width: '100%'
    },
    image: {
      maxHeight: 400,
      width: "100%"
    }
  }));
  return styles();
}
export default theme;

