import React from 'react';
import theme from './style';
import clsx from 'clsx';

const LabelInput = (props) => {
  const classes = theme();
  const {
    text,
    fullWidth,
    className,
    error
  } = props;

  return (
    <div className={clsx(className, classes.titleWrap, fullWidth ? classes.fullWidth : '')}>
      <div>
        <span className={'title'}>{text}</span>
        <span className={'error'}>{error}</span>
      </div>
      <div>{props.children}</div>
    </div>
  );
}

export default LabelInput;