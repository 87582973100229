import {createRef, useState} from 'react';
import useAPI from '../../../api/apiHandler';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, increaseCount } from '../../../redux/states/statesActions';
import { acceptBlogRules } from '../../../redux/settings/settingsActions';
import { useHistory, useParams } from 'react-router-dom';

const BlogHook = () => {
  const history = useHistory();
  const params = useParams();
  const { id } = params;

  const dispatch = useDispatch();
  const acceptedBlogRules = useSelector(state => state.settings.acceptedBlogRules);
  const userData = useSelector(state => state.auth.userData);
  const [setRequest] = useAPI();
  const [comment, setComment] = useState({ consent: false, content: '' })
  const [modal, setModal] = useState({ key: '' })
  const commentRef = createRef();
  const closeModal = () => {
    setModal();
  }
  const openRules = () => {
    setModal({ key: 'rules' });
  }

  const handleAcceptBlogRules = (value) => {
    dispatch(acceptBlogRules(value));
  }

  const handleSubmitComment = (ref) => {
    dispatch(setLoading(true))
    var formdata = new FormData();

    formdata.append("blog_id", id);
    formdata.append("content", comment.content);
    formdata.append("consent", comment.consent);

    let url = 'comment/create'
    const method = 'POST';

    setRequest({
      url: url,
      method: method,
      data: formdata,
    }).then(res => {
      if (res) {
        setComment({ consent: false, content: '' })
        dispatch(increaseCount());
      }
      dispatch(setLoading(false))
    })
    ref.current.editor.setContent("");
  }

  return [comment, acceptedBlogRules, userData, modal, closeModal, openRules, setComment, handleAcceptBlogRules, handleSubmitComment, commentRef];
}

export default BlogHook;