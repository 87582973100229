import { useSelector } from 'react-redux';

const useReplace = () => {
  const access_token = useSelector(state => state.auth.access_token);
  const replace = {
    tokenForString: (data) => {
      return data.replace(new RegExp(access_token, 'g'), '_TOKEN_');
    },
    stringForToken: (data) => {
      return data.replace(new RegExp('_TOKEN_', 'g'), access_token);
    }
  }



  return replace;
}
export default useReplace;