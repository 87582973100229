import React, { useState } from 'react';
import theme from './style';
import { Paper } from '@material-ui/core';
import { Text } from '../../../components/DataDisplay';
import { InputField } from '../../../components/Inputs';
import Button from '../../../components/Button';
import translate from '../../../functions/applyTranslation';
import { useDispatch, useSelector } from 'react-redux';
import {
    setLoading
} from '../../../redux/states/statesActions';
import useAPI from '../../../api/apiHandler';

const Security = (props) => {
    const classes = theme();
    // eslint-disable-next-line
    const dispatch = useDispatch();
    const [setRequest] = useAPI();
    const loading = useSelector(state => state.states.loading);
    const [invalidList, setInvalidList] = useState([]);
    const [data, setData] = useState({
        password: '',
        confirmPassword: '',
        success: false
    })

    const { saveMode } = props;

    const handleChange = (value, id) => {
        setData({ ...data, success: false, [id]: value })
    }

    const handleSubmit = (e) => {
        try { e.preventDefault() } catch (err) { };
        if (validator()) {
            dispatch(setLoading(true))
            setRequest({
                url: 'user/setPassword',
                method: 'PATCH',
                data: {
                    password: data.password
                },
            }).then(res => {
                if (res) {
                    setData({
                        password: '',
                        confirmPassword: '',
                        success: true
                    })
                }
                dispatch(setLoading(false))
            })
              .catch(() => dispatch(setLoading(false)))
        }
    }

    const validator = () => {
        let invalid = [];
        Object.keys(data).forEach((key) => {
            var value = data[key];
            switch (key) {
                case 'password':
                    if (!value || value.length === 0) {
                        invalid.push('password')
                    }
                    break;
                case 'confirmPassword':
                    if (!value || value.length === 0
                        || (data.password !== data.confirmPassword)
                    ) {
                        invalid.push('confirmPassword')
                    }
                    break;
                default:
                    return true;
            }
        });
        setInvalidList(invalid);
        return invalid.length === 0
    }
    return (
        <Paper elevation={0} className={classes.formWrap}>
            <div className={classes.titleWrap}>
                <Text className={classes.title} text={translate('profile', 'title_change_password')} />
            </div>
            <form onSubmit={handleSubmit}>
                <InputField className={classes.input} fullWidth placeholder={translate('profile', 'placeholder_password')} type='password' value={data.password} onChange={handleChange} id='password'
                    invalid={invalidList.includes('password')} />
                <InputField className={classes.input} fullWidth placeholder={translate('profile', 'placeholder_confirm_password')} type='password' value={data.confirmPassword} onChange={handleChange} id='confirmPassword'
                    invalid={invalidList.includes('confirmPassword')} />
                <div className={classes.loginButtonWrap}>
                    <Button disabled={loading || !saveMode} icon='save' fill label={translate('profile', 'button_save')} type='submit' />
                </div>
                {data && data.success &&
                    <div className={classes.loginButtonWrap}>
                        <Text text={translate('profile', 'label_set_password_success')} className={classes.success} />
                    </div>
                }
            </form>
        </Paper>
    )
}
export default Security;