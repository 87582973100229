
import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import { InputField } from '../../components/Inputs';
import Button from '../../components/Button';
import translate from '../../functions/applyTranslation';
import theme from './style';
import TextEditor from './../../components/Inputs/TextEditor'
import decode from 'decode-html';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function BlogComment(props) {
  const { modal, closeModal, callBack, content } = props;
  const classes = theme();
  const [data, setData] = useState({
    content: content || '',
  })
  const handleClose = () => {
    closeModal();
  };
  const formValid = () => {
    return !data.content.length;
  }
  const handleSubmit = () => {
    callBack(data);
    setData({
      content: '',
    })
  }

  const handleHtml = (e) => {
    return e.replace(/(<([^>]+)>)/gi, '');
  }

  useEffect(() => {
    if (content)
      setData({
        content: content,
      })
  }, [content])

  const childRef = useRef();
  const handleFileModal = () => {
    childRef.current.handleFileModal();
  }


  return (
    <div>
      <Dialog
        open={modal === 'edit_comment'}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>
          {translate('blog', 'title_comment_edit')}
          <small>{translate('blog', 'subtitle_comment_edit')}</small>
        </DialogTitle>
        <DialogContent>
          <TextEditor ref={childRef} simple={true}
            className={classes.content}
            value={decode(data.content)} onChange={(e) => setData({
              content: e,
            })}
            maxLength={1000}
            placeholder={translate('blog', 'placeholder_comment')} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} label={translate('', 'button_cancel')} />
          <Button disabled={formValid()} onClick={handleSubmit} label={translate('', 'button_save')} fill icon='save' />
        </DialogActions>
      </Dialog>
    </div>
  );
}
