import { useEffect, useState } from 'react';
import useAPI from '../../api/apiHandler';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line
import { setLoading } from '../../redux/states/statesActions';
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment';
import useReplace from '../../functions/replaceToken';
import { formValidator } from './validation';
const NewsListHook = (props) => {
  const history = useHistory();
  const replace = useReplace();
  const params = useParams();
  let newForm = true;
  if (!params.id || String(params.id).toUpperCase() === 'NEW') {
    newForm = true;
  } else {
    newForm = false;
  }
  const [paramId, setParamId] = useState(-1);
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const [categories, setCategories] = useState([])
  const emptyData = {
    newForm: newForm,
    status_id: newForm ? 1 : null,
    visibility: [1, 2],
    keywords: [],
    title: '',
    intro: '',
    content: '',
    lead_image_id: null,
    lead_image_path: null,
    lead_image_name: '',
    categories: [],
    publish_date: moment(),
  }
  const [data, setData] = useState(emptyData);
  const [validator, setValidator] = useState([]);
  const [modal, setModal] = useState({ key: '' })

  const CATEGORIES = [
    {
      id: '1',
      name: 'Nyheter'
    },
    {
      id: '2',
      name: 'Gudstjänster'
    },
    {
      id: '3',
      name: 'Begravningar'
    },
    {
      id: '4',
      name: 'Framhävd'
    },
    {
      id: '5',
      name: 'Krisinformation'
    },
  ];

  const closeModal = () => {
    setModal();
  }
  const handleChange = (key, value) => {
    setData({ ...data, [key]: value })
  }
  const handleActions = (action) => {
    switch (action) {
      case 'delete':
        setModal({ key: 'delete' });
        break;
      case 'save':
        submitData();
        break;
      case 'back':
        history.push('/article')
        break;
      default:
        break;
    }
  }

  const handleDelete = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `article?article_id=${params.id}`,
      method: 'DELETE',
    }).then(res => {
      if (res) {
        history.push('/article');
      }
      dispatch(setLoading(false))
    })

  }

  const fetchData = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `article/?article_id=${params.id}`,
      method: 'GET',
    }).then(res => {
      if (res) {
        let {
          status_id,
          keywords,
          title,
          intro,
          content,
          visibility,
          lead_image_id,
          categories,
          publish_date,
          certified_by,
          unpublished_by,
        } = res.data;
        setData({
          ...data,
          ...res.data,
          status_id: (status_id && status_id.id) ? status_id.id : status_id,
          visibility: visibility.split(',').map(item => Number(item)),
          keywords: keywords ? keywords.split(',') : [],
          title: title,
          intro: intro,
          content: replace.stringForToken(content),
          lead_image_id: lead_image_id.id,
          lead_image_path: lead_image_id.path,
          lead_image_name: lead_image_id.name,
          categories: categories.map(item => Number(item.id)),
          publish_date: moment.unix(publish_date),
          certified_by: certified_by,
          unpublished_by: unpublished_by
        })
      }
      dispatch(setLoading(false))
    })
  }
  const fetchCategories = () => {
    dispatch(setLoading(true))
    let url = `categories`;

    setRequest({
      url: url,
    }).then(res => {
      if (res) {
        let keys = Object.keys(res.data)
        let arr = keys.map(item => {
          return res.data[item]
        })
        setCategories(arr)
      }
      dispatch(setLoading(false))
    })
  }
  const submitData = () => {
    dispatch(setLoading(true))
    let valid = true;
    const validated = formValidator(data);
    setValidator(validated)
    valid = validated.length === 0;
    if (!valid) {
      dispatch(setLoading(false))
      return;
    }

    let body = {
      categories: data.categories.join(),
      content: replace.tokenForString(data.content),
      intro: data.intro,
      keywords: data.keywords.join(),
      publish_date: moment(data.publish_date).unix(),
      status_id: data.status_id,
      lead_image_id: data.lead_image_id,
      title: data.title,
      visibility: JSON.stringify(data.visibility),
    }

    if (!newForm) {
      body.article_id = params.id
    }
    setRequest({
      url: `article${!newForm ? '' : ''}`,
      method: newForm ? 'POST' : 'PATCH',
      data: body,
    }).then(res => {
      dispatch(setLoading(false))
      if (res) {
        history.push('/article');
      }
    })
  }
  const handleImageUpload = (files, id) => {
    if (files) {
      let file = files[0];
      if (file) {

        var body = new FormData();
        body.append('file', file);
        body.append('tags', 'gallery');
        dispatch(setLoading(true))
        setRequest({
          url: 'file',
          method: 'POST',
          data: body,
        }).then(res => {
          if (res) {
            setData({
              ...data,
              lead_image_id: res.data.id,
              lead_image_path: res.data.path,
              lead_image_name: res.data.name
            })
          }
          dispatch(setLoading(false))
        })

      }
    } else {
      setData({
        ...data,
        lead_image_id: id
      })
    }
  }
  useEffect(() => {
    /*fetchCategories();*/
    if (!newForm) {
      fetchData();
    }
    else
      setData(emptyData)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (params.id !== paramId) {
      setParamId(params.id)
      if (!newForm) {
        fetchData();
      }
      else
        setData(emptyData)
    }

  }, [params])

  return [data, validator, categories, modal, closeModal, handleDelete, handleChange, handleActions, submitData, handleImageUpload, CATEGORIES];
}

export default NewsListHook;