import { makeStyles } from "@material-ui/core/styles";
import ColorScheme from '../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    header: {
      backgroundColor: ColorScheme.mediumBlue,
      maxHeight: 80,
      display: 'flex',
      justifyContent: 'space-between',
      padding: '12px 28px',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex:102,
      '@media (min-width: 951px)': {
        maxHeight: 42
      }
    },
    tablet: {
      maxHeight: 72,
      padding: 0
    },
  }));
  return styles();
}
export default theme;
