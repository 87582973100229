import React from 'react';
import GridView from './gridview';
import BlogView from './blogview';
import ListView from './listview';
import HybridView from './hybridview';
import ActivityGridView from './activitygridview';
import ApplicationView from './applicationview';
import ProfileView from './profileview';
import { withWidth } from '@material-ui/core';
const ListItem = (props) => {
  const {
    viewType,
  } = props;
  switch (viewType) {
    case 'profile':
      return <ProfileView {...props} />
    case 'application':
      return <ApplicationView {...props} />
    case 'blog':
      return <BlogView {...props} />
    case 'grid':
      return <GridView {...props} />
    case 'list':
      return <ListView {...props} />
    case 'hybrid':
      return <HybridView {...props} />
    case 'activity':
      return <ActivityGridView {...props} />
      // return <ActivityView {...props} />
    default:
      return false;
  }
}

export default withWidth()(ListItem);