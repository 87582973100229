import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    radioGroup: {
      display: 'inline-flex',
      height: 22,
      alignItems: 'center',
      cursor: 'pointer',
      '& span': {
        fontSize: 13,
        color: ColorSchemes.darkGrey
      },
      '& svg': {
        fill: ColorSchemes.mediumBlue,
        fontSize: 14,
        height: 14,
      },
      '& .MuiIconButton-root': {
        padding: '5px 5px 5px 9px',
      }
    },
  }));
  return styles();
}
export default theme;
