import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    inputWrapper: {
      position: 'relative',
      display: 'inline-flex',
      height: 35,
      '& ::after': {
        border: 'none !important'
      },
      '& ::before': {
        border: 'none !important'
      },
      '& .MuiFormControl-root': {
        flexGrow: 1,
      },
      '& .MuiInput-root': {
        borderColor: ColorSchemes.highDarkGrey,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 3,
        flexGrow: 1,
      },
      '& .MuiInputBase-input': {
        // paddingLeft: 6,
        // paddingRight: 6,
        padding: '2px 10px',
        background: 'none',
        fontSize: 14,
        // paddingTop: 8,
        // paddingBottom: 8,
        color: ColorSchemes.darkGrey,
      },
    },
    multiline: {
      height: 'auto',
    },
    fullWidth: {
      width: '100%'
    },
    invalid: {
      '& .MuiInput-root': {
        borderColor: ColorSchemes.darkOrange,
        borderWidth: '3px'
      },
    },
    icon: {
      position: 'absolute',
      top: '5px',
      right: '10px',
      cursor: 'pointer'
    },
    right: {
      '& input': {
        textAlign: 'end'
      }

    },
    length: {
      position: 'absolute',
      top: '11px',
      right: '20px',
      fontSize: 12,
      color: ColorSchemes.lowDarkGrey,
    }
  }));
  return styles();
}
export default theme;
