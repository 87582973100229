import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const {
    mobile
  } = props;
  const styles = makeStyles(theme => ({
    headerWrap: {
      isplay: 'inline-flex',
      justifyContent: 'space-between',
      marginBottom: 10,
      width: '100%',
    },
    buttonGroup: {
      justifyContent: 'flex-end',
      display: 'inline-flex',
      '& >:not(:last-child)': {
        // '& >:nth-child(1)': {
        marginRight: 10,
      }
    },
    contentWrap: {
      paddingLeft: mobile ? 0 : 10
    },
    htmlContent: {
      display: 'inline-block',
      width: '100%',
      lineHeight: "140%"
    }
  }));
  return styles();
}
export default theme;

