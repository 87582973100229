import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  let widthMultiplier = 1;
  const { width } = props;
  switch (width) {
    case 'lg':
      widthMultiplier = 0.8;
      break;
    case 'md':
      widthMultiplier = 0.7;
      break;
    case 'sm':
      widthMultiplier = 0.6;
      break;
    case 'xs':
      widthMultiplier = 0.5;
      break;
    default:
      break;
  }
  const styles = makeStyles(theme => ({
    listViewWrap: {
      borderRadius: 3,
      border: `1px solid ${ColorSchemes.lowLightGrey}`,
      backgroundColor: ColorSchemes.white,
      boxShadow: `3px 3px 6px ${ColorSchemes.highLightGrey}`,
      width: 'calc(100% - 20px)',
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 15,
      '&.highlight': {
        border: '4px solid #ffe000',
        width: 'calc(100% - 26px)',
      },
      '&.block': {
        width: 'calc(100% - 40px)',
        padding: 20,
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      '& .image': {
        borderRadius: 3,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: 130,
        width: 190 * widthMultiplier,
        marginRight: 10,
        cursor: 'pointer',
      },
      '& .itemTitle': {
        cursor: 'pointer',
      },
    },
    gridViewWrap: {
      borderRadius: 3,
      border: `1px solid ${ColorSchemes.lowLightGrey}`,
      backgroundColor: ColorSchemes.white,
      boxShadow: `3px 3px 6px ${ColorSchemes.highLightGrey}`,
      width: 'calc(100% - 20px)',
      padding: 10,
      display: 'block',
      marginBottom: 15,
      '&.highlight': {
        border: '4px solid #ffe000',
        width: 'calc(100% - 26px)',
      },
      '& .image': {
        borderRadius: 3,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: 150,
        width: '100%',
        marginRight: 10,
        marginBottom: 0,
        cursor: 'pointer',
      },
      '& .itemTitle': {
        cursor: 'pointer',
      },
      '& .itemText': {
        height: 40
      }
    },
    hybridViewWrap: {
      borderRadius: 3,
      border: `1px solid ${ColorSchemes.lowLightGrey}`,
      backgroundColor: ColorSchemes.white,
      boxShadow: `3px 3px 6px ${ColorSchemes.highLightGrey}`,
      width: 'calc(100% - 20px)',
      padding: 10,
      display: 'block',
      alignItems: 'center',
      marginBottom: 15,
      '& .image': {
        borderRadius: 3,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: 55,
        width: 100,
        marginRight: 10,
        cursor: 'pointer',
      },
      '& .itemTitle': {
        cursor: 'pointer',
      },
    },
    hybridHeaderWrap: {
      display: 'inline-flex',
      width: '100%',
    },
    messageWrap: {
      '& .itemTitle': {
        color: ColorSchemes.black,
        fontSize: 19,
        marginBottom: 6,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      '& .itemText': {
        fontSize: 14,
        lineHeight: '19px',
        color: '#444',
        '& p': {
          margin: '7px 0px',
        }
      },
      '& .actionOpen': {
        color: ColorSchemes.mediumBlue,
        fontSize: 14,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'flex-end',
        cursor: 'pointer',
      },
    },
    listMessageWrap: {
      width: `calc(100% - ${(190 * widthMultiplier) + 10}px)`,
    },
    gridMessageWrap: {
      width: '100%',
    },
    hybridMessageWrap: {
      display: 'block',
      width: '100%',
    },
    hybridTextWrap: {
      width: `calc(100% - ${100 + 10}px)`,
    },
    dateWrap: {
      width: '100%',
      display: width === 'xs' ? 'block' : 'inline-flex',
      justifyContent: 'space-between',
      marginBottom: 4,
      '& .itemDate': {
        color: ColorSchemes.highDarkGrey,
        fontSize: 12
      },
      '& .cartStatus': {
        color: ColorSchemes.orange,
        fontSize: 12,
        fontWeight: 500,
      },
    },
    detailsWrap: {
      width: '100%',
      fontSize: 13,
      '& svg': {
        height: 14,
        width: 14,
        marginRight: 7,
        fill: ColorSchemes.mediumBlue
      },
      '& .marginNoIcon': {
        paddingLeft: 20,
        paddingBottom: 5,
      },
      '& .marginGroup': {
        paddingTop: 5,
        paddingBottom: 5,
      },
      '& .customMargin': {
        paddingTop: 5,
      },
      '& .button': {
        marginTop: 10,
        marginBottom: 10,
        background: ColorSchemes.green,
        color: ColorSchemes.white,
        borderRadius: 3,
        paddingTop: 10,
        paddingBottom: 10,
        fontWeight: 'bold',
        textAlign: 'center'
      }
    },
    availabilityWrap: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '5px 0px',
      '& .circle': {
        height: 11,
        width: 11,
        borderRadius: '50%',
        marginRight: 10,
      },
      '& .available': {
        backgroundColor: ColorSchemes.green
      },
    },
    infoWrap: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    infoWrapNoGuide: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    guideInfo: {
      color: "#8B8B8B"
    }
  }));
  return styles();
}
export default theme;
