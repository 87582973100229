import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme'
const theme = () => {
  const styles = makeStyles(theme => ({
    headerWrap: {
      marginBottom: 10,
      padding: '10px 0px',
    },
    titleWrap: {
      display: 'inline-flex',
      width: '100%',
      alignItems: 'center',
      '& >:nth-child(1)': {
        flexGrow: 1,
      }
    },
    title: {
      fontSize: '20px !important',
      paddingTop: '8px',
    },
    buttonGroup: {
      '& >:not(:last-child)': {
        marginRight: 10,
      }
    },
    block: {
      borderRadius: 3,
      border: `1px solid ${ColorSchemes.lowLightGrey}`,
      backgroundColor: ColorSchemes.white,
      boxShadow: `3px 3px 6px ${ColorSchemes.highLightGrey}`,
      width: 'calc(100% - 40px)',
      padding: 20,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 16,
      flexWrap: 'wrap',
      flexDirection: 'row',
      display: 'flex',
    },
    commentsResume: {
      fontSize: '12px',
      lineHeight: '14px',
      color: ColorSchemes.highDarkGrey,
      width: '100%',
      display: 'inline-flex',
      marginBottom: '2px',
      justifyContent: 'flex-end',
    },
    comments: {
      width: '100%',
      flexWrap: 'wrap',
      flexDirection: 'row',
      display: 'flex',
    },
    comment: {
      position: 'relative',
      width: 'calc(100% - 40px)',
      padding: 20,
      flexWrap: 'wrap',
      flexDirection: 'row',
      display: 'flex',
      border: '1px solid #E2E2E2',
      borderTop: 'none',
    },
    contentWrap: {
      marginTop: 10,
    },
  }));
  return styles();
}
export default theme;

