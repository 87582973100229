import { makeStyles } from "@material-ui/core/styles";
import { findByLabelText } from "@testing-library/dom";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    buttonWrap: {
      paddingBottom: 10,
      marginBottom: 10,
      borderBottom: `1px solid ${ColorSchemes.highDarkGrey}`
    },
    mainGrid:{
      display: 'inline-flex',
      '& .marginGroup': {
        display: 'inline-flex',
        fontSize: '15px',
        lineHeight: '23px',
        '& span': {
          marginRight: 20
        }
      },
    },
    headerWrap: {
      fontSize: 13,
      marginBottom: 20,
      '& .marginNoIcon': {
        paddingLeft: 20,
        paddingBottom: 5,
      },
      '& .marginGroup': {
        paddingTop: 6,
        paddingBottom: 6,
        display: 'flex',
      },
      '& .customMargin': {
        paddingTop: 5,
      },
      '& .button': {
        cursor: 'pointer',
        marginTop: 5,
        marginBottom: 5,
        background: ColorSchemes.green,
        color: ColorSchemes.white,
        borderRadius: 3,
        paddingTop: 10,
        paddingBottom: 10,
        fontWeight: 'bold',
        textAlign: 'center'
      }
    },
    alignLeft: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap'
    },
    alignRight: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap'
    },
    titleWrap: {
      marginBottom: 0,
    },
    location: {
      fontWeight: 'bold',
      lineHeight: '15px',
      display: 'block'
    },
    clockWrap: {
      display: 'flex',
      '& p': {
        fontSize: '30px !important',
        lineHeight: '37px !important',
      }
    },
    clock: {
      marginTop: 3,
      marginRight: 7,
      fill: ColorSchemes.mediumBlue,
      height: 29,
      fontSize: 29,
      color: ColorSchemes.darkBlue
    },
  }));
  return styles();
}
export default theme;
