import { makeStyles } from "@material-ui/core/styles";
import ColorScheme from '../../../settings/colorScheme'
const theme = () => {
  const styles = makeStyles(theme => ({
    pageWrap: {

    },
    formFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 10,
      paddingTop: 10,
      borderTop: `1px solid ${ColorScheme.highDarkGrey}`,
      '& >:nth-child(1)': {
        marginRight: 10,
      }
    }

  }));
  return styles();
}
export default theme;