import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import TranslationsTable from  '../../components/Translations/translationsTable';
import translate from '../../functions/applyTranslation';
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    display: "flex"
  },
  paper: {
    padding: 20,
    width: "100%"
  },
  paperTitle: {
    width: "100%",
    padding: 20,
    marginBottom: 20,
  },
}));
const TranslationsEditor = props => {
  const classes = useStyles();

  // document.title = translate('','translations_browser_title') + ' | Prodiagnostics';

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={10}>
        <Paper className={classes.paperTitle} elevation={0}>
          <Typography variant="h6" gutterBottom>
            {translate('translations', 'page_title')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {translate('translations', 'page_subtitle')}
          </Typography>
        </Paper>
        <Paper className={classes.paper} elevation={0}>
          <TranslationsTable {...props} />
        </Paper>
      </Grid>
    </Grid>

  );
};
export default TranslationsEditor;