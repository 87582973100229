import {

} from './errorsTypes';
const initialState = {

}
const ErrorsReducer = function (state = initialState, action) {
  const { type,  } = action;
  switch (type) {

    default:
      return state;
  }
}
export default ErrorsReducer;