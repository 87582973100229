import React, { useState } from 'react';
import Media from 'react-media';
import clsx from 'clsx';
import theme from './style';
import { Typography, Grid } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import translate from '../../functions/applyTranslation'
import { useHistory } from 'react-router-dom';
import usePermissionsHook from '../../functions/permissions';
import { useSelector } from 'react-redux';

const MobileMyPages = (props) => {
  const {open, handleToggleMobileMyPages, handleLogout, handleNavigation, classes, permissions, hasPermission} = props;
  const {userData} = useSelector(state => state.auth);
  return (
    <>
      {open &&
        <ClickAwayListener onClickAway={() => handleToggleMobileMyPages(false)}>
          <div className={classes.mobileWrapper}>
            <Grid container>
              <Grid item xs={12} className={clsx(classes.mobileItemMargin, classes.mobileItemBorder, classes.mobileGreetingRow)}>
                <div style={{display: "flex"}}>
                  <PersonIcon/>
                  &nbsp;
                  <Typography>{translate('', "my_pages_mobile_greeting")}, {userData.name}</Typography>
                </div>
                <div>
                  <ExitToAppIcon onClick={() => handleToggleMobileMyPages(false)}/>
                </div>
              </Grid>
              <Grid item xs={12} className={classes.mobileItemMargin}>
                <Typography variant="body2" className={classes.mobileMenuItem} onClick={() => handleNavigation('start')}>Start</Typography>
              </Grid>
              <Grid item xs={12} className={classes.mobileItemMargin}>
                <Typography variant="body2" className={classes.mobileMenuItem} onClick={() => handleNavigation('contacts')}>{translate('', 'menu_contacts')}</Typography>
              </Grid>
              <Grid item xs={12} className={clsx(classes.mobileItemMargin, classes.mobileItemBorder)}>
                <Typography variant="body2" className={classes.mobileMenuItem} onClick={() => handleNavigation('profile')}>{translate('', 'navbar_shortcut_my_pages')}</Typography>
              </Grid>
              {hasPermission(permissions.APPLICANT_ACCESS) &&
                <Grid item xs={12} className={classes.mobileItemMargin}>
                  <Typography variant="body2" className={classes.mobileMenuItem} onClick={() => handleNavigation('applications')}>{translate('', 'my_pages_menu_applications')}</Typography>
                </Grid>
              }
              {hasPermission(permissions.USERS_ACCESS) &&
                <Grid item xs={12} className={classes.mobileItemMargin}>
                  <Typography variant="body2" className={classes.mobileMenuItem} onClick={() => handleNavigation('profiles')}>{translate('', 'my_pages_menu_profiles')}</Typography>
                </Grid>
              }
              {hasPermission(permissions.ACTIVITIES_CREATE) &&
                <Grid item xs={12} className={classes.mobileItemMargin}>
                  <Typography variant="body2" className={classes.mobileMenuItem} onClick={() => handleNavigation('activities')}>{translate('', 'my_pages_menu_new_activity')}</Typography>
                </Grid>
              }
              {hasPermission(permissions.NEWS_CREATE) &&
                <Grid item xs={12} className={classes.mobileItemMargin}>
                  <Typography variant="body2" className={classes.mobileMenuItem} onClick={() => handleNavigation('news')}>{translate('', 'my_pages_menu_new_news')}</Typography>
                </Grid>
              }
              {hasPermission(permissions.GALLERY) &&
                <Grid item xs={12} className={clsx(classes.mobileItemMargin, classes.mobileItemBorder)}>
                  <Typography variant="body2" className={classes.mobileMenuItem} onClick={() => handleNavigation('gallery')}>{translate('', 'my_pages_menugallery')}</Typography>
                </Grid>
              }
              <Grid item xs={12} className={hasPermission(permissions.GALLERY) ? classes.mobileItemMargin : clsx(classes.mobileItemMargin, classes.mobileItemBorderTop)}>
                <a href="/information/255" target="_blank" className={clsx(classes.targetBlankLink, classes.mobileMenuItem)}>{translate('', "menu_help")}</a>
              </Grid>
              <Grid item xs={12} className={classes.mobileItemMargin}>
                <Typography variant="body2" className={classes.mobileMenuItem} onClick={() => handleLogout()}><b>{translate('', 'navbar_shortcut_log_out')}</b></Typography>
              </Grid>
            </Grid>
          </div>
        </ClickAwayListener>
      }
    </>
  )
}

const DesktopMyPages = (props) => {
  const {classes, open, setOpen, permissions, hasPermission, handleNavigation, handleLogout, toggleMenu} = props;
  return (
    <div className={clsx(classes.myInfoWrap, open ? classes.openWrap : classes.closeWrap)}>
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <div className={classes.myInfoContainer}>
          <Typography className={'title'}>{translate('', 'my_pages_title')}</Typography>
          {hasPermission(permissions.APPLICANT_ACCESS) &&
            <Typography onClick={() => handleNavigation('applications')} className={'menuItem'}>{translate('', 'my_pages_menu_applications')}</Typography>
          }
          {hasPermission(permissions.USERS_ACCESS) &&
            <Typography onClick={() => handleNavigation('profiles')} className={'menuItem'}>{translate('', 'my_pages_menu_profiles')}</Typography>
          }
          {hasPermission(permissions.ACTIVITIES_CREATE) &&
            <Typography onClick={() => handleNavigation('activities')} className={'menuItem'}>{translate('', 'my_pages_menu_new_activity')}</Typography>
          }
          {hasPermission(permissions.NEWS_CREATE) &&
            <Typography onClick={() => handleNavigation('news')} className={'menuItem'}>{translate('', 'my_pages_menu_new_news')}</Typography>
          }
          {hasPermission(permissions.PAGES_CREATE) &&
            <Typography onClick={() => handleNavigation('pages')} className={'menuItem'}>{translate('', 'my_pages_menu_manage_pages')}</Typography>
          }
          {hasPermission(permissions.GALLERY) &&
            <Typography onClick={() => handleNavigation('gallery')} className={'menuItem'}>{translate('', 'my_pages_menugallery')}</Typography>
          }
          <Typography onClick={() => handleNavigation('personal-info')} className={'menuItem'}>{translate('', 'my_pages_menu_account_settings')}</Typography>
          <Typography onClick={handleLogout} className={'menuItem'}>{translate('', 'my_pages_menu_log_out')}</Typography>
        </div>
        <div className={classes.pullingPin} onClick={toggleMenu}>
          <PersonIcon className={'userIcon'} />
          <ExpandMoreIcon className={'arrowIcon'} />
        </div>
      </div>
    </ClickAwayListener>
  </div >  
  );
};

const MyPages = (props) => {
  const classes = theme();
  const history = useHistory();
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const [open, setOpen] = useState(false);
  const {
    handleLogout,
    mobileMyPages,
    handleToggleMobileMyPages
  } = props;
  const toggleMenu = () => {
    setOpen(!open)
  }
  const handleNavigation = (e) => {
    switch (e) {
      case 'applications':
        history.push(`/applications`);
        break;
      case 'activities':
        history.push(`/activities/new`);
        break;
      case 'news':
        history.push(`/article/new`);
        break;
      case 'gallery':
        history.push(`/gallery`);
        break;
      case 'pages':
        history.push(`/information`);
        break;
      case 'personal-info':
        history.push(`/profile/personal-info`);
        break;
      case 'profiles':
        history.push(`/profile`);
        break;
      case 'start':
        history.push('/');
        break;
      case 'contacts':
        history.push('/information/90');
        break;
      case 'profile':
        history.push('/profile/personal-info');
      default:
        break;
    }
  }
  return (
    <Media queries={{
      desktop: "(min-width: 541px)",
      mobile: "(max-width: 540px)"
    }}>
      {matches => (
        <>
          {matches.desktop && 
          <DesktopMyPages
            classes={classes}
            open={open}
            setOpen={setOpen}
            permissions={permissions}
            hasPermission={hasPermission}
            handleNavigation={handleNavigation}
            handleLogout={handleLogout}
            toggleMenu={toggleMenu}
          />}
          {matches.mobile && 
          <MobileMyPages
            open={mobileMyPages}
            handleToggleMobileMyPages={handleToggleMobileMyPages}
            handleLogout={handleLogout}
            handleNavigation={handleNavigation}
            classes={classes}
            permissions={permissions}
            hasPermission={hasPermission}
          />}
        </>
      )}
    </Media>
  );
}

export default MyPages;