
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide, FormControlLabel, Checkbox } from '@material-ui/core';
import Button from '../../../Button';
import { Label, InputField } from '../..'
import translate from '../../../../functions/applyTranslation';
import theme from './style';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function AlertDialogSlide(props) {
  const { modal, functions, submitModal } = props;
  const classes = theme();
  const [data, setData] = useState({
    text: '',
    file: '',
    target: '0'
  })
  const handleClose = () => {
    functions.closeModal();
  };
  const formValid = () => {
    return !data.text.length || !data.file.length
  }
  const handleSubmit = () => {
    submitModal('link', data);
    setData({
      text: '',
      file: '',
      target: '0'
    })
    functions.closeModal();
  }
  return (
    <div>
      <Dialog
        open={modal === 'link'}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>{translate('news-edit', 'title_editor_link_modal')}</DialogTitle>
        <DialogContent>
          <Label text={translate('news-edit', 'form_label_link_text')}>
            <InputField value={data.text} fullWidth invalid={!data.text.length} onChange={(e) => { setData({ ...data, text: e }) }} />
          </Label>
          <Label text={translate('news-edit', 'form_label_link')}>
            <InputField value={data.file} fullWidth invalid={!data.file.length} onChange={(e) => { setData({ ...data, file: e }) }} />
          </Label>
          <FormControlLabel
            control={
              <Checkbox
                checked={data.target === '1'}
                onChange={e => setData({...data, target: e.target.checked ? '1' : '0'})}
                value='target'
              />
            }
            label={translate('news-edit', 'form_label_link_target')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} label={translate('', 'button_cancel')} />
          <Button disabled={formValid()} onClick={handleSubmit} label={translate('', 'button_confirm')} fill icon='save' />
        </DialogActions>
      </Dialog>
    </div>
  );
}
