import React, {useEffect, useState} from 'react';
import theme from './style';
import clsx from 'clsx';
import {
  InputAdornment,
  FormControl,
  OutlinedInput,
  Grid,
  ClickAwayListener
} from '@material-ui/core';
import Button from '../../Button';
import {SmallTitle} from '../../DataDisplay';
import SearchIcon from '@material-ui/icons/Search';
import translate from '../../../functions/applyTranslation';
import usePermissionsHook from '../../../functions/permissions';
import {useHistory, useLocation} from 'react-router-dom';
import useAPI from '../../../api/apiHandler';
import {useDispatch, useSelector} from 'react-redux';
import {setLoading} from '../../../redux/states/statesActions';
import {useQueryState} from "react-router-use-location-state";

const SearchBar = (props) => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const history = useHistory();
  const loading = useSelector(state => state.states.loading);
  const classes = theme();
  const [values, setValues] = useQueryState("text", query.get('text') || "");
  const [result, setResult] = useState({
    static_pages: [],
    blogs: [],
    events: [],
    articles: [],
    empty: true
  })
  const [open, setOpen] = useState(false);
  const handleSubmit = (e) => {
    try {
      e.preventDefault()
    } catch (error) {
    }
    ;
    handleSearch(true);
  }

  const handleInputChange = (e) => {
    setValues(e.target.value);
  }

  const handleSearch = (e) => {
    if (e) {
      dispatch(setLoading(true))
      setResult({
        static_pages: [],
        blogs: [],
        events: [],
        articles: [],
        empty: true
      });
      setRequest({
        url: `search?text=${values}&limit=2`,
        method: 'GET',
      }).then(res => {
        if (res) {
          let result = res.data;
          if ((result.static_pages && result.static_pages.length > 0) ||
            (result.events && result.events.length > 0) ||
            (result.blogs && result.blogs.length > 0) ||
            (result.articles && result.articles.length > 0))
            result.empty = false;
          setResult(result);
        }
        dispatch(setLoading(false))
      })
        .catch(() => dispatch(setLoading(false)))
    } else {
      setValues('');
    }
    setOpen(e);
  }

  const handleListNavigation = (area, id) => {
    switch (area) {
      case 'activities':
        if (id)
          history.push(`/activities/${id}`);
        else
          history.push(`/search?text=${values}&content_type=events`);
        break;
      case 'news':
        if (id)
          history.push(`/article/${id}`);
        else
          history.push(`/search?text=${values}&content_type=articles`);
        break;
      case 'blog':
        if (id)
          history.push(`/blog/${id}`);
        else
          history.push(`/search?text=${values}&content_type=blogs`);
        break;
      case 'pages':
        if (id)
          history.push(`/information/${id}`);
        else
          history.push(`/search?text=${values}&content_type=static_pages`);
        break;
      default:
        setValues('')
        history.push(`/search?text=${values}`);
        break;
    }
    setOpen(false);
  }

  const handleFocus = () => {
    setOpen(true)
  }

  const [queued, setQueued] = useState(0);
  useEffect(() => {
    if (values && values.length >= 2) {
      if (!loading)
        handleSearch(true);
      else {
        setQueued(queued + 1);
        setTimeout(() => {
          setQueued(-1);
        }, 1000);
      }

    }
  }, [values])

  useEffect(() => {
    if (queued === -1)
      handleSearch(true);
  }, [queued])

  return (
    <div className={classes.searchContainer}>
      <form onSubmit={handleSubmit}>
        <FormControl className={clsx(classes.margin, classes.searchInput, open ? 'active' : '')} variant="outlined">
          <OutlinedInput
            value={values}
            // onFocus={() => handleFocus()}
            onClick={() => handleFocus()}
            onChange={handleInputChange}
            placeholder={translate('', 'navbar_search_placeholder')}
            endAdornment={
              <InputAdornment
                position="end"
                onClick={() => handleSearch(!open)}>
                <SearchIcon/>
              </InputAdornment>
            }
          />
        </FormControl>
      </form>
      {open &&
      <div>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Grid container className={classes.resultsContainer}>
              <Grid item sm={6}>
                <SmallTitle className={classes.title} text={translate('search', 'title_display_results')}/>
              </Grid>
              <Grid item sm={6} className={classes.floatRight}>
                {result && !result.empty &&
                <Button className={classes.link} label={translate('search', 'action_see_more_list') + ' >>'}
                        onClick={() => handleListNavigation(null)}/>
                }
              </Grid>
              {result && result.events && result.events.length > 0 &&
              <Grid item sm={12}>
                <SmallTitle className={classes.subTitle} text={translate('search', 'subtitle_activities')}/>
                {result.events.map((item) => {
                  return <Button className={classes.item} label={item.event.title}
                                 onClick={() => handleListNavigation('activities', item.jfm_event.id)}/>
                })}
                <Button className={classes.link} label={translate('search', 'action_see_more') + ' >>'}
                        onClick={() => handleListNavigation('activities')}/>
              </Grid>
              }
              {result && result.static_pages && result.static_pages.length > 0 &&
              <Grid item sm={12}>
                <SmallTitle className={classes.subTitle} text={translate('search', 'subtitle_pages')}/>
                {result.static_pages.map((item) => {
                  return <Button className={classes.item} label={item.title}
                                 onClick={() => handleListNavigation('pages', item.id)}/>
                })}
                <Button className={classes.link} label={translate('search', 'action_see_more') + ' >>'}
                        onClick={() => handleListNavigation('pages')}/>
              </Grid>
              }
              {result && result.articles && result.articles.length > 0 &&
              <Grid item sm={12}>
                <SmallTitle className={classes.subTitle} text={translate('search', 'subtitle_news')}/>
                {result.articles.map((item) => {
                  return <Button className={classes.item} label={item.title}
                                 onClick={() => handleListNavigation('news', item.id)}/>
                })}
                <Button className={classes.link} label={translate('search', 'action_see_more') + ' >>'}
                        onClick={() => handleListNavigation('news')}/>
              </Grid>
              }
              {result && result.blogs && result.blogs.length > 0 && hasPermission(permissions.BLOG_ACCESS) &&
              <Grid item sm={12}>
                <SmallTitle className={classes.subTitle} text={translate('search', 'subtitle_blog')}/>
                {result.blogs.map((item) => {
                  return <Button className={classes.item} label={item.title}
                                 onClick={() => handleListNavigation('blog', item.id)}/>
                })}
                <Button className={classes.link} label={translate('search', 'action_see_more') + ' >>'}
                        onClick={() => handleListNavigation('blog')}/>
              </Grid>
              }
              {!loading && (!result || result.empty) &&
              <Grid item sm={12}>
                <SmallTitle className={classes.subTitle} text={translate('search', 'empty_search')}/>
              </Grid>
              }
            </Grid>
          </ClickAwayListener>
      </div>
      }
    </div>
  );
}
export default SearchBar;