import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme'
const theme = (props) => {
  const styles = makeStyles(theme => ({
    availabilityWrap: {
      display: 'inline-flex',
      alignItems: 'center',
      paddingTop: 5,
      '& .circle': {
        height: 11,
        width: 11,
        borderRadius: '50%',
        marginRight: 10,
      },
      '& .available': {
        backgroundColor: ColorSchemes.green
      },
      '& .noAvailable': {
        backgroundColor: ColorSchemes.red
      },
      '& .lowAvailable': {
        backgroundColor: ColorSchemes.orange
      },
    },
  }));
  return styles();
}
export default theme;

