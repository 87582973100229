import React from "react";
import {Typography} from "@material-ui/core";
import Step1 from "./step1";
import Step3 from "./step3";
import Button from "../../components/Button";
import translate from "../../functions/applyTranslation";
import theme from "./style";
import Step2 from "./step2";
import useGuideBookingHook from "./hook";
import moment from "moment";

const GuideBooking = () => {
  const classes = theme();

  const [programs, data, displayedText, setDisplayedText, handleSelectChange, handleTextChange, invalidList, handleDateChange, dialogOpen, setDialogOpen, events, submit, createEvent, errorCalendar, bookingComplete, tempEvent, checkedGDPR, setCheckGDPR, checkSlot, freeSlot, datesRange, setDatesRange] = useGuideBookingHook();

  const getCompleteText = () => {
    const text = translate('booking', 'booking_complete');
    for (let i in text) {
      const item = text[i];

      if (typeof item === "string") {
        if (item.includes("##Program##")) {
          text[i] = text[i].replace("##Program##", data.selectedProgram.name)
        }
        if (item.includes("##BookedDay##")) {
          text[i] = text[i].replace("##BookedDay##", String(moment(data.startDate).format('D MMMM YYYY')))
        }
        if (item.includes("##BookedTime##")) {
          text[i] = text[i].replace("##BookedTime##", String(moment(data.startDate).format('HH:mm')))
        }
        if (item.includes("##organisation##")) {
          text[i] = text[i].replace("##organisation##", data.organisation)
        }
        if (item.includes("##nbrofVisitors##")) {
          text[i] = text[i].replace("##nbrofVisitors##", data.nr_visitors)
        }
        if (item.includes("##contactperson##")) {
          text[i] = text[i].replace("##contactperson##", data.name)
        }
        if (item.includes("##phonenumber##")) {
          text[i] = text[i].replace("##phonenumber##", data.phone)
        }
        if (item.includes("##email##")) {
          text[i] = text[i].replace("##email##", data.email)
        }
        if (item.includes("##comment##")) {
          text[i] = text[i].replace("##comment##", data.message || "-")
        }
        if (item.includes("##guideName##")) {
          text[i] = text[i].replace("##guideName##", "-")
        }
        if (item.includes("##endTime##")) {
          text[i] = text[i].replace("##endTime##", String(moment(data.endDate).format('HH:mm')))
        }
      }
    }
    return text;
  }

  return (
    <>
      {!bookingComplete ?
        (
          <div style={{overflowX: "hidden"}}>
            <Step1 programs={programs} data={data} displayedText={displayedText} setDisplayedText={setDisplayedText}
                   handleSelectChange={handleSelectChange}/>
            {Object.keys(data.selectedProgram).length > 0 &&
            <>
              <Step2 data={data} handleDateChange={handleDateChange} dialogOpen={dialogOpen}
                     setDialogOpen={setDialogOpen}
                     events={events} createEvent={createEvent} errorCalendar={errorCalendar}
                     tempEvent={tempEvent} checkSlot={checkSlot} freeSlot={freeSlot} setDatesRange={setDatesRange}/>
              <Step3 data={data} handleTextChange={handleTextChange} invalidList={invalidList} checkedGDPR={checkedGDPR}
                     setCheckedGDPR={setCheckGDPR}/>
              <Button
                fill
                label={translate('booking', 'reserve')}
                className={classes.reserveButton}
                onClick={submit}
              />
            </>
            }
          </div>
        )
        :
        (
          <Typography variant="h6"
                      className={classes.completeText}>{getCompleteText()}</Typography>
        )
      }
    </>
  );
};

export default GuideBooking;
