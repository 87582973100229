
import React, { useContext } from 'react';
import EventList from '../../components/EventList';
import { BookingContext, Provider } from "./bookingContext";
import theme from './style';
import Resume from './resume';
import Header from './header';
import usePermissionsHook from '../../functions/permissions';
import { useHistory } from 'react-router-dom'

const CheckList = (props) => {
  const history = useHistory();
  const classes = theme();
  const bookingContext = useContext(BookingContext);
  const [permissions, roles, hasPermission] = usePermissionsHook();

  if (!hasPermission(permissions.ACTIVITIES_SECURITY_ACCESS))
    history.push('/');

  const { data, products, ticketsSold, fetchSoldTickets, callback } = bookingContext;

  if (!!data) {
    const {
      event,
      jfm_event
    } = data;
    const {
      event_start,
      event_end
    } = jfm_event
    const {
      title,
      start_time,
      end_time,
      registered_users
    } = event;
    
    const checkedIn = (ticketsSold.filter(x => x.checkin) || []).length;
    const notCheckedIn = registered_users - checkedIn;
    const checkedOut = (ticketsSold.filter(x => x.checkout) || []).length;

    return (
      <div>
        <Header title={title} event_start_time={event_start} event_end_time={event_end} booked_start_time={start_time} booked_end_time={end_time} />
        <Resume checkedIn={checkedIn} notCheckedIn={notCheckedIn} checkedOut={checkedOut} totalBooked={registered_users || 0} />
        <EventList products={products} tickets={ticketsSold} tablet={true} fetchTickets={fetchSoldTickets} callback={callback} />
      </div>
    );
  } else {
    return null;
  }
}

const ApplyProvider = (props) => {
  return (<Provider>
    <CheckList {...props} />
  </Provider>)
}
export default ApplyProvider;