import React from 'react';
import theme from './style';
import parse from 'html-react-parser';

const ApplicationBody = (props) => {
    const classes = theme();
    const {
        application,
        path,
    } = props;

    const {
        name,
        phone,
        mail,
        ssn,
        adress,
        zip_code,
        city,
        ref,
        message
    } = application;


    return (

        <div className={`${classes.main} readOnly`}>
            <div className={classes.title}>
                {`${name} • ${ssn} • ${mail} • ${phone}`}
            </div>
            <div className={`${classes.content}`}>
                <div>{`${adress} • ${zip_code} • ${city}`}</div>
                {message &&
                    <div><span className={classes.message}>{message}</span></div>
                }
            </div>
        </div >
    );
}

export default ApplicationBody;