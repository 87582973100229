import { useState, useEffect } from 'react';
import useAPI from '../../api/apiHandler';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/states/statesActions';
import { useParams, useHistory } from 'react-router-dom';
import useReplace from '../../functions/replaceToken';
const NewsDetailHook = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const [setRequest] = useAPI();
  const [data, setData] = useState();
  const replace = useReplace();
  const { id } = params;
  const fetchNewsDetail = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `article/?article_id=${id}`,
      method: 'GET',
    }).then(res => {
      if (res) {
        // setData({...res.data,content:replace.stringForToken(res.data.content)})
        setData(res.data)
      }
      dispatch(setLoading(false))
    })
  }
  useEffect(() => {
    if (setRequest && id) {
      fetchNewsDetail();
    }
    // eslint-disable-next-line
  }, [id])
  const handleActions = (type) => {
    switch (type) {
      case 'back':
        history.goBack();
        break;
      case 'edit':
        history.push(`/article/${id}/edit`);
        break;
      default:
        break;
    }
  }
  return [data, handleActions];
}

export default NewsDetailHook;