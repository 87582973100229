import React, { Fragment, memo } from 'react';
import theme from './style';
import { Paper, CircularProgress } from '@material-ui/core';
import Button from '../../components/Button';
import { BigTitle, SmallTitle, Text } from '../../components/DataDisplay';
import { Label, InputField } from '../../components/Inputs';
import translate from '../../functions/applyTranslation';
import useRecoverPassword from './hook';
const ResetPassword = (props) => {
  const classes = theme();
  const [data, handleNavigation, handleLogin, loading] = useRecoverPassword(props);
  return (
    <Fragment>
      <Paper elevation={4} className={classes.formWrap}>
        <div className={classes.titleWrap}>
          <BigTitle text={translate('reset_password', 'title_reset_password_auth')} />
        </div>
        {data.ok &&
          <Fragment>
            <Text text={translate('reset_password', 'label_check_email')} />
            <div className={classes.loginButtonWrap}>
              <Button disabled={loading} fill label={translate('reset_password', 'button_goto_login')} onClick={handleLogin} />
            </div>
          </Fragment>
        }
        {!data.ok &&
          <div style={{ textAlign: 'center' }}>
            <CircularProgress style={{ width: 20, height: 20 }} />
            <Text text={translate('reset_password', 'label_waiting')} />
          </div>
        }
      </Paper>

      <Paper elevation={4} className={classes.formWrap}>
        <Button label={translate('login', 'label_change_login_method_bankid')} onClick={handleNavigation} />
      </Paper>
    </Fragment >
  );
}

export default memo(ResetPassword);