import React, { useState } from 'react';
import theme from './style';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

const AccordionComp = (props) => {
  const classes = theme();
  const [state, setState] = useState(false)
  const {
    children,
    title,
    clean,
    expanded,
    onChange,
    info,
    blog
  } = props;

  const handleChange = () => {
    setState(!state)
  }
  return (
    <Accordion expanded={onChange ? expanded : state} onChange={onChange || handleChange} className={`${classes.accordion} ${info ? 'info' : ''} ${blog ? 'blog' : ''} ${clean ? 'clean' : ''} `}>
      <AccordionSummary
        className={`${classes.summary} ${info ? 'info' : ''}  ${blog ? 'blog' : ''} ${clean ? classes.left : ''}`}
        expandIcon={blog?<MoreHorizIcon />:<ExpandMoreIcon />}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails className={`${classes.details} ${info ? 'info' : ''} ${blog ? 'blog' : ''}`}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
export default AccordionComp;