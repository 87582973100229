import React, { Fragment, memo } from 'react';
import theme from './style';
import { Paper } from '@material-ui/core';
import Button from '../../components/Button';
import { BigTitle, SmallTitle, Text } from '../../components/DataDisplay';
import { Label, InputField } from '../../components/Inputs';
import translate from '../../functions/applyTranslation';
import useAuthPassword from './hook';
const AuthPassword = (props) => {
  const classes = theme();
  const [data, handleNavigation, handleApplyAccount, handleRecover, handleSubmit, handleChange, step, handleBack, invalidList, loading] = useAuthPassword(props);
  return (
    <Fragment>
      <Paper elevation={4} className={classes.formWrap}>
        <div className={classes.titleWrap}>
          <BigTitle text={translate('login', 'title_password_auth')} />
        </div>
        {step === 1 &&
          <form onSubmit={handleSubmit}>
            <Label text={translate('login', 'placeholder_personal_number')}>
              <InputField fullWidth value={data.ssn} onChange={handleChange} id='ssn' name="ssn"
                invalid={invalidList.includes('ssn')} />
            </Label>
            <Label text={translate('login', 'placeholder_password')}>
              <InputField fullWidth type='password' value={data.password} onChange={handleChange} id='password'
                invalid={invalidList.includes('password')} />
            </Label>
            <div className={classes.loginButtonWrap}>
              <Button disabled={loading} fill label={translate('login', 'button_login')} type='submit' />
            </div>
            <div>
              <Button label={translate('login', 'label_forgot_password')} onClick={handleRecover} />
            </div>
          </form>
        }
        {step === 2 &&
        <form onSubmit={handleSubmit}>
          <Label text={translate('login', 'placeholder_2fa_code')}>
            <InputField fullWidth value={data.code} onChange={(e) => handleChange(e.toUpperCase(), 'code')} id='code'
                        invalid={invalidList.includes('code')} />
          </Label>
          <div className={classes.loginButtonWrap}>
            <Button style={{ marginRight: 10 }} label={translate('', 'button_back')} onClick={handleBack} />
            <Button disabled={loading} fill label={translate('', 'button_ok')} type='submit' />
          </div>
        </form>
        }
      </Paper>
      <Paper elevation={4} className={classes.formWrap}>
        <Button label={translate('login', 'label_change_login_method_bankid')} onClick={handleNavigation} />
      </Paper>
      <Paper elevation={4} className={classes.formWrap}>
        <SmallTitle className={classes.paddingFix} text={translate('login', 'label_help_sign_2fa_title')} />
        <Text className={classes.help} text={translate('login', 'label_help_sign_2fa_step1')} />
        <Text className={classes.help} text={translate('login', 'label_help_sign_2fa_step2')} />
        <Text className={classes.help} text={translate('login', 'label_help_sign_2fa_step3')} />
        <Text className={classes.help} text={translate('login', 'label_help_sign_2fa_step4')} />
        <Text className={classes.help} text={translate('login', 'label_help_sign_2fa_step5')} />
      </Paper>
      <Paper elevation={4} className={classes.alternativeWrap}>
        <SmallTitle text={translate('login', 'label_no_account')} />
        <Button label={translate('login', 'label_apply_account')} onClick={handleApplyAccount} />
      </Paper>
    </Fragment >
  );
}

export default memo(AuthPassword);