import { useState, useEffect } from 'react';
import useAPI from '../../api/apiHandler';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line
import { setLoading } from '../../redux/states/statesActions';
import { SET_USER_DATA } from '../../redux/auth/authTypes';
import moment from 'moment';

const Hook = (props) => {
    const dispatch = useDispatch();
    const [setRequest] = useAPI();
    const [searchText, setSearchText] = useState('');
    const { products, tickets, tablet, setComments, fetchTickets, callback } = props;
    const [data, setData] = useState([])
    const [users, setUsers] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(tablet ? -1 : 10)
    const [rows, setRows] = useState([])
    const [selected, setSelected] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])

    const removeUser = (registerId) => {
        dispatch(setLoading(true))
        setRequest({
            url: `event/user?calendar_user_id=${registerId}`,
            method: 'DELETE',
        }).then(res => {
            if (res && fetchTickets) {
                fetchTickets();
            }
            dispatch(setLoading(false))
        })
          .catch(() => dispatch(setLoading(false)))
    };

    const fetchUsers = () => {
        let url = `users?page=1&limit=99999`;
        return setRequest({
            url: url,
            method: 'GET',
        }).then(res => {
            if (res && res.data && res.data.content) {
                setUsers(res.data.content.map((item) => {
                    return {
                        id: item.id,
                        name: item.name,
                        ssn: item.ssn,
                        email: item.mail
                    }
                }))
            }
        })
    }

    const clearSearch = () => {
        setSearchText('');
    }

    const search = (text) => {
        setSearchText(text.toUpperCase());
    }

    const sortAndPage = () => {
        dispatch(setLoading(true))
        let _data = JSON.parse(JSON.stringify(data));
        if (tablet) {
            if (searchText)
                _data = _data.filter(item => item.name.toUpperCase().search(searchText) >= 0 || item.ssn.toUpperCase().search(searchText) >= 0);
            _data.sort((a, b) => (a.user.name > b.user.name) ? 1 : (a.user.name === b.user.name) ? ((a.name > b.name) ? 1 : -1) : -1);
        }
        else {
            _data.sort((a, b) => (a.user.name > b.user.name) ? 1 : (a.user.name === b.user.name) ? ((a.id > b.id) ? 1 : -1) : -1);
        }

        let last_id, last_cdate = null;
        if (setComments) {
            const _comments = _data.filter(item => item.comments);
            let comments = [];

            _comments.forEach(element => {
                // if (last_id !== element.user.id || last_cdate !== element.cdate) {
                comments.push({
                    id: element.user.id,
                    date: element.cdate,
                    name: element.name,
                    ssn: element.ssn,
                    comment: element.comments,
                    products: [{
                        id: element.product.id,
                        name: element.product.name,
                        quantity: 1
                    }]
                });
                last_id = element.user.id;
                last_cdate = element.cdate;
                // }
                // else {
                //     let comment = comments.find(item => last_id === item.id && last_cdate === item.date);
                //     if (comment) {
                //         let product = comment.products.find(prod => prod.id === element.product.id);
                //         if (!product)
                //             comment.products.push({
                //                 id: element.product.id,
                //                 name: element.product.name,
                //                 quantity: 1
                //             });
                //         else
                //             product.quantity++;
                //     }
                // }
            });
            setComments(comments);

            if (pageSize > 0) {
                _data = _data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
            }
        }

        last_id = null;
        last_cdate = null;
        _data.forEach(element => {
            if (tablet) {
                if (last_id !== element.user.id) {
                    element.title = true;
                    last_id = element.user.id;
                }
                else
                    element.title = false;
            }
            else {
                if (last_id !== element.user.id || last_cdate !== element.cdate) {
                    element.title = true;
                    last_id = element.user.id;
                    last_cdate = element.cdate;
                }
                else
                    element.title = false;
            }
        });
        setSelected([]);
        setRows(_data);
        dispatch(setLoading(false))
    }

    const toogleSelected = (id) => {
        let _data = JSON.parse(JSON.stringify(selected));
        const index = _data.findIndex(item => Number(item) === Number(id));
        if (index >= 0)
            _data.splice(index, 1);
        else
            _data.push(id);

        setSelected(_data);
    }

    const selectAll = () => {
        if (selected && selected.length > 0 && selected.length === rows.length)
            setSelected([]);
        else {
            let _data = rows.map(item => item.id);
            setSelected(_data);
        }
    }

    const checkinUser = (registerId) => {
        dispatch(setLoading(true))
        var checkin = new FormData();
        checkin.append("calendar_user_id", Number(registerId));
        checkin.append("checkin", moment().unix());
        setRequest({
            url: `event/user`,
            method: 'PATCH',
            data: checkin
        }).then(res => {
            if (res) {
                callback(res.data);
            }
            dispatch(setLoading(false))
        })
          .catch(() => dispatch(setLoading(false)))
    };

    const checkoutUser = (registerId) => {
        dispatch(setLoading(true))
        var checkout = new FormData();
        checkout.append("calendar_user_id", Number(registerId));
        checkout.append("checkout", moment().unix());
        setRequest({
            url: `event/user`,
            method: 'PATCH',
            data: checkout
        }).then(res => {
            if (res) {
                callback(res.data);
            }
            dispatch(setLoading(false))
        })
          .catch(() => dispatch(setLoading(false)))
    };

    const updateUser = (res) => {
        let _data = JSON.parse(JSON.stringify(rows));
        let index = _data.findIndex(item => Number(item.id) === Number(res.id));
        if (index >= 0) {
            _data[index].attendance.checkin = res.checkin ? moment.unix(res.checkin).format('HH:mm') : null;
            _data[index].attendance.checkout = res.checkout ? moment.unix(res.checkout).format('HH:mm') : null;
        }
        setRows(_data);
    }

    useEffect(() => {
        if (selected) {
            let duplicates = rows.filter(row => selected.includes(row.id)).map(row => { return { id: row.user.id, email: row.user.email } });
            setSelectedUsers(duplicates.filter((elem, index) => duplicates.findIndex(obj => obj.id === elem.id) === index));
        }
        else
            setSelectedUsers([]);
    }, [selected])

    useEffect(() => {
        if (!users)
            fetchUsers();
    }, [])

    useEffect(() => {
        if (tickets && users) {
            let _data = tickets.map((item) => {
                const user = users.find(x => Number(x.id) === Number(item.user_id));
                const product = products.find(x => Number(x.id) === Number(item.ticket));
                return {
                    index: 0,
                    id: item.id,
                    name: item.name,
                    ssn: item.ssn,
                    cdate: item.cdate,
                    comments: item.message,
                    user: {
                        id: item.user_id,
                        name: user ? user.name : null,
                        email: user ? user.email : null,
                        ssn: user ? user.ssn : null
                    },
                    product: {
                        id: item.ticket,
                        name: product ? product.name : null,
                        member: item.member,
                    },
                    attendance: {
                        checkin: item.checkin ? moment.unix(item.checkin).format('HH:mm') : null,
                        checkout: item.checkout ? moment.unix(item.checkout).format('HH:mm') : null,
                    },
                    title: false
                }
            });
            setData(_data);
        }
    }, [tickets, users])

    useEffect(() => {
        if (data) {
            sortAndPage();
        }
    }, [data, pageSize, pageNumber, searchText])

    return [rows, selected, toogleSelected, selectAll, search, clearSearch, selectedUsers, data, pageNumber, pageSize, setPageNumber, setPageSize, removeUser, checkinUser, checkoutUser];
}

export default Hook;