import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const styles = makeStyles(theme => ({
    gridWrapper: {
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      alignItems: 'center'
    },
    imgWrap: {
      width: '100%',
      marginBottom: 60,
      display: 'flex',
      justifyContent: 'center',
    }
  }));
  return styles();
}
export default theme;

