
import React, { useState } from 'react';
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import Button from '../../../components/Button';
import { InputField } from '../../../components/Inputs';
import translate from '../../../functions/applyTranslation';
import theme from './style';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function AddManual(props) {
  const { modal, closeModal, submitModal } = props;
  const classes = theme();
  const [data, setData] = useState({
    name: '',
    ssn: '',
    comment: '',
  })
  const handleClose = () => {
    closeModal();
  };
  const formValid = () => {
    return !data.name.length || !data.ssn.length
  }
  const handleSubmit = () => {
    submitModal(data);
    setData({
      name: '',
      ssn: '',
      comment: '',
    })
    closeModal();
  }
  return (
    <div>
      <Dialog
        open={modal === 'add'}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>
          {translate('booking', 'title_add_manual')}
          <small>{translate('booking', 'subtitle_add_manual')}</small>
        </DialogTitle>
        <DialogContent>
          <Grid container className={classes.usersTicketWrap}>
            <Grid item xs={9}>
              <InputField value={data.name} placeholder={`${translate('booking', 'label_name')}`} fullWidth
                onChange={(e) => { setData({ ...data, name: e }) }} />
            </Grid>
            <Grid item xs={3}>
              <InputField value={data.ssn} placeholder={translate('booking', 'label_ssn')} fullWidth
                onChange={(e) => { setData({ ...data, ssn: e }) }} />
            </Grid>
          </Grid>
          <InputField value={data.comment}
            className={classes.content}
            multiline
            placeholder={translate('booking', 'label_add_manual_comments')} fullWidth
            onChange={(e) => {
              setData({ ...data, comment: e })
            }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} label={translate('', 'button_cancel')} />
          <Button disabled={formValid()} onClick={handleSubmit} label={translate('', 'button_confirm')} fill icon='save' />
        </DialogActions>
      </Dialog>
    </div>
  );
}
