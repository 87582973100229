import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Slide} from '@material-ui/core';
import Button from '../../../Button';
import {Label, InputField,} from '../..'
import {Text} from '../../../DataDisplay'
import translate from '../../../../functions/applyTranslation';
import theme from './style';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function AlertDialogSlide(props) {
  const {modal, functions, submitModal} = props;
  const classes = theme();
  const MAX_FILE_SIZE = 8000000; //8 Mb
  const [data, setData] = useState({
    text: '',
    file: null,
  });
  const [correctFileSize, setCorrectFileSize] = useState(false);

  const handleClose = () => {
    functions.closeModal();
  };
  const formValid = () => {
    return !data.text.length || !data.file || data.file.size > MAX_FILE_SIZE
  }
  const triggerUpload = () => {
    let element = document.getElementById('filehandler');
    element.value = "";
    element.click();
  }
  const handleFileSelect = (e) => {
    let file = e.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      setCorrectFileSize(false);
    } else {
      setCorrectFileSize(true);
    }
    setData({...data, file: file})
  }
  const handleSubmit = () => {
    submitModal('file', data);
    setData({
      text: '',
      file: null,
    })
    functions.closeModal();
  }
  return (
    <div>
      <Dialog
        open={modal === 'file'}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle
          className={classes.dialogTitle}>{translate('news-edit', 'title_editor_file_uploader_modal')}</DialogTitle>
        <DialogContent>
          <Label text={translate('news-edit', 'form_label_file_link')}>
            <InputField value={data.text} fullWidth invalid={!data.text.length} onChange={(e) => {
              setData({...data, text: e})
            }}/>
          </Label>
          <Label text={translate('news-edit', 'form_label_file_select')}>
            <Button onClick={triggerUpload} label={translate('', 'button_upload')} icon='upload'/>
            <input type='file' id='filehandler' hidden className='fileInput' onChange={handleFileSelect}/>
          </Label>
          <Label text={translate('news-edit', 'form_label_chosen_file')}>
            {data.file &&
            <>
              <Text text={data.file.name}/>
              {!correctFileSize && <Text className={classes.fileTooBigText} text={translate('', 'file_size_too_big')}/>}
            </>
            }
          </Label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} label={translate('', 'button_cancel')}/>
          <Button disabled={formValid()} onClick={handleSubmit} label={translate('', 'button_confirm')} fill
                  icon='save'/>
        </DialogActions>
      </Dialog>
    </div>
  );
}
