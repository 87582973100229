import React from 'react';
import theme from './style';
import moment from 'moment';
import Accordion from '../Accordion';
import CommentIcon from '@material-ui/icons/Comment';
import translate from '../../../functions/applyTranslation';
import Text from '../Text'
import { useSelector } from 'react-redux';

const ProfileHeader = (props) => {
    const userData = useSelector(state => state.auth.userData);
    const classes = theme();
    const {
        id,
        ssn,
        name,
        path,
        mail,
        role,
        active,
        expanded,
        handleExpanded,
        onClick
    } = props;

    const allowEdit = false;
    const key = `user_${id}`;

    const handleClick = (id) => {
        if (onClick) {
            onClick(id)
        }
    }

    return (
        <div className={classes.main} onClick={() => handleClick(id)}>
            <div className={classes.left}>
                <div className={`${classes.author} ${!active ? 'inactive' : ''}`}>
                    {`${ssn} • ${name} • ${mail} • ${translate('profile', role)}${!active ? `• ${translate('profile', 'inactive')}` : ''}`}
                </div>
            </div>
        </div >
    );
}

export default ProfileHeader;