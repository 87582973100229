
import React, { createContext, useEffect, useState } from "react";
import useBlog from './hooks/blog';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const BlogContext = createContext({});

export const Provider = props => {
  const history = useHistory();
  const [dispatch, setLoading, setRequest, blog, comments, fetchBlog, fetchComments, deleteBlog, deleteComment, handleAcceptBlogRules, notFound] = useBlog();
  const params = useParams();
  const { id } = params;

  const acceptedBlogRules = useSelector(state => state.settings.acceptedBlogRules);
  const count = useSelector(state => state.states.count);
  const [modal, setModal] = useState({ key: '' })
  const closeModal = () => {
    setModal();
  }
  const openRules = () => {
    setModal({ key: 'rules' });
  }

  const openDelete = (item, id) => {

    let key = 'delete';
    if (item === 'comment_id')
      key = 'delete_comment';

    setModal({ key: key, [item]: id });
  }

  const openUpdate = (item, id) => {
    const comment = comments.find(x => Number(x.comment.id) === Number(id))
    setModal({ key: 'edit_comment', comment_id: id, content: (comment || {}).comment.content });
  }

  const handleNavigation = (options) => {
    switch (options.action) {
      case 'edit':
        if (options.item === 'blog_id')
          history.push(`/${options.path}/${options.value}/edit`);
        break;
      case 'delete':
        break;
      default:
        history.push(`/blog`);
        break;
    }
  }

  const updateComment = (comment_id, content) => {
    dispatch(setLoading(true))
    var formdata = new FormData();

    formdata.append("comment_id", comment_id);
    formdata.append("content", content);

    let url = `comment/update`;
    const method = `PATCH`;
    setRequest({
      url: url,
      method: method,
      data: formdata,
    }).then(res => {
      if (res && res.data) {
        closeModal();
        fetchComments(id);
      }
      dispatch(setLoading(false))
    })
  }


  const [expanded, setExpanded] = useState()
  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    closeModal();
    fetchBlog(id)
    fetchComments(id)
  }, [count, id])

  useEffect(() => {
    fetchBlog(id)
    fetchComments(id)
  }, [])

  const blogContext = {
    blog,
    comments,
    handleNavigation,
    expanded,
    handleExpanded,
    modal,
    closeModal,
    openRules,
    openDelete,
    openUpdate,
    deleteBlog,
    deleteComment,
    updateComment,
    handleAcceptBlogRules,
    acceptedBlogRules,
    notFound
  };

  return <BlogContext.Provider value={blogContext}>{props.children}</BlogContext.Provider>;
};

export const { Consumer } = BlogContext;