
import React, { createContext, useEffect, useState } from "react";
import useAPI from '../../api/apiHandler';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/states/statesActions';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';

export const BookingContext = createContext({});

export const Provider = props => {
  const dispatch = useDispatch();
  const params = useParams();
  const [setRequest] = useAPI();
  const { id } = params;
  const history = useHistory();
  const [data, setData] = useState()
  const [products, setProducts] = useState([])
  const [ticketsSold, setTicketsSold] = useState([]);

  const fetchEventDetail = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `events?limit=1&page=1&event_id=${id}`,
      method: 'GET',
    }).then(res => {
      if (res && res.data) {
        let _data = res.data.content[0];
        setProducts(_data.products.map(item => { return { ...item, title: item.name } }))
        setData(_data)
      }
      dispatch(setLoading(false))
    })
  }
  const fetchSoldTickets = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `event/users?event_id=${id}`,
      method: 'GET',
    }).then(res => {
      if (res && res.data) {
        let keys = Object.keys(res.data);
        let data = res.data;
        let _data = keys.map(key => {
          return {
            id: key,
            ...data[key],
          }
        })
        setTicketsSold(_data);
      }
      dispatch(setLoading(false))
    })
  }

  const callback = (user) => {
    let _data = JSON.parse(JSON.stringify(ticketsSold));
    let index = _data.findIndex(item => Number(item.id) === Number(user.id));
    if (index >= 0) {
        _data[index] = user;
    }
    setTicketsSold(_data);
  }
  
  useEffect(() => { fetchEventDetail() }, [])
  useEffect(() => { fetchSoldTickets() }, [])

  const _bookingContext = {
    data,
    ticketsSold,
    products,
    fetchSoldTickets,
    callback
  };
  return <BookingContext.Provider value={_bookingContext}>{props.children}</BookingContext.Provider>;
};

export const { Consumer } = BookingContext;