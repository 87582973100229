import {useDispatch, useSelector} from 'react-redux';
import {
  setError
} from '../redux/states/statesActions'
import {setLogin, setLogout} from '../redux/auth/authActions';
import axios from 'axios';
import translate from '../functions/applyTranslation';
import moment from 'moment';

const server = window.location.origin;
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};


const APICall = () => {
  const dispatch = useDispatch();
  const {access_token, expires_in, refresh_token, lastRefresh} = useSelector(state => state.auth);
  let awaiting = false;

  const refreshToken = async () => {
    //if (!awaiting && access_token) {
    if (!awaiting && access_token && refresh_token && (lastRefresh < moment().add(-120, 'seconds').unix())) {
      awaiting = true;
      setTimeout(() => {
        awaiting = false;
      }, 1000);
      try {
        const config = {
          method: 'POST',
          url: `${server}/api/refresh_token`,
          headers: {'Authorization': `Bearer ${refresh_token}`}
        }
        const response = await axios(config);

        if (response.status !== 200) {
          dispatch(setLogout());
        } else if (response && response.data) {
          let {access_token, refresh_token, expires_in} = response.data;
          const newExpiresIn = expires_in + moment().unix();

          await dispatch(setLogin({
            access_token: access_token,
            refresh_token: refresh_token,
            expires_in: newExpiresIn,
            role: parseJwt(access_token).role,
            lastRefresh: moment().unix()
          }));
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  const apiCall = async (props, atempt = 1) => {

    if (access_token && (moment().unix() > expires_in)) {
      dispatch(setLogout())
      return;
    }

    if (!props) {
      let err = {error: 'Missing Props'}
      throw err
    } else {
      const {
        method,
        url,
        data,
        displayError,
        responseType
      } = props;

      let contentType = 'application/json';
      if (data instanceof FormData)
        contentType = 'multipart/form-data';

      if (url && !url.startsWith('notifications') && !url.startsWith('login') && !url.startsWith('check_login_status')) {
        await refreshToken();
      }

      if (url) {
        let config = {
          method: method || 'GET',
          url: `${server}/api/${url}`,
          data: data,
          headers: {'Content-Type': contentType}
        }
        if (access_token) {
          config.headers['Authorization'] = `Bearer ${access_token}`;
        }
        if (responseType) {
          config.responseType = responseType
        }

        return await axios(config).catch(err => {
          let _err = {}
          if (err.response.status > 499) {
            _err = {
              error: translate('', 'generic_api_call_error'),
              err: null,
            }
          } else {
            _err = {
              error: typeof err.response.data.message === 'string' ? translate('api', err.response.data.message) : translate('', 'generic_api_call_error'),
              err: err.response,
            }
          }
          //FIXME: handle token refresh & retry to call before throwing error
          console.log(_err)
          if (_err.err.status === 401) {
            dispatch(setLogout());
          }
          if (displayError)
            dispatch(setError(_err))
          return false;
        })
      } else {
        let err = {error: 'Missing URL'}
        throw err
      }
    }
    ;
  };

  const apiMultipleCalls = async (options, atemp = 1) => {
    if (access_token && moment().unix() > expires_in) {
      dispatch(setLogout())
      return;
    }
    if (!options && !options.length) {
      let err = {error: 'Missing Requests'}
      throw err
    } else {
      return Promise.all(options.map(item => apiCall(item, atemp)));
    }
  };


  return [apiCall, apiMultipleCalls];
}
export const useImage = () => {
  const access_token = useSelector(state => state.auth.access_token);
  const getImageURL = (id) => {
    return `${server}/api/file/download?file_id=${id}&token=${access_token}`;
    // return `/api/file/download?file_id=${id}&token=${access_token}`;
  }
  return [getImageURL];

}
export default APICall;
