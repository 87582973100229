import React, { Fragment } from 'react';
import translate from '../../../functions/applyTranslation';
import { Text } from '../../../components/DataDisplay';
import theme from './style';
import { Grid } from '@material-ui/core';
import moment from 'moment';

const Resume = (props) => {
    const classes = theme();

    const { max, max_ppl, pay, registered_users, friends, guests, children } = props;


    return (
        <Fragment>
            <Text className={classes.title} text={`${translate('booking', 'label_statistics_title')}`} />
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <Text className={classes.detail} text={`${translate('booking', 'label_statistics_registed')} ${registered_users}`} />
                    <Text className={classes.detail} text={`${translate('booking', 'label_statistics_paid')} ${pay}`} />
                    <Text className={classes.detail} text={`${translate('booking', 'label_statistics_total_places')} ${max_ppl} ${max ? ` (${max})` : ''}`} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Text className={classes.detail} text={`${translate('booking', 'label_statistics_friends')} ${friends || 0}`} />
                    <Text className={classes.detail} text={`${translate('booking', 'label_statistics_guests')} ${guests || 0}`} />
                    <Text className={classes.detail} text={`${translate('booking', 'label_statistics_children')} ${children || 0}`} />
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default Resume;