import React, {useEffect, useState} from 'react';
import theme from './style';
import ClampLines from 'react-clamp-lines';
import moment from 'moment';
import translate from '../../../functions/applyTranslation';
import clsx from 'clsx'
import {useHistory} from 'react-router-dom';
import useAPI, {useImage} from '../../../api/apiHandler';
import {Typography} from "@material-ui/core";
import {setLoading} from "../../../redux/states/statesActions";
import {useDispatch} from "react-redux";

const ListView = (props) => {
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const history = useHistory();
  const [getImageURL] = useImage();
  const [guide, setGuide] = useState({});
  const {
    item,
    width,
    path,
    showStatus,
    statusList,
    guides
  } = props;
  const classes = theme({width: width});
  const {
    jfm_event,
    event,
    asset,
  } = item;
  const {
    id,
    event_start,
    tour_guide
  } = jfm_event;
  const {
    title,
    image,
    type
  } = event;
  const {
    name,
    description
  } = asset;
  const hanldeNavigation = () => {
    history.push(`${path}/${id}`)
  }
  const statusName = () => {
    if (!statusList) return '';
    var status = statusList.find(x => Number(x.id) === Number(jfm_event.event_status));
    if (!status) return '';
    return status.title;
  }

  let url = getImageURL(image);//`${window.location.origin}${image}`
  if (type !== 'event')
    url = `${process.env.PUBLIC_URL}/img/meeting.png`;

  const fetchGuideData = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `user/profile?user_id=${tour_guide}`,
      method: 'GET',
    }).then(res => {
      if (res && res.data) {
        setGuide({
          name: res.data.name
        });
      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  }

  useEffect(() => {
    if (tour_guide && tour_guide !== "0") {
      fetchGuideData();
    }
  }, []);

  return (
    <div className={classes.gridViewWrap}>
      <div className={'image'} style={{backgroundImage: `url(${url})`}} onClick={hanldeNavigation}/>
      <div
        className={clsx(classes.messageWrap, classes.gridMessageWrap)}
        style={!jfm_event.tour_guide || jfm_event.tour_guide === "0" ? {marginBottom: 3} : {}}
      >
        <div className={classes.dateWrap}>
          <div className={'itemDate'}>{event_start ? moment.unix(event_start).format('DD MMMM, YY') : '-'}</div>
          {showStatus &&
          <div className={'cartStatus'}>{`(${statusName()})`}</div>
          }
        </div>
        <div className={'itemTitle'} onClick={hanldeNavigation}>
          {title}
        </div>
        <ClampLines
          text={description || ''}
          lines={2}
          ellipsis="..."
          buttons={false}
          className="itemText"
          innerElement="p"
        />
        <div className={tour_guide && tour_guide !== "0" ? classes.infoWrap : classes.infoWrapNoGuide}>
          {tour_guide && tour_guide !== "0" &&
          <div>
            <Typography
              className={classes.guideInfo}
              variant="caption"
            >
              {translate('activities', 'guide_activity_grid_view')}: {guide.name}
            </Typography>
          </div>
          }
          <div className={'actionOpen'} onClick={hanldeNavigation}>
            {translate('', 'label_read_more')}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ListView;