
import React, { createContext, useEffect, useState } from "react";
import useBlog from './hooks/blog';
import useFilters from './hooks/filter';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const BlogContext = createContext({});

export const Provider = props => {
  const history = useHistory();
  const [filters, setFilters] = useFilters();
  const [blog, fetchBlog, deleteBlog, handleAcceptBlogRules] = useBlog();
  const count = useSelector(state => state.states.count);
  const acceptedBlogRules = useSelector(state => state.settings.acceptedBlogRules);
  const [modal, setModal] = useState({ key: '' })
  const closeModal = () => {
    setModal();
  }

  const openRules = () => {
    setModal({ key: 'rules' });
  }

  const openDelete = (item, id) => {
    setModal({ key: 'delete', [item]: id });
  }

  const handlePagination = (action) => {
    setFilters('page', (action === 'next' ? filters.page += 1 : filters.page -= 1))
  }

  const [expanded, setExpanded] = useState()
  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNavigation = (options) => {
    switch (options.action) {
      case 'new':
          history.push(`${options.path}/new/edit`);
        break;
      case 'edit':
        if (options.item === 'blog_id')
          history.push(`${options.path}/${options.value}/edit`);
        break;
      case 'delete':
        break;
      default:
        history.push(`${options.path}/${options.value}`);
        break;
    }
  }

  useEffect(() => {
    closeModal()
    fetchBlog(filters)
  }, [filters, count])

  const blogContext = {
    filters,
    blog,
    setFilters,
    expanded,
    handleExpanded,
    handlePagination,
    handleNavigation,
    modal,
    closeModal,
    openRules,
    openDelete,
    deleteBlog,
    handleAcceptBlogRules,
    acceptedBlogRules,
  };

  return <BlogContext.Provider value={blogContext}>{props.children}</BlogContext.Provider>;
};

export const { Consumer } = BlogContext;