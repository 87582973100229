import React from 'react';
import Media from 'react-media';
import {Grid} from "@material-ui/core";
import theme from './style';
import Logo from './Logo'
import SearchBar from './SearchBar'
import UserInfo from './UserInfo'
import { useHistory } from 'react-router-dom';

const MobileHeader = (props) => {
  const {
    tabletMode,
    handleLogout,
    handleNavigation,
    toggleMobileMyPages
  } = props;

  return (
    <Grid  container
      direction="row"
      justify="space-between"
      alignItems="center" 
    >
      <Grid item xs={4}>
        <Logo onClick={handleNavigation} />
      </Grid>
      <Grid item xs={4}>
        <UserInfo tabletMode={tabletMode} handleLogout={handleLogout} toggleMobileMyPages={toggleMobileMyPages}/>
      </Grid>
      <Grid item xs={12}>
        <SearchBar/>
      </Grid>
    </Grid>
  );
};

const Header = (props) => {
  const history = useHistory();
  const classes = theme();
  const {
    tabletMode,
    handleLogout,
    toggleMobileMyPages
  } = props;

  const handleNavigation=()=> {
    history.push('/');
  }
  return (
    <div className={classes.header}>
      <Media
        queries={{
          desktop: "(min-width: 541px)",
          mobile: "(max-width: 540px)"
        }}
      >
        {matches => (
          <>
            {matches.mobile && (
            <MobileHeader
              tabletMode
              handleLogout={handleLogout}
              handleNavigation={handleNavigation}
              toggleMobileMyPages={toggleMobileMyPages}
            />)}
            {matches.desktop && (
              <>
                <Logo tabletMode={tabletMode} onClick={handleNavigation} />
                {!tabletMode && <SearchBar />}
                <UserInfo tabletMode={tabletMode} handleLogout={handleLogout} />
              </>
            )}
          </>
        )}
      </Media>
    </div>
  );
}
export default Header;