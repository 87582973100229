import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    dropzoneGroup: {
      display: 'flex',
      // height: 22,
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
      padding: 20,
      borderWidth: 1,
      borderStyle: 'dashed',
      borderColor: ColorSchemes.mediumBlue,
      flexDirection: 'column',
      '& svg': {
        cursor: 'pointer',
        fill: ColorSchemes.mediumBlue,
        fontSize: 35,
        height: 35,
        marginBottom: 10,
        '&:hover': {
          fill: ColorSchemes.orange,
        }
      },
    },
    invalid: {
      borderColor: ColorSchemes.darkOrange,
      borderWidth: '3px'
    },
    text: {
      color: ColorSchemes.darkGrey,
      fontSize: 14,
      marginLeft: 5,
    },
    galleryTextWrap: {
      display: 'inline-flex',
      color: ColorSchemes.darkBlue,
      fontSize: 12,
    },
    galleryTrigger: {
      cursor: 'pointer',
      color: ColorSchemes.mediumBlue,
      fontSize: 12,
      paddingLeft: 3,
      paddingRight: 3,
      textDecoration: 'underline',
      '&:hover': {
        color: ColorSchemes.orange,
      }
    }
  }));
  return styles();
}
export default theme;
