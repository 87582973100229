import {SET_TRANSLATIONS, SET_ACCEPT_BLOG_RULES, SET_WIDE_CARD} from './settingsTypes'

export const setTranslations = (payload) => {
  return {
    type: SET_TRANSLATIONS,
    payload: payload
  }
};

export const acceptBlogRules = (payload) => {
  return {
    type: SET_ACCEPT_BLOG_RULES,
    payload: payload
  }
};

export const setWideCard = payload => {
  return {
    type: SET_WIDE_CARD,
    payload
  }
};
