import React, { memo } from 'react';
import theme from './style';
const LogoComponent = (props) => {
  const { height, onClick } = props;
  const classes = theme({ height });
  const handleClick = () => {
    if (onClick)
      onClick();
  }
  return (
    <img src='/img/logoBlue.png' alt='Logo' className={`${classes.logo} ${onClick ? 'pointer' : ''}`} onClick={handleClick} />
  );
}
export default memo(LogoComponent);