import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    searchContainer: {
      width: '50%',
      position: 'relative',
      '@media (max-width: 540px)': {
        width: "100%",
        marginTop: 5
      }
    },
    searchInput: {
      height: 34,
      width: '100%',
      zIndex: 1000,
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 0
      },
      '& .MuiInputBase-root': {
        height: 32,
        '& input': {
          background: ColorSchemes.white,
          border: `1px solid ${ColorSchemes.lowDarkGrey}`,
          borderRadius: '5px 0px  0px 5px',
          padding: 11,
          fontSize: 14,
          height: 8,
        }
      },
      '& .MuiOutlinedInput-notchedOutline': {
        height: 32,
        borderColor: 'transparent !important'
      },
      '& .MuiInputAdornment-positionEnd': {
        marginLeft: 'auto',
        height: 32,
        width: 32,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        background: ColorSchemes.lowDarkGrey,
        borderRadius: '0px 5px 5px 0px',
        padding: '0px 6px',
        '& svg': {
          fill: ColorSchemes.white
        }
      },
      '& :hover': {
        '& input': {
          borderColor: `${ColorSchemes.orange} !important`,
        },
        '& .MuiInputAdornment-positionEnd': {
          backgroundColor: ColorSchemes.orange,
          cursor: 'pointer'
        }
      },
      '&.active': {
        '& input': {
          borderColor: `${ColorSchemes.orange} !important`,
        },
        '& .MuiInputAdornment-positionEnd': {
          backgroundColor: ColorSchemes.orange,
        }
      }
    },
    title: {
      lineHeight: '14px !important',
      fontSize: '12px !important',
      color: `${ColorSchemes.orange} !important`,
    },
    subTitle: {
      marginTop: '17px !important',
      fontWeight: 700,
      lineHeight: '14px !important',
      fontSize: '12px !important',
      paddingBottom: '3px !important'
    },
    link:{
      lineHeight: '14px !important',
      fontSize: '12px !important',
      padding: '0 !important'
    },
    floatRight: {
      textAlign: 'right',
    },
    inputHolder: {
      marginRight: 55,
      marginBottom: 20,
    },
    backDropSearchInput: {
      width: '100%',
      '& input': {
        color: ColorSchemes.highDarkGrey,
        fontSize: 29
      }
    },
    item: {
      color: `${ColorSchemes.black} !important`,
      padding: `0 !important`,
      fontSize: `12px !important`,
      marginBottom: '3px !important',
      fontWeight: `normal !important`,
      display: 'block !important',
      '&:hover': {
        color: `${ColorSchemes.orange} !important`,
      }
    },
    resultsContainer: {
      position: 'absolute',
      top: 16,
      padding: '31px 15px 15px 15px',
      background: ColorSchemes.white,
      borderRadius: 5,
      border: `solid 1px ${ColorSchemes.orange} !important`,
      zIndex: 999,
    }
  }));
  return styles();
}
export default theme;

