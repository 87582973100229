
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import Button from '../../../Button';
import { Label, InputField, } from '../..'
import { Image, } from '../../../DataDisplay'
import translate from '../../../../functions/applyTranslation';
import generatePreviewImgUrl from '../../../../functions/imgPreview';
import theme from './style';
import { Check } from '../../../Inputs'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function AlertDialogSlide(props) {
  const { modal, functions, submitModal } = props;
  const classes = theme();
  const [data, setData] = useState({
    text: '',
    file: null,
    size: null,
    align: null,
  })
  const handleClose = () => {
    functions.closeModal();
  };
  const formValid = () => {
    return !data.text.length || !data.file
  }
  const triggerUpload = () => {
    let element = document.getElementById('filehandler');
    element.value = "";
    element.click();
  }
  const handleFileSelect = (e) => {
    let file = e.target.files[0];
    generatePreviewImgUrl(file, previewImgUrl => {
      setData({ ...data, file: file, preview: previewImgUrl })
    })
  }
  const handleSubmit = () => {
    submitModal('image', data);
    setData({
      text: '',
      file: null,
      size: null,
      align: null,
    })
    functions.closeModal();
  }
  const handleAlign = (value) => {
    setData({ ...data, align: (value === data.align) ? null : value })
  }
  const handleSize = (value) => {
    setData({ ...data, size: (!value || value === data.size) ? null : value })
  }
  return (
    <div>
      <Dialog
        open={modal === 'image'}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>{translate('news-edit', 'title_editor_image_uploader_modal')}</DialogTitle>
        <DialogContent>
          <Label text={translate('news-edit', 'form_label_image_alt')}>
            <InputField value={data.text} fullWidth invalid={!data.text.length} onChange={(e) => { setData({ ...data, text: e }) }} />
          </Label>
          <Label text={translate('news-edit', 'form_label_image_select')}>
            <Button onClick={triggerUpload} label={translate('', 'button_upload')} icon='upload' />
            <input type='file' id='filehandler' hidden className='fileInput' onChange={handleFileSelect} accept="image/*" />
          </Label>
          <Label text={translate('news-edit', 'form_label_image_align')}>
            <div>
              <Check value={data.align === 1} label={translate('news-edit', 'form_label_image_align_left')} className={classes.marginRight} onChange={() => handleAlign(1)} />
              <Check value={data.align === 2} label={translate('news-edit', 'form_label_image_align_right')} onChange={() => handleAlign(2)} />
            </div>
          </Label>
          <Label text={translate('news-edit', 'form_label_image_size')}>
            <div>
              <Check value={data.size === null} label={translate('news-edit', 'form_label_image_size_original')} className={classes.marginRight} onChange={() => handleSize()} />
              <Check value={data.size === 1} label={translate('news-edit', 'form_label_image_size_small')} className={classes.marginRight} onChange={() => handleSize(1)} />
              <Check value={data.size === 2} label={translate('news-edit', 'form_label_image_size_medium')} className={classes.marginRight} onChange={() => handleSize(2)} />
              <Check value={data.size === 3} label={translate('news-edit', 'form_label_image_size_big')} onChange={() => handleSize(3)} />
            </div>
          </Label>
          {data.file &&
            <Label text={translate('news-edit', 'form_label_chosen_image')}>
              <Image url={data.preview} className={classes.image} />
            </Label>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} label={translate('', 'button_cancel')} />
          <Button disabled={formValid()} onClick={handleSubmit} label={translate('', 'button_confirm')} fill icon='save' />
        </DialogActions>
      </Dialog>
    </div>
  );
}
