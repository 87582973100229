import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme'
const theme = (props) => {
  const styles = makeStyles(theme => ({
    viewContainer: {
      position: 'relative',
      minHeight: '100vh',
      backgroundColor:ColorSchemes.background,
    },
    contentWrap: {
      paddingBottom: 54,
      paddingTop: 112,
      paddingLeft: 10,
      paddingRight: 10,
      '@media (max-width: 540px)': {
        paddingTop: 151
      }
    },
    gridContainer: {
      justifyContent: 'center'
    },
    paperContainer: {
      padding: '20px 25px'
    }
  }));
  return styles();
}
export default theme;

