import { useEffect, useState } from 'react';
import useAPI from '../../api/apiHandler';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line
import { setLoading } from '../../redux/states/statesActions';
import { useHistory, useParams, useLocation } from 'react-router-dom'
import moment from 'moment';
import useReplace from '../../functions/replaceToken';
import usePermissionsHook from '../../functions/permissions';

const ProfileHook = (props) => {
  const history = useHistory();
  const location = useLocation();
  const replace = useReplace();
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.userData);
  const [setRequest] = useAPI();
  const [expanded, setExpanded] = useState(false);
  const [personal, setPersonal] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [settings, setSettings] = useState(null);
  const [booking, setBooking] = useState([]);
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const [saveMode, setSaveMode] = useState(null);

  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const setInitialNotification = id => {
    setRequest({
      url: `user/settings?user_id=${id}`,
      method: 'PATCH',
      data: {
        newsletter_subscribed: "1",
        user_id: id,
      },
    })
      .then(() => {
        setNotifications({
          articles: false,
          events: false,
          newsletters: true,
          blogs: false,
          id: id,
          success: false
        });
      })
  }

  const fetchNotifications = (id) => {
    setRequest({
      url: `user/settings?user_id=${id}`,
      method: 'GET',
    }).then(res => {
      if (res && res.data) {
        if (res.data.length > 0) {
          setNotifications({
            articles: res.data[0].article_published_notification == "1",
            events: res.data[0].event_published_notification == "1",
            newsletters: res.data[0].newsletter_subscribed == "1",
            blogs: res.data[0].blog_published_subscription == "1",
            id: id,
            success: false
          });
          setSettings(res.data[0])
        }
        else {
          setInitialNotification(id);
          setSettings(null)
        }
      }
    })
  }

  const fetchBooking = (id) => {
    
    setRequest({
      url: `event/tickets`,
      method: 'GET',
    }).then(res => {
      if (res && res.data) {
        
      }
    })
  }

  const fetchData = () => {
    dispatch(setLoading(true))
    setRequest({
      url: Number(id) > 0 ? `user/profile?user_id=${id}` : 'user/profile',
      method: 'GET',
    }).then(res => {
      if (res && res.data) {
        setPersonal({
          id: res.data.id,
          ssn: res.data.ssn,
          fullName: res.data.name,
          phoneNr: res.data.phone,
          email: res.data.mail,
          role: res.data.role,
          active: res.data.active,
          tour_guide: res.data.tour_guide
        });
        fetchNotifications(res.data.id);
        fetchBooking(res.data.id);
      }
      dispatch(setLoading(false))
    })
  }

  if (Number(id) > 0 && !hasPermission(permissions.USERS_ACCESS))
    history.push('/');

  const handleActions = (action) => {
    history.goBack();
  }

  useEffect(() => {
    if (!personal)
      fetchData();
    if(location.pathname === "/profile/personal-info")
      fetchData(); 
    if (id && Number(id) > 0) {
      setExpanded('personal')
      let extraSettings = hasPermission(permissions.USERS_EDIT) ? 'admin' : null;
      if (Number(userData.id) === Number(id) && !extraSettings)
        extraSettings = 'user';
      setSaveMode(extraSettings);
    }
    else
      setSaveMode('user');

    // eslint-disable-next-line
  }, [location.pathname])

  return [id, booking, personal, settings, notifications, expanded, roles, saveMode, handleExpanded, handleActions, setNotifications];
}

export default ProfileHook;