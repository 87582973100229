import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    group: {
      display: 'block',
      backgroundColor: ColorSchemes.mediumBlue,
      color: ColorSchemes.white,
      marginTop: '7px',
      padding: '3px 4px 2px 4px',
      fontSize: '12px',
      lineHeight: '14px',
      '&.tablet': {
        marginTop: 4,
        marginBottom: 6,
        fontSize: '14px',
        lineHeight: '17px',
        padding: '4px 4px 3px 4px',
      }
    },
    main: {
      width: '100%',
      border: 'solid 1px #8B8B8B',
      paddingBottom: '7px',
      '&.tablet': {
        border: 'none'
      }
    },
    row: {
      paddingTop: '7px',
      display: 'flex',
      '&.tablet': {
        padding: 0,
        borderTop: `solid 1px ${ColorSchemes.black}`,
        '&.borderNone': {
          borderTop: 'none',
        },
        '&.closed' :{
          backgroundColor: '#8B8B8B',
          color: ColorSchemes.white
        }
      }
    },
    element: {
      display: 'inline-flex',
      fontSize: '12px',
      lineHeight: '14px',
      '&.tablet': {
        padding: '10px 0',
        fontSize: '16px',
        lineHeight: '19px',
      }
    },
    header: {
      backgroundColor: ColorSchemes.tableOddBg,
      display: 'flex',
      padding: '6px 0',
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 'bold',
      alignItems: 'center',
      marginBottom: '-7px',
      '&.tablet': {
        marginBottom: '-4px',
        fontSize: '16px',
        lineHeight: '19px',
        padding: '11px 0 10px 0'
      },
      '& $name': {
        display: 'inline-flex',
      },
      '& $ticket': {
        display: 'inline-flex',
      },
      '& $options': {
        display: 'inline-flex',
      },

    },
    name: {
      width: 'calc(40% - 33px)',
      paddingLeft: '6px',
      '&.tablet': {
        width: '40%',
        paddingLeft: 2
      }
    },
    ticket: {
      width: 'calc(30% - 6px)',
      paddingLeft: '6px',
      '&.tablet': {
        width: '30%'
      }
    },
    options: {
      width: 'calc(30% - 6px)',
      paddingRight: '6px',
      flexDirection: 'row-reverse',
      '&.tablet': {
        padding: '2px 0',
        width: '30%'
      },
    },
    unsubscribe: {
      fontSize: '12px !important',
      lineHeight: '14px !important',
      padding: '0 !important',
    },
    checkbox: {
      height: '12px !important'
    },
    iconComments: {
      cursor: 'default',
      color: ColorSchemes.white,
      fontSize: 12,
      marginLeft: 13,
      marginRight: 5
    },
    labelComments: {
      cursor: 'default',
      color: ColorSchemes.white,
      fontWeight: 'bold',
      display: 'inline-flex'
    },
    groupItem: {
      display: 'inline-flex'
    },
    emailWrap: {
      marginTop: 7,
      alignSelf: 'flex-start'
    },
    checkin: {
      margin: 3,
      marginRight: 13,
      fontSize: 14,
      lineHeight: '23px',
      minWidth: '76px !important',
      padding: '6px 10px !important',
      height: '29px !important',
      maxHeight: '29px !important',
      borderRadius: '3px !important',
      color: '#fff !important',
      backgroundColor: '#FF9000 !important',
      '&.in': {
        backgroundColor: '#07A717 !important',
        '&:disabled': {
          backgroundColor: '#07A717 !important',
        }
      },
      '&.out': {
        backgroundColor: '#636363 !important',
        '&:disabled': {
          backgroundColor: '#636363 !important',
        }
      },
    },
    checkout: {
      margin: 3,
      marginRight: 8,
      fontSize: 14,
      lineHeight: '23px',
      minWidth: '76px !important',
      padding: '6px 10px !important',
      height: '29px !important',
      maxHeight: '29px !important',
      borderRadius: '3px !important',
      color: '#fff !important',
      backgroundColor: '#B92D00 !important',
      '&.in': {
        backgroundColor: '#B92D00 !important',
        '&:disabled': {
          backgroundColor: '#B92D00 !important',
        }
      },
      '&.out': {
        backgroundColor: '#636363 !important',
        '&:disabled': {
          backgroundColor: '#636363 !important',
        }
      },
    },
    search: {
      marginBottom: 16
    },
    searchButton: {
      marginLeft: 8,
      fontSize: '16px !important',
      lineHeight: '21px !important',
      color: '#fff !important',
      backgroundColor: '#07A717 !important',
      padding: '6px 10px !important',
      height: '43px !important',
      maxHeight: '43px !important',
      borderRadius: '5px !important',
    },
    searchClearButton: {
      marginLeft: 26,
      fontSize: '16px !important',
      lineHeight: '21px !important'
    },
    searchInput: {
      '& input': {
        lineHeight: '21px !important',
        padding: '13px 14px 11px 14px !important'
      }
    }
  }));
  return styles();
}
export default theme;
