import {useEffect} from "react";
import {withRouter} from "react-router-dom";

const AnchorScroll = ({location, children, data}) => {
  useEffect(() => {
    let element;
    if(data) {
      element = document.getElementById(location.hash.replace("#", ""));
    }
      setTimeout(() => {
        window.scrollTo({
          behavior: element ? "smooth" : "auto",
          top: element ? element.offsetTop - 130 : 0
        });
      }, 100);
    }, [location, data]);

  return children;
};

export default withRouter(AnchorScroll);