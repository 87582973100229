
import React, { createContext, useEffect, useState } from "react";
import useAPI from '../../api/apiHandler';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/states/statesActions';
export const GalleryContext = createContext({});
export const Provider = props => {
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const [category, setCategory] = useState(null);
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([])
  const [dataSettings, setDataSettings] = useState({
    totalItems: 0,
    totalPages: 1,
  })
  const [filter, setFilter] = useState({
    page: 1,
    limit: 20,
  })
  const handleCategoryChange = (id) => {
    setCategory(id);
  }
  const handleSelect = (id) => {
    let index = selected.findIndex(sel => sel === id);
    let _selected = JSON.parse(JSON.stringify(selected));
    if (index > -1) {
      _selected.splice(index, 1)
    } else {
      _selected.push(id);
    }
    setSelected(_selected);
  }
  const handleSelectAll = () => {
    if (selected.length === data.length) {
      clearSelected();
    } else {
      setSelected(data.map(item => item.id))
    }
  }
  const clearSelected = () => {
    setSelected([])
  }
  const fetchGallery = () => {
    dispatch(setLoading(true))
    const { page, limit, } = filter;
    let url = `files?&page=${page}&limit=${limit}&status=all`;
    setRequest({
      url: url,//FIXME: fix the url
      method: 'GET',
    }).then(res => {
      if (res) {
        setData(res.data.files)
        setDataSettings({
          totalPages: Number(res.data.pages),
          totalItems: Number(res.data.total)
        })
      }
      dispatch(setLoading(false))
    })
  }

  const handlePagination = (action) => {
    clearSelected();
    switch(action) {
      case "next":
        setFilter({...filter, page: filter.page += 1});
        break;
      case "back":
        setFilter({...filter, page: filter.page -= 1});
        break;
      case "first":
        setFilter({...filter, page: 1});
        break;
      case "last":
        setFilter({...filter, page: dataSettings.totalPages});
        break;
      default:
        break;
    }
  }
  const handleDelete = async () => {
    dispatch(setLoading(true))
    closeModal();
    await selected.forEach(async id => {
      await deleteItem(id)
      return true;
    })
    clearSelected();
    setTimeout(() => {
      fetchGallery();
    }, 1000);
  }
  const deleteItem = (id) => {
    let url = `file?file_id=${id}`;
    return setRequest({
      url: url,
      method: 'DELETE',
    })
  }
  const handleTypeToogle = () => {
    dispatch(setLoading(true))
    let _data = JSON.parse(JSON.stringify(data));
    selected.forEach(item => {
      const index = data.findIndex(x => Number(x.id) === Number(item));
      if (index >= 0) 
        _data[index].public = Number(category) === 1 ? 1 : 0;

      setRequest({
        url: `file`,
        method: 'PATCH',
        data: {
          file_id: item,
          public: Number(category) === 1 ? 1 : 0
        }
      })
    })
    setData(_data);
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 1000);
  }

  const triggerUpload = () => {
    let element = document.getElementById('filehandler');
    element.value = "";
    element.click();
  }
  const handleFileUpload = (e) => {
    let file = e.target.files[0];
    var data = new FormData();
    data.append('file', file);
    // data.append('tags', '');
    dispatch(setLoading(true))
    setRequest({
      url: 'file',
      method: 'POST',
      data: data,
    }).then(res => {
      if (res) {
        fetchGallery();
      }
      dispatch(setLoading(false))
    })
  }
  useEffect(() => {
    if (setRequest) {
      fetchGallery();
    }
    // eslint-disable-next-line
  }, [filter])

  const [modal, setModal] = useState({ key: '' })
  const closeModal = () => {
    setModal();
  }
  const openDelete = () => {
    setModal({ key: 'delete' });
  }

  const galleryContext = {
    category,
    selected,
    data,
    dataSettings,
    filter,

    modal,
    closeModal,
    openDelete,

    handleCategoryChange,
    handleSelect,
    handleSelectAll,
    handlePagination,
    handleDelete,
    triggerUpload,
    handleFileUpload,
    handleTypeToogle,
  };
  return <GalleryContext.Provider value={galleryContext}>{props.children}</GalleryContext.Provider>;
};

export const { Consumer } = GalleryContext;