import React from 'react';
import theme from './style';
import BlogHeader from '../BlogHeader';
import BlogBody from '../BlogBody';
import { useHistory } from 'react-router-dom';

const BlogView = (props) => {
  const history = useHistory();
  const {
    item,
    width,
    path,
    expanded,
    handleExpanded,
    handleDelete,
    onDelete,
    onClick,
    readOnly 
  } = props;

  const classes = theme({ width: width });
  const {
    author,
    num_of_comments,
    blog,
  } = item;

  const {
    id,
    author_id,
    content,
    date_created,
    title,
    updated
  } = blog;

  return (
    <div className={`${classes.listViewWrap} block`}>
        <BlogHeader         
          id={id}
          author={author}
          author_id={author_id}
          date_created={date_created}
          num_of_comments={num_of_comments}
          showComments={true}
          updated={updated}
          path={path}
          item={'blog_id'}
          expanded={expanded}
          handleExpanded={handleExpanded}
          handleDelete={handleDelete}
          readOnly={readOnly}
          onClick={onClick} />
        <BlogBody id={id} title={title} content={content} path={path} item={'blog_id'} onClick={onClick} collapsed={true} />
      </div>

  );
}
export default BlogView;