import React, { useContext } from 'react';
import theme from './style';
import { Grid, } from '@material-ui/core';
import Button from '../../components/Button';
import { SmallTitle, GalleryListItem } from '../../components/DataDisplay';
import { Check, Select } from '../../components/Inputs';
import translate from '../../functions/applyTranslation';
import clsx from 'clsx';
import { GalleryContext, Provider } from "./galleryContext";
import moment from 'moment'

import { useImage } from '../../api/apiHandler';
import { Fragment } from 'react';
import DeleteModal from '../delete-modal';

const GalleryList = (props) => {
  const classes = theme();
  const [getImageURL] = useImage();
  const galleryContext = useContext(GalleryContext);
  const {
    category,
    selected,
    data,
    dataSettings,
    filter,

    handleCategoryChange,
    handleSelect,
    handleSelectAll,
    handlePagination,
    handleDelete,
    handleTypeToogle,
    
    modal, openDelete, closeModal, 

    triggerUpload,
    handleFileUpload,
  } = galleryContext;
  return (
    <Fragment>
      <DeleteModal title={translate('gallery-list', 'label_delete_title')} subTitle={translate('gallery-list', 'label_delete_subtitle')} modal={modal ? modal.key : null} closeModal={closeModal} callBack={handleDelete} />
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.galleryList}>
            <div className={classes.spaceBetween}>
              <SmallTitle text={translate('gallery-list', 'title_gallery_list')} />
              <Button label={translate('', 'button_upload')} fill
                onClick={triggerUpload}
              />
              <input type='file' id='filehandler' hidden onChange={handleFileUpload} />
            </div>

            <Grid container className={classes.actionsWrapper}>
              <Grid item xs={12} sm={6} lg={6} className='selectHolder item'>
                {/* <Label fullWidth text={translate('gallery-list', 'select_label_change_category')}> */}
                <Select
                  placeholder={translate('gallery-list', 'select_label_change_category')}
                  fullWidth
                  data={[
                    {
                      id: 1,
                      title: translate('gallery-list', 'select_option_staff_selected')
                    },
                    {
                      id: 2,
                      title: translate('gallery-list', 'select_option_user_uploaded')
                    },
                  ]}
                  selected={category}
                  onChange={handleCategoryChange}
                />
                {/* </Label> */}
              </Grid>
              <Grid item xs={12} sm={6} lg={3} className='executeHolder item'>
                <Button label={translate('', 'button_execute')} fill
                  onClick={handleTypeToogle}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={3} className='deleteHolder item'>
                <Button label={translate('', 'button_delete')} fill
                  onClick={openDelete}
                />
              </Grid>
              <Grid item xs={12} className='checkHolder item'>
                <Check
                  value={selected.length === data.length && data.length > 0}
                  onChange={handleSelectAll}
                  label={translate('', 'check_label_select_all')}
                  className={classes.margin}
                />
              </Grid>
            </Grid>
            {data.map(item =>
              <GalleryListItem
                key={item.id}
                id={item.id}
                url={getImageURL(item.id)}
                selected={selected.includes(item.id)}
                filename={item.name}
                date={moment.unix(item.creation_date).format('DD MMMM, YY')}
                category={`${item.tags} - ${Number(item.public) === 1 ?
                  translate('gallery-list', 'select_option_staff_selected') : translate('gallery-list', 'select_option_user_uploaded')}`}
                onSelect={handleSelect}
              />
            )}
            <div className={clsx(classes.flexEnd, classes.paginatorLabel)}>
              {translate('news-list', 'label_page_counter').replace('##page##', filter.page).replace('##total##', dataSettings.totalPages)}
            </div>
            <div className={
              filter.page !== 1 && filter.page !== dataSettings.totalPages ? classes.spaceBetween :
                filter.page === 1 ? classes.flexEnd : classes.flexStart
            }>
              {filter.page !== 1 &&
                <div>
                  <Button className={classes.firstPageBtn} label={translate('news-list', 'button_page_first')} fill onClick={(e) => handlePagination('first')} />
                  <Button label={translate('news-list', 'button_page_back')} fill onClick={(e) => handlePagination('back')} />
                </div>
              }
              {dataSettings.totalPages && filter.page !== dataSettings.totalPages &&
                <div>
                  <Button label={translate('news-list', 'button_page_next')} fill onClick={(e) => handlePagination('next')} />
                  <Button className={classes.lastPageBtn} label={translate('news-list', 'button_page_last')} fill onClick={(e) => handlePagination('last')} />
                </div>
              }
            </div>
          </div>
        </Grid>
      </Grid >
    </Fragment>
  );
}

const ApplyProvider = (props) => {
  return (<Provider>
    <GalleryList {...props} />
  </Provider>)
}
export default ApplyProvider;