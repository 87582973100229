import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLocation } from '../../redux/states/statesActions';
import { setLogout, setUserData } from '../../redux/auth/authActions';
import useAPI from '../../api/apiHandler';
import { useHistory } from 'react-router-dom';

const TabletHook = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [setRequest] = useAPI();
  const handleLogout = (type) => {
    dispatch(setLogout())
    history.replace('/')
  }
  const updateLocation = () => {
    dispatch(setLocation(window.location.pathname));
  }
  const fetchUserData = () => {
    setRequest({
      url: 'user/profile',
      method: 'GET',
    }).then(res => {
      if (res) {
        dispatch(setUserData(res.data))
      }
    })
  }
  useEffect(() => {
    fetchUserData();
  }, [])
  return [handleLogout, updateLocation];
}

export default TabletHook;