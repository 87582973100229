import React, {createContext, useEffect, useState} from "react";
import {useParams, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setLoading} from '../../redux/states/statesActions';
import {useSelector} from 'react-redux';
import useAPI from '../../api/apiHandler';
import moment from 'moment';
import {
  validatorForm1,
  validatorForm2,
  validatorForm3,
  validatorForm4,
} from './formValidators';
import {priceFromDb, priceToDb} from '../../functions/applyPrices';

export const ActivitiesContext = createContext({});

export const Provider = props => {
  const userData = useSelector(state => state.auth.userData);
  const params = useParams();
  const [paramId, setParamId] = useState(-1);
  const history = useHistory();
  const dispatch = useDispatch();
  const [setRequest, multipleRequests] = useAPI();
  const [selectedTab, setSelectedTab] = useState(1);
  const [locations, setLocations] = useState([])
  const [associations, setAssociations] = useState([])
  const [categories, setCategories] = useState([]);
  const [submited, setSubmited] = useState(false);
  const [validator, setValidator] = useState({
    tab1: [],
    tab2: [],
    tab3: [],
    tab4: [],
  })

  const [information, setInformation] = useState({});
  const [user, setUser] = useState({});

  const emptyData = {
    validated_by: [],
    status_id: 1,
    tab1: {
      // contact_name: '',
      // contact_phone: '',
      // contact_email: '',
      // ownership_field: '',
      owner_ssn: '',
      owner_name: '',
      alt_phone: '',
      alt_email: '',
      booking_organization_type: 1,
      booking_type: null,
      activity_type: 1,
      categorize: '',
      room_time_from: null,
      room_time_to: null,
      event_time_from: null,
      event_time_to: null,
      room_id: null,
      other_room: '',
    },
    tab2: {
      activity_type: 1,
      image: null,
      title: '',
      content: '',
    },
    tab3: {
      activity_type: 1,
      participants_number: '',
      registration_required: false,
      registration_start_date: null,
      registration_end_members: null,
      registration_end_non_members: null,
      payment_required: false,
      prices_adult_member: null,
      prices_adult_non_member: null,
      prices_adult_age_from: null,
      prices_adult_age_to: null,
      prices_children_member: null,
      prices_children_non_member: null,
      prices_children_age_from: null,
      prices_children_age_to: null,
      amount_member: null,
      amount_non_member: null,
    },
    tab4: {
      activity_type: 1,
      visibility: [],
      keywords: [],
      congregation_notification: '',
    }
  };

  const [data, setData] = useState(emptyData)

  const [modal, setModal] = useState({key: ''})
  const closeModal = () => {
    setModal();
  }
  const handleModalDelete = () => {
    setModal({key: 'delete'});
  }

  const handleSelectedTab = (e) => {
    let valid = true;
    if (e > selectedTab) {
      for (var i = selectedTab; i < e; i++) {
        if (valid) {
          let validated = [];
          switch (i) {
            case 1:
              validated = validatorForm1(data.tab1);
              setValidator({...validator, tab1: validated})
              valid = validated.length === 0;
              break;
            case 2:
              validated = validatorForm2(data.tab2);
              setValidator({...validator, tab2: validated})
              valid = validated.length === 0;
              break;
            case 3:
              validated = validatorForm3(data.tab3);
              setValidator({...validator, tab3: validated})
              valid = validated.length === 0;
              break;
            case 4:
              validated = validatorForm4(data.tab4);
              setValidator({...validator, tab4: validated})
              valid = validated.length === 0;
              break;
          }
          if (!valid)
            setSelectedTab(i);
        }
      }
    }
    if (valid)
      setSelectedTab(e);
  }

  const handleBack = () => {
    setSelectedTab(selectedTab - 1)
  }
  const setFormData = (key, value) => {
    setData({...data, [key]: value})
  }
  const setTab1Data = (key, value) => {
    let _tab1 = JSON.parse(JSON.stringify(data.tab1));
    _tab1[key] = value;
    if (key === 'room_id') {
      let max = null;
      const location = locations.find(item => item.id === value);
      if (location)
        max = location.max;
      let _tab3 = JSON.parse(JSON.stringify(data.tab3));
      _tab3.participants_number = max;
      setData({...data, tab1: _tab1, tab3: _tab3})
    } else if (key === 'activity_type') {
      let _tab2 = JSON.parse(JSON.stringify(data.tab2));
      let _tab3 = JSON.parse(JSON.stringify(data.tab3));
      let _tab4 = JSON.parse(JSON.stringify(data.tab4));
      _tab2[key] = value;
      _tab3[key] = value;
      _tab4[key] = value;
      setData({...data, tab1: _tab1, tab2: _tab2, tab3: _tab3, tab4: _tab4})
    } else
      setData({...data, tab1: _tab1})
  }
  const setTab2Data = (key, value) => {
    let _tab2 = JSON.parse(JSON.stringify(data.tab2));
    _tab2[key] = value;
    setData({...data, tab2: _tab2})
  }
  const setTab3Data = (key, value) => {
    let _tab3 = JSON.parse(JSON.stringify(data.tab3));
    _tab3[key] = value;
    setData({...data, tab3: _tab3})
  }
  const setTab4Data = (key, value) => {
    let _tab4 = JSON.parse(JSON.stringify(data.tab4));
    _tab4[key] = value;
    setData({...data, tab4: _tab4})
  }

  const fetchEvent = async () => {
    dispatch(setLoading(true))
    let options = [
      {
        url: `events/locations`,
        method: 'GET',
      },
      {
        url: `events/association`,
        method: 'GET',
      },
      {
        url: 'events/event_types',
        method: 'GET'
      }
    ];

    if (params && params.id) {
      options.push({
        url: `events?limit=1&page=1&event_id=${params.id}&cal=1`,
        method: 'GET',
      });
    }

    await multipleRequests(options).then(responses => {
      if (responses && responses.length >= 3) {
        if (responses[0].data) {
          setLocations(responses[0].data.map(item => {
            return {...item, title: item.name}
          }))
        }
        if (responses[1].data) {
          setAssociations(responses[1].data.map(item => {
            return {...item, title: item.name}
          }))
        }
        if (responses[2].data) {
          setCategories(responses[2].data.map(item => {
            return {...item, title: item.name}
          }))
        }
        if (responses.length === 4 && responses[3].data) {
          const res = responses[3];
          try {
            const _data = res.data.content[0];
            const _products = _data.products;
            setRequest({
              url: `user/profile?user_id=${_data.jfm_event.owner}`,
              method: 'GET',
            }).then(result => {
              let _user = null;
              if (result && result.data) {
                _user = {ssn: result.data.ssn, name: result.data.name, email: result.data.mail, phone: result.data.phone}
                setUser(_user);
              }
              loadFetchedData(_data, _products, _user);
              setInformation({
                cdate: _data.event.cdate,
                creator_id: _data.jfm_event.creator_id,
                certified_by: _data.jfm_event.certified_by,
                unpublished_by: _data.jfm_event.unpublished_by
              })
            });
          } catch (error) {
            console.log(error)
          }
        } else
          setData(emptyData)
      }
    })
    dispatch(setLoading(false));
  }

  const extractTicketPrice = (data, type, pay) => {
    let _data = data.find(item => item.type === type && item.pay === pay);

    if (_data) {
      return priceFromDb(_data.amount);
    } else {
      return null;
    }
  }
  const extractChildAge = (data, pos) => {
    var numberPattern = /\d+/g;
    let _data = data.find(item => item.type === '2');
    if (_data) {
      let matchData = _data.name.match(numberPattern);
      if (matchData && matchData.length >= pos) {
        return matchData[pos - 1];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  const extractApprovalStatus = (data) => {
    let _arr = [];
    let _data = JSON.parse(data);
    if (_data.jss === "true") {
      _arr.push(1)
    }
    if (_data.center === "true") {
      _arr.push(2)
    }
    if (_data.editor === "true") {
      _arr.push(3)
    }
    return _arr;
  }

  const loadFetchedData = (_data, _products, _user) => {
    let products = _products.reverse();
    setData(
      {
        validated_by: extractApprovalStatus(_data.jfm_event.approval),
        status_id: _data.jfm_event.event_status,
        tab1: {
          // contact_name: _data.jfm_event.contact_name,
          // contact_phone: _data.jfm_event.contact_phone,
          // contact_email: _data.jfm_event.contact_email,
          //ownership_field: '',
          //owner_ssn: _data.jfm_event.owner !== _data.jfm_event.creator_id ? _user.ssn : '',
          owner_name: _user.name,
          alt_phone: _data.jfm_event.alt_phone ? _data.jfm_event.alt_phone.trim() : null,
          alt_email: _data.jfm_event.alt_email ? _data.jfm_event.alt_email.trim() : null,
          booking_organization_type: _data.jfm_event.grp_type || 2,
          booking_type: _data.jfm_event.grp_type === 2 ? null : _data.jfm_event.grp ? _data.jfm_event.grp.split(',') : null,
          activity_type: _data.event.type === 'event' ? 1 : _data.event.type === 'meeting' ? 2 : 3,
          categorize: _data.jfm_event.event_type,
          room_time_from: _data.event.start_time ? moment.unix(_data.event.start_time) : null,
          room_time_to: _data.event.end_time ? moment.unix(_data.event.end_time) : null,
          event_time_from: _data.jfm_event.event_start ? moment.unix(_data.jfm_event.event_start) : null,
          event_time_to: _data.jfm_event.event_end ? moment.unix(_data.jfm_event.event_end) : null,
          room_id: _data.asset.id || -1,
          other_room: _data.event.location,
        },
        tab2: {
          activity_type: _data.event.type === 'event' ? 1 : _data.event.type === 'meeting' ? 2 : 3,
          image: _data.event.image,
          title: _data.event.title,
          content: _data.event.description,
        },
        tab3: {
          activity_type: _data.event.type === 'event' ? 1 : _data.event.type === 'meeting' ? 2 : 3,
          participants_number: _data.event.max_ppl,
          registration_required: _data.event.osa === '1',
          registration_start_date: _data.event.osa_start ? moment.unix(_data.event.osa_start) : null,
          registration_end_members: _data.event.osa_pay_end ? moment.unix(_data.event.osa_pay_end) : null,
          registration_end_non_members: _data.event.osa_end ? moment.unix(_data.event.osa_end) : null,
          payment_required: _data.event.pay === '1',
          prices_adult_member: extractTicketPrice(products, '1', '1'),
          prices_adult_non_member: extractTicketPrice(products, '1', '0'),
          prices_children_member: extractTicketPrice(products, '2', '1'),
          prices_children_non_member: extractTicketPrice(products, '2', '0'),
          prices_children_age_from: extractChildAge(products, 1),
          prices_children_age_to: extractChildAge(products, 2),
          amount_member: extractTicketPrice(products, '3', '1'),
          amount_non_member: extractTicketPrice(products, '3', '0'),
        },
        tab4: {
          activity_type: _data.event.type === 'event' ? 1 : _data.event.type === 'meeting' ? 2 : 3,
          visibility: _data.jfm_event.visibility ? _data.jfm_event.visibility.split(',').map(item => Number(item)) : [],
          keywords: _data.jfm_event.keywords ? _data.jfm_event.keywords.split(',') : [],
          congregation_notification: _data.jfm_event.extra_text || '',
        }
      }
    )
  }
  // useEffect(runValidators, [tab1, tab2, tab3, tab4])
  useEffect(() => {
    if (paramId !== -1)
      dispatch(setLoading(false))
  }, [data])

  useEffect(() => {
    if (params.id !== paramId) {
      setParamId(params.id)
      fetchEvent();
    }
  }, [params])

  const handleReturn = () => {
    history.goBack();
  }

  const handleSubmit = () => {
    let newForm = true
    if (params.id) {
      newForm = false;
    }
    var formdata = new FormData();
    if (!newForm) {
      formdata.append("event_id", params.id);
    }

    formdata.append("jss", data.validated_by.includes(1));
    formdata.append("center", data.validated_by.includes(2));
    formdata.append("editor", data.validated_by.includes(3));
    formdata.append("event_status", data.status_id || '1');

    //TAB 1
    formdata.append("type", data.tab1.activity_type === 1 ? 'event' : data.tab1.activity_type === 2 ? 'meeting' : "synagogue");

    if (newForm) {
      formdata.append("owner_ssn", data.tab1.owner_ssn || userData.ssn);
    } else {
      if (data.tab1.owner_ssn) {
        formdata.append("owner_ssn", data.tab1.owner_ssn || userData.ssn);
        formdata.append("alt_phone", ' ');
        formdata.append("alt_email", ' ' );
      }
    }
    formdata.append("alt_phone", !data.tab1.alt_phone || data.tab1.alt_phone.trim().length === 0 ? ' ' : data.tab1.alt_phone);
    formdata.append("alt_email", !data.tab1.alt_email || data.tab1.alt_email.trim().length === 0 ? ' ' : data.tab1.alt_email);


    // formdata.append("contact_name", data.tab1.contact_name);
    // formdata.append("ownership_field", data.tab1.ownership_field);//FIXME: validate field name with Mattias
    // formdata.append("contact_phone", data.tab1.contact_phone);
    // formdata.append("contact_email", data.tab1.contact_email);

    formdata.append("grp_type", data.tab1.booking_organization_type);
    formdata.append("grp", data.tab1.booking_type);

    formdata.append("asset_id", Number(data.tab1.room_id) === -1 ? '' : data.tab1.room_id);
    formdata.append("location", data.tab1.other_room);
    formdata.append("event_type", data.tab1.categorize);

    formdata.append("start_time", moment(data.tab1.room_time_from).unix());
    formdata.append("end_time", moment(data.tab1.room_time_to).unix());
    formdata.append("event_start", moment(data.tab1.event_time_from).unix());
    formdata.append("event_end", moment(data.tab1.event_time_to).unix());

    //TAB 2
    formdata.append("image", data.tab2.image || 0);
    formdata.append("title", data.tab2.title);
    formdata.append("description", data.tab2.content);

    //TAB 3
    formdata.append("max_ppl", data.tab3.participants_number);
    formdata.append("osa", data.tab3.registration_required ? '1' : '0');
    if (data.tab3.registration_required) {
      formdata.append("osa_start", moment(data.tab3.registration_start_date).unix());
      formdata.append("osa_end", moment(data.tab3.registration_end_non_members).unix());
      formdata.append("osa_pay_end", moment(data.tab3.registration_end_members).unix());
    }
    formdata.append("pay", data.tab3.payment_required ? '1' : '0');
    if (data.tab3.payment_required) {
      let count = 0;
      if (data.tab3.prices_adult_member) {
        formdata.append(`price[${count}][name]`, "Vuxen");
        formdata.append(`price[${count}][pay]`, "1");
        formdata.append(`price[${count}][amount]`, priceToDb(data.tab3.prices_adult_member));
        formdata.append(`price[${count}][type]`, "1");
        count++;
      }
      if (data.tab3.prices_adult_non_member) {
        formdata.append(`price[${count}][name]`, "Vuxen");
        formdata.append(`price[${count}][pay]`, "0");
        formdata.append(`price[${count}][amount]`, priceToDb(data.tab3.prices_adult_non_member));
        formdata.append(`price[${count}][type]`, "1");
        count++;
      }
      if (data.tab3.prices_children_member) {
        formdata.append(`price[${count}][name]`, `Barn ${data.tab3.prices_children_age_from} - ${data.tab3.prices_children_age_to} år`);
        formdata.append(`price[${count}][pay]`, "1");
        formdata.append(`price[${count}][amount]`, priceToDb(data.tab3.prices_children_member));
        formdata.append(`price[${count}][type]`, "2");
        count++;
      }
      if (data.tab3.prices_children_non_member) {
        formdata.append(`price[${count}][name]`, `Barn ${data.tab3.prices_children_age_from} - ${data.tab3.prices_children_age_to} år`);
        formdata.append(`price[${count}][pay]`, "0");
        formdata.append(`price[${count}][amount]`, priceToDb(data.tab3.prices_children_non_member));
        formdata.append(`price[${count}][type]`, "2");
        count++;
      }
      if (data.tab3.amount_member) {
        formdata.append(`price[${count}][name]`, "Familj");
        formdata.append(`price[${count}][pay]`, "1");
        formdata.append(`price[${count}][amount]`, priceToDb(data.tab3.amount_member));
        formdata.append(`price[${count}][type]`, "3");
        count++;
      }
      if (data.tab3.amount_non_member) {
        formdata.append(`price[${count}][name]`, "Familj");
        formdata.append(`price[${count}][pay]`, "0");
        formdata.append(`price[${count}][amount]`, priceToDb(data.tab3.amount_non_member));
        formdata.append(`price[${count}][type]`, "3");
        count++;
      }
    }
    //TAB 4
    formdata.append("extra_text", data.tab4.congregation_notification || '');
    formdata.append("visibility", data.tab4.visibility.join());
    formdata.append("keywords", data.tab4.keywords.join());
    dispatch(setLoading(true))
    setRequest({
      url: `event`,
      method: newForm ? 'POST' : 'PATCH',
      data: formdata,
      displayError: true
    }).then(res => {
      // if (res) {
      //   history.goBack();
      // }
      if (res) {
        if (submited) {
          history.push('/activities')
        } else {
          history.push(`/activities/${res.data.jfm_event.id}`)
          setSubmited(true);
        }
      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  }
  const handleDelete = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `event?event_id=${params.id}`,
      method: 'DELETE',
    }).then(res => {
      if (res) {
        history.push('/activities');
      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  }

  const usersContext = {
    selectedTab, setSelectedTab, handleSelectedTab,
    data,
    information,
    tab1Data: data.tab1,
    tab2Data: data.tab2,
    tab3Data: data.tab3,
    tab4Data: data.tab4,
    locations,
    associations,
    categories,
    setFormData,
    setTab1Data,
    setTab2Data,
    setTab3Data,
    setTab4Data,
    validator,
    handleBack,
    handleSubmit,
    handleDelete,
    modal,
    handleModalDelete,
    closeModal,
    submited,
    validatorForm1,
    validatorForm2,
    validatorForm3,
    validatorForm4,
    setValidator,
    handleReturn,
    userData,
    user
  };
  return <ActivitiesContext.Provider value={usersContext}>{props.children}</ActivitiesContext.Provider>;
};

export const {Consumer} = ActivitiesContext;