import React from 'react';
import theme from './style';
import moment from 'moment';
import Accordion from '../Accordion';
import CommentIcon from '@material-ui/icons/Comment';
import translate from '../../../functions/applyTranslation';
import usePermissionsHook from '../../../functions/permissions';
import Text from '../Text'
import { useSelector } from 'react-redux';
import { AlternateEmail } from '@material-ui/icons';

const BlogHeader = (props) => {
    const userData = useSelector(state => state.auth.userData);
    const [permissions, roles, hasPermission] = usePermissionsHook();
    const classes = theme();
    const {
        id,
        author,
        author_id,
        date_created,
        num_of_comments,
        showComments,
        area,
        path,
        item,
        expanded,
        handleExpanded,
        handleDelete,
        handleUpdate,
        updated,
        onClick,
        readOnly
    } = props;

    const allowEdit = !readOnly && (hasPermission(permissions.BLOG_EDIT) || Number(author_id) === Number(userData.id));
    const key = `${item}_${id}`;

    const handleClick = (action, value) => {
        handleExpanded(key)(null, false);
        if (action === 'delete' && handleDelete) {
            handleDelete(item, value);
        }
        else if (action === 'edit' && handleUpdate) {
            handleUpdate(item, value);
        }
        else if (action === 'edit' && onClick) {
            onClick({ path, item, action, value })
        }
    }

    return (

        <div className={classes.main}>
            <div className={classes.left}>
                <div className={classes.author}>
                    {`${author} • ${moment.unix(date_created).format("DD MMMM")} ${moment.unix(date_created).format("HH:mm")}${updated ? `• ${translate('blog', 'label_edited')}` : ''}`}

                </div>
                {showComments &&
                    <div className={`${classes.comments} ${num_of_comments ? 'active' : ''}`}>
                        <CommentIcon />{`${num_of_comments} ${translate('blog', 'label_coments')}`}
                    </div>
                }
            </div>
            {allowEdit &&
                <div className={classes.edit}>
                    <Accordion blog info expanded={expanded === key} onChange={handleExpanded(key)} >
                        <div>
                            <Text onClick={(e) => { handleClick('edit', id) }} className={classes.actionEdit} text={translate(area || '', 'button_edit')} />
                            <Text onClick={(e) => { handleClick('delete', id) }} className={classes.actionDelete} text={translate(area || '', 'button_delete')} />
                        </div>
                    </Accordion>
                </div>
            }
        </div >
    );
}

export default BlogHeader;