import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme'
const theme = (props) => {
    const styles = makeStyles(theme => ({
        dialogTitle: {
            paddingBottom: 5,
            fontSize: 18,
            lineHeight: '22px',
            '& small': {
                display: 'block',
                fontSize: 12,
                lineHeight: '14px',
                color: ColorSchemes.highDarkGrey
            }
        },
        content: {
            width: 'calc(100% - 6px) !important',
            padding: '3px 3px 0 3px'
        }
    }));
    return styles();
}
export default theme;

