import {useSelector} from 'react-redux';

const usePermissionsHook = () => {
  const userData = useSelector(state => state.auth.userData);
  const blogFeatureActive = true;
  const roles = ['superadmin', 'admin', 'security', 'editor', 'memberplus', 'member']
  const permissions = {
    APPLICANT_ACCESS: 'APPLICANT_ACCESS',
    APPLICANT_APPROVAL: 'APPLICANT_APPROVAL',
    USERS_ACCESS: 'USERS_ACCESS',
    USERS_EDIT: 'USERS_EDIT',
    NEWS_ACCESS: 'NEWS_ACCESS',
    NEWS_CREATE: 'NEWS_CREATE',
    NEWS_EDIT: 'NEWS_EDIT',
    NEWS_DELETE: 'NEWS_DELETE',
    ACTIVITIES_ACCESS: 'ACTIVITIES_ACCESS',
    ACTIVITIES_STATUS: 'ACTIVITIES_STATUS',
    ACTIVITIES_CREATE: 'ACTIVITIES_CREATE',
    ACTIVITIES_EDIT: 'ACTIVITIES_EDIT',
    ACTIVITIES_DELETE: 'ACTIVITIES_DELETE',
    ACTIVITIES_EDITOR_APPROVAL: 'ACTIVITIES_EDITOR_APPROVAL',
    ACTIVITIES_SECURITY_APPROVAL: 'ACTIVITIES_SECURITY_APPROVAL',
    ACTIVITIES_BOOKING_ACCESS: 'ACTIVITIES_BOOKING_ACCESS',
    ACTIVITIES_SECURITY_ACCESS: 'ACTIVITIES_SECURITY_ACCESS',
    PAGES_ACCESS: 'PAGES_ACCESS',
    PAGES_CREATE: 'PAGES_CREATE',
    PAGES_EDIT: 'PAGES_EDIT',
    PAGES_DELETE: 'PAGES_DELETE',
    BLOG_ACCESS: 'BLOG_ACCESS',
    BLOG_CREATE: 'BLOG_CREATE',
    BLOG_EDIT: 'BLOG_EDIT',
    BLOG_DELETE: 'BLOG_DELETE',
    GALLERY: 'GALLERY'
  }
  const blogPermissions = [
    permissions.BLOG_ACCESS,
    permissions.BLOG_CREATE,
    permissions.BLOG_EDIT,
    permissions.BLOG_DELETE,
  ];
  const rolesPermissions = {
    'superadmin': [
      permissions.APPLICANT_ACCESS,
      permissions.APPLICANT_APPROVAL,
      permissions.USERS_ACCESS,
      permissions.USERS_EDIT,
      permissions.NEWS_ACCESS,
      permissions.NEWS_CREATE,
      permissions.NEWS_EDIT,
      permissions.NEWS_DELETE,
      permissions.ACTIVITIES_ACCESS,
      permissions.ACTIVITIES_STATUS,
      permissions.ACTIVITIES_CREATE,
      permissions.ACTIVITIES_EDIT,
      permissions.ACTIVITIES_DELETE,
      permissions.ACTIVITIES_EDITOR_APPROVAL,
      permissions.ACTIVITIES_SECURITY_APPROVAL,
      permissions.ACTIVITIES_BOOKING_ACCESS,
      permissions.ACTIVITIES_SECURITY_ACCESS,
      permissions.PAGES_ACCESS,
      permissions.PAGES_CREATE,
      permissions.PAGES_EDIT,
      permissions.PAGES_DELETE,
      permissions.BLOG_ACCESS,
      permissions.BLOG_CREATE,
      permissions.BLOG_EDIT,
      permissions.BLOG_DELETE,
      permissions.GALLERY
    ],
    'admin': [
      permissions.APPLICANT_ACCESS,
      permissions.APPLICANT_APPROVAL,
      permissions.USERS_ACCESS,
      permissions.USERS_EDIT,
      permissions.NEWS_ACCESS,
      permissions.NEWS_CREATE,
      permissions.NEWS_EDIT,
      permissions.NEWS_DELETE,
      permissions.ACTIVITIES_ACCESS,
      permissions.ACTIVITIES_STATUS,
      permissions.ACTIVITIES_CREATE,
      permissions.ACTIVITIES_EDIT,
      permissions.ACTIVITIES_DELETE,
      permissions.ACTIVITIES_EDITOR_APPROVAL,
      permissions.ACTIVITIES_SECURITY_APPROVAL,
      permissions.ACTIVITIES_BOOKING_ACCESS,
      permissions.ACTIVITIES_SECURITY_ACCESS,
      permissions.PAGES_ACCESS,
      permissions.PAGES_CREATE,
      permissions.PAGES_EDIT,
      permissions.PAGES_DELETE,
      permissions.BLOG_ACCESS,
      permissions.BLOG_CREATE,
      permissions.BLOG_EDIT,
      permissions.BLOG_DELETE,
      permissions.GALLERY
    ],
    'security': [
      permissions.APPLICANT_ACCESS,
      permissions.APPLICANT_APPROVAL,
      permissions.USERS_ACCESS,
      permissions.NEWS_ACCESS,
      permissions.NEWS_CREATE,
      permissions.NEWS_EDIT,
      permissions.NEWS_DELETE,
      permissions.ACTIVITIES_ACCESS,
      permissions.ACTIVITIES_STATUS,
      permissions.ACTIVITIES_CREATE,
      permissions.ACTIVITIES_EDIT,
      permissions.ACTIVITIES_DELETE,
      permissions.ACTIVITIES_SECURITY_APPROVAL,
      permissions.ACTIVITIES_BOOKING_ACCESS,
      permissions.ACTIVITIES_SECURITY_ACCESS,
      permissions.PAGES_ACCESS,
      permissions.PAGES_CREATE,
      permissions.PAGES_EDIT,
      permissions.PAGES_DELETE,
      permissions.BLOG_ACCESS,
      permissions.BLOG_CREATE,
      permissions.BLOG_EDIT,
      permissions.BLOG_DELETE,
      permissions.GALLERY
    ],
    'editor': [
      permissions.NEWS_ACCESS,
      permissions.NEWS_CREATE,
      permissions.NEWS_EDIT,
      permissions.NEWS_DELETE,
      permissions.ACTIVITIES_ACCESS,
      permissions.ACTIVITIES_STATUS,
      permissions.ACTIVITIES_CREATE,
      permissions.ACTIVITIES_EDIT,
      permissions.ACTIVITIES_DELETE,
      permissions.ACTIVITIES_EDITOR_APPROVAL,
      permissions.PAGES_ACCESS,
      permissions.PAGES_CREATE,
      permissions.PAGES_EDIT,
      permissions.PAGES_DELETE,
      permissions.BLOG_ACCESS,
      permissions.BLOG_CREATE,
      permissions.BLOG_EDIT,
      permissions.BLOG_DELETE,
      permissions.GALLERY
    ],
    'memberplus': [
      permissions.NEWS_ACCESS,
      permissions.NEWS_CREATE,
      permissions.ACTIVITIES_ACCESS,
      permissions.ACTIVITIES_CREATE,
      permissions.PAGES_ACCESS,
      permissions.BLOG_ACCESS,
      permissions.BLOG_CREATE,
      permissions.BLOG_EDIT,
    ]
    ,
    'member': [
      permissions.NEWS_ACCESS,
      permissions.ACTIVITIES_ACCESS,
      permissions.PAGES_ACCESS,
    ]
  }

  const hasPermission = (key) => {
    if (!blogFeatureActive && blogPermissions.includes(key)) return false;
    let userPermisssions = [];
    if (userData)
      userPermisssions = rolesPermissions[userData.role];
    return userPermisssions.includes(key);
  }

  return [permissions, roles, hasPermission]
}

export default usePermissionsHook;

