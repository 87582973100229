import React, {useState} from 'react';
import theme from './style';
import {Grid,} from '@material-ui/core';
import Button from '../../components/Button';
import {ListItem, SmallTitle, ViewTypeSelector, Categories} from '../../components/DataDisplay';
import {Select} from '../../components/Inputs';
import useNewsListHook from './hook';
import translate from '../../functions/applyTranslation';
import clsx from 'clsx';
import useStatus from '../../settings/statusList';
import usePermissionsHook from '../../functions/permissions';
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

const TemplateDashboard = (props) => {
  const classes = theme();
  const history = useHistory();
  const [statusList, statusFilter] = useStatus();
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const [news, newsInfo, filter, handleChangeFilter, handlePagination, categories] = useNewsListHook();
  const [view, setView] = useState('list');
  const userData = useSelector(state => state.auth.userData);

  const sumArticlesTotal = () => {
    return newsInfo.totalItems;
    // categories.forEach(item => {
    //   total += Number(item.num_of_articles)
    // })
    // return total;
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.newsList}>
          <div className={classes.spaceBetween}>
            <SmallTitle text={translate('news-list', 'title_news_list')}/>
            <div className={classes.selectGroup}>
              {hasPermission(permissions.NEWS_CREATE) &&
              <Button style={{marginRight: 10}} label={translate('news-list', 'button_new')} fill icon='edit'
                      onClick={() => history.push(`/article/new`)}/>
              }
              {hasPermission(permissions.NEWS_EDIT) &&
              <>
                <span className={classes.selectLabel}>{translate('', 'label_show')}</span>
                <div style={{width: 200}}>
                  <Select fullWidth data={statusFilter} selected={filter.status}
                          onChange={(e) => handleChangeFilter('status', e)}/>
                </div>
              </>
              }
            </div>
          </div>
          <div className={classes.spaceBetween}>
            <div className={classes.categoriesGroup}>
              <Categories selected={!filter.category} id={null} quantity={sumArticlesTotal()}
                          text={translate('', 'label_show_all')} onClick={() => handleChangeFilter('category', null)}/>
              {categories.map(item =>
                <Categories key={item.id} selected={item.id === filter.category} id={item.id}
                            quantity={item.num_of_articles} text={item.name}
                            onClick={(e) => handleChangeFilter('category', e)}/>
              )}
            </div>
            <ViewTypeSelector viewType={view} onChange={setView} type='articles'/>
          </div>
          <Grid container className={classes.newsWrap}>
            {news.map(item =>
              <Grid key={item.id} item xs={12} sm={view === 'list' ? 12 : 6} md={view === 'list' ? 12 : 4}
                    className='item'>
                < ListItem viewType={view} path='article' showStatus={item.article_status==="Ohanterad"}
                           statusList={statusList} item={{
                  id: item.id,
                  date: item.publish_date,
                  title: item.title,
                  intro: item.intro,
                  image: item.lead_image_id,
                  status: item.status,
                  status_id: item.status_id,
                  highlight: item.highlight
                }}/>
              </Grid>
            )}
          </Grid>
          <div className={clsx(classes.flexEnd, classes.paginatorLabel)}>
            {translate('news-list', 'label_page_counter').replace('##page##', filter.page).replace('##total##', newsInfo.totalPages)}
          </div>
          <div className={
            filter.page !== 1 && filter.page !== newsInfo.totalPages ? classes.spaceBetween :
              filter.page === 1 ? classes.flexEnd : classes.flexStart
          }>
            {filter.page !== 1 &&
            <Button label={translate('news-list', 'button_page_back')} fill onClick={(e) => handlePagination('back')}/>
            }
            {!!newsInfo.totalPages && filter.page !== newsInfo.totalPages &&
            <Button label={translate('news-list', 'button_page_next')} fill onClick={(e) => handlePagination('next')}/>
            }
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
export default TemplateDashboard;