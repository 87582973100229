import React from 'react';
import theme from './style';
import ClampLines from 'react-clamp-lines';
import moment from 'moment';
import translate from '../../../functions/applyTranslation';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useImage } from '../../../api/apiHandler';
const ListView = (props) => {
  const history = useHistory();
  const [getImageURL] = useImage();
  const {
    item,
    width,
    path,
    showStatus,
    statusList
  } = props;
  const classes = theme({ width: width });
  const {
    id,
    date,
    title,
    intro,
    image,
    highlight
  } = item;
  const hanldeNavigation = () => {
    history.push(`${path}/${id}`)
  }
  let url = getImageURL(image);//`${window.location.origin}${image}`
  const statusName = () => {
    if (!statusList) return '';
    var status = statusList.find(x => Number(x.id) === Number(item.status_id));
    if (!status) return '';
    return status.title;
  }
  return (
    <div className={`${classes.listViewWrap} ${highlight?'highlight':''}`}>
      <div className={'image'} style={{ backgroundImage: `url(${url})` }} onClick={hanldeNavigation} />
      <div className={clsx(classes.messageWrap, classes.listMessageWrap)}>
        <div className={classes.dateWrap}>
          <div className={'itemDate'}>{date ? moment.unix(date).format('DD MMMM, YY') :
            //FIXME:Remove temp data
            moment().format('DD MMMM, YYYY')
          }</div>
           {showStatus &&
          <div className={'cartStatus'}>{`(${statusName()})`}</div>
           }
        </div>
        <div className={'itemTitle'} onClick={hanldeNavigation}>
          {title}
        </div>
        <ClampLines
          text={intro || ''}
          lines={3}
          ellipsis="..."
          buttons={false}
          className="itemText"
          innerElement="p"
        />
        <div className={'actionOpen'} onClick={hanldeNavigation}>
          {translate('', 'label_read_more')}
        </div>
      </div>
    </div >
  );
}
export default ListView;