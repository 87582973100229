import React from "react";
import {Grid, Typography} from "@material-ui/core";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import {Markup} from 'interweave';
import translate from "../../../functions/applyTranslation";
import SmallTitle from "../../../components/DataDisplay/SmallTitle";
import {Check} from "../../../components/Inputs";
import theme from "../style";

const Step1 = props => {
  const classes = theme();

  const {
    programs,
    data,
    displayedText,
    setDisplayedText,
    handleSelectChange
  } = props;

  return (
    <Grid container>
      {/*Step 1 label*/}
      <Grid item xs={12}>
        <SmallTitle text={translate('booking', 'step1_label')} className={classes.stepLabel}/>
      </Grid>
      {/*Step 1 content*/}
      <Grid item xs={12}>
        <Grid container spacing={4}>
          {/*Left side*/}
          <Grid item xs={12} sm={6}>
            {programs.map(item => (
              <div
                className={data.selectedProgram.id === item.id ? classes.selectedProgramWrapper : classes.programWrapper}
                key={item.id}
                onClick={() => handleSelectChange(item)}
              >
                <Grid container alignItems="center">
                  <Grid item xs={1}>
                    <Check
                      iconClass={data.selectedProgram.id === item.id ? classes.selectedCheckIcon : classes.checkIcon}
                      rounded label="" value={data.selectedProgram.id === item.id}
                      onChange={() => handleSelectChange(item)}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body2"
                                className={data.selectedProgram.id === item.id ? classes.selectedProgramText : classes.programText}>{item.name}</Typography>
                    <div className={classes.programSubtextWrap}>
                      <Typography variant="body2"
                                  className={data.selectedProgram.id === item.id ? classes.selectedProgramSubtext : classes.programSubtext}>{Number(item.duration) / 60 + " " + translate("booking", "program_minutes")}</Typography>
                    </div>
                    {item.note && item.note.length > 0 &&
                    <Typography variant="body2"
                                className={data.selectedProgram.id === item.id ? classes.selectedProgramSubtext : classes.programSubtext}>{item.note}</Typography>
                    }
                  </Grid>
                  <Grid item xs={1}>
                    <InfoRoundedIcon
                      className={data.selectedProgram.id === item.id ? classes.selectedInfoIcon : classes.infoIcon}
                      onClick={e => {
                        e.stopPropagation()
                        setDisplayedText(item)
                      }}/>
                  </Grid>
                </Grid>
              </div>
            ))}
          </Grid>
          {/*Right side*/}
          {displayedText &&
          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={12}>
                <SmallTitle text={displayedText.name} className={classes.descriptionLabel}/>
              </Grid>
              <Grid item xs={12}>
                <Markup content={displayedText.description}/>
              </Grid>

            </Grid>
          </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Step1;
