import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import theme from './style';
import { Grid } from '@material-ui/core';
import Logo from '../../components/DataDisplay/LogoBlue';

const TemplateAuth = (props) => {
  const classes = theme();
  let history = useHistory();
  const [bigForm, setBigForm] = useState(false);
  const { children } = props;

  const handleClick = () => {
    history.push('/');
  }

  useEffect(() => {
    setBigForm(history.location.pathname.search('application') !== -1)
    history.listen((location, action) => {
      setBigForm(location.pathname.search('application') !== -1)
    });
  }, [history]);

  return (
    <div className={classes.root}>
      <Grid container className={classes.gridWrapper}>
        <Grid item xs={10} sm={bigForm ? 8 : 6} md={bigForm ? 6 : 3} className={classes.contentWrap}>
          <div className={classes.imgWrap}>
            <Logo onClick={handleClick} height={75} />
          </div>
          {children}
        </Grid>
      </Grid>
    </div >
  );
}
export default TemplateAuth;