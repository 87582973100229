import {
  SET_LOADING,
  SET_ERROR,
  SET_LOCATION,
  INCREASE_COUNT
} from './statesTypes';
export const setLoading = (payload) => {

  return {
    type: SET_LOADING,
    payload: payload
  }
}
export const setError = (payload) => {
  return {
    type: SET_ERROR,
    payload: payload
  }
}
export const setLocation = (payload) => {
  return {
    type: SET_LOCATION,
    payload: payload
  }
}
export const increaseCount = () => {
  return {
    type: INCREASE_COUNT,
  }
}
