import React from 'react';
import theme from './style';
import ClampLines from 'react-clamp-lines';
import moment from 'moment';
import translate from '../../../functions/applyTranslation';
import clsx from 'clsx'
import {useHistory} from 'react-router-dom';
import {useImage} from '../../../api/apiHandler';
import parse from "html-react-parser";

const ListView = (props) => {
  const history = useHistory();
  const [getImageURL] = useImage();
  const {
    item,
    width,
    path,
    showStatus
  } = props;
  const classes = theme({width: width});
  const {
    id,
    date,
    title,
    intro,
    image,
  } = item;

  const tryParse = (value) => {
    try {
      let intro = "";
      let result = "";
      let body = parse(value || '');
      if (!Array.isArray(body)) {
        if (body.props) {
          for (let key in body.props.children) {
            if (body.props.children[key].props) {
              if (body.props.children[key].props.children && typeof body.props.children[key].props.children === "string") {
                intro += body.props.children[key].props.children
              }
            }
          }
        }
      } else {
        for (let i = 0; i < body.length - 1; i++) {
          if (body[i].props) {
            intro += body[i].props.children;
          }
        }
      }
      result = intro.replace(/\[object Object\]/g, '').trim();
      return result;
    } catch (e) {
      return translate('', 'home_item_content_error');
    }
  }

  const hanldeNavigation = () => {
    history.push(`${path}/${id}`)
  }

  let url = getImageURL(image);
  return (
    <div className={classes.hybridViewWrap}>
      <div className={classes.hybridHeaderWrap}>
        <div className={'image'} style={{backgroundImage: `url(${url})`}} onClick={hanldeNavigation}/>
        <div className={clsx(classes.messageWrap, classes.hybridTextWrap)}>
          <div className={'itemTitle'} onClick={hanldeNavigation}>
            {title}
          </div>
          <div className={classes.dateWrap}>
            <div className={'itemDate'}>{date ? moment.unix(date).format('DD MMMM, YY') :
              //FIXME:Remove temp data
              moment().format('DD MMMM, YYYY')
            }</div>
            {showStatus &&
            <div className={'cartStatus'}>{translate('', 'cart_label_status')}</div>
            }
          </div>
        </div>
      </div>

      <div className={clsx(classes.messageWrap, classes.hybridMessageWrap)}>
        <ClampLines
          text={tryParse(intro) || ''}
          lines={2}
          ellipsis="..."
          buttons={false}
          className="itemText"
          innerElement="p"
        />
        <div className={'actionOpen'} onClick={hanldeNavigation}>
          {translate('', 'label_read_more')}
        </div>
      </div>
    </div>
  );
}
export default ListView;