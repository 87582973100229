import React from 'react';
import theme from './style';
import clsx from 'clsx';
import PropTypes from 'prop-types';
const Tabs = (props) => {
  const classes = theme();
  const {
    onSelect,
    className,
    menuItems,
    selected,
  } = props;

  const handleClick = (id) => {
    if (onSelect) {
      onSelect(id)
    }
  }
  return (
    <div
      className={
        clsx(className, classes.tabWrapper)
      }
    >
      {menuItems.map(item =>
        <div
          onClick={() => handleClick(item.id)}
          key={item.id}
          className={
            clsx(classes.tabItem, selected === item.id ? classes.tabItemSelected : '',)
          }
        >{`${item.text}`}</div>
      )}
    </div >
  );
}
Tabs.propTypes = {
  menuItems: PropTypes.array.isRequired,
}
Tabs.defaultProps = {
  menuItems: [],
  selected: 1,
  onSelect: () => { },
}

export default Tabs;