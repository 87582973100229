import React, { memo, useContext } from 'react';
import theme from './style';
import Button from '../../../components/Button';
import { Text } from '../../../components/DataDisplay';
import { Label, Check, InputField, DatePicker } from '../../../components/Inputs';
import translate from '../../../functions/applyTranslation';
import { Grid } from '@material-ui/core'
import { ActivitiesContext, } from "../actitiesContext";
import { Fragment } from 'react';
const ActivitiesTab3 = (props) => {
  const classes = theme();
  const activitiesContext = useContext(ActivitiesContext);
  const { tab3Data, setTab3Data, validator, handleBack, setSelectedTab, validatorForm3, setValidator } = activitiesContext;
  const validateForm = () => {
    let res = validatorForm3(tab3Data);
    if (!res.length) {
      setValidator({ ...validator, tab3: [] })
      setSelectedTab(4)
    } else {
      setValidator({ ...validator, tab3: res })
    }
  }
  const _validator = validator.tab3;
  return (
    <div className={classes.pageWrap}>
      <div>
        <div className={classes.inputContainer} >
          <Label text={translate('activities-edit', 'tab3_number_participants_title')}>
            <Text text={translate('activities-edit', 'tab3_number_participants_subtitle')} />
          </Label>
        </div>
        <div className={classes.inputContainer} >
          <InputField placeholder={translate('activities-edit', 'tab3_number_participants_placeholder')}
            value={tab3Data.participants_number}
            invalid={_validator.includes('participants_number')}
            onChange={(e) => setTab3Data('participants_number', e)}
          />
        </div>
        {Number(tab3Data.activity_type) === 1 &&
          <Fragment>
            <div className={classes.inputContainer} >
              <Label className={classes.paddingFix} text={translate('activities-edit', 'tab3_registration_title')}>
                <Text text={translate('activities-edit', 'tab3_registration_subtilte')} />
              </Label>
            </div>
            <div className={classes.inputContainer} >
              <Check label={translate('activities-edit', 'tab3_registration_check_label')}
                value={tab3Data.registration_required}
                onChange={(e) => setTab3Data('registration_required', e)}
              />
            </div>
          </Fragment>
        }
        {Number(tab3Data.activity_type) === 1 && tab3Data.registration_required &&
          <Grid container>
            <Grid item xs={12} sm={6} className={`${classes.inputContainer} ${classes.alignBottom}`}>
              <Text text={translate('activities-edit', 'tab3_registration_publish_label')} />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.inputContainer}>
              <Label className={classes.label} text={translate('activities-edit', 'tab3_registration_start_date')}>
              <DatePicker placeholder={translate('activities-edit', 'tab3_registration_start_date')}
                value={tab3Data.registration_start_date}
                invalid={_validator.includes('registration_start_date')}
                onChange={(e) => setTab3Data('registration_start_date', e)}
              />
              </Label>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.inputContainer}>
              <Label className={classes.label} text={translate('activities-edit', 'tab3_registration_osa_latest_members')}>
              <DatePicker placeholder={translate('activities-edit', 'tab3_registration_osa_latest_members')}
                value={tab3Data.registration_end_members}
                invalid={_validator.includes('registration_end_members')}
                onChange={(e) => setTab3Data('registration_end_members', e)}
              />
              </Label>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.inputContainer}>
              <Label className={classes.label} text={translate('activities-edit', 'tab3_registration_osa_latest_friends')}>
              <DatePicker placeholder={translate('activities-edit', 'tab3_registration_osa_latest_friends')}
                value={tab3Data.registration_end_non_members}
                invalid={_validator.includes('registration_end_non_members')}
                onChange={(e) => setTab3Data('registration_end_non_members', e)}
              />
              </Label>
            </Grid>
          </Grid>
        }
        {Number(tab3Data.activity_type) === 1 &&
          <Fragment>
            <div className={classes.inputContainer}>
              <Label className={classes.paddingFix} text={translate('activities-edit', 'tab3_payment_at_event_title')}>
                <Text text={translate('activities-edit', 'tab3_payment_at_event_subtitle')} />
              </Label>
            </div>
            <div className={classes.inputContainer}>
              <Check label={translate('activities-edit', 'tab3_payment_function_check_label')}
                value={tab3Data.payment_required}
                onChange={(e) => setTab3Data('payment_required', e)}
              />
            </div>
          </Fragment>
        }
        {Number(tab3Data.activity_type) === 1 && tab3Data.payment_required &&
          <Fragment>

            <Label className={classes.paddingFix} text={translate('activities-edit', 'tab3_price_list_title')} />
            <Label text={translate('activities-edit', 'tab3_prices_adult_label')}>
              <Grid container>
                <Grid item xs={12} sm={3} className={`${classes.inputContainer} ${classes.leftContainer}`}>
                  <InputField fullWidth
                    money
                    type='number'
                    placeholder={translate('activities-edit', 'tab3_price_member_placeholder')}
                    value={tab3Data.prices_adult_member}
                    invalid={_validator.includes('prices_adult_member')}
                    onChange={(e) => setTab3Data('prices_adult_member', e)}
                  />
                </Grid>
                <Grid item xs={12} sm={3} className={`${classes.inputContainer} ${classes.leftContainer}`}>
                  <InputField fullWidth
                    money
                    type='number'
                    placeholder={translate('activities-edit', 'tab3_price_non_member_placeholder')}
                    invalid={_validator.includes('prices_adult_non_member')}
                    value={tab3Data.prices_adult_non_member}
                    onChange={(e) => setTab3Data('prices_adult_non_member', e)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} />
              </Grid>
            </Label>
            <Label text={translate('activities-edit', 'tab3_prices_children_label')}>
              <Grid container>
                <Grid item xs={12} sm={3} className={`${classes.inputContainer} ${classes.leftContainer}`}>
                  <InputField fullWidth
                    money
                    type='number'
                    placeholder={translate('activities-edit', 'tab3_price_member_placeholder')}
                    invalid={_validator.includes('prices_children_member')}
                    value={tab3Data.prices_children_member}
                    onChange={(e) => setTab3Data('prices_children_member', e)} />
                </Grid>
                <Grid item xs={12} sm={3} className={`${classes.inputContainer} ${classes.leftContainer}`}>
                  <InputField fullWidth
                    money
                    type='number'
                    placeholder={translate('activities-edit', 'tab3_price_non_member_placeholder')}
                    invalid={_validator.includes('prices_children_non_member')}
                    value={tab3Data.prices_children_non_member}
                    onChange={(e) => setTab3Data('prices_children_non_member', e)} />
                </Grid>
                <Grid item xs={12} sm={3} className={`${classes.inputContainer} ${classes.leftContainer}`}>
                  <InputField fullWidth
                    integer
                    type='number'
                    placeholder={translate('activities-edit', 'tab3_from_age_placeholder')}
                    invalid={_validator.includes('prices_children_age_from')}
                    value={tab3Data.prices_children_age_from}
                    onChange={(e) => setTab3Data('prices_children_age_from', e)} />
                </Grid>
                <Grid item xs={12} sm={3} className={classes.inputContainer}>
                  <InputField fullWidth
                    integer
                    type='number'
                    placeholder={translate('activities-edit', 'tab3_to_age_placeholder')}
                    invalid={_validator.includes('prices_children_age_to')}
                    value={tab3Data.prices_children_age_to}
                    onChange={(e) => setTab3Data('prices_children_age_to', e)} />
                </Grid>
              </Grid>
            </Label>
            <Label text={translate('activities-edit', 'tab3_prices_quantity_label')}>
              <Grid container>
                <Grid item xs={12} sm={3} className={`${classes.inputContainer} ${classes.leftContainer}`}>
                  <InputField fullWidth placeholder={translate('activities-edit', 'tab3_amount_menber_placeholder')}
                    money
                    type='number'
                    invalid={_validator.includes('amount_member')}
                    value={tab3Data.amount_member}
                    onChange={(e) => setTab3Data('amount_member', e)} />
                </Grid>
                <Grid item xs={12} sm={3} className={`${classes.inputContainer} ${classes.leftContainer}`}>
                  <InputField fullWidth placeholder={translate('activities-edit', 'tab3_amount_non_member_placeholder')}
                    money
                    type='number'
                    invalid={_validator.includes('amount_non_member')}
                    value={tab3Data.amount_non_member}
                    onChange={(e) => setTab3Data('amount_non_member', e)} />
                </Grid>
                <Grid item xs={12} sm={6} />
              </Grid>
            </Label>
          </Fragment>
        }
      </div>
      <div className={classes.formFooter}>
        <Button label={translate('activities-edit', 'button_back')} onClick={handleBack} />
        <Button label={translate('activities-edit', 'button_next')} fill onClick={validateForm} />
      </div>
    </div >
  );
}
export default memo(ActivitiesTab3);