import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';

const theme = () => {
  const styles = makeStyles(theme => ({
    formWrap: {
      padding: 0,
      marginBottom: 0,
    },
    titleWrap: {
      marginBottom: 10,
    },
    loginButtonWrap: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    input: {
      width: '100%',
      marginBottom: 10,
    },
    success: {
      color: '#07A717 !important',
      fontSize: '12px !important',
      lineHeight: '14px',
      fontWeight: 'bold',
      justifyContent: 'flex-start',
      marginTop: -20
    },
    title: {
      fontSize: '14px !important',
      fontWeight: 'bold !important',
      color: `${ColorSchemes.darkBlue} !important`
    }
  }));
  return styles();
}
export default theme;