import React, {useState, useEffect} from 'react';
import theme from './style';
import {Paper} from '@material-ui/core';
import {Text} from '../../../components/DataDisplay';
import {InputField, Select, Check} from '../../../components/Inputs';
import Button from '../../../components/Button';
import translate from '../../../functions/applyTranslation';
import {useDispatch, useSelector} from 'react-redux';
import {
  setLoading
} from '../../../redux/states/statesActions';
import useAPI from '../../../api/apiHandler';

const Extra = (props) => {
  const classes = theme();
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const loading = useSelector(state => state.states.loading);
  const [invalidList, setInvalidList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState({
    id: null,
    blog_block: false,
    notity_article: false,
    notity_activity: false,
    event_direct_publish: false,
    direct_publish: [],
    role: null,
    active: true,
    success: false,
    tour_guide: true
  })
  const {saveMode, roles, notifications, setNotifications} = props;

  const fetchCategories = () => {
    dispatch(setLoading(true))
    let url = `categories`;

    setRequest({
      url: url,
    }).then(res => {
      if (res) {
        let _categories = [];
        res.data.map(item => {
          if (item.id === "2" || item.id === "3" || item.id === "5") {
            _categories.push(item);
          }
        })
        setCategories(_categories);
      }
      dispatch(setLoading(false))
    })
  }

  const handleDirectPublish = (value, id) => {
    let ids = JSON.parse(JSON.stringify(data.direct_publish));
    if (value)
      ids.push(id);
    else {
      const index = ids.indexOf(id);
      if (index > -1) {
        ids.splice(index, 1);
      }
    }
    setData({...data, success: false, direct_publish: ids})
  }

  const handleChange = (value, id) => {
    setData({...data, success: false, [id]: value})
  }


  const handleSubmit = (e) => {
    try {
      e.preventDefault()
    } catch (err) {
    }
    ;
    if (validator()) {
      dispatch(setLoading(true))
      let userData = {
        user_id: data.id,
        role: data.role,
        active: data.active ? 1 : 0,
        tour_guide: data.tour_guide ? 1 : 0
      }
      setRequest({
        url: saveMode === 'admin' ? `user/profile?user_id=${data.id}` : 'user/profile',
        method: 'PATCH',
        data: userData,
      }).then(res => {
        if (res) {
          const setting = {
            user_id: data.id,
            event_certifier: (data.role === 'editor' || data.role === 'superadmin' || data.role === 'admin') ? (data.notity_activity ? 1 : 0) : 0,
            articles_direct_publish: data.role === 'memberplus' ? (data.direct_publish || []).join(',') : null,
            event_direct_publish: data.role === 'memberplus' ? (data.event_direct_publish ? 1 : 0) : 0,
            newsletter_subscribed: notifications.newsletters || 1
          }
          setRequest({
            url: saveMode === 'admin' ? `user/settings?user_id=${data.id}` : 'user/settings',
            method: 'PATCH',
            data: setting,
          }).then(res => {
            if (res) {
              setData({
                ...data,
                success: true
              })
              setNotifications({
                ...notifications,
                newsletters: notifications.newsletters || true
              });
            }
            dispatch(setLoading(false))
          })
          dispatch(setLoading(false))
        } else
          dispatch(setLoading(false))
      })
    }
  }

  const roleList = (roles || []).map(item => {
    return {
      id: item,
      title: translate('profile', `label_role_${item}`)
    }
  })

  const fetchData = () => {
    if (props.data) {
      setData({
        id: props.data.id,
        blog_block: props.settings.blogpost_create_not_allowed === "1",
        notity_article: props.settings.articles_certifier === "1",
        notity_activity: props.settings.event_certifier === "1",
        direct_publish: props.settings.articles_direct_publish ? props.settings.articles_direct_publish.split(',') : [],
        role: props.data.role,
        active: props.data.active === 1,
        event_direct_publish: props.settings.event_direct_publish,
        success: false,
        tour_guide: props.data.tour_guide === 1
      });
    }
  }

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData();
  }, [props.settings])

  const validator = () => {
    let invalid = [];

    setInvalidList(invalid);
    return invalid.length === 0
  }

  return (
    <Paper elevation={0} className={classes.formWrap}>
      <div className={classes.titleWrap}>
        <Text className={classes.title} text={`${translate('profile', 'title_change_extra_info')}`}/>
      </div>
      {data &&
      <form onSubmit={handleSubmit}>
        <Select className={classes.input} fullWidth data={roleList} selected={data.role}
                onChange={(e) => handleChange(e, 'role')} id='role'/>
        <Check className={classes.input} fullWidth label={translate('profile', 'placeholder_active')}
               value={data.active} onChange={handleChange} id='active'/>
        <Check className={classes.input} fullWidth label={translate("profile", "placeholder_guide")}
               value={data.tour_guide} onChange={handleChange} id='tour_guide'/>
        {data.role !== 'member' &&
        <Check className={classes.input} fullWidth label={translate('profile', 'placeholder_blog_block')}
               value={data.blog_block} onChange={handleChange} id='blog_block'/>
        }
        {data.role === 'editor' &&
        <>
          <Check className={classes.input} fullWidth label={translate('profile', 'placeholder_notity_article_approval')}
                 value={data.notity_article} onChange={handleChange} id='notity_article'/>
          <Check className={classes.input} fullWidth
                 label={translate('profile', 'placeholder_notity_activity_approval')} value={data.notity_activity}
                 onChange={handleChange} id='notity_activity'/>
        </>
        }
        {/*This section may be added in the future*/}
        {/* {data.role === 'memberplus' &&
        <Check className={classes.input} fullWidth label={translate('profile', 'placeholder_event_direct_publish')}
               value={data.event_direct_publish} onChange={handleChange} id='event_direct_publish'/>
        }*/}
        {data.role === 'memberplus' && categories &&
        categories.map(item => {
          return <Check id={`cat_${item.id}`} className={classes.input} fullWidth
                        label={`${translate('profile', 'placeholder_fast_publish')} ${item.name}`}
                        value={data.direct_publish.includes(item.id)} onChange={(e) => handleDirectPublish(e, item.id)}
                        id='article_approval'/>
        })
        }
        <div className={classes.loginButtonWrap}>
          <Button disabled={loading || !saveMode} icon='save' fill label={translate('profile', 'button_save')}
                  type='submit'/>
        </div>
        {data && data.success &&
        <div className={classes.loginButtonWrap}>
          <Text text={translate('profile', 'label_set_personal_info_success')} className={classes.success}/>
        </div>
        }
      </form>
      }
    </Paper>
  )
}
export default Extra;
