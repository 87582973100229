import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    wrapItem: {
      width: '100%',
    },
    listItem: {
      '&:nth-child(odd)': {
        background: ColorSchemes.tableOddBg,
      },
      position: 'relative',
      color: ColorSchemes.mediumBlue,
      display: 'inline-flex',
      fontSize: 12,
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      '& svg': {
        fontSize: 20,
        cursor: 'pointer',
      },
      '& p': {
        margin: '2px 0px',
      }
    },
    check: {
      fontSize: '16px !important',
      position: 'absolute',
      left: 0,
      top: 0
    },
    arrowUp: {
      position: 'absolute',
      right: 20,
      top: 0
    },
    arrowDown: {
      position: 'absolute',
      right: 0,
      top: 0
    }
  }));
  return styles();
}
export default theme;
