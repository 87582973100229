import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    chipGroup: {
      display: 'inline-flex',
      height: 18,
      color: ColorSchemes.white,
      backgroundColor: ColorSchemes.mediumBlue,
      borderRadius: 13,
      padding: '2px 0px',
      fontSize: 12,
      alignItems: 'center',
      cursor: 'pointer',
      '&:not(:last-child)': {
        marginRight: 2,
      },
      '&:hover': {
        backgroundColor: ColorSchemes.orange,
      }
    },
    fullWidth: {
      width: '100%'
    },
    chipIcon: {
      fontSize: 16,
      color: ColorSchemes.white,
      marginRight: 10,
      marginLeft: 4,
    },
    chipText: {
      fontWeight: 500,
      flexGrow: 1,
      marginLeft: 10,
      marginRight: 4,
      fontSize: 13,
      textAlign: 'center',
      color: ColorSchemes.white,
    }
  }));
  return styles();
}
export default theme;
