import React  from 'react';
import theme from './style';
import { Grid, Paper } from '@material-ui/core';
import Header from '../../components/Header';
import useHook from './hook';
import { withRouter, useParams } from 'react-router-dom';

const TemplateTablet = (props) => {
  const [handleLogout, updateLocation] = useHook(props);
  const classes = theme();
  const {
    children, history
  } = props;

  history.listen((location, action) => {
    updateLocation();
  });



  return (
    <div className={classes.root}>
      <Header tabletMode handleLogout={handleLogout} />
      <div className={classes.viewContainer}>
        <div className={classes.contentWrap}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12}>
              <Paper className={classes.paperContainer}>
                {children}
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </div >
  );
}
export default withRouter(TemplateTablet);