import React, { useState, useEffect } from 'react';
import theme from './style';
import { useImage } from '../../../api/apiHandler';
const Image = (props) => {
  const [getImageURL] = useImage();
  const {
    height,
    path,
    url,
    alt,
    className,
    file,
    blobPath,
  } = props;
  // const [picBLOB, setPicBLOB] = useState();
  const classes = theme({ height });
  const imgURL = file ? getImageURL(file) : url || `${window.location.origin}${path}`
  // if (blobPath) {
  //   blobPath(imgURL);
  // }
  // useEffect(() => {
  //   if (file) getImage(file).then(res => setPicBLOB(res));
  // }, [file])
  return (
    <img src={imgURL} alt={alt} className={className} />
  );
}
export default Image;