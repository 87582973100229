import React, { memo } from 'react';
import theme from './style';
const LogoComponent = (props) => {
  const { tabletMode, isBlue, onClick } = props;
  const classes = theme();
  const handleClick = () => {
    if (onClick)
      onClick();
  }
  return (
    <img src={isBlue ? '/img/logoBlue.png' : '/img/logo.png'} alt='Logo' onClick={handleClick} className={`${classes.logo} ${tabletMode ? classes.tablet : ''}`} />
  );
}
export default memo(LogoComponent);