import React from "react";
import {Grid, Typography, Box} from "@material-ui/core";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SmallTitle from "../../../components/DataDisplay/SmallTitle";
import translate from "../../../functions/applyTranslation";
import theme from "../style";
import {Check, InputField, Label} from "../../../components/Inputs";

const Step3 = props => {
  const classes = theme();

  const {
    data,
    handleTextChange,
    invalidList,
    checkedGDPR,
    setCheckedGDPR
  } = props;

  const handlePrivacyIconClick = e => {
    window.open('https://www.jfm.se/integritetspolicy-for-judiskt-kunskapscenter/', '_blank')
    e.stopPropagation();
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <SmallTitle text={translate('booking', 'step3_label')} className={classes.stepLabel}/>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Box clone order={{ xs: 1 }}>
            <Grid item xs={12} md={6} className={classes.textFieldWrapper}>
              <Label className={classes.textFieldLabel} fullwidth text={translate('booking', 'label_organisation_name')}>
                <InputField fullWidth type='text'
                            value={data.organisation} onChange={handleTextChange} id='organisation'
                            invalid={invalidList.includes('organisation')}/>
              </Label>
            </Grid>
          </Box>
          <Box clone order={{ xs: 2 }}>
            <Grid item xs={12} md={6} className={classes.textFieldWrapper}>
              <Label className={classes.textFieldLabel} fullwidth text={translate('booking', 'label_school_address')}>
                <InputField fullWidth type='text'
                            value={data.adress} onChange={handleTextChange} id='adress'
                            invalid={invalidList.includes('adress')}/>
              </Label>
            </Grid>
          </Box>
          <Box clone order={{ xs: 4, md: 3 }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={4}>
                <Grid item xs={6} md={6} className={classes.textFieldWrapper}>
                  <Label className={classes.textFieldLabel} fullwidth text={translate('booking', 'label_course')}>
                    <InputField fullWidth type='text'
                                value={data.grade} onChange={handleTextChange} id='grade'/>
                  </Label>
                </Grid>
                <Grid item xs={6} md={6} className={classes.textFieldWrapper}>
                  <Label className={classes.textFieldLabel} fullwidth text={translate('booking', 'label_number_visitors')}
                        error={invalidList.includes('nr_visitors_to_high') ? translate('booking', 'max_people_error') : ""}>
                    <InputField fullWidth type='number' placeholder={translate("booking", "label_number_visitors")}
                                value={data.nr_visitors} onChange={handleTextChange} id='nr_visitors'
                                invalid={invalidList.includes('nr_visitors') || invalidList.includes('nr_visitors_to_high')}/>
                  </Label>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box clone order={{ xs: 3, md: 4 }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={4}>
                <Grid item xs={4} md={4} className={classes.textFieldWrapper}>
                  <Label className={classes.textFieldLabel} fullwidth text={translate('booking', 'label_zip')}>
                    <InputField fullWidth type='text'
                                value={data.zip} onChange={handleTextChange} id='zip'
                                invalid={invalidList.includes('zip')}/>
                  </Label>
                </Grid>
                <Grid item xs={8} md={8} className={classes.textFieldWrapper}>
                  <Label className={classes.textFieldLabel} fullwidth text={translate('booking', 'label_city')}>
                    <InputField fullWidth type='text'
                                value={data.city} onChange={handleTextChange} id='city'
                                invalid={invalidList.includes('city')}/>
                  </Label>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box clone order={{ xs: 5 }}>

            <Grid item xs={12} md={6} className={classes.textFieldWrapper}>
              <Label className={classes.textFieldLabel} fullwidth text={translate('booking', 'label_contact_person')}>
                <InputField fullWidth placeholder={translate('booking', 'placeholder_contact_person')} type='text'
                            value={data.name} onChange={handleTextChange} id='name'
                            invalid={invalidList.includes('name')}/>
              </Label>
            </Grid>
          </Box>
          <Box clone order={{ xs: 6 }}>
          
            <Grid item xs={12} md={6} className={classes.textFieldWrapper}>
              <Label className={classes.textFieldLabel} fullwidth text={translate('booking', 'label_mobile_phone')}>
                <InputField fullWidth type='text'
                            value={data.phone} onChange={handleTextChange} id='phone'
                            invalid={invalidList.includes('phone')}/>
              </Label>
            </Grid>
          </Box>
          <Box clone order={{ xs: 7 }}>

            <Grid item xs={12} md={6} className={classes.textFieldWrapper}>
              <Label className={classes.textFieldLabel} fullwidth text={translate('booking', 'label_email')}>
                <InputField fullWidth type='text'
                            value={data.email} onChange={handleTextChange} id='email'
                            invalid={invalidList.includes('email')}/>
              </Label>
            </Grid>
          </Box>
          <Box clone order={{ xs: 8 }}>

            <Grid item xs={12} md={6} className={classes.textFieldWrapper}>
              <Label className={classes.textFieldLabel} fullwidth text={translate('booking', 'label_confirm_email')}>
                <InputField fullWidth type='text'
                            value={data.confirmEmail} onChange={handleTextChange} id='confirmEmail'
                            invalid={invalidList.includes('confirmEmail')}/>
              </Label>
            </Grid>
          </Box>
          <Box clone order={{ xs: 9 }}>

            <Grid item xs={12} className={classes.textFieldWrapper}>
              <Label className={classes.textFieldLabel} fullwidth text={translate('booking', 'label_other_info')}>
                <InputField fullWidth multiline type='text' placeholder={translate("booking", "label_other_info")}
                            value={data.message} onChange={handleTextChange} id='message'/>
              </Label>
            </Grid>
          </Box>

        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <div className={classes.accessibilityGrid}>
          <div className={classes.accessibilityImgWrapper}>
            <img src="/img/disabled.png" width="35" className={classes.accessibilityImg}/>
          </div>
          <div>
            <Typography variant="subtitle2">{translate("booking", "disabled_disclaimer")}</Typography>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.contactsWrapper}>
        <Typography variant="subtitle2" className={classes.infoText}>{translate("booking", "contacts")}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.infoGrid}>
        <Typography variant="subtitle2" className={classes.infoText}>{translate("booking", "info")}</Typography>
      </Grid>
      <Grid item xs={12} className={classes.infoText}>
        <div style={{display: "flex", alignItems: "center"}}>
          <Check position="left" labelClass={classes.gdprText} value={checkedGDPR}
                 onChange={() => setCheckedGDPR(!checkedGDPR)} invalid={invalidList.includes('gdpr')}
                 label={translate("booking", "GDPR_label")} extraIcon={<OpenInNewIcon/>}
                 onExtraClick={handlePrivacyIconClick} extraClass={classes.extraClass}/>
        </div>
      </Grid>
    </Grid>
  )
    ;
};

export default Step3;