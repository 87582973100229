import React from 'react';
import theme from './style';
import { DateTimePicker as DP } from '@material-ui/pickers'
import clsx from 'clsx';
import EventIcon from '@material-ui/icons/Event';

function getRandomizer(bottom, top) {
  return function () {
    return Math.floor(Math.random() * (1 + top - bottom)) + bottom;
  }
}

const DateTimePicker = (props) => {
  const id = getRandomizer(11111, 99999)()
  const classes = theme();
  const {
    onChange,
    fullWidth,
    value,
    disableFuture,
    disablePast,
    maxDate,
    minDate,
    placeholder,
    invalid,
    format
  } = props;

  const handleChange = (e) => {
    if (onChange) {
      onChange(e)
    }
  }
  const handleForceClick = () => {
    document.getElementById(id).click();
  }
  const handleForceClickKeyboard = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      document.getElementById(id).click();
    }
  }
  return (
    <div onKeyDown={handleForceClickKeyboard} className={
      clsx(
        classes.datepickerGroup,
        fullWidth ? classes.fullWidth : '',
      )}
    >
      <div tabIndex={0} className={`${classes.borderWrap} ${invalid ? classes.invalid : ''}`}>
        {(placeholder && !value) &&
          <div className={classes.placeholderLabel}
            onClick={handleForceClick}

          > {placeholder}</div>
        }
        <DP
          style={{ display: (placeholder && !value) ? 'none' : 'inline-flex' }}
          id={String(id)}
          ampm={false}
          value={value || undefined}
          disableFuture={disableFuture}
          disablePast={disablePast}
          maxDate={maxDate}
          minDate={minDate}
          invalid={invalid}
          format={format || "YYYY-MM-DD HH:mm"}
          onChange={handleChange}
        />
        <EventIcon className={classes.icon} onClick={handleForceClick} />
      </div>
    </div >
  );
}

export default DateTimePicker;