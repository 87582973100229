export const formValidator = (data) => {
    const {
        //arrays
        visibility,
        //string
        title,
        content
    } = data;

    let formValid = [];
    if (!visibility.length) {
        formValid.push('visibility')
    }
    if (!title.length) {
        formValid.push('title')
    }
    if (!content.length) {
        formValid.push('content')
    }
    return formValid;
}