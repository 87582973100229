import React, { useEffect } from 'react';
import theme from './style';
import { SmallTitle, Accordion, Text } from '../../components/DataDisplay';
import useHook from './hook';
import translate from '../../functions/applyTranslation';
import Booking from './booking';
import Personal from './personal';
import Extra from './extra';
import Security from './security';
import Notifications from './notifications';
import { Grid } from '@material-ui/core';
import { Fragment } from 'react';
import Button from '../../components/Button';

const Profile = (props) => {
  const classes = theme();
  // eslint-disable-next-line
  const [id, booking, personal, settings, notifications, expanded, roles, saveMode, handleExpanded, handleActions, setNotifications] = useHook();

  return (
    <div className={classes.pageWrap}>
      <div className={classes.headerWrap}>
        <div className={classes.titleWrap}>
          <SmallTitle text={translate('profile', 'title_page_profile')} />
          {Number(id) > 0 &&
          <Button label={translate('', 'button_back')} fill icon='back' onClick={() => { handleActions('back') }} />
          }
        </div>
        <Text text={translate('profile', 'sub_title_profile')} />
      </div>
      <Accordion id="booking" expanded={expanded === 'booking'} onChange={handleExpanded('booking')} clean title={
        <Fragment>
          <div className={classes.accordionTitleWrap}>
            <SmallTitle text={translate('profile', 'label_my_activity_booking_title')} />
            <div className={classes.accordionSubtitleWrap}>
              <Text text={translate('profile', 'label_my_activity_booking_subtitle')} />
            </div>
          </div>
        </Fragment>
      }>
        <div>
          {booking &&
          <Booking data={booking} />
          }
        </div>
      </Accordion>
      <Accordion id="personal" expanded={expanded === 'personal'} onChange={handleExpanded('personal')} clean title={
        <Fragment>
          <div className={classes.accordionTitleWrap}>
            <SmallTitle text={translate('profile', 'label_personal_info_title')} />
            <div className={classes.accordionSubtitleWrap}>
              <Text text={translate('profile', 'label_personal_info_subtitle')} />
            </div>
          </div>

        </Fragment>
      }>
        <Grid container>
          <Grid item xs={12} sm={5}>
            {personal &&
            <Personal saveMode={saveMode} roles={roles} data={personal} />
            }
          </Grid>
        </Grid>
      </Accordion>
      <Accordion id="security" expanded={expanded === 'security'} onChange={handleExpanded('security')} clean title={
        <Fragment>
          <div className={classes.accordionTitleWrap}>
            <SmallTitle text={translate('profile', 'label_security_title')} />
            <div className={classes.accordionSubtitleWrap}>
              <Text text={translate('profile', 'label_security_subtitle')} />
            </div>
          </div>
        </Fragment>
      }>
        <Grid container>
          <Grid item xs={12} sm={5}>
            <Security saveMode={saveMode} />
          </Grid>
        </Grid>

      </Accordion>

      {saveMode && saveMode === 'admin' &&
      <Accordion id="extra" expanded={expanded === 'extra'} onChange={handleExpanded('extra')} clean title={
        <Fragment>
          <div className={classes.accordionTitleWrap}>
            <SmallTitle text={translate('profile', 'label_extra_info_title')} />
            <div className={classes.accordionSubtitleWrap}>
              <Text text={translate('profile', 'label_extra_info_subtitle')} />
            </div>
          </div>

        </Fragment>
      }>
        <Grid container>
          <Grid item xs={12} sm={5}>
            {personal &&
            <Extra saveMode={saveMode} roles={roles} data={personal} settings={settings || []} notifications={notifications} setNotifications={setNotifications}/>
            }
          </Grid>
        </Grid>
      </Accordion>
      }
      <Accordion id="notifications" expanded={expanded === 'notifications'} onChange={handleExpanded('notifications')} clean title={
        <Fragment>
          <div className={classes.accordionTitleWrap}>
            <SmallTitle text={translate('profile', 'label_notifications_title')} />
            <div className={classes.accordionSubtitleWrap}>
              <Text text={translate('profile', 'label_notifications_subtitle')} />
            </div>
          </div>
        </Fragment>
      }>
        <div>
          {notifications &&
          <Notifications saveMode={saveMode} data={notifications} personal={personal} settings={settings} id={id}/>
          }
        </div>
      </Accordion>
    </div >
  );
}
export default Profile;