import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    stepper: {
      '& .MuiStepConnector-root': {
        left: 'calc(-50% + 4px)',
        right: 'calc(50% + 4px)',
        top: 10,
      },
      '& .MuiStepConnector-line': {
        borderTopWidth: 2,
      },
      padding: 0,
    },
    root: {
      color: "#eaeaf0",
      display: "flex",
      height: 22,
      alignItems: "center",
    },
    active: {
      backgroundColor: ColorSchemes.green
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: ColorSchemes.highDarkGrey
    },
    completed: {
      backgroundColor: ColorSchemes.green,
      width: 8,
      height: 8,
      borderRadius: "50%"
    },
    label: {
      '& .MuiStepLabel-label': {
        marginTop: 0,
        fontSize: 12,

      }
    }
  }));
  return styles();
}
export default theme;
