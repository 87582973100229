import React, {useEffect, useState} from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Select,
  MenuItem, InputLabel, Typography
} from "@material-ui/core";
import {useDispatch} from "react-redux";
import moment from "moment";
import {setLoading} from "../../redux/states/statesActions";
import useAPI from "../../api/apiHandler";
import translate from '../../functions/applyTranslation';
import {DateTimePicker, Label} from "../../components/Inputs";
import theme from "./style";

const GuideBookingList = () => {
  const columns = [
    {
      name: translate("booking-list", "month_column")
    },
    {
      name: translate("booking-list", "time_column")
    },
    {
      name: translate("booking-list", "program_column")
    },
    {
      name: translate("booking-list", "guide_column")
    }
  ];

  const [setRequest] = useAPI();
  const dispatch = useDispatch();
  const classes = theme();

  const [events, setEvents] = useState({});
  const [guides, setGuides] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [filter, setFilter] = useState({
    startDate: moment().subtract(1, 'months').startOf('month'),
    endDate: moment().subtract(1, 'months').endOf('month'),
    guide: "0",
  });

  moment.locale("sv");

  const fetchData = (startDate, endDate, guide) => {
    let url = `events?limit=100&page=1&type=synagogue&have_tour_guide=1&start_time=${moment(startDate).unix()}&end_time=${moment(endDate).unix()}`;

    if (guide !== "0")
      url += `&tour_guide=${guide}`;

    dispatch(setLoading(true))
    setRequest({
      url: url,
      method: 'GET'
    })
      .then(res => {
        if (res) {
          let _events = {};
          _events["content"] = res.data.content;

          _events.content.forEach(e => {
            if (!_events[e.jfm_event.program_id]) {
              _events[e.jfm_event.program_id] = 1
            } else {
              _events[e.jfm_event.program_id] += 1
            }
          })

          setEvents(_events);
        }
        dispatch(setLoading(false))
      })
      .catch(() => dispatch(setLoading(false)))
  };

  const fetchAllGuides = () => {
    dispatch(setLoading(true))
    setRequest({
      url: 'users/tour_guides',
      method: 'GET'
    })
      .then(res => {
        if (res) {
          setGuides(res.data)
        }
        dispatch(setLoading(false))
      })
      .catch(() => dispatch(setLoading(false)))
  };

  const fetchPrograms = () => {
    dispatch(setLoading(true))
    setRequest({
      url: 'event/tour_programs',
      method: 'GET'
    })
      .then(res => {
        if (res) {
          setPrograms(res.data)
        }
        dispatch(setLoading(false))
      })
      .catch(e => dispatch(setLoading(false)))
  };

  const getEventTime = (event) => {
    const startTime = moment.unix(event.jfm_event.event_start).format('LT');
    const endTime = moment.unix(event.jfm_event.event_end).format('LT');

    return `${startTime} - ${endTime}`
  }

  const getGuideName = (id) => {
    const guide = guides.find(g => g.id === id);

    if (guide)
      return guide.name;

    return translate('booking-list', 'not_assigned');
  };

  const getProgramName = (id) => {
    const program = programs.find(p => p.id === id);
    if(program)
      return program.name

    return "";
  };

  const handleFiltersChange = (id, value) => {
    setFilter({...filter, [id]: value});

    if (id === "startDate") {
      fetchData(value, filter.endDate, filter.guide);
    } else if (id === "endDate") {
      fetchData(filter.startDate, value, filter.guide);
    } else if (id === "guide") {
      fetchData(filter.startDate, filter.endDate, value);
    }
  }

  useEffect(() => {
    fetchData(filter.startDate, filter.endDate, "0");
    fetchAllGuides();
    fetchPrograms();
  }, []);

  return (
    <Grid container justify="center" style={{padding: 20}}>
      <Grid item xs={12} className={classes.filtersWrap}>
        <Grid container>
          <Grid item xs={4} md={3}>
            <Label text={translate('booking-list', 'label_from_date')} fullWidth>
              <DateTimePicker
                value={filter.startDate}
                format="YYYY-MM-DD"
                disableFuture={false}
                disablePast={false}
                onChange={(e) => handleFiltersChange("startDate", e)}
              />
            </Label>
          </Grid>
          <Grid item xs={4} md={3}>
            <Label text={translate('booking-list', 'label_to_date')} fullWidth>
              <DateTimePicker
                value={filter.endDate}
                format="YYYY-MM-DD"
                disableFuture={false}
                disablePast={false}
                onChange={(e) => handleFiltersChange("endDate", e)}
              />
            </Label>
          </Grid>
          <Grid item xs={4} md={3}>
            <Label text={translate('booking-list', 'label_guide')} fullWidth>
              <div className={classes.selectGroup}>
                {(filter.guide === "0") &&
                <InputLabel
                  className={classes.placeholder}>{translate('booking-list', 'placeholder_select')}</InputLabel>
                }
                <Select
                  value={filter.guide}
                  fullWidth
                  onChange={(e) => handleFiltersChange("guide", e.target.value)}
                >
                  {guides && guides.map(guide => (
                    <MenuItem key={guide.id} value={guide.id}>{guide.name}</MenuItem>
                  ))}
                </Select>
              </div>
            </Label>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.totalsWrap}>
        {programs.map(program => (
            <Typography variant="body2" gutterBottom>{`${program.name}: ${events[program.id] || 0}`}</Typography>
        ))}
        {events.content && <Typography variant="body2">{`Total: ${events.content.length}`}</Typography>}
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map((col, index) => (
                  <TableCell key={index}>{col.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {events.content && events.content.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {moment.unix(row.jfm_event.event_start).format("MMMM")}
                  </TableCell>
                  <TableCell>{getEventTime(row)}</TableCell>
                  <TableCell>{getProgramName(row.jfm_event.program_id)}</TableCell>
                  <TableCell>{getGuideName(row.jfm_event.tour_guide)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default GuideBookingList;