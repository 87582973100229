import { makeStyles } from "@material-ui/core/styles";
import { findByLabelText } from "@testing-library/dom";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    resume: {
      backgroundColor: '#CED5E6',
      marginBottom: '24px'
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 18,
      fontSize: 16,
      lineHeight: '23px'
    },
    titleWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: '11px !important',
      '& p': {

        fontSize: '44px !important',
        lineHeight: '53px !important',
      }
    }
  }));
  return styles();
}
export default theme;
