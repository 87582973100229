
import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import { InputField } from '../../../components/Inputs';
import Button from '../../../components/Button';
import translate from '../../../functions/applyTranslation';
import theme from './style';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Modal(props) {
  const { modal, closeModal } = props;
  const classes = theme();
  const [data, setData] = useState({
    text: modal ? (modal.comments || '') : '',
  })
  const handleClose = () => {
    closeModal();
  };
  const formValid = () => {
    return !data.text.length
  }
  const handleSubmit = () => {
    if (modal.callback) {
      modal.callback(modal.type, modal.index, data.text, 'comments');
      closeModal();
    }

    setData({
      text: '',
    })
  }

  useEffect(() => {
    if (modal && (modal.comments || '') !== data.text)
      setData({
        text: modal ? (modal.comments || '') : '',
      })
  }, [modal])

  return (
    <div>
      <Dialog
        open={modal && modal.key === 'comments'}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>
          {translate('activities-cart', 'label_comments_title')}
        </DialogTitle>
        <DialogContent>
          <InputField value={data.text}
            className={classes.content}
            multiline
            placeholder={translate('activities-cart', 'label_comments')} fullWidth
            onChange={(e) => {
              setData({
                text: e,
              })
            }} />
          <small>{translate('activities-cart', 'label_comments_helper')}</small>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} label={translate('', 'button_cancel')} />
          <Button disabled={formValid()} onClick={handleSubmit} label={translate('activities-cart', 'button_save_comment')} fill icon='' />
        </DialogActions>
      </Dialog>
    </div>
  );
}
