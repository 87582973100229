import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core';

const ExpandRowButton = props => {
  const classes = useStyles();

  const { 
    onClick,
    isExpanded,
  } = props;

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <IconButton
      onClick={handleClick}
    >
      {isExpanded ? <RemoveIcon className={classes.smallIcon} /> : <AddIcon className={classes.smallIcon} />}
    </IconButton>
  );
}

const useStyles = makeStyles(() => ({
  smallIcon: {
    fontSize: "1rem",
  }
}))



export default ExpandRowButton;