export const formValidator = (data) => {
    const {
        //nulls
        publish_date,
        //arrays
        visibility,
        categories,
        //string
        title,
        intro,
        content,
        lead_image_id,
    } = data;

    let formValid = [];
    if (!visibility.length) {
        formValid.push('visibility')
    }
    if (!publish_date) {
        formValid.push('publish_date')
    }
    if (!categories.length) {
        formValid.push('categories')
    }
    if (!title.length) {
        formValid.push('title')
    }
    if (!intro.length) {
        formValid.push('intro')
    }
    if (!content.length) {
        formValid.push('content')
    }
    if(!lead_image_id) {
        formValid.push('image')
    }
    return formValid;
}