import React, { useState, useEffect } from 'react';
import theme from './style';
import { TextField, } from '@material-ui/core';
import translate from '../../../functions/applyTranslation';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@material-ui/icons';
import clsx from 'clsx';
const LabelInput = (props) => {
  const classes = theme();
  const {
    money,
    integer,
    type,
    value,
    onChange,
    multiline,
    fullWidth,
    rowsMax,
    rows,
    id,
    invalid,
    placeholder,
    className,
    helperText,
    maxLength,
    name
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [length, setLength] = useState(0);

  const handleClick = () => {
    setShowPassword(!showPassword);
  }

  const handleChange = (e) => {
    if (maxLength) {
      var currentText = e.target.value;
      setLength(currentText.length);
    }
    if (onChange) {
      if (integer) {

      }
      else if (money) {

      }
      onChange(e.target.value, id)
    }
  }

  useEffect(()=>{
    if (maxLength) {
      setLength((value || '').length);
    }
  },[value]);

  return (
    <div className={
      clsx(
        className,
        classes.inputWrapper,
        invalid ? classes.invalid : '',
        fullWidth ? classes.fullWidth : '',
        multiline ? classes.multiline : '',
      )}
    >
      <TextField
        name={name || ""}
        className={`${(money || integer) ? classes.right : ''}`}
        placeholder={placeholder || ''}
        type={showPassword ? 'text' : (type || 'text')}
        multiline={multiline}
        rowsMax={multiline ? (rowsMax || 4) : 0}
        rows={multiline ? (rows || 4) : 0}
        value={value || ''}
        InputProps={{ inputProps: type === 'number' ? { min: 0, step: money ? .01 : 0 } : { maxLength: maxLength } }}
        helperText={helperText}
        onChange={handleChange} />
      {type === 'password' && !showPassword &&
      <VisibilityIcon className={classes.icon} onClick={handleClick} />
      }
      {type === 'password' && showPassword &&
      <VisibilityOffIcon className={classes.icon} onClick={handleClick} />
      }
      {maxLength > 0 &&
      <span className={classes.length}>{`${length} / ${maxLength} ${translate('', 'input_characters')}`}</span>
      }
    </div >
  );
}

export default LabelInput;