import React, {memo, useContext, useEffect, useState} from 'react';
import theme from './style';
import Button from '../../components/Button';
import { SmallTitle, Accordion, Text, Tabs } from '../../components/DataDisplay';
import { Select, Check, } from '../../components/Inputs';
import useStatus from '../../settings/statusList';
import translate from '../../functions/applyTranslation';
import { Grid } from '@material-ui/core'
import Tab1 from './tab1';
import Tab2 from './tab2';
import Tab3 from './tab3';
import Tab4 from './tab4';
import TabReview from './tabReview';
import { ActivitiesContext, Provider } from "./actitiesContext";
import { useParams, useHistory } from 'react-router-dom'
import { Fragment } from 'react';
import PublishInfo from '../../components/PublishInfo';
import DeleteModal from '../delete-modal';
import usePermissionsHook from '../../functions/permissions';
import useAPI from "../../api/apiHandler";

const ActivitiesEdit = (props) => {
  const params = useParams();
  const history = useHistory();
  const activitiesContext = useContext(ActivitiesContext);
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const [statusList] = useStatus();
  const [setRequest] = useAPI();
  const { selectedTab, handleSelectedTab, data, information, setFormData, validator, handleSubmit, handleReturn, handleDelete, modal, handleModalDelete, closeModal, submited } = activitiesContext;
  const [authorName, setAuthorName] = useState(null);

  const classes = theme();
  const newForm = !params.id;

  if (newForm && !hasPermission(permissions.ACTIVITIES_CREATE))
    history.push('/');


  if (!newForm && !hasPermission(permissions.ACTIVITIES_EDIT))
    history.push('/');

  const generateTab = () => {
    switch (selectedTab) {
      case 1:
        return <Tab1 />
      case 2:
        return <Tab2 />
      case 3:
        return <Tab3 />
      case 4:
        return <Tab4 />
      default:
        break;
    }
  }

  const handleValidatedChange = (id, value) => {
    let _validated_by = JSON.parse(JSON.stringify(data.validated_by));
    let index = data.validated_by.findIndex(item => item === id);
    if (index > -1) {
      _validated_by.splice(index, 1)
    } else {
      _validated_by.push(id)
    }
    setFormData('validated_by', _validated_by)
  }
  const isValidated = (id) => {
    return data.validated_by.includes(id);
  }
  const isFormValid = () => {
    let keys = Object.keys(validator);
    let formValid = true;
    keys.forEach(item => {
      if (!validator[item]) { formValid = false }
    })
    return !formValid;
  }

  const fetchActivityInfo = id => {
    setRequest({
      url: `user/profile?user_id=${id}`,
      method: 'GET',
    })
    .then(res => {
      if (res && res.data && typeof res.data.name === 'string') {
        setAuthorName(res.data.name);
      } else {
        console.error("Failed to fetch user name: Data is missing or malformed", res);
      }
    })
    .catch(error => {
      console.error("Error fetching user profile", error);
    });
  }

  useEffect(() => {
    if (information.creator_id && information.creator_id !== '999999') {
      fetchActivityInfo(information.creator_id)
    }
  }, [information.creator_id])

  const disabledItems = (isValidated(1) && isValidated(2)) ? [] : [2];

  return (
    <Fragment>
      <DeleteModal title={translate('activities-edit', 'label_delete_title')} subTitle={translate('activities-edit', 'label_delete_subtitle')} modal={modal ? modal.key : null} closeModal={closeModal} callBack={handleDelete} />

      <div className={classes.pageWrap}>
        <div className={classes.headerWrap}>
          <div className={classes.titleWrap}>
            <SmallTitle text={translate('activities-edit', 'title_activities_form')} />
            <div className={classes.buttonGroup}>
              {!newForm &&
                <Fragment>
                  {hasPermission(permissions.ACTIVITIES_DELETE) &&
                    <Button label={translate('', 'button_delete')} icon='delete' onClick={handleModalDelete} />
                  }
                  <Button label={translate('', 'button_back')} fill icon='back' onClick={handleReturn} />
                  <Button label={translate('', 'button_save')} icon='save' disabled={isFormValid()} fill onClick={handleSubmit} />
                </Fragment>
              }

            </div>
          </div>
          <Text text={translate('activities-edit', 'sub_title_activitites_form')} />
        </div>
        {(hasPermission(permissions.ACTIVITIES_EDITOR_APPROVAL) ||
          hasPermission(permissions.ACTIVITIES_SECURITY_APPROVAL)) &&
          <Grid container className={classes.header}>
            <Grid item xs={12} sm={6} md={4} className={classes.headerCheckGroupWrap}>
              <Check disabled={!hasPermission(permissions.ACTIVITIES_SECURITY_APPROVAL)} value={isValidated(1)} onChange={() => handleValidatedChange(1)} label={translate('activities-edit', 'check_jss_approval')} />
              <Check disabled={!hasPermission(permissions.ACTIVITIES_EDITOR_APPROVAL)} value={isValidated(2)} onChange={() => handleValidatedChange(2)} label={translate('activities-edit', 'check_center_manager_approval')} />
              <Check value={isValidated(3)} onChange={() => handleValidatedChange(3)} label={translate('activities-edit', 'check_editor_check')} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.headerSelectWrap}>
              <Select fullWidth
                data={statusList}
                disabledItems={disabledItems}
                selected={data.status_id}
                onChange={(e) => setFormData('status_id', e)}
              />
            </Grid>
            {!newForm &&
              <Grid item sm={12} md={4} className={classes.headerSelectWrap}>
                <PublishInfo area={'information-edit'}
                  createdDate={information.cdate}
                  createdBy={authorName}
                  managedBy={information.certified_by}
                  publishedBy={information.certified_by}
                  unpublishedBy={information.unpublished_by} />
              </Grid>
            }

          </Grid>
        }
        {!submited &&
          <Fragment>
            <div className={classes.tabSelectorWrap}>
              <Tabs
                selected={selectedTab}
                onSelect={(e) => { handleSelectedTab(e) }}
                //FIXME: add form validator to tab steps
                menuItems={
                  [
                    {
                      id: 1,
                      text: translate('activities-edit', 'label_tab_plan'),
                    },
                    {
                      id: 2,
                      text: translate('activities-edit', 'label_tab_tell'),
                    },
                    {
                      id: 3,
                      text: translate('activities-edit', 'label_tab_notifications'),
                    },
                    {
                      id: 4,
                      text: translate('activities-edit', 'label_tab_other'),
                    },
                  ]
                } />
            </div>
            {generateTab()}
          </Fragment>
        }
        {submited &&
          <TabReview />
        }
      </div >
    </Fragment>
  );
}
const ApplyProvider = (props) => {
  return (<Provider>
    <ActivitiesEdit {...props} />
  </Provider>)
}
export default memo(ApplyProvider);