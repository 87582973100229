import React from 'react';
import theme from './style';
import moment from 'moment';
import Accordion from '../Accordion';
import CommentIcon from '@material-ui/icons/Comment';
import translate from '../../../functions/applyTranslation';
import Text from '../Text'
import { useSelector } from 'react-redux';

const ApplicationHeader = (props) => {
    const userData = useSelector(state => state.auth.userData);
    const classes = theme();
    const {
        id,
        date_created,
        status,
        path,
        item,
        expanded,
        handleExpanded,
        onClick
    } = props;

    const allowApprove = ['superadmin', 'admin'].includes(userData.role) && Number(status) === 0;
    const allowRollback = ['superadmin', 'admin'].includes(userData.role) && Number(status) === 2;
    const key = `application_${id}`;

    const handleClick = (action, value) => {
        if (onClick) {
            onClick({ action, value })
        }
    }

    return (
        <div className={classes.main}>
            <div className={classes.left}>
                <div className={classes.author}>
                    {`${moment.unix(date_created).format("DD MMMM")} ${translate('', 'label_date_at')} ${moment.unix(date_created).format("HH:mm")} • `}<div className={`${classes.status} status_${status}`}>{translate('application-list', `label_status_${status}`)}</div>
                </div>
            </div>
            {allowApprove &&
                <div className={classes.edit}>
                    <Accordion blog info expanded={expanded === key} onChange={handleExpanded(key)} >
                        <div>
                            <Text onClick={(e) => { handleClick('approve', id) }} className={classes.actionEdit} text={translate('application-list', 'button_approve')} />
                            <Text onClick={(e) => { handleClick('reprove', id) }} className={classes.actionDelete} text={translate('application-list', 'button_reprove')} />
                        </div>
                    </Accordion>
                </div>
            }
            {allowRollback &&
                <div className={classes.edit}>
                    <Accordion blog info expanded={expanded === key} onChange={handleExpanded(key)} >
                        <div>
                            <Text onClick={(e) => { handleClick('roolback', id) }} className={classes.actionDelete} text={translate('application-list', 'button_roolback')} />
                            <Text onClick={(e) => { handleClick('approve', id) }} className={classes.actionEdit} text={translate('application-list', 'button_approve')} />
                        </div>
                    </Accordion>
                </div>
            }
        </div >
    );
}

export default ApplicationHeader;