import { combineReducers } from 'redux';
import authReducer from './auth/authReducers';
import cartReducer from './cart/cartReducers';
import statesReducer from './states/statesReducers';
import settingsReducer from './settings/settingsReducers';
import errorsReducer from './errors/errorsReducers';

const rootReducer = combineReducers({
  auth: authReducer,
  states: statesReducer,
  settings: settingsReducer,
  errors: errorsReducer,
  cart: cartReducer,
})


export default rootReducer;