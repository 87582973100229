import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    buttonWrap: {
      paddingBottom: 10,
      marginBottom: 10,
      borderBottom: `1px solid ${ColorSchemes.highDarkGrey}`
    },
    mainGrid:{
      '& >:not(:last-child)': {
        marginRight: 42,
      },
      '&.simple .marginGroup': {
        paddingTop: 5,
        paddingBottom: 5,
        display: 'inline-flex',
      },
    },
    headerWrap: {
      fontSize: 13,
      marginBottom: 20,
      '& svg': {
        height: 14,
        width: 14,
        marginRight: 7,
        fill: ColorSchemes.mediumBlue
      },
      '& .marginNoIcon': {
        paddingLeft: 20,
        paddingBottom: 5,
      },
      '& .marginGroup': {
        paddingTop: 6,
        paddingBottom: 6,
        display: 'flex',
      },
      '& .customMargin': {
        paddingTop: 5,
      },
      '& .button': {
        cursor: 'pointer',
        marginTop: 5,
        marginBottom: 5,
        background: ColorSchemes.green,
        color: ColorSchemes.white,
        borderRadius: 3,
        paddingTop: 10,
        paddingBottom: 10,
        fontWeight: 'bold',
        textAlign: 'center',
        '&.disabled': {
          cursor: 'default',
          background: ColorSchemes.lowDarkGrey
        }
      }
    },
    titleWrap: {
      marginBottom: 12,
    },
    location: {
      fontWeight: 'bold',
      lineHeight: '15px',
      display: 'block'
    },
    image: {
      width: "100%"
    }
  }));
  return styles();
}
export default theme;
