import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    titleWrap: {

      '& .title': {
        fontSize: 14,
        color: ColorSchemes.darkBlue,
        fontWeight: '500',
        paddingBottom: 5,
        paddingTop: 7,
      },
      '& .error': {
        fontSize: 14,
        color: ColorSchemes.orange,
        fontWeight: '500',
        paddingBottom: 5,
        paddingTop: 7,
        paddingLeft: 10
      },
    },
    fullWidth: {
      width: '100%'
    },
  }));
  return styles();
}
export default theme;
