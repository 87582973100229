import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const styles = makeStyles(theme => ({
    logo: {
      maxHeight: 42,
      cursor: 'pointer'
      // width:'auto'
    },
    tablet: {
      padding: '12px 0 18px 28px',
    }
  }));
  return styles();
}
export default theme;

