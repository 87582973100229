import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme';
const theme = () => {
  const styles = makeStyles(theme => ({
    header: {
      borderBottom: 'solid 1px #707070',
      paddingBottom: 20,
      paddingTop: 10,
      float: 'rigth'
    },
    headerWrap: {
      // borderBottom: `1px solid ${ColorSchemes.highDarkGrey}`,
      marginBottom: 10,
      padding: '10px 0px',
    },
    titleWrap: {
      display: 'inline-flex',
      width: '100%',
      alignItems: 'center',
      '& >:nth-child(1)': {
        flexGrow: 1,
      }
    },
    buttonGroup: {
      '& >:not(:last-child)': {
        marginRight: 10,
      }
    },
    accordionTitleWrap: {
      display: 'inline-flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',

    },
    accordionBodyWrap: {
      width: '100%',
      paddingBottom: 20,
      borderBottom: `1px solid ${ColorSchemes.highDarkGrey}`,
    },
    inputGroup: {
      // display: 'flex',
      width: '100%',
      marginTop: 5,
      marginBottom: 5,
    },
    margin: {
      marginRight: 10,
    },
    section: {
      marginTop: 20,
    },
    verticalMargin: {
      marginTop: 5,
      marginBottom: 5
    },
    stepperWrap: {
      paddingLeft: '15%',
      paddingRight: '15%',
    },
    imageHolder: {
      display: 'flex',
      justifyContent: 'center'
    },
    image: {
      height: 100,
      maxWidth: 200,
    }
  }));
  return styles();
}
export default theme;

