import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLocation } from '../../redux/states/statesActions';
import { setLogout } from '../../redux/auth/authActions';
import useAPI from '../../api/apiHandler';
import { useHistory } from 'react-router-dom';
const HomeHook = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const updateLocation = () => {
    dispatch(setLocation(window.location.pathname));
  }
  const handleLogout = (type) => {
    dispatch(setLogout())
    history.replace('/')
  }

  return [handleLogout, updateLocation];
}

export default HomeHook;