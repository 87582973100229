import { useState } from 'react';
const ModalHook = (props) => {
  const [modal, setModal] = useState()
  const closeModal = () => {
    setModal();
  }
  const openLinkModal = () => {
    setModal('link');
  }
  const openFileModal = () => {
    setModal('file');
  }
  const openImageModal = () => {
    setModal('image');
  }
  const openDivModal = () => {
    setModal('div');
  }
  return [modal, {
    closeModal, openFileModal, openImageModal, openLinkModal, openDivModal
  }
  ];
}
export default ModalHook;