import React from 'react';
import theme from './style';
import ClampLines from 'react-clamp-lines';
import moment from 'moment';
import translate from '../../../functions/applyTranslation';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { useHistory } from 'react-router-dom';
import { useImage } from '../../../api/apiHandler';
import {useSelector} from "react-redux";
const ListView = (props) => {
  const history = useHistory();
  const [getImageURL] = useImage();
  const { access_token } = useSelector(state => state.auth);
  const {
    item,
    width,
    path,
    showStatus,
    statusList,
    firstPage
  } = props;
  const classes = theme({ width: width });
  const {
    id,
    date,
    title,
    intro,
    image,
    highlight
  } = item;

  const tryParse = (value) => {
    let result = value;
    let intro = "";

    if(value)
      result = value.replace(/token=(.*?)"/g, `token=${access_token}"`);

    let body = parse(result || '');

    for(let i = 0; i < body.length-1;i++) {
      if(body[i].props) {
        intro += body[i].props.children;
      }
    }

    return intro;
  }

  const tryParseFirstPage = value => {
    try {
      let intro = "";
      let result = "";
      let body = parse(value || '');
      if (!Array.isArray(body)) {
        if (body.props) {
          for (let key in body.props.children) {
            if (body.props.children[key].props) {
              if (body.props.children[key].props.children && typeof body.props.children[key].props.children === "string") {
                intro += body.props.children[key].props.children
              }
            }
          }
        }
      } else {
        for (let i = 0; i < body.length - 1; i++) {
          if (body[i].props) {
            intro += body[i].props.children;
          }
        }
      }
      result = intro.replace(/\[object Object\]/g, '').trim();
      return result;
    } catch (e) {
      return translate('', 'home_item_content_error');
    }
  }

  const hanldeNavigation = () => {
    history.push(`${path}/${id}`)
  }
  const statusName = () => {
    if (!statusList) return '';
    var status = statusList.find(x => Number(x.id) === Number(item.status_id));
    if (!status) return '';
    return status.title;
  }
  let url = getImageURL(image);//`${window.location.origin}${image}`
  return (
    <div className={`${classes.gridViewWrap} ${highlight?'highlight':''}`}>
      <div className={'image'} style={{ backgroundImage: `url(${url})` }} onClick={hanldeNavigation} />
      <div className={clsx(classes.messageWrap, classes.gridMessageWrap)}>
        <div className={classes.dateWrap}>
          <div className={'itemDate'}>{date ? moment.unix(date).format('DD MMMM, YY') :
            //FIXME:Remove temp data
            moment().format('DD MMMM, YYYY')
          }</div>
          {showStatus &&
          <div className={'cartStatus'}>{`(${statusName()})`}</div>
          }
        </div>
        <div className={'itemTitle'} onClick={hanldeNavigation}>
          {title}
        </div>
        <ClampLines
          text={firstPage ? (tryParseFirstPage(intro) || '') : (tryParse(intro) || '')}
          lines={2}
          ellipsis="..."
          buttons={false}
          className="itemText"
          innerElement="p"
        />
        <div className={'actionOpen'} onClick={hanldeNavigation}>
          {translate('', 'label_read_more')}
        </div>
      </div>
    </div >
  );
}
export default ListView;