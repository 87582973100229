import {useState, useEffect} from 'react';
import useAPI from '../../api/apiHandler';
import {useDispatch} from 'react-redux';
import {setLoading} from '../../redux/states/statesActions';
import usePermissionsHook from '../../functions/permissions';
import useSettingsHook from '../../functions/settings';
import useStatus from '../../settings/statusList';

const NewsListHook = (props) => {
  const [role, hasSetting] = useSettingsHook();
  const [statusList, statusFilter, applyFilter] = useStatus();
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const [news, setNews] = useState([])
  const [newsInfo, setNewsInfo] = useState({
    totalItems: 0,
    totalPages: 1,
  })
  const [categories, setCategories] = useState([])
  const [filter, setFilter] = useState({
    page: !!sessionStorage.getItem('nLP') ? Number(JSON.parse(sessionStorage.getItem('nLP'))) : 1,
    status: !!sessionStorage.getItem('nLS') ? Number(JSON.parse(sessionStorage.getItem('nLS'))) : (hasSetting('event_published_notification') ? 3 : -1),
    limit: 6,
    category: null,
  })

  const fetchNews = () => {
    dispatch(setLoading(true))
    const {status, page, limit, category} = filter;
    const visibility = hasPermission(permissions.NEWS_CREATE) ? '1,2' : '2';
    let url = `articles?status_id=${applyFilter(status)}&visibility=${visibility}&page=${page}&limit=${limit}`;
    if (status < 0)
      url = `articles?visibility=${visibility}&page=${page}&limit=${limit}`;
    if (category) {
      url += `&category_id=${category}`
    }
    setRequest({
      url: url,//FIXME: fix the url
      method: 'GET',
    }).then(res => {
      if (res) {
        let keys = Object.keys(res.data.articles)
        let arr = keys.map(item => {
          return res.data.articles[item]
        })

        const crisisArticles = [];
        const noncrisisArticles = [];

        arr.forEach(item => {
          item.highlight = (item.categories || '').split(',').includes('4');
          item.categories.includes('5') ? crisisArticles.push(item) : noncrisisArticles.push(item)
        });

        crisisArticles.sort(sortByDate);
        noncrisisArticles.sort(sortByDate)

        const sortedArticles = [...crisisArticles, ...noncrisisArticles]

        setNews(sortedArticles)
        setNewsInfo({
          totalPages: Number(res.data.pages),
          totalItems: Number(res.data.total)
        })
      }
      dispatch(setLoading(false))
    })
  }

  const fetchCategories = () => {
    dispatch(setLoading(true))
    const {status} = filter;
    // const visibility = status === 2 ? (hasPermission(permissions.NEWS_CREATE) ? '1' : '2') : '1,2'
    const visibility = hasPermission(permissions.NEWS_CREATE) ? '1,2' : '2';
    let url = `categories?status_id=2&visibility=${visibility}`;
    if (status < 0)
      url = `categories?status_id=2&visibility=${visibility}`;
    setRequest({
      url: url,
    }).then(res => {
      if (res) {
        let keys = Object.keys(res.data)
        let arr = keys.map(item => {
          return res.data[item]
        })
        setCategories(arr)
      }
      dispatch(setLoading(false))
    })
  }

  const handleChangeFilter = (key, value) => {
    if (key === 'status') {
      setFilter({...filter, "page": 1, "status": value});
      sessionStorage.setItem("nLP", JSON.parse(1));
      sessionStorage.setItem('nLS', JSON.parse(value));
    } else {
      setFilter({...filter, [key]: value})
    }
  }
  const handlePagination = (action) => {
    setFilter({...filter, page: (action === 'next' ? filter.page += 1 : filter.page -= 1)})
    const page = sessionStorage.getItem("nLP");
    const newPage = (action === 'next' ? Number(page) + 1 : Number(page) - 1);
    sessionStorage.setItem("nLP", newPage);
  }
  useEffect(() => {
    if (setRequest) {
      fetchNews();
      fetchCategories()
    }
    // eslint-disable-next-line
  }, [filter])

  function sortByDate(a, b) {
    return b.publish_date - a.publish_date;
  }

  return [news, newsInfo, filter, handleChangeFilter, handlePagination, categories];
}

export default NewsListHook;