import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    parent: {
      position: 'relative !important'
    },
    wrapper: {
      border: 'solid 1px #636363',
      borderRadius: 3,
    },
    invalid: {
      border: `3px solid ${ColorSchemes.darkOrange}`
    },
    simple: {
      '& .tui-editor-contents.tui-editor-contents-placeholder, .tui-editor-contents': {
        padding: '4px 10px !important',
        color: '#222',
        fontSize: '14px'
      },
      '& .te-toolbar-section': {
        display: 'none !important',
      }
    },
    length: {
      position: 'absolute',
      top: '11px',
      right: '20px',
      fontSize: 12,
      color: ColorSchemes.lowDarkGrey,
    },
    displaySelectionWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      border: "1px solid #e7e7e7"
    },
    selectedDisplay: {
      backgroundColor: "#ffffff",
      padding: "0 10px",
      border: "1px solid #e7e7e7"
    },
    unselectedDisplay: {
      backgroundColor: "#e5e5e5",
      color: "#808080",
      padding: "0 10px",
      border: "1px solid #e7e7e7",
      cursor: "pointer"
    },
    markdownCssLabel: {
      display: 'none'
    },
    markdownNotchedOutline: {
      borderWidth: '1px',
      borderColor: '#cccccc !important'
    }
  }));
  return styles();
}
export default theme;
