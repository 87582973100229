import React, { useContext } from 'react';
import theme from './style';
import { Grid, } from '@material-ui/core';
import Button from '../../components/Button';
import { SmallTitle, SearchResultListItem,Text } from '../../components/DataDisplay';
import translate from '../../functions/applyTranslation';
import clsx from 'clsx';
import { SearchResultsContext, Provider } from "./searchResultsContext";

import { Fragment } from 'react';

const GalleryList = (props) => {
  const classes = theme();
  const searchresulysContext = useContext(SearchResultsContext);
  const {
    data,
    dataSettings,
    filter,
    queryText,
    handlePagination,
    handleNavigation,
  } = searchresulysContext;
  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.galleryList}>
            <div className={classes.spaceBetween}>
              <SmallTitle text={translate('search-results', 'title_list')} />

            </div>
            <div className={classes.searchTextWrap}>
              <Text text={translate('search-results', 'sub_title_list')} /><Text className={classes.searchText} text={queryText} />
            </div>
            <Grid container className={classes.actionsWrapper}>
             
            </Grid>
            {data.map(item =>
              <SearchResultListItem
                key={`${item.area}_${item.id}`}
                id={item.id}
                title={item.title}
                area={item.area}
                areaName={item.areaName}
                handleNavigation={handleNavigation}
              />
            )}
            {/* <div className={clsx(classes.flexEnd, classes.paginatorLabel)}>
              {translate('search-results', 'label_page_counter').replace('##page##', filter.page).replace('##total##', dataSettings.totalPages)}
            </div>
            <div className={
              filter.page !== 1 && filter.page !== dataSettings.totalPages ? classes.spaceBetween :
                filter.page === 1 ? classes.flexEnd : classes.flexStart
            }>
              {filter.page !== 1 &&
                <Button label={translate('search-results', 'button_page_back')} fill onClick={(e) => handlePagination('back')} />
              }
              {!!dataSettings.totalPages && filter.page !== dataSettings.totalPages &&
                <Button label={translate('search-results', 'button_page_next')} fill onClick={(e) => handlePagination('next')} />
              }
            </div> */}
          </div>
        </Grid>
      </Grid >
    </Fragment>
  );
}

const ApplyProvider = (props) => {
  return (<Provider>
    <GalleryList {...props} />
  </Provider>)
}
export default ApplyProvider;