import moment from 'moment';

const canByNewTickets = (event, role) => {
    if (!event || !role) return false;
    if (event.osa !== '1') return false;
    if (role === 'member') {
        const { osa_start, osa_end } = event;
        if (!osa_start || !osa_end) return false;
        if (moment.unix() < osa_start || moment.unix() > osa_end) return false;

        return true;
    }
    const { osa_pay_end } = event;
    if (!osa_pay_end) return false;
    if (moment.unix() > osa_pay_end) return false;
    return true;
}


export default canByNewTickets;