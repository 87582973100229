import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    '@keyframes totop': {
      from: {
        marginTop: 0,
        marginBottom: -5,
      },
      to: {
        marginTop: -5,
        marginBottom: 0,
      }
    },
    toTopWrap: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    button: {
      cursor: 'pointer',
      display: 'grid',
      justifyItems: 'center',
      '& svg': {
        color: ColorSchemes.red,
        fontSize: 24,
        marginBottom: -5,
      },
      '& span': {
        fontSize: 14,
        color: ColorSchemes.black,
      },
      '&:hover': {
        '& svg': {
          animationName: '$totop',
          animationDuration: '1s',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'ease',
          animationDirection: 'alternate-reverse',
        },
      }
    }

  }));
  return styles();
}
export default theme;
