import { useState, memo } from 'react';

const FiltersHook = () => {
  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    status_id: 0
  })

  const setFilters = (key, value) => {
    setFilter({ ...filter, [key]: value })
  }
  return [filter, setFilters];
}

export default FiltersHook;