import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    footer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,

      backgroundColor: ColorSchemes.mediumBlue,
      maxHeight: 39,
      display: 'flex',
      justifyContent: 'space-evenly',
      padding: 12,
      alignItems: 'center',
      '& .footerItem': {
        color: ColorSchemes.white,
        fontSize: 10,
        padding: '0px 14px',
        '&.link': {
          cursor: 'pointer'
        }
      }
    },
    groupLabels: {
      display: 'flex',
    }
  }));
  return styles();
}
export default theme;
