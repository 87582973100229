import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from "../../settings/colorScheme";

const theme = (props) => {
  const styles = makeStyles(theme => ({
    selectGroup: {
      display: 'inline-flex',
      height: 35,
      position: 'relative',
      width: "100%",

      '& ::after': {
        border: 'none !important'
      },
      '& ::before': {
        border: 'none !important'
      },
      '& .MuiInput-root': {
        borderColor: ColorSchemes.highDarkGrey,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 3,
        flexGrow: 1,
      },
      '& .MuiSelect-select': {
        background: 'none',

        fontSize: 14,
        padding: '2px 10px',
        color: ColorSchemes.darkGrey,
      },
      '& .MuiSelect-icon': {
        top: 'calc(50% - 13px)'
      },
    },
    placeholder: {
      position: 'absolute',
      color: '#C3C3C3',
      padding: '10px',
      fontSize: '14px'
    },
    filtersWrap: {
      marginBottom: 20
    },
    totalsWrap: {
      marginBottom: 20
    }
  }));
  return styles();
}
export default theme;
