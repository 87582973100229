import React from 'react';
import theme from './style';
import { Grid, Typography, withWidth } from '@material-ui/core';
import Button from '../../components/Button';
import { TreeViewOrganizer, BigTitle } from '../../components/DataDisplay';
import translate from '../../functions/applyTranslation';
import useMenuOrderHook from './hook';
const HomeView = (props) => {
  const { width, } = props;
  let responsiveTest = (width === 'xs' || width === 'sm');
  const classes = theme({ mobile: responsiveTest });
  const [menu, handleActions, handleReorder, rasterizedMenu, newParentID, setNewParentId, handleReParenting, selected, setSelected] = useMenuOrderHook(props);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.headerWrap}>
        <Grid container>
          <Grid item xs={12} md={6} className={classes.titleGroup}>
            <BigTitle text={translate('menu_order', 'title_change_menu_order')} />
            <Typography>{translate('menu_order', 'sub_title_change_menu_order')}</Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.buttonGroup}>
            <Button label={translate('', 'button_back')} fill icon='back' onClick={() => { handleActions('back') }} />
            <Button label={translate('', 'button_save')} fill icon='save' onClick={() => { handleActions('save') }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} >
        <TreeViewOrganizer
          items={menu}
          handleReorder={handleReorder}
          rasterizedMenu={rasterizedMenu}
          newParentID={newParentID}
          setNewParentId={setNewParentId}
          onParentChange={handleReParenting}
          selected={selected}
          setSelected={setSelected}
        />
      </Grid>
    </Grid >
  );
}
export default withWidth()(HomeView);