import React, { useState, useEffect } from 'react';
import theme from './style';
import { Paper } from '@material-ui/core';
import { Text } from '../../../components/DataDisplay';
import { InputField, Select } from '../../../components/Inputs';
import Button from '../../../components/Button';
import translate from '../../../functions/applyTranslation';
import { useDispatch, useSelector } from 'react-redux';
import {
    setLoading
} from '../../../redux/states/statesActions';
import useAPI from '../../../api/apiHandler';

const Personal = (props) => {
    const classes = theme();
    // eslint-disable-next-line
    const dispatch = useDispatch();
    const [setRequest] = useAPI();
    const loading = useSelector(state => state.states.loading);
    const [invalidList, setInvalidList] = useState([]);
    const [data, setData] = useState({
        id: null,
        ssn: '',
        fullName: '',
        phoneNr: '',
        email: '',
        role: null,
        success: false
    })
    
    const { saveMode, roles } = props;

    const handleChange = (value, id) => {
        setData({ ...data, success: false, [id]: value });
    }

    const roleList = roles.map(item => {
        return {
            id: item,
            title: translate('profile', `label_role_${item}`)
        }
    })

    const handleSubmit = (e) => {
        try { e.preventDefault() } catch (err) { };
        if (validator()) {
            dispatch(setLoading(true))

            let userData = {
                name: data.fullName,
                phone: data.phoneNr,
                mail: data.email,
                user_id: data.id
            }

            setRequest({
                url: "user/profile",
                method: 'PATCH',
                data: userData,
            }).then(res => {
                if (res) {
                    setData({
                        ...data,
                        success: true
                    })
                }
                dispatch(setLoading(false))
            })
              .catch(() => dispatch(setLoading(false)))
        }
    }

    const fetchData = () => {
        if (props.data) {
            setData({
                id: props.data.id,
                ssn: props.data.ssn,
                fullName: props.data.fullName,
                phoneNr: props.data.phoneNr,
                email: props.data.email,
                role: props.data.role,
                active: props.data.active === '1',
                success: false
            });
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [props.data])

    const validator = () => {
        let invalid = [];
        Object.keys(data).forEach((key) => {
            var value = data[key];
            switch (key) {
                case 'fullName':
                    if (!value || value.length === 0) {
                        invalid.push('fullName')
                    }
                    break;
                case 'phoneNr':
                    if (!value || value.length === 0) {
                        invalid.push('phoneNr')
                    }
                    break;
                case 'email':
                    if (!value || value.length === 0) {
                        invalid.push('email')
                    }
                    break;
                default:
                    return true;
            }
        });
        setInvalidList(invalid);
        return invalid.length === 0
    }
    return (
        <Paper elevation={0} className={classes.formWrap}>
            <div className={classes.titleWrap}>
                <Text className={classes.title} text={`${translate('profile', 'title_change_personal_info')} ${data.ssn}`} />
                <Text className={classes.title} text={`${translate('profile', 'placeholder_role')} ${translate('profile', `label_role_${data.role}`)}`}/>
            </div>
            <form onSubmit={handleSubmit}>
                <InputField className={classes.input} fullWidth placeholder={translate('profile', 'placeholder_full_name')} type='text' value={data.fullName} onChange={handleChange} id='fullName'
                    invalid={invalidList.includes('fullName')} />
                <InputField className={classes.input} fullWidth placeholder={translate('profile', 'placeholder_phone_number')} type='text' value={data.phoneNr} onChange={handleChange} id='phoneNr'
                    invalid={invalidList.includes('phoneNr')} />
                <InputField className={classes.input} fullWidth placeholder={translate('profile', 'placeholder_email')} type='text' value={data.email} onChange={handleChange} id='email'
                    invalid={invalidList.includes('email')} />
                <div className={classes.loginButtonWrap}>
                    <Button disabled={loading || !saveMode} icon='save' fill label={translate('profile', 'button_save')} type='submit' />
                </div>
                {data && data.success &&
                    <div className={classes.loginButtonWrap}>
                        <Text text={translate('profile', 'label_set_personal_info_success')} className={classes.success} />
                    </div>
                }
            </form>
        </Paper>
    )
}
export default Personal;