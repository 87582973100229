import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    accordion: {
      borderBottom: `1px solid ${ColorSchemes.highDarkGrey}`,
      boxShadow: "none",
      backgroundColor: ColorSchemes.white,
      borderRadius: '0px !important',
      margin: '0px !important',
      // "&:not(:last-child)": {
      //   borderBottom: 0
      // },
      "&:before": {
        display: "none"
      },
      '&.info': {
        borderBottom: 'none',
        margin: '0 20px 0 0 !important',
      },
      '&.clean': {
        border: 'none',
      },
      '&.info > .MuiCollapse-container': {
        position: 'absolute',
        width: '100%',
        marginLeft: '10px',
        backgroundColor: '#ffffff !important',
        padding: '10px',
        border: `1px solid ${ColorSchemes.lowLightGrey}`,
      },
      '&.blog > .MuiCollapse-container': {
        boxShadow: '0px 9px 13px #00000042',
        borderRadius: '2px',
        right: '-12px',
        minWidth: '100px',
        padding: '10px 25px',
        zIndex: 10,
      }

    },
    summary: {
      padding: 0,
      borderBottom: `1px solid ${ColorSchemes.highDarkGrey}`,
      marginBottom: -1,
      minHeight: '59px !important',
      '& .MuiAccordionSummary-content': {
        margin: '12px 0px !important'
      },
      '&.info': {
        borderBottom: 'none !important',
        minHeight: 'auto !important',
        marginBottom: '0 !important',
      },
      '&.info .MuiAccordionSummary-content': {
        margin: '0 !important',
        textAlign: 'right',
        display: 'revert'
      },
      '&.info .MuiAccordionSummary-expandIcon': {
        padding: '0 !important',
        color: ColorSchemes.darkBlue
      },
    },
    details: {
      padding: '8px 0px 16px 0px',
      '&.info': {
        padding: '0'
      }
    },
    left: {
      border: 'none',
      alignItems: 'start',
      flexDirection: 'row-reverse',
      '& .MuiAccordionSummary-content': {
        margin: '12px 12px 0 12px !important'
      },
      '& .MuiAccordionSummary-expandIcon': {
        paddingLeft: '0 !important',
        paddingBottom: '0 !important',
      },
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        paddingRight: '0 !important',
        paddingBottom: '12px !important',
        paddingLeft: '12px !important',
        paddingTop: '0 !important'
      }
    }
  }));
  return styles();
}
export default theme;
