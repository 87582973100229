import {useState, useEffect} from 'react';
import useAPI from '../../api/apiHandler';
import {useDispatch} from 'react-redux';
import {setLoading} from '../../redux/states/statesActions';
import {useParams} from 'react-router-dom';

const HomeHook = (props) => {
    const {history} = props;
    const params = useParams();
    const {id} = params;
    const dispatch = useDispatch();
    const [setRequest] = useAPI();
    const [menu, setMenu] = useState([])
    const [selected, setSelected] = useState([])
    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [data, setData] = useState();
    const handleSelect = (path) => {
        setSelected(path)
    }

    const flatPages = (temp) => {
        let pages = temp.map(item => flatPage(item)).flat();
        return pages;
    }

    const flatPage = (temp) => {
        let pages = [temp.page];
        if (temp.children && temp.children.length) {
            const children = temp.children.map(item => flatPage(item)).flat()
            pages = [...pages, ...children];
        }
        return pages;
    }

    const fetchTreeViewItems = () => {
        dispatch(setLoading(true))
        setRequest({
            url: 'pages/getsorted',
            method: 'GET',
        }).then(res => {
            if (res) {
                setMenu(res.data)
                checkSelected(res.data);
            }
        })
    }

    const checkSelected = (data) => {
        let temp = [];
        if (id) {

            let findMore = true
            const pages = flatPages(data);
            while (findMore) {
                let number = Number(id);
                if (temp.length)
                    number = Number(temp[0])
                const item = pages.find(item => Number(item.id) === number);
                if (item && temp.length === 0)
                    temp.push(id);
                if (item && item.parent_id && Number(item.parent_id) > 0) {
                    temp.unshift(item.parent_id);
                } else
                    findMore = false;
            }
            setSelected(temp);
        }
        if (!temp.length && !selected.length && data.length) {
            setSelected([data[0].page.id])
        } else
            dispatch(setLoading(false))
    }

    useEffect(() => {
        if (setRequest) {
            fetchTreeViewItems();
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (menu && !selected || !selected.length || Number(id) !== selected[selected.length - 1]) {
            checkSelected(menu);
        }
        // eslint-disable-next-line
    }, [id])

    useEffect(() => {
        let tempbreadcrumbs = [];
        let workingMenuLevel = JSON.parse(JSON.stringify(menu))
        if (selected.length) {
            if (Number(selected[selected.length - 1]) !== Number(id)) {
                history.replace(`/information/${Number(selected[selected.length - 1])}`)
            }
            selected.forEach(sel => {
                let ind = workingMenuLevel.findIndex(item => Number(item.page.id) === Number(sel));
                let newPathLevel = tempbreadcrumbs.length ? [...tempbreadcrumbs[tempbreadcrumbs.length - 1].path, workingMenuLevel[ind].page.id] : [workingMenuLevel[ind].page.id]
                tempbreadcrumbs.push({
                    text: workingMenuLevel[ind].page.title,
                    path: newPathLevel,
                })
                workingMenuLevel = workingMenuLevel[ind].children;
            })
            setBreadcrumbs(tempbreadcrumbs);
            fetchPageData(selected[selected.length - 1]);
        }
        // eslint-disable-next-line
    }, [selected])

    const handleBreadCrumbsSelect = (path) => {
        setSelected(path)
    }
    const fetchPageData = (id) => {
        dispatch(setLoading(true))
        setRequest({
            url: `page/?page_id=${id}`,
            method: 'GET',
        }).then(res => {
            if (res) {
                setData(res.data)
            }
            dispatch(setLoading(false))
        })
    }
    const handleActions = (type) => {
        let id = selected[selected.length - 1];

        switch (type) {
            case 'edit':
                if (id) history.push(`/information/edit/${id}`);
                break;
            case 'subpage':
                if (id) history.push(`/information/subpage/${id}`);
                break;
            case 'new':
                history.push(`/information/new`);
                break;
            default:
                history.push('/information/menu-order');
                break;
        }
    }
    return [menu, selected, handleSelect, breadcrumbs, handleBreadCrumbsSelect, data, handleActions];
}

export default HomeHook;