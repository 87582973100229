import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    itemWrapp: {
      padding: 3,
      display: 'inline-flex',
      cursor: 'pointer',
      width: '100%',
      '&:hover': {
        backgroundColor: ColorSchemes.lowLightGrey
      }
    },
    selected: {
      backgroundColor: ColorSchemes.highLightGrey

    },
    staticWrap: {
      display: 'inline-flex',
      alignItems: 'center',
      '& svg': {
        height: 22,
        width: 22,
        fill: ColorSchemes.mediumBlue
      },
      '& .image': {
        height: 50,
        width: 50,
        marginLeft: 8
      }
    },
    textWrap: {
      display: 'inline-flex',
      width: '100%',
      paddingLeft: 10
    },
    item: {
      padding: 2,
      fontSize: 19,
      color: ColorSchemes.black,
      display: 'inline-flex',
      alignItems: 'center',
      alignContent: 'center',
      flexWrap: 'wrap',
      '& small': {
        flexBasis: '100%',
        lineHeight: '14px',
        marginBottom: '5px',
        fontSize: '12px',
        fontWeight: 'bold',
      }
    }
  }));
  return styles();
}
export default theme;
