import { useState } from 'react';
import useAPI from '../../../api/apiHandler';
import { useDispatch } from 'react-redux';
import { setLoading, increaseCount } from '../../../redux/states/statesActions';
import { acceptBlogRules } from '../../../redux/settings/settingsActions';
import {decode} from 'html-entities';

const BlogHook = () => {
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const [blog, setBlog] = useState([])
  const [navigationData, setSavigationData] = useState({
    totalItems: 0,
    totalPages: 1,
  })

  const fetchBlog = (filters) => {
    dispatch(setLoading(true))
    const { status_id, limit, page } = filters;
    let url = `blogs?limit=${limit}&page=${page}`;
    setRequest({
      url: url,
    }).then(res => {
      if (res) {
        let data = res.data.blogPosts;
        data.forEach(item => {
          item.blog.content = decode(item.blog.content).replace(/(<([^>]+)>)/gi, "");
        });
        setBlog(data);
        setSavigationData({
          totalPages: Number(res.data.pages),
          totalItems: Number(res.data.total)
        })
      }
      dispatch(setLoading(false))
    })
  }

  const deleteBlog = (id) => {
    dispatch(setLoading(true))
    setRequest({
      url: `blog/delete/?blog_id=${id}`,
      method: 'DELETE',
    }).then(res => {
      if (res) {
        dispatch(increaseCount())
      }
      else
        dispatch(setLoading(false))
    })
  };

  const handleAcceptBlogRules = () => {
    dispatch(acceptBlogRules(true));
  }

  return [{
    data: blog,
    settings: navigationData
  }, fetchBlog, deleteBlog, handleAcceptBlogRules];
}

export default BlogHook;