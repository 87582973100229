import React, { useState, useEffect } from "react";
import { Grid, Paper, TextField, Select, MenuItem, InputLabel, FormControl, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowBackIos } from '@material-ui/icons'
import translate from '../../functions/applyTranslation';

// import tempFunc from './tempCommitTrans';
const useStyles = makeStyles(theme => ({
  paperTitle: {
    width: "100%",
    padding: 20,
    marginBottom: 20,
  },
  buttonGroup: {
    marginTop: 20,
    display: "flex",
    justifyContent: "flex-end"
  },
  container: {
    flexDirection: "row",
    justifyContent: "center",
    display: "flex"
  },

  paper: {
    padding: 20,
    width: "100%"
  },
  divider: {
    width: "80%"
  },
  cartButton: {
    width: '100%'
  }
}));
const TranslationsEditorNew = props => {
  const classes = useStyles();
  const [data, setData] = useState({ id: '', system: '', key: '', value: '', lang: 'sv_SE' });
  const [systemList, setSystemList] = useState([]);
  //const { getTranslation, getTranslationKeyById, submitTranslationsEdit, getTranslationSystemList } = React.useContext(LocalizationContext);
//  document.title = getTranslation('translations_form_browser_title') + ' | Prodiagnostics';

  const fetchData = (params) => {
    //TODO
    // getTranslationKeyById(params).then(res => {
    //   setData({ id: res.id, key: res.name, value: res.translation, system: res.system, lang: res.code })
    // })
  }
  const formValidator = () => {
    let error = [];
    Object.keys(data).forEach((key) => {
      var value = data[key];
      switch (key) {
        case 'lang': if (!value || value.length === 0) { error.push('lang') } break;
        case 'key': if (!value || value.length === 0) { error.push('key') } break;
        // case 'value': if (!value || value.length === 0) { error.push('value') } break;
        default:
          return true;
      }
    });
    return error.length === 0;
  }
  const loading = false;
  const submitFunc = () => {
    var form = new FormData();
    form.append("data[system]", data.system);
    form.append("data[name]", data.key);
    form.append("data[translation]", data.value);
    form.append("data[code]", data.lang);
    if (formValidator()) {
      //TODO
      // submitTranslationsEdit(data.id, form).then(res => {
      //   if (res.error === 0) {
      //     setData({ id: '', system: 'frontoffice', key: '', value: '', lang: 'sv_SE' })
      //   }
      // })
    }
  }
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    if (props.match.params.id.toLowerCase() !== 'new') {
      fetchData(props.match.params.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //TODO:
    // getTranslationSystemList().then(res => {
    //   setSystemList(res.data)
    // })
    // eslint-disable-next-line
  }, [])
  const handleBack = () => {
    props.history.goBack();
  }
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={10}>
        <Paper className={classes.paperTitle} elevation={1}>
          <Button
            style={{ margin: '0px 5px', float: 'right' }}
            variant="outlined"
            color="primary"
            onClick={handleBack}
            startIcon={<ArrowBackIos style={{ height: 12, width: 12 }} />}
          >{translate('','generic_button_back_to_list')}</Button>
          <Typography variant="h6" gutterBottom>
            {translate('translations','edit_title')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {translate('translations','edit_subtitle')}
          </Typography>
        </Paper>
        <Paper className={classes.paper} elevation={1}>
          <Grid container>
            <FormControl className='fixSelectAndDates'>
              <InputLabel id="langPicker">{translate('translations','form_page_label_lang')}</InputLabel>
              <Select
                labelId="langPicker"
                value={data.lang}
                name='lang'
                onChange={handleChange}
                disabled={loading}
                error={data.lang === ''}
              >
                <MenuItem value={'en_US'}>{translate('','generic_english_string')}</MenuItem>
                <MenuItem value={'sv_SE'}>{translate('','generic_sweedish_string')}</MenuItem>
              </Select>
            </FormControl>
            <TextField
              variant="filled"
              label={translate('translations','form_page_label_key')}
              name="key"
              value={data.key}
              onChange={handleChange}
              error={data.key === ''}
              disabled={loading || props.match.params.id.toLowerCase() !== 'new'}
            />
            <TextField
              variant="filled"
              label={translate('translations','form_page_label_value')}
              name='value'
              value={data.value}
              disabled={loading}
              onChange={handleChange}
              // error={data.value === ''}
            />
            <FormControl className='fixSelectAndDates'>
              <InputLabel id="systemLabel">{translate('translations','list_table_header_system')}</InputLabel>
              <Select
                labelId="systemLabel"
                value={data.system}
                name='system'
                onChange={handleChange}
                disabled={loading}
                error={data.system === ''}
              >
                {systemList.map(item =>
                  <MenuItem value={item.system}>{item.system}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid container className={classes.buttonGroup}>
            <Button
              style={{ margin: '0px 5px' }}
              variant="outlined"
              color="primary"
              onClick={() => props.history.goBack()}
            >{translate('','generic_button_back')}</Button>

            <Button
              style={{ margin: '0px 5px' }}
              variant="contained"
              color="primary"
              onClick={submitFunc}
            >{translate('','generic_button_save')}</Button>
          </Grid>
        </Paper>
      </Grid >
    </Grid >
  );
};
export default TranslationsEditorNew;