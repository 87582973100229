import React, {useEffect, useState} from 'react';
import theme from './style';
import Button from '../../components/Button';
import {SmallTitle, Accordion, Text, Chip, Stepper, Image} from '../../components/DataDisplay';
import {Select, Label, Check, LabelInput, InputField, TextEditor, DatePicker, Dropzone} from '../../components/Inputs';
import useHook from './hook';
import translate from '../../functions/applyTranslation';
import useStatus from '../../settings/statusList';
import clsx from 'clsx';
import {Grid} from '@material-ui/core'
import moment from 'moment';
import {useHistory, useParams} from 'react-router-dom'
import PublishInfo from '../../components/PublishInfo';
import {Fragment} from 'react';
import usePermissionsHook from '../../functions/permissions';
import DeleteModal from '../delete-modal';
import useAPI from "../../api/apiHandler";

const NewsEdit = (props) => {
  const params = useParams();
  const classes = theme();
  const history = useHistory();
  const [statusList, statusFilter] = useStatus();
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const [setRequest] = useAPI();
  const [authorName, setAuthorName] = useState(null);
  const [certifiedBy, setCertifiedBy] = useState(null);
  const newForm = !params.id;

  if (newForm && !hasPermission(permissions.NEWS_CREATE))
    history.push('/');

  if (!newForm && !hasPermission(permissions.NEWS_EDIT))
    history.push('/');

  // eslint-disable-next-line
  const [data, validator, categories, modal, closeModal, handleDelete, handleChange, handleActions, submitData, handleImageUpload, CATEGORIES] = useHook();
  const handleVisibilityChange = (id, value) => {
    let newArr = [];
    if ((data.visibility.includes(1) && id !== 1) || (id === 1 && value)) {
      newArr.push(1)
    }
    if ((data.visibility.includes(2) && id !== 2) || (id === 2 && value)) {
      newArr.push(2)
    }
    handleChange('visibility', newArr)
  }

  const handleCategoriesChange = (id, value) => {
    let newArr = JSON.parse(JSON.stringify(data.categories));
    let index = data.categories.findIndex(item => item === id);
    if (index === -1) {
      newArr.push(id)
    } else {
      newArr.splice(index, 1);
    }
    handleChange('categories', newArr)
  }

  const handleKeywords = (value) => {
    let _keywords = JSON.parse(JSON.stringify(data.keywords));
    _keywords.push(value);
    handleChange('keywords', _keywords);
  }
  const handleKeywordRemove = (index) => {
    let _keywords = JSON.parse(JSON.stringify(data.keywords));
    _keywords.splice(index, 1);
    handleChange('keywords', _keywords);
  }
  const getStep = () => {
    if (data.status_id && !newForm) {
      if (data.status_id === '2' && data.publish_date.isBefore(moment())) {
        return 3;
      } else if (data.status_id === '2') {
        return 2
      } else if (data.status_id === '3' || data.status_id === '4') {
        return 1;
      } else {
        return 0
      }
    } else {
      return 0;
    }
  }

  const fetchArticleInfo = id => {
    setRequest({
      url: `user/profile?user_id=${id}`,
      method: 'GET',
    })
      .then(res => setAuthorName(res.data.name))
  }

  useEffect(() => {
    if (data.author_id && data.author_id.id) {
      fetchArticleInfo(data.author_id.id)
    }
  }, [data.author_id])

  return (
    <>
      <DeleteModal title={translate('news-edit', 'label_delete_title')}
                   subTitle={translate('news', 'label_delete_subtitle')} modal={modal ? modal.key : null}
                   closeModal={closeModal} callBack={handleDelete}/>
      <div className={classes.pageWrap}>
        <div className={classes.headerWrap}>
          <div className={classes.titleWrap}>
            <SmallTitle text={translate('news-edit', 'title_page_information')}/>
            {(hasPermission(permissions.NEWS_CREATE) || hasPermission(permissions.NEWS_EDIT)) &&
            <div className={classes.buttonGroup}>
              {!newForm && hasPermission(permissions.NEWS_DELETE) &&
              <Button label={translate('', 'button_delete')} icon='delete' onClick={() => {
                handleActions('delete')
              }}/>
              }
              <Button label={translate('', 'button_back')} fill icon='back' onClick={() => {
                handleActions('back')
              }}/>
              <Button label={translate('', 'button_save')} icon='save' fill onClick={() => {
                handleActions('save')
              }}/>
            </div>
            }
          </div>
          <Text text={translate('news-edit', 'sub_title_news_form')}/>
        </div>
        {hasPermission(permissions.NEWS_EDIT) &&
        <>
          <div className={classes.stepperWrap}>
            <Stepper step={getStep()}/>
          </div>
          <Grid container className={classes.header}>

            <Fragment>
              <Grid item sm={12} md={4}>
                <Select fullWidth
                        data={statusList}
                        selected={data.status_id}
                        onChange={(e) => handleChange('status_id', e)}
                />
              </Grid>
              <Grid item mdUp md={4}/>
              <Grid item sm={12} md={4} className={classes.headerSelectWrap}>
                {!newForm &&
                <PublishInfo area={'news-edit'}
                             createdDate={data.date_created}
                             createdBy={authorName}
                             managedBy={(data.certified_by && data.certified_by.name) ? data.certified_by.name : null}
                             publishedBy={(data.certified_by && data.certified_by.name)  ? data.certified_by.name : null}
                             unpublishedBy={data.unpublished_by ? data.unpublished_by.name : null}/>
                }
              </Grid>
            </Fragment>

          </Grid>
        </>
        }


        <div classN me={classes.accordionBodyWrap}>
          <Grid container className={classes.inputGroup}>
            <Grid item xs={12} sm={6}>
              <Label fullWidth text={translate('news-edit', 'label_visibility')}>
                <Check invalid={validator.includes('visibility')} value={data.visibility.includes(2)}
                       onChange={(e) => handleVisibilityChange(2, e)}
                       label={translate('news-edit', 'form_check_label_pay_member')} className={classes.margin}/>
                <Check invalid={validator.includes('visibility')} value={data.visibility.includes(1)}
                       onChange={(e) => handleVisibilityChange(1, e)}
                       label={translate('news-edit', 'form_check_label_non_pay_member')} className={classes.margin}/>
              </Label>

            </Grid>
            <Grid item xs={12} sm={6}>
              <Label fullWidth text={translate('news-edit', 'label_publication_date')}>
                <DatePicker
                  fullWidth
                  invalid={validator.includes('publish_date')}
                  value={data.publish_date}
                  onChange={(e) => handleChange('publish_date', e)}
                />
              </Label>
            </Grid>
          </Grid>
          <Label fullWidth text={translate('news-edit', 'label_categorization')}>
            <Text text={translate('news-edit', 'label_subtitle_categorization')}/>
            {CATEGORIES.map(item => {
              let id = Number(item.id);
              return <Check invalid={validator.includes('categories')} key={id} value={data.categories.includes(id)}
                             onChange={(e) => handleCategoriesChange(id, e)} label={item.name}
                             className={classes.margin}/>
            })}
          </Label>

          <Label fullWidth text={translate('news-edit', 'label_search_tags')}>
            <Text text={translate('news-edit', 'label_subtitle_search_tags')}/>
            <div className={classes.inputGroup}>
              {data.keywords.map((item, index) =>
                <Chip key={index} id={index} label={item} className={clsx(classes.margin, classes.verticalMargin)}
                      onClick={handleKeywordRemove}/>
              )}
            </div>
            <LabelInput fullWidth onClick={handleKeywords}/>
          </Label>
        </div>
        <div className={classes.section}>
          <SmallTitle text={translate('news-edit', 'title_news_form')}/>
        </div>
        <Label text={translate('news-edit', 'form_label_select_image')}>
          <Text text={translate('news-edit', 'form_sublabel_select_image')}/>
          <Grid container>
            <Grid item xs={12} sm={data.lead_image_id ? 9 : 12}>
              <Dropzone invalid={validator.includes('image')} onChange={handleImageUpload}/>
            </Grid>
            {data.lead_image_id &&
            <Grid item xs={12} sm={3} className={classes.imageHolder}>
              <Image file={data.lead_image_id} className={classes.image}/>
            </Grid>
            }
          </Grid>
        </Label>
        <Label fullWidth text={translate('news-edit', 'form_label_title')} className={classes.verticalMargin}>
          <InputField invalid={validator.includes('title')} fullWidth value={data.title}
                      onChange={(e) => handleChange('title', e)}/>
        </Label>
        <Label fullWidth text={translate('news-edit', 'form_label_intro')} className={classes.verticalMargin}>
          <InputField invalid={validator.includes('intro')} multiline rows={2} maxRows={4} fullWidth value={data.intro}
                      onChange={(e) => handleChange('intro', e)}/>
        </Label>
        <Label fullWidth text={translate('news-edit', 'form_label_content')} className={classes.verticalMargin}>
          {(data.newForm || (!data.newForm && data.status_id)) &&
          <TextEditor invalid={validator.includes('content')} value={data.content}
                      onChange={(e) => handleChange('content', e)}/>
          }
        </Label>
      </div>
    </>
  );
}
export default NewsEdit;