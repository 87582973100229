import React, {createRef, useRef} from 'react';
import { Grid } from '@material-ui/core';
import Button from '../../components/Button';
import { InputField, Check } from '../../components/Inputs';
import translate from '../../functions/applyTranslation';
import useBlog from './hooks/blog';
import theme from './style';
import decode from 'decode-html';
import TextEditor from './../../components/Inputs/TextEditor'

export default function BlogComment(props) {
  const classes = theme();

  const {show, setShow} = props;

  const [comment, acceptedBlogRules, userData, modal, closeModal, openRules, setComment, handleAcceptBlogRules, handleSubmitComment, commentRef] = useBlog();
  // const childRef = createRef("comment");
  const handleFileModal = () => {
    commentRef.current.handleFileModal();
  }

  const handleSubmit = () => {
    handleSubmitComment(commentRef);
    setShow(false);
  }

  const handleHtml = (e) => {
    return e.replace(/(<([^>]+)>)/gi, '');
  }


  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.userName}>
          {userData.name}
        </Grid>
        <Grid item xs={12} className={classes.title}>
          {translate('blog', 'label_new_comment_title')}
        </Grid>
        <Grid item xs={12}>
          {/* <InputField value={comment.content}
            className={classes.content}
            multiline
            maxLength={1000}
            placeholder={translate('blog', 'placeholder_comment')} fullWidth
            onChange={(e) => {
              setComment({ ...comment, content: e })
            }} /> */}

          <TextEditor ref={commentRef} simple={true}
            value={decode(comment.content)} onChange={(e) => setComment({ ...comment, content: e })}
            maxLength={1000}
            placeholder={translate('blog', 'placeholder_comment')} />
        </Grid>
        <Grid item xs={12}>
          {/* <div className={classes.acceptWrap}>
          <Check value={acceptedBlogRules} onChange={() => handleAcceptBlogRules(!acceptedBlogRules)} label={translate('blog', 'check_rules_approval')} />
        </div> */}
          <div className={classes.acceptWrap}>
            <Check value={comment.consent} onChange={() => setComment({ ...comment, consent: !comment.consent })} label={translate('blog', 'check_rules_approval')} />
          </div>
          <div di className={classes.addWrap}>
            <Button disabled={!comment || !comment.consent || !comment.content } fill label={translate('blog', 'button_comment_add')} onClick={handleSubmit} icon={'email'} />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
