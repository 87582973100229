import React from 'react';
import theme from './style';
import { Link } from '@material-ui/core';
const BreadCrumbs = (props) => {
  const classes = theme();
  const {
    onClick,
    items,
  } = props;

  const handleChange = (value) => {
    if (onClick) {
      onClick(value)
    }
  }
  return (
    <div className={classes.breadcrumbWrap} >
      {items.map((item, ind) => {
        return <div key={ind}>
          {ind !== 0 ? <span>/</span> : ''}
          {ind + 1 === items.length ?
            <p>{item.text}</p> :
            <Link onClick={() => handleChange(item.path)}>{item.text}</Link>
          }
        </div>
      })}
    </div >
  );
}
BreadCrumbs.propTypes = {
}


export default BreadCrumbs;