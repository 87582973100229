import React, { Fragment, memo } from 'react';
import theme from './style';
import { Paper } from '@material-ui/core';
import Button from '../../components/Button';
import { BigTitle, SmallTitle, Text } from '../../components/DataDisplay';
import { Label, InputField } from '../../components/Inputs';
import translate from '../../functions/applyTranslation';
import useRecoverPassword from './hook';

const RecoverPassword = (props) => {
  const classes = theme();
  const [data, submited, handleNavigation, handleApplyAccount, handleSubmit, handleChange, invalidList, loading] = useRecoverPassword(props);
  return (
    <form onSubmit={handleSubmit}>
      <Paper elevation={4} className={classes.formWrap}>
        <div className={classes.titleWrap}>
          <BigTitle text={translate('recover_password', 'title_recover_password')} />
        </div>
        <Label text={translate('login', 'placeholder_personal_number')}>
          <InputField fullWidth value={data.ssn} onChange={handleChange} id='ssn'
            invalid={invalidList.includes('ssn')} />
        </Label>
        <div className={classes.loginButtonWrap}>
          <Button disabled={loading} fill label={translate('recover_password', 'button_recover_password')} type='submit' />
        </div>
        {submited &&
          <div className={classes.loginButtonWrap}>
            <Text text={translate('recover_password', 'label_recover_password_success')} className={classes.success} />
          </div>
        }
      </Paper>
      <Paper elevation={4} className={classes.formWrap}>
        <Button label={translate('login', 'label_change_login_method_bankid')} onClick={handleNavigation} />
      </Paper>
      <Paper elevation={4} className={classes.alternativeWrap}>
        <SmallTitle text={translate('login', 'label_no_account')} />
        <Button label={translate('login', 'label_apply_account')} onClick={handleApplyAccount} />
      </Paper>
    </form >
  );
}

export default memo(RecoverPassword);