import React, { memo, useContext } from 'react';
import theme from './style';
import Button from '../../../components/Button';
import { Label, Dropzone, InputField, TextEditor } from '../../../components/Inputs';
import translate from '../../../functions/applyTranslation';
import { ActivitiesContext, } from "../actitiesContext";
import { Grid } from '@material-ui/core'
import { Image } from '../../../components/DataDisplay';

import useAPI from '../../../api/apiHandler';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/states/statesActions';
const ActivitiesTab2 = (props) => {
  const classes = theme();
  const activitiesContext = useContext(ActivitiesContext);
  const { tab2Data, setTab2Data, validator, handleBack, setSelectedTab, validatorForm2, setValidator } = activitiesContext;
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const handleImageUpload = (files, id) => {
    if (files) {
      let file = files[0];
      if (file) {

        var body = new FormData();
        body.append('file', file);
        body.append('tags', 'gallery');
        dispatch(setLoading(true))
        setRequest({
          url: 'file',
          method: 'POST',
          data: body,
        }).then(res => {
          if (res) {
            setTab2Data('image', res.data.id)
          }
          dispatch(setLoading(false))
        })
          .catch(() => dispatch(setLoading(false)))
      } 
    }
    else {
      setTab2Data('image', id)
    }
  }
  const validateForm = () => {
    let res = validatorForm2(tab2Data);
    if (!res.length) {
      setValidator({ ...validator, tab2: [] })
      setSelectedTab(3)
    } else {
      setValidator({ ...validator, tab2: res })
    }
  }
  const _validator = validator.tab2;
  return (
    <div className={classes.pageWrap}>
      <div>
        {Number(tab2Data.activity_type) === 1 &&
          <Label text={translate('activities-edit', 'tab2_main_image_title')}>
            <Grid container>
              <Grid item xs={12} sm={tab2Data.image ? 9 : 12}>
                <Dropzone invalid={_validator.includes('image')} onChange={handleImageUpload} />
              </Grid>
              {tab2Data.image &&
                <Grid item xs={12} sm={3} className={classes.imageHolder}>
                  <Image file={tab2Data.image} className={classes.image} invalid={_validator.includes('image')} />
                </Grid>
              }
            </Grid>
          </Label>
        }
        <Label className={classes.paddingFix} text={translate('activities-edit', 'tab2_heading_title')}>
          <InputField fullWidth placeholder={translate('activities-edit', 'tab2_heading_placeholder')}
            value={tab2Data.title}
            invalid={_validator.includes('title')}
            onChange={(e) => setTab2Data('title', e)}
          />
        </Label>
        <Label className={classes.paddingFix} text={translate('activities-edit', 'tab2_content_title')}>
          <TextEditor
            value={tab2Data.content} onChange={(e) => setTab2Data('content', e)}
            invalid={_validator.includes('content')}
            placeholder={translate('activities-edit', 'tab2_content_placeholder')} />
        </Label>
      </div>
      <div className={classes.formFooter}>
        <Button label={translate('activities-edit', 'button_back')} onClick={handleBack} />
        <Button label={translate('activities-edit', 'button_next')} fill onClick={validateForm} />
      </div>
    </div >
  );
}
export default memo(ActivitiesTab2);