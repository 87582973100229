import React, {Fragment} from 'react';
import {Typography} from "@material-ui/core";
import theme from './style';
import Button from '../../components/Button';
import ToTopWrap from '../../components/ToTopWrap';
import {Image, BigTitle} from '../../components/DataDisplay';
import translate, {tryParseToHtml} from '../../functions/applyTranslation';
import useInformationHook from './hook';
import moment from 'moment';
import usePermissionsHook from '../../functions/permissions';
import {useSelector} from 'react-redux';

const TemplateDashboard = (props) => {
  const classes = theme();
  const [data, handleNavigation] = useInformationHook();
  const loading = useSelector(state => state.states.loading);
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const { access_token } = useSelector(state => state.auth);

  return (
    <div className={classes.pageWrap}>
      {(data && !loading) && (
        <>
          <div className={classes.headerWrap}>
            <div className={classes.buttonGroup}>
              <Button label={translate('', 'button_back')} icon='back' fill onClick={() => {
                handleNavigation('back')
              }}/>
              {hasPermission(permissions.NEWS_EDIT) &&
              <Button label={translate('news', 'button_edit_page')} icon='edit' fill onClick={() => {
                handleNavigation('edit')
              }}/>
              }
            </div>
            <p
              className={classes.date}>{`${translate('', 'label_published')} ${moment.unix(data.publish_date).format('D MMMM YYYY')}`}</p>
          </div>
          <div className={classes.titleWrap}>
            <BigTitle text={data.title} alt={`${Number(data.status_id.id) !== 1 ? '' : `(${data.status_id.name})`}`}/>
          </div>
          <Image file={data.lead_image_id.id}
            // url={'https://ingleswinner.com/blog/wp-content/uploads/2014/08/quando-usar-what-ou-which.jpg'}
                 alt={data.title} path={data.lead_image_id.path} className={classes.image}/>
          <div className={classes.contentWrap}>
            <div className={classes.htmlContent}>
              {tryParseToHtml(data.content, access_token)}
            </div>
          </div>
          <ToTopWrap/>
        </>)}
      {(!data && !loading) && (<div style={{textAlign: "center"}}>
        <Typography variant="h3">{translate('news', 'article_not_found')}</Typography>
      </div>)}
    </div>
  );
}
export default TemplateDashboard;