import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setLoading
} from '../../redux/states/statesActions';
import useAPI from '../../api/apiHandler';
const RecoverPasswordHook = (props) => {
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const [invalidList, setInvalidList] = useState([]);
  const [data, setData] = useState({
    ssn: ''
  })
  const loading = useSelector(state => state.states.loading);
  const [submited, setSubmited] = useState(false);
  const handleNavigation = () => {
    const { history } = props;
    history.push('');
  }
  const handleApplyAccount = () => {
    const { history } = props;
    history.push('application');
  }
  const handleSubmit = (e) => {
    try { e.preventDefault() } catch (err) { };
    
    if (formValidator()) {
      dispatch(setLoading(true))
      setSubmited(false);
      setRequest({
        url: 'login/reset_password',
        method: 'POST',
        data: data,
      }).then(async res => {
        if (res) {
          setSubmited(true);
        }
        dispatch(setLoading(false))
      })
        .catch(() => dispatch(setLoading(false)))
    }
  }

  const handleChange = (value, id) => {
    setData({ ...data, [id]: value })
  }

  const formValidator = () => {
    let invalid = [];
    Object.keys(data).forEach((key) => {
      var value = data[key];
      switch (key) {
        case 'ssn':
          if (!value || value.length === 0) {
            invalid.push('ssn')
          }
          break;
        default:
          return true;
      }
    });
    setInvalidList(invalid);
    return invalid.length === 0;
  }
  return [data, submited, handleNavigation, handleApplyAccount, handleSubmit, handleChange, invalidList, loading];
}

export default RecoverPasswordHook;