
import React, { useState } from 'react';
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import Button from '../../components/Button';
import translate from '../../functions/applyTranslation';
import theme from './style';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BlogRules(props) {
  const { modal, closeModal, acceptedBlogRules, acceptBlogRules } = props;
  const classes = theme();
  const handleClose = () => {
    closeModal();
  };

  const handleSubmit = () => {
    acceptBlogRules();
    closeModal();
  }

  return (
    <div>
      <Dialog
        open={modal === 'rules'}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>
          {translate('blog', 'title_rules')}
        </DialogTitle>
        <DialogContent>
          <Grid container className={classes.usersTicketWrap}>
            <Grid item xs={12}>
              {translate('blog', 'content_blog_rules')}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} label={translate('', 'button_cancel')} />
          {!acceptedBlogRules &&
            <Button onClick={handleSubmit} label={translate('', 'button_accept')} fill icon='check' />
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
