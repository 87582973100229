import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import Media from 'react-media';
import clsx from "clsx";
import {SmallTitle, Text} from "../../../components/DataDisplay";
import translate from "../../../functions/applyTranslation";
import theme from "./style";
import moment from "moment";

const BookingInfo = props => {
  const classes = theme();

  const {email, contact, phone, nr_visitors, message, program, eventStart, eventEnd} = props;

  const [infoObj, setInfoObj] = useState({});

  const tryParseString = () => {
    try {
      let o = JSON.parse(message);
      if (o && typeof o === "object") {
        let obj = {
          message: o.message || "-",
          adress: o.adress || "-",
          zip: o.zip || "-",
          city: o.city || "-",
          grade: o.grade || "-",
          organisation: o.organisation || "-"
        }
      setInfoObj(obj);
      }
      else {
       let o = {
         message: message,
         adress: "-",
         zip: "-",
         city: "-",
         grade: "-",
         organisation: "-"
       };
       setInfoObj(o);
      }
    }
    catch (e) {
      let o = {
        message: message,
        adress: "-",
        zip: "-",
        city: "-",
        grade: "-",
        organisation: "-"
      };
      setInfoObj(o);
    }

    return false;
  }

  useEffect(() => {
    tryParseString()
  }, []);

  return (
    <>
      <SmallTitle text={translate('booking', 'tour_info')} className={classes.title}/>
      <Grid container>
        <Media queries={{
          desktop: "(min-width: 541px)",
          mobile: "(max-width: 540px)"
        }}>
          {matches => (
            <>
              {matches.desktop &&
              <>
                <Grid item xs={5} sm={5}>
                  <Text className={classes.text} text={translate('booking', 'label_organisation_name')}/>
                  <Text className={classes.text} text={translate('booking', 'label_contact_person')}/>
                  <Text className={classes.text} text={translate('booking', 'label_school_address')}/>
                  <Text className={classes.text} text={translate('booking', 'label_zip')}/>
                  <Text className={classes.text} text={translate('booking', 'label_city')}/>
                  <Text className={classes.text} text={translate('booking', 'label_mobile_phone')}/>
                  <Text className={classes.text} text={translate('booking', 'label_course')}/>
                  <Text className={classes.text} text={translate('booking', 'label_email')}/>
                  <Text className={classes.text} text={translate('booking', 'label_number_visitors')}/>
                  <Text className={classes.text} text={translate('booking', 'label_other_info')}/>
                  <Text className={classes.text} text={translate('booking', 'label_program')}/>
                  <Text className={classes.text} text={translate('booking', 'start_time')}/>
                  <Text className={classes.text} text={translate('booking', 'end_time')}/>
                </Grid>
                <Grid item xs={7} sm={7}>
                  <Text className={classes.text} text={infoObj.organisation}/>
                  <Text className={classes.text} text={contact}/>
                  <Text className={classes.text} text={infoObj.adress}/>
                  <Text className={classes.text} text={infoObj.zip}/>
                  <Text className={classes.text} text={infoObj.city}/>
                  <Text className={classes.text} text={phone}/>
                  <Text className={classes.text} text={infoObj.grade}/>
                  <Text className={classes.text} text={email}/>
                  <Text className={classes.text} text={nr_visitors}/>
                  <Text className={classes.text} text={infoObj.message}/>
                  <Text className={classes.text} text={program.name}/>
                  <Text className={classes.text} text={moment.unix(eventStart).format('HH:mm')}/>
                  <Text className={classes.text} text={moment.unix(eventEnd).format('HH:mm')}/>
                </Grid>
              </>
              }
              {matches.mobile &&
              <>
                <Text className={clsx(classes.text, classes.bold)} text={translate('booking', 'label_organisation_name')}/>
                <Text className={classes.text} text={infoObj.organisation}/>
                <Text className={clsx(classes.text, classes.bold)} text={translate('booking', 'label_contact_person')}/>
                <Text className={classes.text} text={contact}/>
                <Text className={clsx(classes.text, classes.bold)} text={translate('booking', 'label_school_address')}/>
                <Text className={classes.text} text={infoObj.adress}/>
                <Text className={clsx(classes.text, classes.bold)} text={translate('booking', 'label_zip')}/>
                <Text className={classes.text} text={infoObj.zip}/>
                <Text className={clsx(classes.text, classes.bold)} text={translate('booking', 'label_city')}/>
                <Text className={classes.text} text={infoObj.city}/>
                <Text className={clsx(classes.text, classes.bold)} text={translate('booking', 'label_mobile_phone')}/>
                <Text className={classes.text} text={phone}/>
                <Text className={clsx(classes.text, classes.bold)} text={translate('booking', 'label_course')}/>
                <Text className={classes.text} text={infoObj.grade}/>
                <Text className={clsx(classes.text, classes.bold)} text={translate('booking', 'label_email')}/>
                <Text className={classes.text} text={email}/>
                <Text className={clsx(classes.text, classes.bold)} text={translate('booking', 'label_number_visitors')}/>
                <Text className={classes.text} text={nr_visitors}/>
                <Text className={clsx(classes.text, classes.bold)} text={translate('booking', 'label_other_info')}/>
                <Text className={classes.text} text={infoObj.message}/>
                <Text className={clsx(classes.text, classes.bold)} text={translate('booking', 'label_program')}/>
                <Text className={classes.text} text={program.name || '-'}/>
                <Text className={clsx(classes.text, classes.bold)} text={translate('booking', 'start_time')}/>
                <Text className={classes.text} text={moment.unix(eventStart).format('HH:mm')}/>
                <Text className={clsx(classes.text, classes.bold)} text={translate('booking', 'end_time')}/>
                <Text className={classes.text} text={moment.unix(eventEnd).format('HH:mm')}/>
              </>
              }
            </>
          )}
        </Media>
      </Grid>
    </>
  );
};

export default BookingInfo;
