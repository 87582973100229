import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    wrapItem: {
      width: '100%',
      display: 'flex',
    },
    listItem: {
      color: ColorSchemes.mediumBlue,
      display: 'inline-flex',
      fontSize: 16,
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor:'pointer',
      '& svg': {
        fontSize: 20,
        cursor: 'pointer',
        margin: '-7px 5px 0 -25px',
      },
      '& p': {
        margin: '2px 0px',
        paddingBottom: '6px'
      }
    },
    path: {
      color: `${ColorSchemes.black} !important`,
      fontWeight: 'bold'
    },
    active: {
      color: `${ColorSchemes.orange} !important`,
      fontWeight: 'bold'
    },
  }));
  return styles();
}
export default theme;
