let colorsScheme = {
  white: '#fff',
  darkBlue: '#041F43',
  mediumBlue: '#005DAA',
  lightBlue: "#008cff",
  black: '#000',
  highDarkGrey: '#636363',
  highLightGrey: '#00000029',
  lowDarkGrey: '#8B8B8B',
  lowLightGrey: '#EFEFEF',
  orange: '#FF9000',
  darkOrange: '#ff8c00',
  red: '#B92D00',
  background: '#E3E6EC',
  tableOddBg: '#E4E4E4',
  green:'#07A717',
  blueBG:'#CED5E6',
  darkGrey: '#222',
}
export default colorsScheme;