import React, { useState } from 'react';
import theme from './style';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import translate from '../../../functions/applyTranslation';
import { Gallery } from '../../DataDisplay';
const DropZone = (props) => {
  const classes = theme();
  const [galleryState, setGalleryState] = useState(false)
  const {
    invalid,
    onChange,
    multiple,
  } = props;

  const validateFile = (file) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon'];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  }
  const handleDropZone = e => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    let validUploadedFiles = [];
    if (multiple) {
      for (let i = 0; i < files.length; i++) {
        if (validateFile(files[i])) {
          validUploadedFiles.push(files[i])
        }
      }
    } else {
      validUploadedFiles.push(files[0])
    }
    if (onChange) {
      onChange(validUploadedFiles)
    }
  }
  const handleUnusedEvents = (e) => {
    try { e.preventDefault() } catch (error) { };

  }
  const handleHiddenInput = e => {
    e.preventDefault();
    const files = e.target.files;
    if (files.length) {

      let validUploadedFiles = [];
      for (let i = 0; i < files.length; i++) {
        if (validateFile(files[i])) {
          validUploadedFiles.push(files[i])
        }
      }
      if (onChange) {
        onChange(validUploadedFiles)
      }
    }
  }
  const handleGalleryState = () => {
    setGalleryState(!galleryState)
  }
  const handleGalerySelect = (e) => {
    onChange(null, e)
    setGalleryState(false)
  }
  const handleForceClickKeyboard = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      document.getElementById('dropzoneHidden').click()
    }
  }
  const replaceForGalleryTrigger = (string) => {
    let splitedString = string.split('##')
    if (splitedString.length === 3) {
      return <div className={classes.galleryTextWrap}>
        {`${splitedString[0]} `}
        <div className={classes.galleryTrigger} onClick={handleGalleryState}>{splitedString[1]}</div>
        {` ${splitedString[2]}`}
      </div >
    } else {
      return false;
    }
  }
  return (
    <div className={`${classes.dropzoneGroup} ${invalid ? classes.invalid: ''}`}
      onDrop={handleDropZone}
      onDragOver={handleUnusedEvents}
      onDragEnter={handleUnusedEvents}
      onDragLeave={handleUnusedEvents}
    >
      <CloudUploadOutlinedIcon onKeyDown={handleForceClickKeyboard} tabIndex={ 0 } onClick={() => document.getElementById('dropzoneHidden').click()} />
      <input type='file' id='dropzoneHidden' onChange={handleHiddenInput} accept="image/*" multiple={multiple} hidden />
      {replaceForGalleryTrigger(translate('', 'dropzone_gallery_text'))}
      {galleryState && <Gallery open={galleryState} onSelect={handleGalerySelect} onClose={handleGalleryState} />}
    </div >
  );
}
export default DropZone;