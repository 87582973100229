import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
    const styles = makeStyles(theme => ({
        main: {
            display: 'inline-flex',
            flex: '0 0 100%',
            justifyContent: 'space-between',
        },
        left: {
            display: 'flex'
        },
        author: {
            fontSize: '12px',
            lineHeight: '14px',
            color: ColorSchemes.highDarkGrey,
            marginRight: '13px',
        },
        comments: {
            fontSize: '12px',
            lineHeight: '14px',
            color: ColorSchemes.highDarkGrey,
            '& svg': {
                with: 14,
                height: 14,
                marginBottom: '-2px'
            },
            '&.active': {
                color: ColorSchemes.mediumBlue,

            }
        },
        edit: {
            cursor: 'pointer',
            '& svg': {
                height: 16,
                color: ColorSchemes.black,
                opacity: '0.54',
            },
        },
        actionEdit: {
            display:'inline-block',
            marginBottom: 5
        },
        actionDelete: {
            display:'inline-block',
        }
    }));
    return styles();
}
export default theme;
