import { useState, useEffect } from 'react';
import useAPI, { useImage } from '../../../api/apiHandler';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/states/statesActions';
const NewsDetailHook = (props, setFileID) => {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [appendingLoader, setAppendingLoader] = useState(false);

  // eslint-disable-next-line
  const [setRequest] = useAPI();
  const [getImageURL] = useImage();
  const submitFileToAPI = (props) => {
    dispatch(setLoading(true))
    var data = new FormData();
    data.append('file', props.file);
    setRequest({
      url: 'file',
      method: 'POST',
      data: data,
    }).then(res => {
      if (res) {
        setData({
          type: 'file',
          fileId: res.data.id,
          text: props.text,
          element: props.element
        })
      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  }

  const submitImageToAPI = (file) => {
    dispatch(setLoading(true))
    let data = new FormData();
    data.append('file', file);
    setRequest({
      url: 'file',
      method: 'POST',
      data: data,
    }).then(res => {
      if (res) {
        setData({
          type: 'image',
          fileId: res.data.id,
          // text: props.text,
          // element: props.element,
          // align: props.align,
          // size: props.size,
        })
      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  }

  const submitLink = (props) => {
    dispatch(setLoading(true))
    setData({
      type: 'link',
      url: props.file,
      text: props.text,
      element: props.element
    })
    dispatch(setLoading(false))
  }
  
  // useEffect(() => {
  //   if (data) {
  //     setAppendingLoader(true)
  //     const {
  //       type, url, fileId, text, size, align, target
  //     } = data;
  //     let content = props.value;
  //     if (type === 'image') {
  //       props.onChange(content + `<img src="${getImageURL(fileId)}" className='small' alt="${text}" ${size ? `width="${size * 150}"` : ''} ${align ? `style="float: ${align === 1 ? 'left' : 'right'}"` : ''} />`)
  //     } else if (type === 'link') {
  //      /*  const newContent = target === '1' ? `<a href="${url}" target="_blank">${text}</a>` : `<a href="${url}">${text}</a>`
  //       props.onChange(content + newContent); */
  //     } else {
  //       props.onChange(content + `<a href="${getImageURL(fileId)}" target="_blank">${text}</a>`);
  //
  //     }
  //     setData();
  //     dispatch(setLoading(false))
  //     setTimeout(() => {
  //       setAppendingLoader(false)
  //     }, (0));
  //   }
  //   // eslint-disable-next-line
  // }, [data])

  return [submitImageToAPI, submitFileToAPI, submitLink, appendingLoader];
}

export default NewsDetailHook;