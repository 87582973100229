import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme'
const theme = () => {
  const styles = makeStyles(theme => ({
    newsList: {
      '&.test:not(:last-child)': {
        marginBottom: 15,
      }
    },
    spaceBetween: {
      width: `100%`,
      display: 'inline-flex',
      justifyContent: 'space-between',
      marginBottom: 15,
    },
    flexEnd: {
      width: `100%`,
      display: 'inline-flex',
      justifyContent: 'flex-end',
      marginBottom: 5,
    },
    flexStart: {
      width: `100%`,
      display: 'inline-flex',
      justifyContent: 'flex-start',
      marginBottom: 5,
    },
    selectLabel: {
      marginRight: 10,
    },
    selectGroup: {
      alignItems: 'center',
      display: 'inline-flex',
    },
    newsWrap: {
      width: 'calc(100% + 10px)',
      marginLeft: -5,
      '& .item': {
        padding: '0px 5px'
      }
    },
    paginatorLabel: {
      fontSize: 11,
      color: ColorSchemes.highDarkGrey,
    }
  }));
  return styles();
}
export default theme;

