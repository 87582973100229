import { useState, useEffect } from 'react';
import useAPI from '../../../api/apiHandler';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/states/statesActions';
import useFilters from './filter';
import { useHistory } from 'react-router-dom'

const ApplicationHook = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [setRequest] = useAPI();
  const [applications, setApplications] = useState([])
  const [filters, setFilters] = useFilters();
  const [rows, setRows] = useState([])
  const [navigationData, setSavigationData] = useState({
    totalItems: 0,
    totalPages: 1,
  })

  const fetchApplications = () => {
    dispatch(setLoading(true))
    let url = `applicant`;
    setRequest({
      url: url,
    }).then(res => {
      if (res) {
        let data = res.data;
        setApplications(data);
      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  }

  const setStatus = (id, status_id) => {
    dispatch(setLoading(true))

    var formdata = new FormData();
    formdata.append("applicant_id", id);
    formdata.append("status", status_id);
    setRequest({
      url: 'applicant',
      method: 'PATCH',
      data: formdata
    }).then(res => {
      if (res) {
        if (Number(status_id) === 1) {
          redirectToProfile(id);
        }
        else
          fetchApplications();
      }
      dispatch(setLoading(false))
    })
      .catch(() =>  dispatch(setLoading(false)))
  }

  const redirectToProfile = (id) => {
    let url = `users?page=1&limit=99999`;
    return setRequest({
      url: url,
      method: 'GET',
    }).then(res => {
      if (res && res.data && res.data.content) {
        const application = rows.find(x => Number(x.id) === Number(id));
        if (application) {
          const user = res.data.content.find(x => x.ssn === application.ssn);
          if (user)
            history.push(`/profile/${user.id}`);
        }
      }
    })
  }

  const sortAndPage = () => {
    dispatch(setLoading(true))

    let _data = JSON.parse(JSON.stringify(applications));
    const { status_id, limit, page } = filters;

    if (status_id !== null) {
      _data = _data.filter(a => Number(a.status) === Number(status_id));
    }

    let pages = 1;
    if (_data.length && limit) {
      const relation = _data.length / limit;
      pages = Number(relation) - Number(relation) % 1;
      if (Number(relation) % 1 > 0)
        pages++;
    }

    setSavigationData({
      totalPages: Number(pages),
      totalItems: Number(_data.length)
    })

    _data.sort((a, b) => (a.cdate < b.cdate) ? 1 : -1);
    if (limit > 0) {
      _data = _data.slice((page - 1) * limit, page * limit)
    }

    setRows(_data);
    dispatch(setLoading(false))
  }

  useEffect(() => {
    if (applications) {
      sortAndPage();
    }
  }, [applications, filters])

  useEffect(() => {
    fetchApplications();
  }, [])

  const handlePagination = (action) => {
    setFilters('page', (action === 'next' ? filters.page += 1 : filters.page -= 1))
  }

  return [{
    rows: rows,
    filters: filters,
    settings: navigationData
  }, setFilters, setStatus, handlePagination];
}

export default ApplicationHook;