import React, { memo } from 'react';
import theme from './style';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from 'react-redux';
const Loading = () => {
  const classes = theme();
  const loading = useSelector(state => state.states.loading);
  return (
    <Backdrop open={!!loading} className={classes.backdrop} >
      <CircularProgress className={classes.circle} />
    </Backdrop>
  );
}
export default memo(Loading);