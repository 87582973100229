import React, { memo, useState } from 'react';
import theme from './style';
import { Grid, } from '@material-ui/core';
import Button from '../../components/Button';
import { Select } from '../../components/Inputs';
import { ListItem, SmallTitle } from '../../components/DataDisplay';
import translate from '../../functions/applyTranslation';
import clsx from 'clsx';
import { Fragment } from 'react';
import { momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import useHook from './hooks/application';


const ApplicationList = (props) => {
  const localizer = momentLocalizer(moment)
  const classes = theme();
  const [data, setFilters, setStatus, handlePagination] = useHook();

  const { rows, filters, settings } = data;

  const handleNavigation = (options) => {
    if (options.action === 'approve')
      setStatus(options.value, 1);
    else if (options.action === 'reprove')
      setStatus(options.value, 2);
    else
      setStatus(options.value, 0);
  }

  const filterList = [
    { id: 1, title: translate('application-list', `label_status_0`) },
    { id: 2, title: translate('application-list', `label_status_1`) },
    { id: 3, title: translate('application-list', `label_status_2`) },
  ];
  const [expanded, setExpanded] = useState()
  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <div className={classes.pageWrap}>
        <div className={classes.headerWrap}>
          <div className={classes.titleWrap}>
            <SmallTitle text={translate('application-list', 'title_application_list')} />
            <div className={classes.selectGroup}>
              <span className={classes.selectLabel}>{translate('', 'label_show')}</span>
              <div style={{ width: 200 }}>
                <Select fullWidth data={filterList} selected={Number(filters.status_id) + 1} onChange={(e) => setFilters('status_id', Number(e - 1))} />
              </div>
            </div>
          </div>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.newsList}>
              <Fragment>
                <Grid container className={classes.newsWrap}>
                  {rows.map(item =>
                    <Grid key={item.id} item xs={12} className='item'>
                      <ListItem viewType={'application'} path='applications' item={item} onClick={handleNavigation} expanded={expanded} handleExpanded={handleExpanded} />
                    </Grid>
                  )}
                </Grid>
                <div className={clsx(classes.flexEnd, classes.paginatorLabel)}>
                  {translate('application-list', 'label_page_counter').replace('##page##', filters.page).replace('##total##', settings.totalPages)}
                </div>
                <div className={
                  filters.page !== 1 && filters.page !== settings.totalPages ? classes.spaceBetween :
                    filters.page === 1 ? classes.flexEnd : classes.flexStart
                }>
                  {filters.page !== 1 &&
                    <Button label={translate('application-list', 'button_page_back')} fill onClick={(e) => handlePagination('back')} />
                  }
                  {!!settings.totalPages && filters.page !== settings.totalPages &&
                    <Button label={translate('application-list', 'button_page_next')} fill onClick={(e) => handlePagination('next')} />
                  }
                </div>
              </Fragment>
            </div>
          </Grid>
        </Grid >
      </div>
    </>
  );
}

export default memo(ApplicationList);