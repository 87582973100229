import React from 'react';
import Button from '../components/Button';
import { Chip, Breadcrumbs, Categories, ViewTypeSelector, ListItem, TreeView, TreeViewOrganizer, Tabs } from '../components/DataDisplay';
import { Select, DatePicker, LabelInput, Check, InputField, Dropzone, TextEditor, RadioGroup } from '../components/Inputs';

import moment from 'moment';
const TemplateDashboard = (props) => {
  const breadcrumbsMockData = [
    {
      text: 'Home',
      path: [0]
    },
    {
      text: 'Sample 1',
      path: [0]
    },
    {
      text: 'Sample 2',
      path: [0]
    },
    {
      text: 'Sample 3',
      path: [0]
    }
  ]

  return (
    <div >
      <Button label='sample1' icon='save' />
      <Button label='sample1' />
      <Button label='sample111' fill icon='save' disabled />
      <Button label='sample1' fill icon='save' fullWidth />
      <Select icon='save' />
      <Select icon='save' fullWidth />
      <DatePicker />
      <DatePicker fullWidth />
      <LabelInput />
      <LabelInput fullWidth />
      <Chip />
      <Chip fullWidth />
      <Check label='sample1' />
      <Check value={true} label='sample2' />
      <br />
      <InputField />
      <InputField fullWidth />
      <InputField multiline />
      <InputField fullWidth multiline />
      <Dropzone multiple />
      <Breadcrumbs items={breadcrumbsMockData} />
      <Categories id='sample' quantity={61} text='Sample 1' />
      <Categories id='sample' quantity={112} text='Sample 2' />
      <ViewTypeSelector viewType='grid' />
      <ListItem viewType='list' item={{}} />
      <ListItem viewType='grid' item={{}} />
      <ListItem viewType='hybrid' item={{}} />
      <TreeView />
      <hr />
      <TreeViewOrganizer />
      <TextEditor />
      <Tabs
        selected={3}
        onSelect={(e) => { console.log(e) }}
        menuItems={
          [
            {
              id: 1,
              text: 'Sample 1',
            },
            {
              id: 2,
              text: 'Sample 2',
            },
            {
              id: 3,
              text: 'Sample 3',
            },
            {
              id: 4,
              text: 'Sample 4',
            },
          ]
        } />
      <RadioGroup
        items={
          [
            {
              id: 1,
              text: 'Sample 1',
            },
            {
              id: 2,
              text: 'Sample 2',
            },
            {
              id: 3,
              text: 'Sample 3',
            },
            {
              id: 4,
              text: 'Sample 4',
            },
          ]
        }
      />
    </div >
  );
}

export default TemplateDashboard;