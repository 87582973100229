import {useState, useEffect} from 'react';
import useAPI from '../../api/apiHandler';
import {useDispatch} from 'react-redux';
import {setLoading} from '../../redux/states/statesActions';
import {useParams, useHistory} from 'react-router-dom';
import {priceFromDb} from '../../functions/applyPrices';

const NewsDetailHook = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const [setRequest, multipleRequests] = useAPI();
  const [data, setData] = useState({
    jfm_event: {},
    event: {},
    asset: {},
    user: {},
    associations: []
  });
  const [notFound, setNotFound] = useState(false);
  const {id} = params;
  const fetchData = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `events?limit=1&page=1&event_id=${id}`,
      method: 'GET',
    }).then(res => {
      if (res) {
        let _data = res.data.content[0] || {};
        if (_data && _data.products)
          _data.products = _data.products.map(x => ({...x, amount: priceFromDb(x.amount)}));

        setRequest({
          url: `user/profile?user_id=${_data.jfm_event.owner}`,
          method: 'GET',
        }).then(result => {
          if (result && result.data)
            _data.user = {
              ssn: result.data.ssn,
              name: result.data.name,
              email: result.data.mail,
              phone: result.data.phone
            };

          setRequest({
            url: `events/association`,
            method: 'GET',
          }).then(res => {
            if (res.data) {
              _data.associations = res.data.filter(x => (_data.jfm_event.grp || '').split(',').includes(x.id));
            }
            setData(_data)
          })


        });

      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  }

  const fetchEvent = async () => {
    dispatch(setLoading(true));
    let options = [
      {
        url: `events?limit=1&page=1&event_id=${id}&cal=1`,
        method: 'GET',
      },
      {
        url: `events/association`,
        method: 'GET',
      }
    ];

    await multipleRequests(options).then(responses => {
      if (responses && responses.length === 2) {
        if (responses[0].data.total === 0) {
          setNotFound(true)
        }
        let _data = {};
        if (responses[0].data) {
          const res = responses[0];
          _data = res.data.content[0] || {};
          if (_data && _data.products)
            _data.products = _data.products.map(x => ({...x, amount: priceFromDb(x.amount)}));
        }
        if (responses[1].data) {
          _data.associations = responses[1].data.filter(x => ((_data.jfm_event && _data.jfm_event.grp) ? _data.jfm_event.grp : '').split(',').includes(x.id));
        }
        if (_data.jfm_event) {
          setRequest({
            url: `user/profile?user_id=${_data.jfm_event.owner}`,
            method: 'GET',
          }).then(result => {
            if (result && result.data)
              _data.user = {
                ssn: result.data.ssn,
                name: result.data.name,
                email: result.data.mail,
                phone: result.data.phone
              };
            setData(_data);
          });
        }
        dispatch(setLoading(false))
      } else
        dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  }
  useEffect(() => {
    if (setRequest && id) {
      fetchEvent();
    }
    // eslint-disable-next-line
  }, [id])
  const handleActions = (type) => {
    switch (type) {
      case 'back':
        history.goBack();
        break;
      case 'edit':
        history.push(`/activities/${id}/edit`);
        break;
      case 'cart':
        history.push(`/activities/${id}/cart`);
        break;
      case 'booking':
        history.push(`/activities/${id}/booking`);
      default:
        break;
    }
  }
  return [data, handleActions, notFound];
}

export default NewsDetailHook;