import { useState, useEffect } from 'react';
import moment from "moment";
import useAPI from '../../api/apiHandler';
import {useDispatch, useSelector} from 'react-redux';
import { setLoading } from '../../redux/states/statesActions';
import setTargetLink from '../../functions/setTargetLink'
import useStatus from "../../settings/statusList";
import useSettingsHook from "../../functions/settings";
import usePermissionsHook from "../../functions/permissions";

const HomeHook = (props) => {
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const [news, setNews] = useState([])
  const [blogs, setBlogs] = useState([])
  const [activities, setActivities] = useState([])
  const [, , applyFilter] = useStatus();
  const [, hasSetting] = useSettingsHook();
  const [permissions, , hasPermission] = usePermissionsHook();
  const { history } = props;
  const {role} = useSelector(state => state.auth);

  const handleNewsNavigation = (type) => {
    history.push('article');
  }
  const handleActivitiesNavigation = (type) => {
    history.push('activities?view=list');
  }
  const handleBlogNavigation = (id) => {
    if (id && Number(id) > 0)
      history.push(`blog/${id}`);
    else
      history.push('blog');
  }
  const fetchNews = () => {
    dispatch(setLoading(true))
    const status = hasSetting('event_published_notification') ? 3 : -1
    const visibility = hasPermission(permissions.NEWS_CREATE) ? '1,2' : '2';
    // let url = 'articles?status_id=2&visibility=1,2&page=1&limit=3'
    let url = `articles?visibility=${visibility}&page=1&limit=3&status_id=2`;
    setRequest({
      url: url,
      method: 'GET',
    }).then(res => {
      if (res) {
        let keys = Object.keys(res.data.articles)
        let arr = keys.map(item => {
          return res.data.articles[item]
        })

        const crisisArticles = [];
        const noncrisisArticles = [];

        arr.forEach(item => {
          item.highlight = (item.categories || '').split(',').includes('4');
          item.categories.includes('5') ? crisisArticles.push(item) : noncrisisArticles.push(item)
        });

        crisisArticles.sort(sortByDate);
        noncrisisArticles.sort(sortByDate)

        const sortedArticles = [...crisisArticles, ...noncrisisArticles]

        setNews(sortedArticles)
      }
      dispatch(setLoading(false))
    })
  }
  // eslint-disable-next-line
  const fetchActivities = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `events?status_id=2&visibility=1,2&page=1&limit=3&type=event&start_time=${moment(new Date()).startOf('day').unix()}&end_time=2556143999`,
      method: 'GET',
    }).then(res => {
      if (res && res.data) {
        setActivities(res.data.content)
      }
      dispatch(setLoading(false))
    })
  }
  const fetchBlogs = () => {
    dispatch(setLoading(true))
    setRequest({
      url: 'blogs?page=1&limit=3',
      method: 'GET',
    }).then(res => {
      if (res) {
        let data = res.data.blogPosts;
        data.forEach(item => {
          item.content = setTargetLink(data.content);
        });
        setBlogs(data);
      }
      dispatch(setLoading(false))
    })
  }
  useEffect(() => {
    if (setRequest) {
      fetchNews();
      fetchActivities();
      if(role !== "member") {
        fetchBlogs();
      }
    }
    // eslint-disable-next-line
  }, [])

  function compare(a,b) {
    if (a.categories.includes("4")){
      return -1;
    }
    if (!a.categories.includes("4")){
      return 1;
    }
    return 0;
  }

  function sortByDate(a,b) {
    return b.publish_date - a.publish_date;
  }

  return [news, activities, blogs, handleNewsNavigation, handleActivitiesNavigation, handleBlogNavigation];
}

export default HomeHook;