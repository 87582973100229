import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    header: {
      display: 'inline-flex',
      justifyContent: 'space-between',
      padding: 24,
      '& p': {
        color: ColorSchemes.black
      },
      '& svg': {
        fill: ColorSchemes.highDarkGrey,
        padding: 5,
        cursor: 'pointer',
        marginRight: -5,
        marginTop: -5
      }
    },
    categorySection: {
      backgroundColor: ColorSchemes.lowLightGrey,
      padding: '12px 24px',
      '& .item': {
        display: 'flex',
        alignItems: 'center'
      },
      '& .itemBottom': {
        display: 'flex',
        alignItems: 'flex-end',
        paddingBottom: 7,
      }
    },
    paddingLeft: {
      paddingLeft: 10,
    },
    pushRight: {
      justifyContent: 'flex-end'
    },
    gallerySection: {
      maxHeight: 437,
      overflow: 'auto',
      padding: '12px 24px',
      display: 'flex',
      justifyContent: 'center',
      '& .galleryItem': {
        cursor: 'pointer',
        padding: 5,
      },
      '& .galleryItemSelected': {
        backgroundColor: ColorSchemes.orange
      },
      '& .selectedText': {
        color: ColorSchemes.white
      },
      '& .galleryItem:hover': {
        backgroundColor: ColorSchemes.orange,
        '& .textItem': {
          color: ColorSchemes.white
        },


      }
    },
    loadMoreSection: {
      padding: '12px 24px',
      display: 'flex',
      justifyContent: 'center'
    },
    icon: {
      float: 'right',
      fill: ColorSchemes.white
    },
    image: {
      width: "100%"
    }
  }));
  return styles();
}
export default theme;
