import React, {memo} from 'react';
import Media from 'react-media';
import theme from './style';
import {Typography} from '@material-ui/core';
import clsx from 'clsx';
import translate from '../../functions/applyTranslation'
import usePermissionsHook from '../../functions/permissions';
import {useHistory, withRouter} from 'react-router-dom';

const SubHeader = (props) => {
  const classes = theme();
  const history = useHistory();
  const [permissions, roles, hasPermission] = usePermissionsHook();

  const handleNavigation = (path) => {
    if (path === '/contacts')
      path = '/information/90'
    history.push(path)
  }

  let menulist = [
    {
      string: 'menu_start',
      path: '/'
    }
  ];

  let mobileMenulist = [];

  if (hasPermission(permissions.NEWS_ACCESS)) {
    menulist.push({
      string: 'menu_news',
      path: '/article'
    });

    mobileMenulist.push({
      string: 'menu_news',
      path: '/article'
    });
  }

  if (hasPermission(permissions.ACTIVITIES_ACCESS)) {
    menulist.push({
      string: 'menu_activities_meetings',
      path: '/activities'
    });

    mobileMenulist.push({
      string: 'menu_activities_meetings',
      path: '/activities'
    })
  }
  if (hasPermission(permissions.BLOG_ACCESS)) {
    menulist.push({
      string: 'menu_blog',
      path: '/blog'
    });

    mobileMenulist.push({
      string: 'menu_blog',
      path: '/blog'
    })
  }
  if (hasPermission(permissions.PAGES_ACCESS)) {
    menulist.push({
      string: 'menu_information',
      path: '/information'
    });

    mobileMenulist.push({
      string: 'menu_information',
      path: '/information'
    });
  }
  menulist.push({
    string: 'menu_contacts',
    path: '/contacts'
  });

  const isActive = (path) => {
    let url = window.location.pathname;
    if ((path !== '/' && url.search(path) > -1) || (url === '/' && path === '/')) {
      return 'menuActive';
    } else {
      return '';
    }
  }
  return (
    <div className={classes.subHeader}>
      <div className={classes.menuWrapper}>
        <Media queries={{
          desktop: "(min-width: 541px)",
          mobile: "(max-width: 540px)"
        }}>
          {matches => (
            <>
              {matches.mobile &&
              <>
                {mobileMenulist.map((item, ind) => (
                  <Typography
                    key={ind}
                    className={clsx('menuItem', isActive(item.path))}
                    onClick={() => handleNavigation(item.path)}>{translate('', item.string)}
                  </Typography>
                ))}
              </>
              }

              {matches.desktop &&
              <>
                {menulist.map((item, ind) => (
                  <Typography
                    key={ind}
                    className={clsx('menuItem', isActive(item.path))}
                    onClick={() => handleNavigation(item.path)}>{translate('', item.string)}
                  </Typography>
                ))}
                <a href="/information/255" target="_blank" className={classes.targetBlankLink}>{translate('', "menu_help")}</a>
              </>
              }
            </>
          )}
        </Media>
      </div>
    </div>
  );
}
export default withRouter(memo(SubHeader));