import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const {
    mobile
  } = props;
  const styles = makeStyles(theme => ({
    newsList: {
      paddingRight: mobile ? 0 : 20,
      '&.test:not(:last-child)': {
        marginBottom: 15,
      }
    },
    blogsList: {
      marginTop: 15,
    },
    activitiesList: {
      paddingLeft: mobile ? 0 : 7.5,
    },
    titleWrapper: {
      width: `100%`,
      display: 'inline-flex',
      justifyContent: 'space-between',
      marginBottom: 5,
    },
    buttonContainer: {
      width: `100%`,
      display: 'inline-flex',
      justifyContent: 'center',
      marginBottom: 20
    }
  }));
  return styles();
}
export default theme;

