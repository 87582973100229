import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setLoading
} from '../../redux/states/statesActions';
import useAPI from '../../api/apiHandler';
const ResetPasswordHook = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.states.loading);
  const [setRequest] = useAPI();
  const [data, setData] = useState({
    ok: false,
  })
  const handleNavigation = () => {
    const { history } = props;
    history.push('');
  }
  const handleLogin = () => {
    const { history } = props;
    history.push('/login');
  }
  useEffect(() => {
    dispatch(setLoading(true))
    setRequest({
      url: 'login/generate_password',
      method: 'POST',
      data: {
        token: props.match.params.token
      },
    }).then(res => {
      if (res) {
        setData({ ok: true })
      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  }, [])

  return [data, handleNavigation, handleLogin, loading];
}

export default ResetPasswordHook;