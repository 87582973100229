import React, { memo, useContext } from 'react';
import theme from './style';
import { Grid, Paper } from '@material-ui/core';
import { BlogHeader, BlogBody, SmallTitle, Text } from '../../components/DataDisplay';
import { BlogContext, Provider } from "./blogContext";
import { momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import Button from '../../components/Button';
import translate from '../../functions/applyTranslation';
import usePermissionsHook from '../../functions/permissions';
import { Label, InputField, TextEditor } from '../../components/Inputs';
import ToTopWrap from '../../components/ToTopWrap';
import decode from 'decode-html';
import BlogRules from '../blog-rules';
import DeleteModal from '../delete-modal';
import { useSelector } from 'react-redux';
import CommentModal from '../blog-comment';

const BlogDetail = (props) => {
  const localizer = momentLocalizer(moment)
  const userData = useSelector(state => state.auth.userData);
  const classes = theme();
  const blogContext = useContext(BlogContext);
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const { access_token } = useSelector(state => state.auth);

  const {
    handleNavigation,
    blog,
    comments,
    expanded,
    handleExpanded,
    validator,
    validateForm,
    setData,
    modal,
    closeModal,
    openDelete,
    openUpdate,
    openRules,
    deleteBlog,
    deleteComment,
    updateComment,
    acceptedBlogRules,
    handleAcceptBlogRules
  } = blogContext;

  if (blog) {
    const {
      id,
      author_id,
      content,
      date_created,
      title,
      author,
      num_of_comments
    } = blog;

    const path = `blog`;
    const handleSubmit = () => {
      const result = validateForm();
      if (!result.length) {
        handleNavigation({ action: 'submit' })
      }
    }

    const handleDelete = () => {
      deleteComment(modal.comment_id);
    }

    const handleUpdate = (data) => {
      updateComment(modal.comment_id, data.content)
    }

    const allowEdit = (hasPermission(permissions.BLOG_EDIT) || Number(author_id) === Number(userData.id));
    if (!allowEdit && blog.id !== "new")
      handleNavigation({ action: 'back' })

    return (
      <>
        <CommentModal modal={modal ? modal.key : null} content={modal ? modal.content : null} closeModal={closeModal} callBack={handleUpdate} />
        <DeleteModal title={translate('blog', 'label_delete_blog_comment_title')} subTitle={translate('blog', 'label_delete_blog_comment_subtitle')} modal={modal ? modal.key : null} closeModal={closeModal} callBack={handleDelete} />
        <BlogRules modal={modal ? modal.key : null} closeModal={closeModal} acceptedBlogRules={acceptedBlogRules} acceptBlogRules={handleAcceptBlogRules} />
        <div className={classes.pageWrap}>
          <div className={classes.headerWrap}>
            <div className={classes.titleWrap}>
              <SmallTitle className={classes.title} text={`${id === 'new' ? translate('blog-edit', 'title_blog_new') : translate('blog-edit', 'title_blog_edit')}`} />
              <div className={classes.buttonGroup}>
                <Button label={translate('', 'button_back')} fill icon='back' onClick={() => handleNavigation({ action: 'back' })} />
                <Button label={translate('', 'button_save')} icon='save' fill onClick={() => handleSubmit()} />
              </div>
            </div>
            <Button onClick={openRules} label={translate('blog', 'button_read_terms')} />
          </div>
          <Grid container>
            <Grid item xs={12} >
              <Paper className={classes.block}>
                {Number(id) > 0 &&
                  <BlogHeader
                    id={id}
                    author={author}
                    author_id={author_id}
                    date_created={date_created}
                    num_of_comments={num_of_comments}
                    showComments={true}
                    path={path}
                    item={'blog_id'}
                    expanded={expanded}
                    handleExpanded={handleExpanded}
                    handleDelete={openDelete}
                    onClick={handleNavigation}
                    readOnly={true} />
                }
                <Grid container>
                  <Grid item xs={12} >
                    <Label text={translate('blog-edit', 'label_heading_title')}>
                      <InputField fullWidth placeholder={translate('blog-edit', 'label_heading_placeholder')}
                        value={title}
                        invalid={!validator || validator.includes('title')}
                        onChange={(e) => setData('title', e)}
                      />
                    </Label>
                  </Grid>
                  <Grid item xs={12} className={classes.contentWrap} >
                    <Label text={translate('blog-edit', 'label_content_title')}>
                      <TextEditor
                        value={decode(content.replace(/token=(.*?)"/g, `token=${access_token}"`))}
                        onChange={(e) => {setData('content', e)}}
                        placeholder={translate('blog-edit', 'label_content_placeholder')}
                      />
                    </Label>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {comments && comments.length > 0 &&
              <>
                <Grid item xs={12} className={classes.commentsResume}>
                  {`${comments.length} ${translate('blog', 'label_coments')} `}
                </Grid>
                <Grid item xs={12} >
                  <Paper className={classes.comments}>
                    {comments.map(comment =>
                      <div className={`${classes.comment} blog-comment`}>
                        <BlogHeader
                          id={comment.comment.id}
                          author={comment.author.name}
                          author_id={comment.comment.author_id}
                          date_created={comment.comment.date_created}
                          updated={comment.comment.updated}
                          path={path}
                          item={'comment_id'}
                          handleDelete={openDelete}
                          handleUpdate={openUpdate}
                          expanded={expanded}
                          handleExpanded={handleExpanded}
                          onClick={handleNavigation} />
                        <BlogBody id={comment.comment.id} content={comment.comment.content} path={path} item={'comment_id'} />
                      </div>
                    )}
                  </Paper>
                </Grid>
              </>
            }
          </Grid >
          <p>&nbsp;</p>
          <ToTopWrap />
        </div>
      </>
    );
  }
  else {
    return null;
  }
}


const ApplyProvider = (props) => {
  return (<Provider>
    <BlogDetail {...props} />
  </Provider>)
}
export default memo(ApplyProvider);