import React, {useEffect, useState} from 'react';
import theme from './style';
import AppsIcon from '@material-ui/icons/Apps';
import {useSelector} from "react-redux";
import ReorderIcon from '@material-ui/icons/Reorder';
import {Tooltip} from '@material-ui/core';
import translate from '../../../functions/applyTranslation';
import EventIcon from '@material-ui/icons/Event';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';

const DatePicker = (props) => {
  const classes = theme();
  const {
    onChange,
    viewType,
    type,
    showTable
  } = props;

  const auth = useSelector(state => state.auth);

  const [view, setView] = useState(auth.role === "admin" || auth.role === "superadmin" || auth.role === "security");

  const handleChange = (value) => {
    if (onChange) {
      sessionStorage.setItem("aLV", value);
      onChange(value)
    }
  };

  const _view = sessionStorage.getItem("aLV");

  let _viewType = 1;

  if (auth.role === "admin" || auth.role === "superadmin" || auth.role === "security") {
    if (!_view) {
      if (type === 'activities' && viewType === 'activity') {
        _viewType = 2;
      } else if (viewType === 'grid') {
        _viewType = 3;
      } else if (viewType === 'calendar') {
        _viewType = 4;
      } else if (viewType === "table") {
        _viewType = 5;
      }
    } else {
      if (type === 'activities' && _view === 'activity') {
        _viewType = 2;
      } else if (viewType === 'grid') {
        _viewType = 3;
      } else if (_view === 'calendar') {
        _viewType = 4;
      }

      if (_view === "table" && showTable) {
        _viewType = 5;
      } else if (_view === 'calendar') {
        _viewType = 4;
      } else {
        _viewType = 2;
      }
    }
  } else {
    if (!_view) {
      if (type === 'activities' && viewType === 'activity') {
        _viewType = 2;
      } else if (viewType === 'grid') {
        _viewType = 3;
      } else if (viewType === 'calendar') {
        _viewType = 4;
      } else if (viewType === "table") {
        _viewType = 5;
      }
    } else {
      if (type === 'activities' && _view === 'activity') {
        _viewType = 2;
      } else if (viewType === 'grid') {
        _viewType = 3;
      } else if (_view === 'calendar') {
        _viewType = 4;
      }

      if (_view === "table" && showTable) {
        _viewType = 5;
      } else if (_view === 'calendar') {
        _viewType = 4;
      } else {
        _viewType = 2;
      }
    }
  }

  useEffect(() => {
    if (auth.userData)
      setView(auth.role === "admin" || auth.role === "superadmin" || auth.role === "security" || auth.userData.tour_guide == "1")
  }, [auth]);


  return (
    <div className={classes.viewTypesWrap}>
      {type === 'articles' &&
      <Tooltip title={translate('', 'tooltip_listview')}>
        <ReorderIcon className={_viewType === 1 ? 'selected' : ''} onClick={() => {
          handleChange([1, 3].includes(_viewType) ? 'list' : 'activity')
        }}/>
      </Tooltip>
      }
      {auth.userData &&
      <>
        {type === "activities" && (auth.userData.tour_guide == "1") &&
        <Tooltip title={translate("", "tooltip_table")}>
          <TableChartOutlinedIcon className={_viewType === 5 ? 'selected' : ''} onClick={() => {
            handleChange('table')
          }}/>
        </Tooltip>
        }
      </>
      }
      <Tooltip title={translate('', 'tooltip_gridview')}>
        <AppsIcon className={(_viewType === 3 || _viewType === 2) ? 'selected' : ''} onClick={() => {
          handleChange([1, 3].includes(_viewType) ? 'grid' : 'activity')
        }}/>
      </Tooltip>

      {type === 'activities' &&
      <Tooltip title={translate('', 'tooltip_calendar')}>
        <EventIcon className={_viewType === 4 ? 'selected' : ''} onClick={() => {
          handleChange('calendar')
        }}/>
      </Tooltip>
      }
    </div>
  );
}


export default DatePicker;
