const MockData=  [
  {
    id:1,
    title:'Gallery'
  },
  {
    id:2,
    title:'User uploaded'
  },
]

export default MockData;