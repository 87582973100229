import React, {memo, useContext, useState} from 'react';
import theme from './style';
import {Grid, Paper, Typography} from '@material-ui/core';
import {BlogHeader, BlogBody, SmallTitle, Text} from '../../components/DataDisplay';
import {BlogContext, Provider} from "./blogContext";
import {momentLocalizer} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import Button from '../../components/Button';
import translate from '../../functions/applyTranslation';
import ToTopWrap from '../../components/ToTopWrap';
import BlogRules from '../blog-rules';
import BlogComment from '../blog-comments';
import usePermissionsHook from '../../functions/permissions';
import useSettingsHook from '../../functions/settings';
import DeleteModal from '../delete-modal';
import CommentModal from '../blog-comment';
import {useSelector} from "react-redux";

const BlogDetail = (props) => {
  const localizer = momentLocalizer(moment);
  const classes = theme();
  const blogContext = useContext(BlogContext);
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const [role, hasSetting] = useSettingsHook();
  const {userData} = useSelector(state => state.auth);
  const [showEditor, setShowEditor] = useState(false);

  const {
    handleNavigation,
    blog,
    comments,
    expanded,
    handleExpanded,
    modal,
    closeModal,
    openRules,
    openDelete,
    openUpdate,
    deleteBlog,
    deleteComment,
    updateComment,
    acceptedBlogRules,
    handleAcceptBlogRules,
    notFound
  } = blogContext;
  if (blog && !notFound) {
    const {
      id,
      author_id,
      content,
      date_created,
      title,
      author,
      num_of_comments
    } = blog;

    const path = `blog`;

    const handleDelete = () => {
      if (modal && modal.key === 'delete')
        deleteBlog(modal.blog_id);
      if (modal && modal.key === 'delete_comment')
        deleteComment(modal.comment_id);
    }

    const handleUpdate = (data) => {
      updateComment(modal.comment_id, data.content)
    }

    return (
      <>
        <CommentModal modal={modal ? modal.key : null} content={modal ? modal.content : null} closeModal={closeModal}
                      callBack={handleUpdate}/>
        <DeleteModal title={translate('blog', 'label_delete_blog_title')}
                     subTitle={translate('blog', 'label_delete_blog_subtitle')} modal={modal ? modal.key : null}
                     closeModal={closeModal} callBack={handleDelete}/>
        <DeleteModal defaultModal={'delete_comment'} title={translate('blog', 'label_delete_blog_comment_title')}
                     subTitle={translate('blog', 'label_delete_blog_comment_subtitle')} modal={modal ? modal.key : null}
                     closeModal={closeModal} callBack={handleDelete}/>
        <BlogRules modal={modal ? modal.key : null} closeModal={closeModal} acceptedBlogRules={acceptedBlogRules}
                   acceptBlogRules={handleAcceptBlogRules}/>
        <div className={classes.pageWrap}>
          {!notFound && (
            <>
              <div className={classes.headerWrap}>
                <div className={classes.titleWrap}>
                  <SmallTitle className={classes.title} text={translate('blog', 'title_blog_detail')}/>
                  <div className={classes.buttonGroup}>
                    <Button label={translate('', 'button_back')} fill icon='back'
                            onClick={() => handleNavigation({action: 'back'})}/>
                  </div>
                </div>
                <Button onClick={openRules} label={translate('blog', 'button_read_terms')}/>
              </div>
              <Grid container>
                <Grid item xs={12}>
                  <Paper className={classes.block}>
                    <BlogHeader
                      id={id}
                      author={author}
                      author_id={author_id}
                      date_created={date_created}
                      num_of_comments={num_of_comments}
                      showComments={true}
                      path={path}
                      item={'blog_id'}
                      expanded={expanded}
                      handleExpanded={handleExpanded}
                      handleDelete={openDelete}
                      onClick={handleNavigation}
                      readOnly={role === "memberplus" && Number(author_id) !== Number(userData.id)}
                    />
                    <BlogBody id={id} title={title} content={content} path={path} item={'blog_id'}/>
                  </Paper>
                </Grid>
                {comments && comments.length > 0 &&
                <>
                  <Grid item xs={12} className={classes.commentsResume}>
                    {`${comments.length} ${translate('blog', 'label_coments')} `}
                  </Grid>
                  <Grid item xs={12} style={{marginBottom: 16}}>
                    <Paper className={classes.comments}>
                      {comments.map(comment =>
                        <div className={`${classes.comment} blog-comment`}>
                          <BlogHeader
                            key={`comment_${comment.comment.id}`}
                            id={comment.comment.id}
                            author={comment.author.name}
                            author_id={comment.comment.author_id}
                            date_created={comment.comment.date_created}
                            updated={comment.comment.updated}
                            path={path}
                            item={'comment_id'}
                            expanded={expanded}
                            handleDelete={openDelete}
                            handleUpdate={openUpdate}
                            handleExpanded={handleExpanded}
                            onClick={handleNavigation}
                            readOnly={role === "memberplus" && Number(comment.author.id) !== Number(userData.id)}
                          />
                          <BlogBody id={comment.comment.id} content={comment.comment.content} path={path}
                                    item={'comment_id'}/>
                        </div>
                      )}
                    </Paper>
                  </Grid>
                </>
                }
                {!showEditor &&
                    <Button label={translate('blog', 'add_comment')} fill
                            onClick={() => setShowEditor(true)}/>
                }
                {(hasPermission(permissions.BLOG_CREATE) && !hasSetting('blogpost_create_not_allowed') && showEditor) &&
                <Grid item xs={12}>
                  <BlogComment show={showEditor} setShow={setShowEditor}/>
                </Grid>
                }
              </Grid>
              <p>&nbsp;</p>
              <ToTopWrap/>
            </>
          )}
        </div>
      </>
    );
  } else {
    return (
      <>
        {notFound &&
        <div style={{textAlign: "center"}}>
          <Typography variant="h4">{translate('blog', 'blog_not_found')}</Typography>
        </div>
        }
      </>
    )
      ;
  }
}


const ApplyProvider = (props) => {
  return (<Provider>
    <BlogDetail {...props} />
  </Provider>)
}
export default memo(ApplyProvider);
