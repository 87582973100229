import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const styles = makeStyles(theme => ({
    logo: {
      width: '100%'
    }
  }));
  return styles();
}
export default theme;

