import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    disabled: {
      backgroundColor: `${ColorSchemes.lowDarkGrey} !important`,
      borderColor: 'transparent !important',
      cursor: 'not-allowed !important'
    },
    button: {
      maxHeight: 27,
      cursor: 'pointer',
      display: 'inline-flex',
      fontSize: 13,
      fontWeight: 'bold',
      justifyContent: 'center',
      alignItems: 'center',
      color: ColorSchemes.mediumBlue,
      borderWidth: 1,
      borderRadius: 13,
      borderStyle: 'solid',
      borderColor: 'transparent',
      padding: '4px 0px',
      backgroundColor: 'transparent',
      outline: 'none',
      '& svg': {
        fontSize: 13,
        paddingRight: 5,
      },
      '&:hover': {
        color: ColorSchemes.orange,
      }
    },
    fill: {
      padding: '5px 0px',
      borderRadius: 13,
      color: ColorSchemes.white,
      backgroundColor: ColorSchemes.mediumBlue,
      '& :first-child': {
        paddingRight: 10,
        paddingLeft: 10,
      },
      '& svg': {
        paddingLeft: 10,
      },
      '& :not(:first-child)': {
        paddingRight: 10,
        paddingLeft: 0,
      },
      '&:hover': {
        backgroundColor: ColorSchemes.orange,
        color: ColorSchemes.white,
        borderColor: ColorSchemes.white
      }
    },
    selected: {
      backgroundColor: ColorSchemes.orange,
      color: ColorSchemes.white,
      borderColor: ColorSchemes.white
    },
    fullWidth: {
      width: '100%'
    },
    green: {
      backgroundColor: ColorSchemes.green,
    }
  }));
  return styles();
}
export default theme;
