import React from 'react';
import theme from './style';
import Icon from './icon';
import clsx from 'clsx';
const Button = (props) => {
  const classes = theme();
  const {
    icon,
    label,
    onClick,
    fill,
    fullWidth,
    type,
    disabled,
    selected,
    style,
    color,
    className
  } = props;
  
  const handleClick = () => {
    if (onClick && !disabled) {
      onClick()
    }
  }
  return (
    <button
      style={style}
      disabled={disabled}
      className={
        clsx(
          selected ? classes.selected : '',
          disabled ? classes.disabled : '',
          classes.button,
          fullWidth ? classes.fullWidth : '',
          fill ? classes.fill : '',
          color ? classes[color] : '',
          className? className:''
        )}
      onClick={handleClick}
      type={type || 'button'}
    >
      <Icon icon={icon} />
      <span className={'text'}>{label}</span>
    </button>
  );
}
export default Button;