import { makeStyles } from "@material-ui/core/styles";
import { MicNone } from "@material-ui/icons";
import ColorSchemes from '../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    myInfoWrap: {
      zIndex: 101,
      position: 'fixed',
      right: 10,
      transition: 'all 1s ease 0s',
    },
    openWrap: {
      top: 65,
      '& $myInfoContainer': {
        height: 'auto',
        paddingBottom: 15
      }
    },
    closeWrap: {
      top: -51,
      '& $myInfoContainer': {
        height: 115,
        overflow: 'hidden',
        '& .menuItem:nth-child(n+4)': {
          display:'none',
        }
      }
    },
    myInfoContainer: {
      backgroundColor: ColorSchemes.white,
      border: `1px solid ${ColorSchemes.mediumBlue}`,
      width: 'auto',
      '& .title': {
        color: ColorSchemes.darkBlue,
        fontSize: 16,
        fontWeight: 'bold',
        padding: '14px 10px'
      },
      '& .menuItem': {
        cursor: 'pointer',
        fontSize: 12,
        color: ColorSchemes.mediumBlue,
        padding: '3px 10px'
      },
    },
    pullingPin: {
      marginLeft: 'auto',
      backgroundColor: ColorSchemes.mediumBlue,
      '&:hover': {
        backgroundColor: ColorSchemes.orange,
        '& .arrowIcon': {
          top: -7
        }
      },
      width: 29,
      height: 37,
      borderRadius: '0px 0px 5px 5px',
      textAlign: 'center',
      '& svg': {
        color: ColorSchemes.white,
        fontSize: 20
      },
      '& .arrowIcon': {
        position: 'relative',
        top: -9
      }
    },
    mobileWrapper: {
      position: "fixed",
      top: 0,
      right: 0,
      width: 300,
      backgroundColor: "#ffffff",
      zIndex: 102,
      boxShadow: `-3px 3px 4px ${ColorSchemes.highLightGrey}`
    },
    mobileGreetingRow: {
      display: "flex",
      justifyContent: "space-between"
    },
    mobileItemMargin: {
      margin: "3px 0",
      color: ColorSchemes.highDarkGrey,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 3,
    },
    mobileItemBorder: {
      borderBottom: `2px solid ${ColorSchemes.lowLightGrey}`,
      paddingBottom: 6 
    },
    targetBlankLink: {
      color: "#636363",
      cursor: "pointer",
      fontSize: 14,
      textDecoration: "none",
      boxShadow: "none"
    },
    mobileItemBorderTop: {
      borderTop: `2px solid ${ColorSchemes.lowLightGrey}`,
      paddingTop: 6
    },
    mobileMenuItem: {
      cursor: "pointer"
    }
  }));
  return styles();
}
export default theme;
