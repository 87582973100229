
import React, { createContext, useEffect, useState } from "react";
import useBlog from './hooks/blog';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const BlogContext = createContext({});

export const Provider = props => {
  const history = useHistory();
  const [dispatch, setLoading, blog, comments, setBlog, fetchBlog, setRequest, fetchComments, deleteBlog, deleteComment, handleAcceptBlogRules] = useBlog();
  const params = useParams();
  const { id } = params;
  const count = useSelector(state => state.states.count);
  const acceptedBlogRules = useSelector(state => state.settings.acceptedBlogRules);
  const [modal, setModal] = useState({ key: '' })
  const closeModal = () => {
    setModal();
  }
  const openRules = () => {
    setModal({ key: 'rules' });
  }

  const openDelete = (item, id) => {
    setModal({ key: 'delete', [item]: id });
  }

  const openUpdate = (item, id) => {
    const comment = comments.find(x => Number(x.comment.id) === Number(id))
    setModal({ key: 'edit_comment', comment_id: id, content: (comment || {}).comment.content });
  }

  const handleNavigation = (options) => {
    switch (options.action) {
      case 'submit':
        postBlog(id);
        break;
      default:
        history.goBack();
        break;
    }
  }

  const postBlog = (id) => {
    dispatch(setLoading(true))
    var formdata = new FormData();
    if (id !== 'new') {
      formdata.append("blog_id", id);
    }
    formdata.append("title", blog.title);
    formdata.append("content", blog.content);
    formdata.append("image_id", 0);
    let url = `${id === 'new' ? 'blog/create' : 'blog/update'}`;
    const method = `${id === 'new' ? 'POST' : 'PUT'}`;

    setRequest({
      url: url,
      method: method,
      data: formdata,
    }).then(res => {
      if (res && res.data) {
        history.push(`/blog/${id === 'new' ? res.data.id : id}`);
      }
      dispatch(setLoading(false))
    })
  }

  const updateComment = (comment_id, content) => {
    dispatch(setLoading(true))
    var formdata = new FormData();

    formdata.append("comment_id", comment_id);
    formdata.append("content", content);

    let url = `comment/update`;
    const method = `PATCH`;
    setRequest({
      url: url,
      method: method,
      data: formdata,
    }).then(res => {
      if (res && res.data) {
        closeModal();
        fetchComments(id);
      }
      dispatch(setLoading(false))
    })
  }

  const [expanded, setExpanded] = useState()
  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [validator, setValidator] = useState([]);
  const validateForm = () => {
    let formValid = [];
    if (!blog.title.length) {
      formValid.push('title')
    }
    if (!blog.content.length) {
      formValid.push('content')
    }
    setValidator(formValid);
    return formValid;
  }

  const setData = (key, value) => {
    setBlog({ ...blog, [key]: value })
  }

  useEffect(() => {
    if (id && Number(id) > 0) {
      fetchBlog(id)
      fetchComments(id)
    }
    else {
      setBlog({ id: 'new', title: '', content: '' });
    }
  }, [])

  useEffect(() => {
    closeModal()
    fetchBlog(id)
    fetchComments(id)
  }, [count])

  const blogContext = {
    blog,
    comments,
    handleNavigation,
    expanded,
    handleExpanded,
    validator,
    validateForm,
    setData,
    modal,
    closeModal,
    openRules,
    openDelete,
    openUpdate,
    deleteBlog,
    deleteComment,
    updateComment,
    handleAcceptBlogRules,
    acceptedBlogRules,
  };

  return <BlogContext.Provider value={blogContext}>{props.children}</BlogContext.Provider>;
};

export const { Consumer } = BlogContext;