import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme'
const theme = (props) => {
    const styles = makeStyles(theme => ({
        container: {
            padding: '10px 20px',
        },
        userName: {
            color: `${ColorSchemes.orange}`,
            fontSize: 14,
            lineHeight: '17px',
            marginBottom: 9,
        },
        title: {
            fontSize: 14,
            lineHeight: '20px',
            marginBottom: 20,
        },
        acceptWrap: {
            display: 'flex',
            marginTop: 14,
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        addWrap: {
            marginTop: 25,
            alignSelf: 'flex-end',
            textAlign: 'right'
        },
        uploadSimple: {
            display: 'inline-flex'
        },
    }));
    return styles();
}
export default theme;

