import { useState, useEffect } from 'react';
import useAPI from '../../api/apiHandler';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/states/statesActions';
import translate from '../../functions/applyTranslation';

const HomeHook = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const [menu, setMenu] = useState([])
  const [rasterizedMenu, setRasterizedMenu] = useState([])
  const [updatedData, setUpdatedData] = useState([]);
  const [newParentID, setNewParentId] = useState();
  const [selected, setSelected] = useState([])
  const fetchTreeViewItems = () => {
    dispatch(setLoading(true))
    setRequest({
      url: 'pages/getsorted',
      method: 'GET',
    }).then(res => {
      if (res) {
        setMenu(res.data)
      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  }
  useEffect(() => {
    if (setRequest) {
      fetchTreeViewItems();
    }
    // eslint-disable-next-line
  }, [])
  const handleActions = (type) => {
    switch (type) {
      case 'back':
        history.goBack();
        break;
      case 'save':
        patchMenuOrder()
        break;
      default:
        break;
    }
  }
  const handleReorder = (path, item, action) => {
    let _menu = JSON.parse(JSON.stringify(menu));
    let targetPath = [...path];

    // Find the actual item and its parent in the tree structure
    const findItemAndParent = (menuArray, path) => {
        let parent = null;
        let current = menuArray;
        path.forEach(id => {
            parent = current;
            current = current.find(item => item.page.id === id).children;
        });
        return { parent, current };
    };

    const { parent, current } = findItemAndParent(_menu, targetPath);
    const currentIndex = parent.findIndex(i => i.page.id === item.page.id);
    const newIndex = action === 'up' ? currentIndex - 1 : currentIndex + 1;

    // Prevent moving outside the array bounds
    if (newIndex < 0 || newIndex >= parent.length) return;

    // Swap items in the parent array to change the order
    [parent[currentIndex], parent[newIndex]] = [parent[newIndex], parent[currentIndex]];

    // Update sort orders to reflect the current order in the UI
    parent.forEach((item, index) => {
        item.page.sort_order = index + 1; // Reset sort orders based on array positions
    });

    // Update the array for API submission
    let _updatedData = [...updatedData];
    updateSortOrderInUpdatedData(_updatedData, parent[currentIndex].page.id, parent[currentIndex].page.sort_order);
    updateSortOrderInUpdatedData(_updatedData, parent[newIndex].page.id, parent[newIndex].page.sort_order);

    setMenu(_menu); // Update the state to reflect the new order
    setUpdatedData(_updatedData); // Collect changes to be saved
};

// Helper function to manage updates to the updatedData state
const updateSortOrderInUpdatedData = (updatedData, pageId, newSortOrder) => {
    let data = updatedData.find(data => data.page_id === pageId);
    if (data) {
        data.sort_order = newSortOrder;
    } else {
        updatedData.push({
            page_id: pageId,
            sort_order: newSortOrder,
            // Add other necessary fields here
        });
    }
}

  const rasterizeMenus = (data) => {
    let count = 0;
    let completeArray = [];
    data.forEach(item => {
      let children = item.children ? JSON.parse(JSON.stringify(item.children)) : null;
      delete item.children;
      completeArray.push(item)
      if (children && children.length > 0) {
        completeArray = completeArray.concat(children);
        count++;
      }
    })
    if (count > 0) {
      rasterizeMenus(completeArray);
    } else {

      setRasterizedMenu([{
        id: '0',
        title: translate('information','placeholder_top_level'),
        sort_order: '0',
        parent_id: '0'
      }].concat(completeArray.map(item => item.page)))
    }
  }
  const formParentIdPath = async () => {
    if (newParentID !== '0') {
        let path = [];
        let lastID = newParentID;

        while (lastID !== '0') { // Loop until the top level is reached
            let obj = rasterizedMenu.find(item => item.id === lastID);
            if (obj) {
                path.push(obj.id);
                lastID = obj.parent_id; // Move up in the hierarchy
            } else {
                // Break the loop if no object is found to prevent infinite looping
                break;
            }
        }
        return path.reverse();
    } else {
        return null;
    }
}
  const handleReParenting = async (oldPath) => {
    let _menu = JSON.parse(JSON.stringify(menu));
    let workObj = _menu;
    let parentObj = _menu;
    let childInd;
    //REMOVE FROM OLD POSITION;
    await oldPath.forEach((pathId, ind) => {
      let obj = workObj.find(item => item.page.id === pathId)
      if ((oldPath.length - 1) === ind) {
        childInd = workObj.findIndex(item => item.page.id === pathId);
        workObj = JSON.parse(JSON.stringify(obj));
      } else {
        parentObj = obj;
        workObj = obj.children;
      }
    })

    if (workObj.page.parent_id === '0') {
      parentObj.splice(childInd, 1);
    } else {
      parentObj.children.splice(childInd, 1);
    }
    //ADD TO NEW POSITION;
    let arr = _menu;
    let parentArr = _menu;
    let parentPath = await formParentIdPath();
    let sortOrder = 0;
    if (parentPath) {
      await parentPath.forEach((pathId, ind) => {
        let obj = arr.find(item => item.page.id === pathId)
        if (parentPath[parentPath.length - 1 === ind]) {
          arr = obj;
        } else {
          arr = obj.children
          parentArr = obj;
        }
      })
      if (parentArr.children.length > 0) {
        parentArr.children.forEach(item => {
          let itemSortOrder = Number(item.page.sort_order);
          if (itemSortOrder > sortOrder) {
            sortOrder = itemSortOrder;
          }
        });
      }
      workObj.page.sort_order = sortOrder + 1;
      workObj.page.parent_id = newParentID;
      parentArr.children.push(workObj);
    } else {
      parentArr.forEach(item => {
        let itemSortOrder = Number(item.page.sort_order);
        if (itemSortOrder > sortOrder) {
          sortOrder = itemSortOrder;
        }
      });
      workObj.page.sort_order = sortOrder + 1;
      workObj.page.parent_id = newParentID;
      parentArr.push(workObj);
    }

    //UPDATE POSTING OBJECT;
    let _updatedData = JSON.parse(JSON.stringify(updatedData));
    let selectedIndex = _updatedData.findIndex(updData => updData.page_id === workObj.page.id);
    if (selectedIndex > -1) {
      _updatedData[selectedIndex].sort_order = sortOrder + 1;
      _updatedData[selectedIndex].parent_id = newParentID;
    } else {
      _updatedData.push({
        page_id: workObj.page.id,
        title: workObj.page.title,
        sort_order: sortOrder + 1,
        parent_id: newParentID
      })
    }
    setUpdatedData(_updatedData);
    setSelected([])
    setMenu(_menu)
  }
  const patchMenuOrder = () => {
    if (updatedData.length > 0) {
      setRequest({
        url: 'pages/updatesorted',
        method: 'PATCH',
        data: updatedData
      }).then(res => {
        setUpdatedData([]);
        fetchTreeViewItems();
      })
    }
  }
  useEffect(() => {
    rasterizeMenus(JSON.parse(JSON.stringify(menu)));
    // eslint-disable-next-line
  }, [menu])
  return [menu, handleActions, handleReorder, rasterizedMenu, newParentID, setNewParentId, handleReParenting, selected, setSelected];
}
export default HomeHook;