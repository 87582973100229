import React, {useState, useRef, useEffect} from 'react';
import ReactQuill, {Quill} from 'react-quill';
import {Typography, TextField} from "@material-ui/core";
import "react-quill/dist/quill.snow.css";
import {Editor} from '@tinymce/tinymce-react';
import {useDispatch, useSelector} from 'react-redux';
import useModal from './modalHook';
import ModalLink from './modals/linkModal';
import ModalFile from './modals/fileModal';
import ModalImage from './modals/imageModal';
import ModalDiv from "./modals/divModal";
import useEditor from './editorHook';
import useAPI, {useImage} from '../../../api/apiHandler';
import {setLoading} from '../../../redux/states/statesActions';
import theme from "./style";
import "./style.css"

const QuillEditor = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const classes = theme();
  const editorRef = useRef(null);
  const {onChange, placeholder, value, invalid} = props;
  const [modal, functions] = useModal();
  const [submitImageToAPI] = useEditor(props);
  const [setRequest] = useAPI();
  const [getImageURL] = useImage();

  const [editorHtml, setEditorHtml] = React.useState(value);
  const [displayType, setDisplayType] = React.useState(0);
  const [markdownContent, setMarkdownContent] = React.useState(value);
  const [content, setContent] = React.useState(value);

  const server = window.location.origin;
  const {access_token} = useSelector(state => state.auth);

  const linkHandler = () => {
    functions.openLinkModal();
  };

  const imageHandler = () => {
    functions.openImageModal();
  };

  const fileHandler = () => {
    functions.openFileModal();
  }

  const modules = React.useMemo(() => ({
    toolbar: {
      container: "#toolbar",
      handlers: {
        insertLink: linkHandler,
        insertImage: imageHandler,
        insertFile: fileHandler
      }
    },
  }), []);

  const formats = [
    "header",
    "bold",
    "italic",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
  ];

  const handleSaveInMarkdown = e => {
    setMarkdownContent(e.target.value);
    onChange(e.target.value);
  }

  const handleChange = (html) => {
    setEditorHtml(html);
    setMarkdownContent(html);
    setContent(html)
    onChange(html);
  };

  const handleWGClick = () => {
    if (displayType === 1) {
      handleChange(markdownContent);
    }

    setDisplayType(0)
  }

  const handleMarkdownClick = () => {
    if (displayType === 0) {
      setMarkdownContent(value);
    }

    setDisplayType(1);
  }

  const submitLink = (props) => {
    // dispatch(setLoading(true))
    // dispatch(setLoading(false))
  }

  // const submitImageToAPI = (data) => {
  //     dispatch(setLoading(true));
  //
  //     let formData = new FormData();
  //     formData.append('file', data.file);
  //     setRequest({
  //         url: 'file',
  //         method: 'POST',
  //         data: formData,
  //       })
  //       .then(res => {
  //           if (res) {
  //                 const src = getImageURL(res.data.id);
  //                 const newContent = `<img src="${src}" className='small' alt="${data.text}" ${data.size ? `width="${data.size * 150}"` : ''} ${data.align ? `style="float: ${data.align === 1 ? 'left' : 'right'}"` : ''} />`
  //                 handleChange(editorHtml + newContent);
  //             }
  //           dispatch(setLoading(false))
  //       })
  // };

  const handleDivWrapp = (data) => {
    const children = editorRef.current.selection.getContent();
    const wrappedChildren = `<div id="${data.text}">${children}</div>`;
    const content = editorRef.current.getContent();
    const newContent = content.replace(children, wrappedChildren);
    onChange(newContent);
  };

  const submitFileToAPI = (data) => {
    dispatch(setLoading(true));
    let formData = new FormData();
    formData.append('file', data.file);

    setRequest({
      url: 'file',
      method: 'POST',
      data: formData,
    })
      .then(res => {
        if (res) {
          editorRef.current.insertContent(`<a href="${getImageURL(res.data.id)}" target="_blank">${data.text}</a>`);
        }
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)))
  };

  const submitModal = (type, data) => {
    if (type === 'link') {
      submitLink({
        ...data,
      });
      let newContent = data.target === '1' ? `<a href="${data.file}">${data.text}</a>` : `<a href="${data.file}">${data.text}</a>`
      handleChange(editorHtml + newContent);
    } else if (type === 'image') {
      submitImageToAPI(data);
    } else if (type === "file") {
      submitFileToAPI(data);
    } else {
      handleDivWrapp(data);
    }
  }

  const handleOnChange = (newContent) => {
    onChange(newContent);
  }

  function example_image_upload_handler(blobInfo, success, failure, progress) {
    let xhr, formData;

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', `${server}/api/file`);
    xhr.setRequestHeader('Authorization', `Bearer ${access_token}`)

    xhr.upload.onprogress = function (e) {
      progress(e.loaded / e.total * 100);
    };

    xhr.onload = function () {
      let json;

      if (xhr.status === 403) {
        failure('HTTP Error: ' + xhr.status, {remove: true});
        return;
      }

      if (xhr.status < 200 || xhr.status >= 300) {
        failure('HTTP Error: ' + xhr.status);
        return;
      }

      json = JSON.parse(xhr.responseText);
      json.location = getImageURL(json.id);

      if (!json || typeof json.location != 'string') {
        failure('Invalid JSON: ' + xhr.responseText);
        return;
      }

      success(json.location);
    };

    xhr.onerror = function () {
      failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };

    formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());

    xhr.send(formData);
  };

  return (
    <div className={`${invalid ? classes.invalid : ''}`}>
      <ModalLink modal={modal} functions={functions} submitModal={submitModal}/>
      <ModalFile modal={modal} functions={functions} submitModal={submitModal}/>
      <ModalImage modal={modal} functions={functions} submitModal={submitModal}/>
      <ModalDiv modal={modal} functions={functions} submitModal={submitModal}/>
      {displayType === 0 &&
      <>
        <input type="file" id="image-upload-tinymce" name="single-image" style={{ display: "none" }} accept="image/png, image/gif, image/jpeg, image/jpg, image/svg" />
        <Editor
          ref={ref}
          apiKey="2iky5c9lu0x9agf9yr804qjoi96442905r04m9dfyzu56kuz"
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={content}
          init={{
            forced_root_block: "div",
            height: 500,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code'
            ],
            toolbar: 'formatselect | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent divwrapped' +
              '| link image customInsertFileButton |',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } img {max-width: 100%;}',
            link_assume_external_targets: true,
            relative_urls: false,
            setup: function (editor) {
              editor.ui.registry.addButton("customInsertFileButton", {
                icon: "upload",
                tooltip: "Insert file",
                onAction: function (_) {
                  functions.openFileModal();
                }
              })
              editor.ui.registry.addButton('divwrapped', {
                tooltip: 'Wrap selected content with a div',
                icon: 'template',
                onAction: function (_) {
                  functions.openDivModal();
                }
                // { editor.dom.setOuterHTML(editor.selection.getNode(),'<div class="red9">'+editor.dom.getOuterHTML(editor.selection.getNode())+'</div>'); }
              })
            },
            file_picker_types: 'image',
            images_upload_handler: example_image_upload_handler,
            file_picker_callback: (callback, value, meta) => {
              if (meta.filetype == 'image') {

                let input = document.getElementById('image-upload-tinymce');
                input.click();

                input.onchange = () => {
                  let file = input.files[0];
                  let reader = new FileReader();

                  reader.onload = (e) => {
                    let img = new Image();
                    img.src = reader.result;

                    callback(e.target.result, {
                      alt: file.name
                    });
                  };

                  reader.readAsDataURL(file);
                };
              }
            }
          }}
          onEditorChange={newContent => handleOnChange(newContent)}
        />
      </>
      }
      {displayType === 1 &&
      <div>
        <TextField
          placeholder={placeholder}
          value={markdownContent}
          multiline
          fullWidth
          letiant="outlined"
          onChange={e => handleSaveInMarkdown(e)}
          InputLabelProps={{
            classes: {
              root: classes.markdownCssLabel
            }
          }}
          InputProps={{
            classes: {
              notchedOutline: classes.markdownNotchedOutline
            },
            inputMode: 'numeric'
          }}
          inputProps={{
            style: {
              minHeight: "400px"
            },
          }}
        />
      </div>
      }
      {/*Markdown*/}
      <div className={classes.displaySelectionWrapper}>
        <Typography
          className={displayType === 0 ? classes.selectedDisplay : classes.unselectedDisplay}
          onClick={() => handleWGClick()}
          letiant="body2">
          WYSIWYG
        </Typography>
        <Typography
          className={displayType === 1 ? classes.selectedDisplay : classes.unselectedDisplay}
          letiant="body2"
          onClick={() => handleMarkdownClick()}
        >Markdown
        </Typography>
      </div>
    </div>
  )
})

export default QuillEditor;