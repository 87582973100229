import React, { Fragment, memo, } from 'react';
import theme from './style';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Paper, Grid } from '@material-ui/core';
import Button from '../../components/Button';
import { BigTitle, SmallTitle, Text } from '../../components/DataDisplay';
import { Label, InputField } from '../../components/Inputs';
import translate from '../../functions/applyTranslation';
import useAuth from './hook';
const ApplicationForm = (props) => {
  const { data, invalidList, handleChange, handleSubmit, success, loading } = useAuth(props);

  const {
    history
  } = props;
  const classes = theme();
  const handleFormSubmit = (e) => {
    try { e.preventDefault(); } catch (error) { }
    handleSubmit();
  }
  return (
    <Fragment>
      {success &&
        <Paper elevation={4} className={classes.formWrap}>
          <BigTitle className={classes.header} text={translate('application', 'title_application_success')} />
          <Text className={classes.subHeader} text={translate('application', 'label_application_success')} />
          <div className={classes.buttonWrap}>
            <Button fill label={translate('application', 'label_go_to')} onClick={() => history.push('')} />
          </div>
        </Paper>
      }
      {!success &&
        <form onSubmit={handleFormSubmit}>
          <Paper elevation={4} className={classes.formWrap}>
            <BigTitle className={classes.header} text={translate('application', 'title_application')} />
            <Text className={classes.subHeader} text={translate('application', 'label_explaining')} />

            <Grid container>
              <Grid item xs={12} sm={6}>
                <Grid container>
                  <Grid item xs={12} className={'item'}>
                    <InputField invalid={invalidList.includes('fullName')} placeholder={translate('application', 'placeholder_full_name')} fullWidth value={data.fullName} onChange={handleChange} id='fullName' />
                  </Grid>
                  <Grid item xs={12} className={'item'}>
                    <InputField invalid={invalidList.includes('ssn')} placeholder={translate('application', 'placeholder_personal_number')} fullWidth value={data.ssn} onChange={handleChange} id='ssn' />
                  </Grid>
                  <Grid item xs={12} className={'item'}>
                    <InputField invalid={invalidList.includes('address1')} placeholder={translate('application', 'placeholder_address1')} fullWidth value={data.address1} onChange={handleChange} id='address1' />
                  </Grid>
                  <Grid item xs={12} className={'item'}>
                    <InputField invalid={invalidList.includes('address2')} placeholder={translate('application', 'placeholder_address2')} fullWidth value={data.address2} onChange={handleChange} id='address2' />
                  </Grid>
                  <Grid item xs={5} className={'item'}>
                    <InputField invalid={invalidList.includes('postNr')} placeholder={translate('application', 'placeholder_post_number')} fullWidth value={data.postNr} onChange={handleChange} id='postNr' />
                  </Grid>
                  <Grid item xs={7} className={'item'}>
                    <InputField invalid={invalidList.includes('city')} placeholder={translate('application', 'placeholder_city')} fullWidth value={data.city} onChange={handleChange} id='city' />
                  </Grid>
                </Grid>
              </Grid>
 
              <Grid item xs={12} sm={6}>
                <Grid item xs={12} className={'item'}>
                  <InputField invalid={invalidList.includes('phoneNr')} placeholder={translate('application', 'placeholder_phone_number')} fullWidth value={data.phoneNr} onChange={handleChange} id='phoneNr' />
                </Grid>
                <Grid item xs={12} className={'item'}>
                  <InputField invalid={invalidList.includes('email')} placeholder={translate('application', 'placeholder_email')} fullWidth value={data.email} onChange={handleChange} id='email' />
                </Grid>
                <Grid item xs={12} className={'item'}>
                  <InputField invalid={invalidList.includes('confirmEmail')} placeholder={translate('application', 'placeholder_confirm_email')} fullWidth value={data.confirmEmail} onChange={handleChange} id='confirmEmail' />
                </Grid>
                <Grid item xs={12} className={'item'}>
                  <InputField invalid={invalidList.includes('message')} className={classes.forceHeight} multiline rows={2} rowsMax={2} placeholder={translate('application', 'placeholder_message')} fullWidth value={data.message} onChange={handleChange} id='message' />
                </Grid>
              </Grid>

            </Grid>
            <div className={classes.buttonWrap}>
              <Button disabled={loading} fill label={translate('application', 'button_apply')} type='submit' />
            </div>
          </Paper>
        </form>
      }
    </Fragment>
  );
}

export default ApplicationForm;