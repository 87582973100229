import React from "react";
import {makeStyles, TableCell, TableHead, TableRow, TableSortLabel} from "@material-ui/core";

const EnhancedTableHead = props => {
  const classes = useStyles();
  const {sort, onRequestSort, cells} = props;

  return (
    <TableHead>
      <TableRow>
        {cells && cells.map(headCell => {
          if (headCell.visible) {
            return (
              <TableCell
                className={classes.forcePointer}
                key={headCell.id}
                sortDirection={sort.column === headCell.id ? sort.by : false}
                align='left'
              >
                <TableSortLabel
                  active={sort.column === headCell.id}
                  direction={sort.column === headCell.id ? sort.by : "asc"}
                  onClick={() => {
                    if (headCell.sort)
                      onRequestSort(headCell.id, sort.by === "asc" ? "desc" : "asc")
                  }}
                  hideSortIcon={!headCell.sort}>
                  {headCell.name}
                </TableSortLabel>
              </TableCell>
            )
          }
        })}
      </TableRow>
    </TableHead>
  )
};

const useStyles = makeStyles(({
  forcePointer: {
    cursor: 'pointer',
  }
}));

export default EnhancedTableHead;
