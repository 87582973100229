import React, {Fragment} from 'react';
import theme from './style';
import DateRangeIcon from '@material-ui/icons/DateRange';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import {BigTitle, Image} from '../DataDisplay';
import {Grid} from '@material-ui/core';
import {useImage} from '../../api/apiHandler';
import moment from 'moment';
import translate from "../../functions/applyTranslation";

const EventHeader = (props) => {
  const classes = theme();
  const [getImageURL] = useImage();
  const {className, title, date_time, end_time, location, simple, image, children, event_category} = props;
  let url = null;
  if (image && image > 0)
    url = getImageURL(image);
  return (
    <div className={className}>
      <div className={classes.headerWrap}>
        <div className={classes.titleWrap}>
          <BigTitle text={title}/> {/* alt={`(${title})`} /> */}
        </div>
        {!simple && image && url &&
        <div style={{marginBottom: '15px'}}>
          <Image url={url} alt={title} path={null} className={classes.image}/>
        </div>
        }
        <Grid container className={classes.borderBottom}>
          <Grid item xs={12} sm={simple ? 12 : 8} className={`${classes.mainGrid} ${simple ? 'simple' : ''}`}>
            <div className={'marginGroup'}>
              <DateRangeIcon/>
              <b>{moment.unix(date_time).format('dddd, D MMMM, YYYY')}</b>
            </div>
            <div className={'marginGroup'}>
              <QueryBuilderIcon/>
              {/* {simple ?
                <span>{`${moment.unix(date_time).format('HH:mm')} - ${moment.unix(end_time).format('HH:mm')}`}</span>
                :
                <span>{moment.unix(date_time).format('HH:mm')}</span>
              } */}
              <span>{`${moment.unix(date_time).format('HH:mm')} - ${moment.unix(end_time).format('HH:mm')}`}</span>
            </div>
            <div className={'marginGroup'}>
              <LocationCityIcon/>
              <div>
                {simple ?
                  <div
                    className={classes.location}>{`${location.name || location.location} (${moment.unix(date_time).format('HH:mm')} - ${moment.unix(end_time).format('HH:mm')})`} </div>
                  :
                  <div
                    className={classes.location}>{location.name || location.location}</div>
                }
                <span>{location.description}</span>
              </div>
            </div>
            {simple &&
            <div className={'marginGroup'}>
              <span><b>{`${translate("", "category_header")}`}</b></span>
              <span>{`: ${event_category}`}</span>
            </div>}
          </Grid>
          {!simple && children &&
          <Grid item xs={12} sm={4}>
            {children}
          </Grid>
          }
        </Grid>
      </div>
    </div>
  );
}
export default EventHeader;