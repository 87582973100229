import React from 'react';
import theme from './style';
import { Grid, Paper, Typography } from '@material-ui/core';
import Button from '../../components/Button';
import { SmallTitle, } from '../../components/DataDisplay';
import useHook from './hook';
import translate from '../../functions/applyTranslation';
import clsx from 'clsx';
import moment from 'moment';
import { useHistory } from 'react-router-dom'
const TemplateDashboard = (props) => {
  const classes = theme();
  const history = useHistory();
  const [notifications, notificationsInfo, filter, handlePagination] = useHook();
  const handleNavigation = (route, id) => {
    if (id) {
      history.push(`${route}/${id}`)
    } else {
      history.push(`${route}`)
    }
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <div >
          <div className={classes.spaceBetween}>
            <SmallTitle text={translate('notifications-list', 'title_notification_list')} />
          </div>

          <Grid container className={classes.wrap}>
            {notifications.map(item =>
              <Grid key={item.id} item xs={12} >
                <Paper className='item' onClick={() => handleNavigation(item.content_type, item.id)}>
                  <Typography className={classes.popoverDate}>{moment.unix(item.created).format('YYYY-MM-DD')}</Typography>
                  <Typography className={classes.popoverType}>{item.message_simple}</Typography>
                </Paper>
              </Grid>
            )}
          </Grid>
          <div className={clsx(classes.flexEnd, classes.paginatorLabel)}>
            {translate('news-list', 'label_page_counter').replace('##page##', filter.page).replace('##total##', notificationsInfo.totalPages)}
          </div>
          <div className={
            filter.page !== 1 && filter.page !== notificationsInfo.totalPages ? classes.spaceBetween :
              filter.page === 1 ? classes.flexEnd : classes.flexStart
          }>
            {filter.page !== 1 &&
              <Button label={translate('news-list', 'button_page_back')} fill onClick={(e) => handlePagination('back')} />
            }
            {!!notificationsInfo.totalPages && filter.page !== notificationsInfo.totalPages &&
              <Button label={translate('news-list', 'button_page_next')} fill onClick={(e) => handlePagination('next')} />
            }
          </div>
        </div>
      </Grid>
    </Grid >
  );
}
export default TemplateDashboard;