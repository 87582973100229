
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogTitle, Slide } from '@material-ui/core';
import Button from '../../components/Button';
import translate from '../../functions/applyTranslation';
import theme from './style';
import { useSelector } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function DeleteModal(props) {
  const { title, subTitle, defaultModal, callBack, modal, closeModal } = props;
  const loading = useSelector(state => state.states.loading);
  const classes = theme();
  const handleClose = () => {
    closeModal();
  };
  const handleClick= () => {
    callBack();
  }

  const modalKey = defaultModal || 'delete';
  return (
    <div>
      <Dialog
        open={modal === modalKey}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>
          {title}
          <small>{subTitle}</small>
        </DialogTitle>
        <DialogActions>
          <Button fill onClick={handleClose} label={translate('', 'button_cancel')} />
          <Button disabled={loading} onClick={handleClick} label={translate('', 'button_delete')} fill icon='delete' />
        </DialogActions>
      </Dialog>
    </div>
  );
}
