import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme'
const theme = (props) => {
  const styles = makeStyles(theme => ({
    buttonWrap: {
      paddingBottom: 10,
      marginBottom: 10,
      borderBottom: `1px solid ${ColorSchemes.highDarkGrey}`
    },
    headerWrap: {
      fontSize: 13,
      marginBottom: 20,
      '& svg': {
        height: 14,
        width: 14,
        marginRight: 7,
        fill: ColorSchemes.mediumBlue
      },
      '& .marginNoIcon': {
        paddingLeft: 20,
        paddingBottom: 5,
      },
      '& .marginGroup': {
        paddingTop: 5,
        paddingBottom: 5,
      },
      '& .customMargin': {
        paddingTop: 5,
      },
      '& .button': {
        cursor: 'pointer',
        marginTop: 5,
        marginBottom: 5,
        background: ColorSchemes.green,
        color: ColorSchemes.white,
        borderRadius: 3,
        paddingTop: 10,
        paddingBottom: 10,
        fontWeight: 'bold',
        textAlign: 'center'
      }
    },
    subHeaderWrap: {
      marginBottom: 20,
    },
    soldTicketsWrap: {
      marginBottom: 20,
    },
    productsWrap: {
      '& .singleline': {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
      },
      '& .circle': {
        height: 15,
        width: 15,
        backgroundColor: ColorSchemes.orange,
        borderRadius: '50%',
        marginRight: 5,
      },
      '& p': {
        margin: 0,
        padding: 0
      },
      '& .circleText': {
        fontWeight: 'bold',
        fontSize: 12,
      }
    },
    cartWrap: {
      margin: '0px 10px',
      padding: 15,
      backgroundColor: '#F2F5FC',
      border: `1px solid #8B8B8B`,
      '& h4': {
        marginTop: 0,
        marginBottom: 20,
        fontSize: 14,
        fontWeight: 'bold'
      },
      '& .row': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 13,
      },
      '& p': {
        margin: '5px 0px',
        fontSize: 13,
      },
      '& b': {
        margin: '15px 0px 5px 0px',
      },
    },
    triangleWrap: {
      marginTop: -1,
      height: 30,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center'
    },
    triangle: {
      // position:'absolute',
      marginTop: '-20px',
      backgroundColor: '#F2F5FC',
      borderBottom: `1px solid #8B8B8B`,
      borderRight: `1px solid #8B8B8B`,
      height: 40,
      width: 40,
      transform: 'rotate(45deg)'
    },
    usersTicketWrap: {
      paddingTop: 10,
      '& .MuiGrid-item': {
        padding: 3
      }
    },
    alignFlexEnd: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    checkText: {
      '& p': {
        color: ColorSchemes.darkBlue
      }
    },
    checkUnderText: {
      fontSize: 12,
      color: ColorSchemes.darkBlue
    },
    goToPayment: {
      marginTop: '12px',
    },
    error: {
      backgroundColor: ColorSchemes.red,
      padding: 10,
      marginBottom: 10,
    },
    disclaimer: {
      backgroundColor: ColorSchemes.mediumBlue,
      padding: 10,
      marginBottom: 10,

    },
    whiteText: {
      '& .title': {
        color: `${ColorSchemes.white} !important`,
      },
      color: ColorSchemes.white,
      fontSize: 14,
    },
    availabilityWrap: {
      display: 'inline-flex',
      alignItems: 'center',
      paddingTop: 5,
      fontWeight: 'bold',
      fontSize: 12,
      '& .circle': {
        height: 15,
        width: 15,
        borderRadius: '50%',
        marginRight: 10,
      },
      '& .available': {
        backgroundColor: ColorSchemes.green
      },
      '& .noAvailable': {
        backgroundColor: ColorSchemes.red
      },
      '& .lowAvailable': {
        backgroundColor: ColorSchemes.orange
      },
    },
    comments: {
      padding: '3px',
      width: 'calc(100% - 6px)'
    },
    commentsHelper: {
      padding: '3px !important',
    },
    commentsAdd: {
      color: '#FF9000',
      display: 'flex',
      alignItems: 'center',
      '& small': {
        fontSize: '11px',
        marginLeft: '7px'
      },
      '& svg': {
        fontSize: '30px',

        cursor: 'pointer'
      }
    },
    addToCart: {
      marginTop: 14
    }
  }));
  return styles();
}
export default theme;

