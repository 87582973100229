import React, {Fragment, useContext, useRef, useState} from 'react';
import theme from './style';
import {Grid, Typography, Box, MenuItem, Select, Dialog, Paper} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from '../../components/Button';
import EventHeader from '../../components/EventHeader';
import EventList from '../../components/EventList';
import translate from '../../functions/applyTranslation';
import moment from 'moment';
import {BookingContext, Provider} from "./bookingContext";
import {Label, Check} from '../../components/Inputs'
import Resume from './resume';
import ToTopWrap from '../../components/ToTopWrap';
import {SmallTitle, Text} from '../../components/DataDisplay';
import {useReactToPrint} from "react-to-print";
import AddManual from './modals/addManual';
import EmailSelected from './modals/emailSelected';
import EmailSended from './modals/emailSended';
import {v4 as uuidv4} from 'uuid';
import usePermissionsHook from '../../functions/permissions';
import {useHistory} from 'react-router-dom'
import BookingInfo from "./info";
import {Markup} from "interweave";

moment.locale('sv')

const Booking = (props) => {
  const FREDIK_ID = 39
  const componentRef = useRef();
  const history = useHistory();
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mailTemplate, setMailTemplate] = useState('newGuideTemplate');
  const classes = theme();
  const bookingContext = useContext(BookingContext);
  const {
    userData,
    data,
    products,
    ticketsSold,
    comments,
    setComments,
    fetchSoldTickets,
    modal,
    closeModal,
    openAddManual,
    openEmailSelected,
    submitAddManual,
    submitEmailSelected,
    handleBack,
    handleSecurity,
    guides,
    guide,
    setGuide,
    submitGuideChange,
    handleSendMail,
    guideSaveSuccess,
    fetchGuideData,
    guideData,
    mailLogs,
    eventTypes
  } = bookingContext;
  if (!!data) {
    const {
      event,
      asset,
      jfm_event,
      program
    } = data;
    const {
      event_start,
      owner,
      alt_email,
      alt_phone,
      event_type,
      tour_guide,
      event_end,
      extra_text
    } = jfm_event
    const {
      title,
      max_ppl,
      pay,
      registered_users,
      type,
      location
    } = event;
    const {
      name,
      description,
      max
    } = asset;

    if (!hasPermission(permissions.ACTIVITIES_BOOKING_ACCESS) && Number(userData.id) !== Number(owner))
      history.push('/');

    let guests, friends, children = 0;

    const getDialogText = (mailTemplate) => {

      if(!guideData.name){
       fetchGuideData();
      }

      let text = translate("booking", mailTemplate);
      for (let i in text) {
        const item = text[i];
        if (typeof item === 'object') {
          if (item.props.children) {
            if (item.props.children.includes('##Program##')) {
              text[i] = program.name;
            }
            if (item.props.children.includes('##guideEmail##') && guideData.email) {
              let updated = Object.assign({}, item, { props: { ...item.props, children: guideData.email } });
              text[i] = updated;
            }
          }
        }

        if (typeof item === "string") {
          if (item.includes('##guideName##') && ( guides[0].name || guideData.name )) {
            let guideName = guides.find(item => item.id == guide);
            if(guideName && guideName.name){
              text[i] = text[i].replace("##guideName##", tour_guide ? guideName.name : guideName.name);
            }
          }
          if (item.includes('##startTime##')) {
            text[i] = text[i].replace("##startTime##", String(moment.unix(event_start).format('HH:mm')));
          }
          if (item.includes('##endtime')) {
            text[i] = text[i].replace("##endtime##", String(moment.unix(event_end).format('HH:mm')));
          }
          if (item.includes('##BookedDay##')) {
            text[i] = text[i].replace('##BookedDay##', String(moment.unix(event_start).format('DD MMM YYYY')));
          }
          if (item.includes('##ProgramContent##') && program.description) {
            text[i] = text[i].replace('##ProgramContent##', program.description)
          }
          if (item.includes('##guideMobile##') && guideData.phoneNr) {
            text[i] = text[i].replace('##guideMobile##', guideData.phoneNr)
          }
          if (item.includes('##guideEmail##') && guideData.email) {
            text[i] = text[i].replace('##guideEmail##', guideData.email)
          }
          if (item.includes('##ProgramLength##')) {
            text[i] = text[i].replace('##ProgramLength##', Number(program.duration) / 60)
          }
          if (item.includes('##Program##')) {
            text[i] = text[i].replace('##Program##', program.name)
          }
        }
      }

      let result = "";
      for(let i in text) {
        let item = text [i];

        if(typeof item === "string") {
          result += item;
        }

        if(typeof item === "object") {
          if(item.type === "br") {
            result += "<br>";
          }
          if(item.type === "strong") {
            result += `<strong>${item.props.children}</strong>`;
          }
          if(item.type === "a") {
            result += `<a href=${item.props.href}>${item.props.children}</a>`
          }
        }
      }
      return {text, result};
    }

    const prepareMailToPayload = text => {
      let result = "";

      for (let item of text) {
        if (typeof item === "string") {
          result += item;
        } else if (item.type === "strong") {
          result += `<b>${item.props.children}</b>`;
        }else if (item.props && item.props.children) {
          result += item.props.children;
        } else if (item.type === "br") {
          result += "<br>";
        }
      }

      return result;
    }

    const prepareTextToClipboard = text => {
      let result = "";

      for (let item of text) {
        if (typeof item === "string") {
          result += item;
        } else if (item.props && item.props.children) {
          result += item.props.children;
        } else if (item.type === "br") {
          result += "\n";
        }
      }

      return result;
    };

    const replaceMailLogsPlaceholders = (text, { tour_guide_name, mail_logs: { timestamp, status } }) => {
      return text
        .replace("##guidename##", tour_guide_name || "")
        .replace("##timestamp##", timestamp || "")
        .replace("##status##", status || "");
    }

    const getMailLogsText = () => {
      return mailLogs[0].map(mailLog => replaceMailLogsPlaceholders(translate("booking", "mail_log_label"), mailLog));
    }

    const mailAlreadySent = () => {
      if(mailLogs[0].length === 0) {
        return false
      }

      return mailLogs[0].some(mailLog => guideData.name && guideData.name === mailLog.tour_guide_name);
    }

    const getEventTypeText = () => {
      return eventTypes.find(item => item.id === event_type).name || "-"
    };

    const getCommentText = (comment) => {
      try {
        let o = JSON.parse(comment);
        if (o && typeof o === "object") {
          return o.message;
        }
        else {
          return comment
        }
      }
      catch (e) {
        return comment;
      }
    }

    const handleSendMailButton = () => {
      if(mailTemplate === 'genericTemplate' && tour_guide != FREDIK_ID){
        handleSendMail(prepareMailToPayload(getDialogText("booking_email").text));
      }
      if(mailTemplate === "genericTemplate" && tour_guide == FREDIK_ID){
        handleSendMail(prepareMailToPayload(getDialogText("booking_email_fredik").text));
      }
      if(mailTemplate === "newGuideTemplate" && tour_guide != FREDIK_ID){
        handleSendMail(prepareMailToPayload(getDialogText("booking_new_guide_email").text));
      }
      if(mailTemplate === "newGuideTemplate" && tour_guide == FREDIK_ID){
        handleSendMail(prepareMailToPayload(getDialogText("booking_new_guide_email_Fredik").text));
      }
      setDialogOpen(false);
    }

    const handleMailTemplateChange = (event) => {
      setMailTemplate(event.target.value);
    };

    const handleSaveGuide = () => {
      submitGuideChange(prepareMailToPayload(getDialogText("booking_confirmation_mail").text));
    }

    return (
      <>
        <AddManual modal={modal ? modal.key : null} closeModal={closeModal} submitModal={submitAddManual}/>
        <EmailSelected modal={modal ? modal.key : null} users={modal ? modal.users : []} closeModal={closeModal}
                       submitModal={submitEmailSelected}/>
        <EmailSended modal={modal ? modal.key : null} closeModal={closeModal}/>
        <div className={classes.pageWrap}>
          <div className={classes.buttonWrap}>
            <Button fill label={translate('booking', 'button_go_to_activity')} onClick={(e) => handleBack()}/>
            {hasPermission(permissions.ACTIVITIES_SECURITY_ACCESS) &&
            <Button fill label={translate('booking', 'button_jss_view')} onClick={(e) => handleSecurity()}/>
            }
          </div>
          {eventTypes && eventTypes.length > 0 &&
          <EventHeader simple={true}
                       title={title}
                       date_time={event_start}
                       end_time={event_end}
                       location={{name: name || '', description: description || '', location: location}}
                       event_category={getEventTypeText()}
          />}
            {(type === "synagogue" || type === '14') &&
          <Grid container className={classes.groupTourInfoWrapper}>
            <Grid item xs={12} className={classes.selectWrap}>
              <Grid container spacing={1} style={{marginTop: 10}}>
                <Grid item xs={12} sm={4} style={{display: "flex", alignItems: "center"}}>
                  <div className={classes.selectGroup}>
                    <Label fullWidth text={translate("booking", "select_guide_label")}>
                      <Select
                        value={guides.find(g => g.id === guide) ? guides.find(g => g.id === guide).id : "0"}
                        fullWidth
                        onChange={e => setGuide(e.target.value)}
                      >
                        {guides && guides.map(guide => (
                          <MenuItem key={guide.id} value={guide.id}>{guide.name}</MenuItem>
                        ))}
                      </Select>
                      {guideSaveSuccess &&
                      <Text text={translate('booking', 'label_set_guide_success')} className={classes.success}/>
                      }
                    </Label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={2} className={classes.saveGuideButtonWrapper}>
                  <Button fill label={translate('booking', 'button_save_guide')} onClick={handleSaveGuide}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <BookingInfo
                email={alt_email || "-"}
                contact={(ticketsSold.length > 0 && ticketsSold[0].name) ? ticketsSold[0].name.split(" - ")[0] : "-"}
                phone={alt_phone || "-"}
                nr_visitors={(ticketsSold.length > 0 && ticketsSold[0].name) ? ticketsSold[0].name.split(" - ")[1] : "-"}
                message={(ticketsSold.length > 0 && ticketsSold[0].message) ? ticketsSold[0].message : "-"}
                program={program}
                eventStart={event_start || '-'}
                eventEnd={event_end || '-'}
              />
            </Grid>
            <Grid item xs={12} className={classes.emailButtonWrap}>
              <Button fill label={translate("booking", "email_button")} disabled={!tour_guide || tour_guide === "0"} onClick={() => {
                setDialogOpen(true);
                setMailTemplate('newGuideTemplate');
                fetchGuideData();
              }}/>
              <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <Paper style={{padding: 10}} elevation={4} className={classes.modalTopBar}>
                  <Grid container alignItems="center" justify="space-between">
                    <Grid item>
                      {translate("booking", "teacher_mail_label")} <br></br> {alt_email || "-"}
                    </Grid>
                    <Grid item>
                      <Button fill label={translate("booking", "send_mail_button")} onClick={handleSendMailButton}/>
                    </Grid>
                  </Grid>
                </Paper>
                {mailAlreadySent() &&
                  <Paper style={{padding: 10}} elevation={4} className={classes.modalTopBarMailSent}>
                    <Grid container>
                      <Grid item>
                        {translate("booking", "email_already_sent_label")}
                      </Grid>
                    </Grid>
                  </Paper>
                }
                <div style={{padding: 15, position: "relative"}}>
                  <Select
                    value={mailTemplate}
                    defaultValue="genericTemplate"
                    onChange={handleMailTemplateChange}
                    className={classes.customSelect}
                  >
                    <MenuItem value="genericTemplate">{translate("booking", "generic_mail_template")}</MenuItem>
                    <MenuItem value="newGuideTemplate">{translate("booking", "new_guide_mail_template")}</MenuItem>
                  </Select>
                </div>
                {mailTemplate === "genericTemplate" && tour_guide != FREDIK_ID &&
                <div style={{padding: 50, position: "relative"}}>
                  <>
                    <Markup content={getDialogText('booking_email').result}/>
                    <FileCopyIcon className={classes.copyToClipboardIcon}
                                  onClick={() => navigator.clipboard.writeText(prepareTextToClipboard(getDialogText("booking_email").text))}/>
                  </>
                </div>
                }
                {mailTemplate === "genericTemplate" && tour_guide == FREDIK_ID &&
                <div style={{padding: 50, position: "relative"}}>
                  <>
                    <Markup content={getDialogText('booking_email_fredik').result}/>
                    <FileCopyIcon className={classes.copyToClipboardIcon}
                                  onClick={() => navigator.clipboard.writeText(prepareTextToClipboard(getDialogText("booking_email_fredik").text))}/>
                  </>
                </div>
                }
                {mailTemplate === "newGuideTemplate" && tour_guide != FREDIK_ID &&
                  <div style={{padding: 50, position: "relative"}}>
                    <>
                      <Markup content={getDialogText('booking_new_guide_email').result}/>
                      <FileCopyIcon className={classes.copyToClipboardIcon}
                                    onClick={() => navigator.clipboard.writeText(prepareTextToClipboard(getDialogText("booking_new_guide_email").text))}/>
                    </>
                  </div>
                }
                {tour_guide && tour_guide == FREDIK_ID && mailTemplate === "newGuideTemplate" &&
                  <div style={{padding: 50, position: "relative"}}>
                    <>
                      <Markup content={getDialogText('booking_new_guide_email_Fredik').result}/>
                      <FileCopyIcon className={classes.copyToClipboardIcon}
                                    onClick={() => navigator.clipboard.writeText(prepareTextToClipboard(getDialogText("booking_new_guide_email_Fredik").text))}/>
                    </>
                  </div>
                }
              </Dialog>
              <div>
                <br></br>
                {mailLogs[0].length > 0 &&
                mailLogs.map((mailLog, i) => {
                  if(mailLog[0].mail_logs.status == 'sent'){
                    return (
                      <div key={mailLog[0].mail_logs.id} className={classes.mailLogSuccess}>
                        {getMailLogsText().map((text, index) => <div key={index}>{text}</div>)}
                      </div>
                    )
                  }
                  return (
                    <div key={mailLog[0].mail_logs.id} className={classes.mailLogError}>
                      {getMailLogsText().map((text, index) => <div key={index}>{text}</div>)}
                    </div>
                  )
                })
                }
              </div>
            </Grid>
          </Grid>
          }
          {extra_text &&
          <div className={classes.subHeaderWrap}>
            <SmallTitle text={translate('booking', 'label_message')}/>
            <Text text={extra_text}/>
          </div>
          }
          <div className={classes.subHeaderWrap}>
            <SmallTitle text={translate('booking', 'label_registed_persons')}/>
            <Text text={translate('booking', 'label_registed_persons_info')}/>
          </div>
          <Grid container className={classes.statistcsWrap}>
            <Grid item xs={12} sm={6}>
              <Resume max={max}
                      max_ppl={max_ppl}
                      pay={pay}
                      registered_users={registered_users}
                      guests={guests}
                      friends={friends}
                      children={children}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.actionsContainer}>
              <div className={classes.printWrap}>
                {comments && comments.length > 0 &&
                <Fragment>
                  <Button icon={'print'} label={`${translate('booking', 'button_print')} ${comments.length}`}
                          onClick={handlePrint}/>
                  <Box ref={componentRef} display="none" displayPrint="block" id='commentsToPrint'>
                    <div className={classes.printWapper}>
                      <EventHeader simple={true}
                                   title={title}
                                   date_time={event_start}
                                   end_time={event_end}
                                   location={{name: name || '', description: description || '', location: location}}
                                   event_category={getEventTypeText()}
                      />
                      {comments.map(item => {
                        return (
                          <div key={`block_${uuidv4()}}`} className={classes.printBlock}>
                            <div>{translate('booking', 'label_print_booking')}</div>
                            <div>{`${item.name} | ${item.ssn}`}</div>
                            {item.products.map(product =>
                              <div
                                key={`prod_${uuidv4()}`}>{`${product.name || (Number(product.id) === 0 ? translate('booking', 'label_manually_entered') : '')} x ${product.quantity}`}</div>
                            )}
                            <div>{`${translate('booking', 'label_print_comment')} ${getCommentText(item.comment)}`}</div>
                          </div>
                        )
                      })
                      }
                    </div>
                  </Box>
                </Fragment>
                }
              </div>
              <div className={classes.addWrap}><Button fill label={translate('booking', 'button_add')}
                                                       onClick={openAddManual}/></div>
            </Grid>
          </Grid>
          <EventList setComments={setComments} products={products} tickets={ticketsSold} tablet={false}
                     openEmailSelected={openEmailSelected} fetchTickets={fetchSoldTickets}/>

          <div className={classes.disclaimer}>
            <Label className={classes.whiteText} text={translate('booking', 'label_disclaimer_title')}>
              <Typography className={classes.whiteText}>{translate('booking', 'label_disclaimer_info')}</Typography>
            </Label>
          </div>
          <ToTopWrap/>
        </div>
      </>
    );

  } else {
    return null;
  }
}

const ApplyProvider = (props) => {
  return (<Provider>
    <Booking {...props} />
  </Provider>)
}
export default ApplyProvider;
