import React from 'react';
import theme from './style';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
const SmallTitle = (props) => {
  const classes = theme();
  const {
    className,
    fullWidth,
    text
  } = props;

  return (
    <div className={
      clsx(
        classes.smallTitleWrap,
        fullWidth ? classes.fullWidth : ''
      )}
    >
      <Typography className={`${classes.smallTitleText} ${className ? className : ''}`}>{text}</Typography>
    </div >
  );
}
SmallTitle.propTypes = {
}


export default SmallTitle;