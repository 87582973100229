import React from 'react';
import theme from './style';
import PropTypes from 'prop-types';
import { RadioGroup as RG, FormControlLabel, Radio } from '@material-ui/core';
import clsx from 'clsx';
const RadioGroup = (props) => {
  const classes = theme();
  const {
    selected,
    onChange,
    items,
    className,
  } = props;

  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }
  return (
    <RG value={Number(selected)} onChange={handleChange} className={clsx(className, classes.radioGroup)}>
      {items.map(item => <FormControlLabel key={item.id} value={Number(item.id)} control={<Radio />} label={item.text} />)}
    </RG>
  );
}

RadioGroup.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}
RadioGroup.defaultProps = {
  items: [],
  selected: null,
  onChange: () => { },
}
export default RadioGroup;