import { makeStyles } from "@material-ui/core/styles";
import ColorScheme from '../../settings/colorScheme';
const theme = (props) => {
    const styles = makeStyles(theme => ({
        infoDetail: {
            paddingBottom: 5,
        },
        title: {
            fontSize: '14px !important',
            fontWeight: 'normal !important'
        }
    }));
    return styles();
}
export default theme;