import {
  LOGIN,
  LOGOUT,
  SET_USER_DATA,
  SET_USER_SETTINGS
} from './authTypes';
const initialState = {
  access_token: null,
  refresh_token: null,
  expires_in: null,
  role: null,
  userData: null,
  userSettings: null,
  lastRefresh: null
}
const AuthReducer = function (state = initialState, action) {
  const { type, payload, } = action;
  switch (type) {
    case LOGIN:
      return {
        ...state,
        ...payload,
      }
    case SET_USER_DATA:
      return {
        ...state,
        userData: payload,
      }
    case SET_USER_SETTINGS:
      return {
        ...state,
        userSettings: payload,
      }
    case LOGOUT:
      return {
        access_token: null,
        refresh_token: null,
        expires_in: null,
        role: null,
        userData: null,
        userSettings: null,
        lastRefresh: null
      }
    default:
      return state;
  }
}
export default AuthReducer;