import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
    const styles = makeStyles(theme => ({
        main: {
            display: 'inline-block',
            cursor:'pointer',
            '&.readOnly': {
                cursor:'default',
            }
        },
        title: {
            marginTop: 5,
            width: '100%',
            fontSize:'14px',
            lineHeight:'17px',
            fontWeight: 'bold',
            color: ColorSchemes.black
        },
        content: {
            width: '100%',
            fontSize:'14px',
            lineHeight:'17px',
            color: ColorSchemes.black,
            '&.collapsed':{
                display: '-webkit-box',
                '-webkit-line-clamp': 3,
                '-webkit-box-orient': 'vertical', 
                maxHeight: '66px',
                overflow: 'hidden',
            },
            '& div':{
                marginTop: 3,
            }
        },
      message:{
          color: `ColorSchemes.highDarkGrey !important`,
          fontSize:'13px !important',
      }
    }));
    return styles();
}
export default theme;
