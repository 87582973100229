
import React, { createContext, useEffect, useState } from "react";
import useAPI from '../../api/apiHandler';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/states/statesActions';
import translate from "../../functions/applyTranslation";
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryState } from "react-router-use-location-state";

export const SearchResultsContext = createContext({});
export const Provider = props => {
  const history = useHistory();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery()
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const [queryText, setQueryText] = useQueryState("text", query.get('text'));
  const [queryContent, setQueryContent] = useQueryState("content_type", query.get('content_type') || "");
  const [data, setData] = useState([])
  const [dataSettings, setDataSettings] = useState({
    totalItems: 0,
    totalPages: 1,
  })
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
    text: query.get('text'),
    content_type: query.get('content_type')
  })

  const fetchSearchResults = () => {
    dispatch(setLoading(true))
    const { page, limit, text,content_type } = filter;
    let url = `search?&page=${page}&limit=${limit}&text=${text}`
    if(content_type)
      url += `&content_type=${content_type}`;
    setRequest({
      url: url,
      method: 'GET',
    }).then(res => {
      if (res && res.data) {
        let data = []
        data =data.concat(res.data.articles.map(item => ({ id: item.id, title: item.title, area: 'articles', areaName: translate('search-results', `label_area_articles`) })));
        data =data.concat(res.data.blogs.map(item => ({ id: item.id, title: item.title, area: 'blogs', areaName: translate('search-results', `label_area_blogs`) })));
        data =data.concat(res.data.events.map(item => ({ id: item.jfm_event.id, title: item.event.title, area: 'events', areaName: translate('search-results', `label_area_events`) })));
        data =data.concat(res.data.static_pages.map(item => ({ id: item.id, title: item.title, area: 'static_pages', areaName: translate('search-results', `label_area_static_pages`) })));
        data.sort((a, b) => (a.id > b.id) ? 1 : (a.id === b.id) ? ((a.area > b.area) ? 1 : -1) : -1);
        setData(data);
        setDataSettings({
          totalPages: 1,
          totalItems: data.length
        })
      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  }

  const handlePagination = (action) => {
    setFilter({ ...filter, page: (action === 'next' ? filter.page += 1 : filter.page -= 1) })
  }

  const handleNavigation = (area, id) => {
    switch (area) {
      case 'events':
        if (id)
          history.push(`/activities/${id}`);
        else
          history.push(`/activities`);
        break;
      case 'articles':
        if (id)
          history.push(`/article/${id}`);
        else
          history.push(`/article`);
        break;
      case 'blogs':
        if (id)
          history.push(`/blog/${id}`);
        else
          history.push(`/blog`);
        break;
      case 'static_pages':
        if (id)
          history.push(`/information/${id}`);
        else
          history.push(`/information`);
        break;
      default:

        break;
    }
  }

  useEffect(() => {
    if (queryText !== filter.text || queryContent !== filter.content_type) {
      setFilter({
        page: 1,
        limit: 20,
        text: queryText,
        content_type: queryContent
      })
    }
    // eslint-disable-next-line
  }, [queryText, queryContent])

  useEffect(() => {
    if (setRequest) {
      fetchSearchResults();
    }
    // eslint-disable-next-line
  }, [filter])

  const searchResultsContext = {
    data,
    dataSettings,
    filter,
    queryText,
    handlePagination,
    handleNavigation
  };
  return <SearchResultsContext.Provider value={searchResultsContext}>{props.children}</SearchResultsContext.Provider>;
};

export const { Consumer } = SearchResultsContext;