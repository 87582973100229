import React, { useEffect, useState } from 'react';
import theme from './style';
import { SmallTitle } from '..'
import { Dialog, Grid } from '@material-ui/core';
import translate from '../../../functions/applyTranslation';
import toLongDate from '../../../functions/formatLongDate';
import Categories from '../../../settings/galleryTypes';
import CloseIcon from '@material-ui/icons/Close';
import { Label, Select } from '../../Inputs';
import { Image, Text } from '../../DataDisplay';
import Button from '../../Button';
import clsx from 'clsx'
import LaunchIcon from '@material-ui/icons/Launch';
import useAPI, { useImage } from '../../../api/apiHandler';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/states/statesActions';

const DatePicker = (props) => {
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const [getImageURL] = useImage();
  const [selected, setSelected] = useState();
  const [filter, setFilter] = useState({
    page: 1,
    limit: 8,
    category: 1
  });
  const [data, setData] = useState([]);
  const classes = theme();
  const {
    open,
    onSelect,
    onClose
  } = props;
  const fetchData = () => {
    dispatch(setLoading(true))
    const { limit, page } = filter;
    setRequest({
      url: `files?page=${page}&limit=${limit}&tags=gallery&status=${filter.category === 1 ? 'public' : 'private'}`, //category=${category}`,
      method: 'GET',
    }).then(res => {
      if (res) {
        setData([...data, ...res.data.files]);
      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  }
  const handleOpenPictureNewPage = (id) => {
    // try { e.preventPropagation(); } catch (error) { }
    // const imgURL = `${window.location.origin}${path}`
    var win = window.open(getImageURL(id), '_blank');
    // var win = window.open(imgURL, '_blank');
    win.focus();
  }
  const scrollToBottom = () => {
    let objDiv = document.getElementById("gallery");
    if (objDiv) {
      objDiv.scrollTo({ top: objDiv.scrollHeight, behavior: 'smooth' });
    }
  }
  const handleFileSelect = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      // let _data = {
      //   file: uploadedFile,
      //   tags: 'gallery'
      // }
      var _data = new FormData();
      _data.append('file', uploadedFile);
      _data.append('tags', 'gallery');
      dispatch(setLoading(true))
      setRequest({
        url: `file`,
        method: 'POST',
        data: _data
      }).then(res => {
        if (res) {
          document.getElementById('filehandler').value = '';
          setData([]);
          setSelected();
          setFilter({
            category: 2,
            page: 1,
            limit:8,
          });
        }
        dispatch(setLoading(false))
      })
        .catch(() => dispatch(setLoading(false)))
    }
  }

  const handleUseFile = () => {
    if (onSelect) {
      onSelect(selected);
    }
  }
  useEffect(() => {
    scrollToBottom();
  }, [data])
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [filter])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={open}
      onClose={onClose}
    >
      <div className={classes.header}>
        <SmallTitle text={translate('gallery', 'modal_title')} />
        <CloseIcon onClick={onClose} />
      </div>
      <div className={classes.categorySection}>
        <Grid container>
          <Grid item xs={12} sm={4} className={'item'} >
            <Label text={translate('gallery', 'label_change_category')} fullWidth>
              <Select icon='save'
                fullWidth
                data={Categories}
                onChange={(e) => {
                  setSelected();
                  setData([]);
                  setFilter({
                    page: 1,
                    limit: 8,
                    category: e
                  })
                }}
                selected={filter.category}
              />
            </Label>
          </Grid>
          <Grid item xs={6} sm={4} className={clsx('itemBottom', classes.paddingLeft)}>
            <Button label={translate('gallery', 'file_upload_button')} onClick={() => document.getElementById('filehandler').click()} />
            <input type='file' id='filehandler' hidden className='fileInput' onChange={handleFileSelect} accept="image/*" />
          </Grid>
          <Grid item xs={6} sm={4} className={clsx('itemBottom', classes.pushRight)}>
            <Button label={translate('gallery', 'button_select_image')} fill disabled={!selected} onClick={handleUseFile} />
          </Grid>
        </Grid>
      </div>
      <div className={classes.gallerySection} id='gallery'>
        <Grid container>
          {data.map(item => {
            let blobURL = null;
            return <Grid key={item.id} item xs={6} sm={3} md={3} className={clsx('galleryItem', item.id === selected ? 'galleryItemSelected' : '')}
              onClick={() => setSelected(selected === item.id ? null : item.id)}
            >
              <Image
                file={item.id}
                blobPath={(e) => { blobURL = e }}
                alt={item.name}
                className={classes.image}/>
              <div>
                {item.id === selected &&
                  <LaunchIcon className={classes.icon} onClick={(e) => handleOpenPictureNewPage(item.id)} />
                }
                <Text text={toLongDate(item.creation_date)} className={item.id === selected ? 'selectedText' : 'textItem'} />
                <Text text={item.name} className={item.id === selected ? 'selectedText' : 'textItem'} />
              </div>
            </Grid>
          })}
        </Grid>
      </div>
      <div className={classes.loadMoreSection}>
        <Button label={translate('', 'labe_load_more')} disabled={(filter.page * filter.limit) > data.length} fill onClick={() => {
          setFilter({ ...filter, page: filter.page + 1 });
        }} />
      </div>
    </Dialog>
  );
}

export default DatePicker;