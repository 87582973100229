import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    placeholderLabel: {
      // paddingTop: 8,
      // paddingLeft: 6,
      // paddingBottom: 8,
      fontSize: 14,
      padding: '2px 10px',
      color: ColorSchemes.darkGrey,
      width: '100%'
    },
    datepickerGroup: {
      display: 'inline-flex',
      minWidth: 200,
      height: 35,
      // paddingLeft: 6,
      '& ::after': {
        border: 'none !important'
      },
      '& ::before': {
        border: 'none !important'
      },
      '& .MuiFormControl-root': {

        flexGrow: 1,
      },
      '& .MuiInputBase-input': {
        //paddingLeft: 6,
        background: 'none',
        // fontSize: 12,
        // paddingTop: 8,
        // paddingBottom: 8,
        // color: ColorSchemes.lowDarkGrey,

        fontSize: 14,
        padding: '2px 10px',
        color: ColorSchemes.darkGrey,
      },
    },
    fullWidth: {
      width: '100%'
    },
    icon: {
      fontSize: 21,
      color: ColorSchemes.lowDarkGrey,
      padding: '0px 6px',
    },
    borderWrap: {
      display: 'inline-flex',
      alignItems: 'center',
      borderColor: ColorSchemes.highDarkGrey,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 3,
      flexGrow: 1,
    },
    invalid: {
        borderColor: ColorSchemes.darkOrange,
        borderWidth: 3,
    },
  }));
  return styles();
}
export default theme;
