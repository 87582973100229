import React, {Fragment} from 'react';
import theme from './style';
import clsx from 'clsx';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useSelector} from "react-redux";
import displayForMembers from "../../../functions/informationMemberPermission";

const TreeView = (props) => {
  const classes = theme();
  const userData = useSelector(state => state.auth.userData);
  // const [selected, setSelected] = useState([])
  const {
    items,
    handleSelect,
    selected,
  } = props;

  const ListItem = (item, level, path) => {
    if(displayForMembers(userData.role, item)) {
      JSX.push(
        <div key={item.page.id} className={classes.wrapItem}>
          <div className={clsx(
            classes.listItem,
            selected.length > level && Number(selected[level - 1]) === Number(item.page.id) ? classes.path : '',
            selected.length === level && Number(selected[level - 1]) === Number(item.page.id) ? classes.active : '',
          )}
               style={{paddingLeft: 22 * (level)}}
               onClick={() => handleSelect(path)}
          >
            {!!item.children.length && Number(selected[level - 1]) === Number(item.page.id) &&
            <ExpandLessIcon/>
            }
            {!!item.children.length && !(Number(selected[level - 1]) === Number(item.page.id)) &&
            <ExpandMoreIcon/>
            }
            <p>{item.page.title}</p>

          </div>
        </div>
      )
    }
  }
  let JSX = [];
  const generateMenu = (arr, level, path) => {
    level++;
    if (arr) {
      arr.forEach(item => {
        ListItem(item, level, [...path, item.page.id]);
        if (item.children.length > 0 &&
          (Number(selected[level - 1]) === Number(item.page.id))) {
          generateMenu(item.children, level, [...path, item.page.id])

        }
      })
    }
  }
  generateMenu(items, 0, []);
  return <Fragment>{JSX}</Fragment>
}
export default TreeView;