import { makeStyles } from "@material-ui/core/styles";
import ColorScheme from '../../../settings/colorScheme'
const theme = () => {
  const styles = makeStyles(theme => ({
    pageWrap: {

    },
    formFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 10,
      paddingTop: 10,
      borderTop: `1px solid ${ColorScheme.highDarkGrey}`,
      '& >:nth-child(1)': {
        marginRight: 10,
      }
    },
    imageHolder: {
      display: 'flex',
      justifyContent: 'center'
    },
    image: {
      height: 100,
      width: '100px !important',
    },
    paddingFix: {
      paddingTop: 13,
    }
  }));
  return styles();
}
export default theme;