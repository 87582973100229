import React from 'react';
import { Table, TableHead, TableBody, TableContainer, TableRow, TableCell, TableFooter, TablePagination } from '@material-ui/core';
import { ExpandLess, ExpandMore, NavigateNext, Launch } from '@material-ui/icons';
import TablePaginationActions from '../../components/Table/pagination';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router";
import { Markup } from 'interweave';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import translate from '../../functions/applyTranslation';

const useStyles = makeStyles(theme => ({
  forcePointer: {
    cursor: 'pointer',
    // verticalAlign: 'middle',
  },
  centerText: {
    display: 'flex',
    alignItems: 'center'
  },
  disabledPointer: {
    cursor: 'not-allowed'
  },
  displayNegativeFeedback: {
    background: '#FAD1D7',
  },
  infoSolutionsSynced: {
    padding: '4px 8px',
    display: 'inline-flex',
    lineHeight: '2.2',
    color: '#28A745',
    verticalAlign: 'bottom'
  }

}));
const TableComponent = (props) => {
  const classes = useStyles();
  // const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.rows.length - props.page * props.rowsPerPage);
  const tableCols = [];
  props.columns.forEach((col, ind) => {
    if (col.visible) {
      tableCols.push({ ind: ind, fn: col.functionalName, displayRed: col.displayRed })
    }
  });
  const history = useHistory();
  return (
    <TableContainer>
      <Table size="small">
        {!props.noHeader &&
          <TableHead>
            <TableRow>
              {props.isNested &&
                <TableCell />
              }
              {props.columns.map(col => {
                if (col.visible) {
                  if (col.type === 'check') {
                    return <TableCell key={`col_${col.functionalName}`} style={{ paddingBottom: 0, paddingTop: 10, cursor: 'pointer' }} onClick={props.handleSelectAll} >
                      {props.allChecked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                    </TableCell>
                  } else {
                    return <TableCell
                      className={col.sortable ? classes.forcePointer : classes.disabledPointer}
                      key={`col_${col.functionalName}`}
                      align={col.align || 'left'}
                      style={col.style || {}}
                      onClick={() => {
                        if (col.sortable) {
                          props.handleSortChange(col.functionalName, (props.sort.column !== col.functionalName) || (props.sort.column === col.functionalName && props.sort.by === 'desc') ? 'asc' : 'desc')
                        }
                      }}
                    >
                      <div className={classes.centerText}>
                        {col.name}
                        {!props.notSortable &&
                          <>
                            {(props.sort.column === col.functionalName) && props.sort.by === 'desc' &&
                              <ExpandMore color="secondary" />
                            }
                            {(props.sort.column === col.functionalName) && props.sort.by === 'asc' &&
                              <ExpandLess color="secondary" />
                            }
                          </>
                        }
                      </div>
                    </TableCell>
                  }
                } else { return <React.Fragment key={`col_${col.functionalName}`} /> }
              })}
            </TableRow>
          </TableHead>
        }
        <TableBody>
          {props.rows.map((row, i) => {
            return <React.Fragment key={'bodyRow_' + i}>
              <TableRow className={props.onLineClick || props.hasCheck ? classes.forcePointer : ''}
                onClick={(e) => {
                  if (props.hasCheck) {
                    props.handleSelectLine(row.sys_id)
                  } else if (props.isNested && !props.specialPDFDownloadField) {
                    props.setNestedId(props.nestedId === row.sys_id ? null : row.sys_id);
                  } else if (props.onLineClick) {
                    history.push(props.onLineClick + (props.analysisTable ? row.referral.sys_id : (row.sys_id ? row.sys_id : row.id)))
                  }
                }}>
                {props.isNested &&
                  <TableCell
                    onClick={() => {
                      if (!!props.specialPDFDownloadField) {
                        props.setNestedId(props.nestedId === row.sys_id ? null : row.sys_id);
                      }
                    }}
                    width={18}
                    className={classes.forcePointer}
                    key={`col_expand`}
                  >
                    {!(props.nestedId === row.sys_id) &&
                      <NavigateNext color="secondary" />
                    }
                    {props.nestedId === row.sys_id &&
                      <ExpandMore color="secondary" />
                    }

                  </TableCell>}
                {tableCols.map((col, ind) => {
                  if (col.fn === 'check') {
                    return <TableCell key={'check' + ind}>
                      {row[col.fn] ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                    </TableCell>
                  } else {
                    let displayData = props.analysisTable ? row[col.fn.split('.')[0]][col.fn.split('.')[1]] : row[col.fn];
                    return <TableCell
                      onClick={() => {
                        if (!!props.specialPDFDownloadField) {
                          props.setNestedId(props.nestedId === row.sys_id ? null : row.sys_id);
                        }
                      }}
                      key={`row_${ind}`}
                      style={props.noHeader ? { fontWeight: 'bold', cursor: !!props.specialPDFDownloadField ? 'pointer' : 'default' } : {}}
                      className={(col.displayRed && row.displayRed) ? classes.displayNegativeFeedback : ''}
                      align={props.columns[col.ind].align || 'left'}
                      component={ind === 0 ? "th" : ''}
                      scope="row"
                    >
                      {(typeof displayData === 'object') && !!displayData &&
                        <div style={{ display: 'flex' }}>
                          {displayData.color &&
                            <FiberManualRecordIcon style={{ fill: displayData.color, marginRight: 5 }} />
                          }
                          <Markup content={displayData.name || ''} />
                          {displayData.icon &&
                            <Launch style={{ cursor: 'pointer', marginLeft: 5, fill: '#222' }} onClick={(e) => {
                              e.preventDefault();
                              window.open(displayData.url, "_blank");
                            }} />
                          }
                        </div>

                      }
                      {!(typeof displayData === 'object') &&
                        <Markup content={displayData || ''} />
                      }
                    </TableCell>
                  }
                })}
              </TableRow>
            </React.Fragment>
          }
          )}
          {(props.rows.length === 0) && (
            <TableRow>
              <TableCell align='center' colSpan={props.columns.length + (props.isNested ? 1 : 0)}>
                {translate('', 'generic_table_body_no_match')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {
          !props.hideControls &&
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                colSpan={props.columns.length + (props.isNested ? 1 : 0)}
                count={props.count}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                labelRowsPerPage={translate('', 'generic_table_pagination_rows_per_page')}
                labelDisplayedRows={({ from, to, count }) => translate('', 'generic_table_pagination_totals').replace('##from##', from).replace('##to##', to).replace('##count##', count)}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={props.handleChangePage}
                onChangeRowsPerPage={props.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        }
      </Table>
    </TableContainer >
  );
}
export default TableComponent;