import React, { memo, useContext } from 'react';
import theme from './style';
import Button from '../../../components/Button';
import { Text, Chip } from '../../../components/DataDisplay';
import { Label, Check, LabelInput, InputField } from '../../../components/Inputs';
// import useTabsHook from './tabsHook';
import translate from '../../../functions/applyTranslation';
import clsx from 'clsx';
import { ActivitiesContext } from "../actitiesContext";
const ActivitiesTab4 = (props) => {
  const classes = theme();
  const activitiesContext = useContext(ActivitiesContext);
  const { tab4Data, setTab4Data, validator, handleBack, handleSubmit, validatorForm4, setValidator } = activitiesContext;
  const validateForm = () => {
    let res = validatorForm4(tab4Data);
    if (!res.length) {
      setValidator({ ...validator, tab4: [] })
      handleSubmit();
    } else {
      setValidator({ ...validator, tab4: res })
    }
  }
  const _validator = validator.tab4;
  const handleVisibilityChange = (id, value) => {
    let _visibility = JSON.parse(JSON.stringify(tab4Data.visibility));
    let index = tab4Data.visibility.findIndex(item => item === id);
    if (index > -1) {
      _visibility.splice(index, 1)
    } else {
      _visibility.push(id)
    }
    setTab4Data('visibility', _visibility)
  }
  const isVisibility = (id) => {
    return tab4Data.visibility.includes(id);
  }
  const handleKeywords = (value) => {
    let _keywords = JSON.parse(JSON.stringify(tab4Data.keywords));
    _keywords.push(value);
    setTab4Data('keywords', _keywords);
  }
  const handleKeywordRemove = (index) => {
    let _keywords = JSON.parse(JSON.stringify(tab4Data.keywords));
    _keywords.splice(index, 1);
    setTab4Data('keywords', _keywords);
  }
  return (
    <div className={classes.pageWrap}>
      <div>
        <div className={classes.inputContainer}>
          <Label text={translate('activities-edit', 'tab4_visibility_title')}>
            <Text text={translate('activities-edit', 'tab4_visibility_subtilte')} />
          </Label>
        </div>
    
        <div         >
          <Check invalid={_validator.includes('visibility')} value={isVisibility(1)} onChange={() => handleVisibilityChange(1)} label={translate('activities-edit', 'tab4_visibility_check_member_label')} />
          <Check invalid={_validator.includes('visibility')} value={isVisibility(2)} onChange={() => handleVisibilityChange(2)} label={translate('activities-edit', 'tab4_visibility_check_non_member_label')} />
        </div>
        <div className={classes.inputContainer}>
          <Label className={classes.paddingFix} text={translate('activities-edit', 'tab4_applicants_title')}>
            <Text text={translate('activities-edit', 'tab4_applicants_subtitle')} />
          </Label>
        </div>
        <div className={classes.inputGroup}>
          {tab4Data.keywords.map((item, index) =>
            <Chip key={index} id={index} label={item} className={clsx(classes.margin, classes.verticalMargin)} onClick={handleKeywordRemove} />
          )}
        </div>
        <LabelInput fullWidth
          onClick={handleKeywords}
          invalid={_validator.includes('keywords')}
        />
        {Number(tab4Data.activity_type) === 1 &&
          <Label className={classes.paddingFix} text={translate('activities-edit', 'tab4_notify_congregation_title')}>
            <InputField fullWidth multiline
              value={tab4Data.congregation_notification}
              onChange={(e) => setTab4Data('congregation_notification', e)}
            />
          </Label>
        }
        <Label className={classes.paddingFix} text={translate('activities-edit', 'tab4_what_now_title')}>
          <Text text={translate('activities-edit', 'tab4_what_now_subtitle')} />
        </Label>
      </div>
      <div className={classes.formFooter}>
        <Button label={translate('activities-edit', 'button_back')} onClick={handleBack} />
        <Button label={translate('activities-edit', 'button_finish_save')} fill onClick={validateForm} />
      </div>
    </div >
  );
}
export default memo(ActivitiesTab4);