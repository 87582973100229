import { useDispatch } from 'react-redux';
import { setLoading } from '../redux/states/statesActions';
import { setTranslations } from '../redux/settings/settingsActions';
import useAPI from '../api/apiHandler';
import { useState } from 'react';

const RouterHook = (props) => {
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const [count, setCount] = useState(0);

  const syncTranslations = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `translations/sync`,
      method: 'GET',
    }).then(res => {
      if (res) {
        fetchTranslations();
      }
      else {
        dispatch(setLoading(false))
      }
    })
  }

  const fetchTranslations = () => {
    setRequest({
      //FIXME: Switch to sweedish
      url: `translations?language=sv-SE`,
      //url: `translations?language=${process.env.NODE_ENV === 'development' ? 'en-US' : 'sv-SE'}`,
      method: 'GET',
    }).then(res => {
      if (res) {
        dispatch(setTranslations(res.data.context))
      }
      else if (process.env.NODE_ENV === 'development') {
        if (count === 0)
          syncTranslations();
        setCount(count + 1)
      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  }
  return { fetchTranslations };
}

export default RouterHook;