import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme'
const theme = (props) => {
  const styles = makeStyles(theme => ({

  }));
  return styles();
}
export default theme;

