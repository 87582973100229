import { makeStyles } from "@material-ui/core/styles";
const theme = () => {
  const styles = makeStyles(theme => ({
    header: {
      borderBottom: 'solid 1px #707070',
      paddingBottom: 20,
    },
    headerWrap: {
      marginBottom: 10,
      padding: '10px 0px',
    },
    infoDetail: {
      paddingBottom: 5,
    },
    titleWrap: {
      display: 'inline-flex',
      width: '100%',
      alignItems: 'center',
      '& >:nth-child(1)': {
        flexGrow: 1,
      }
    },
    buttonGroup: {
      '& >:not(:last-child)': {
        marginRight: 10,
      }
    },
    tabSelectorWrap: {
      width: '100%',
      margin: '20px 0px'
    },

    headerCheckGroupWrap: {
      display: 'grid',
    },
    headerSelectWrap: {
      // padding: '0px 10px'
    },
    headerLabelWrap: {
      textAlign: 'right'
    },

  }));
  return styles();
}
export default theme;

