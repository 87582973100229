import React, { memo, useState } from 'react';
import theme from './style';
import { Grid, } from '@material-ui/core';
import Button from '../../components/Button';
import { ListItem, SmallTitle } from '../../components/DataDisplay';
import translate from '../../functions/applyTranslation';
import usePermissionsHook from '../../functions/permissions';
import clsx from 'clsx';
import { Fragment } from 'react';
import { momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import useHook from './hooks/profile';
import { useHistory } from 'react-router-dom'
import { InputField } from '../../components/Inputs'

const ProfileList = (props) => {
  const history = useHistory();
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const localizer = momentLocalizer(moment)
  const classes = theme();
  const [data, setFilters, handleNavigation, handlePagination, search, clearSearch, onFormSubmit] = useHook();
  const { rows, filters, settings } = data;
  const [searchText, setSearchText] = useState('')
  const [expanded, setExpanded] = useState()
  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (!hasPermission(permissions.USERS_ACCESS))
    history.push('/');

  return (
    <>
      <div className={classes.pageWrap}>
        <div className={classes.headerWrap}>
          <div className={classes.titleWrap}>
            <SmallTitle text={translate('profile-list', 'title_profile_list')} />
          </div>
        </div>
        <Grid container>
          <Grid item xs={12}>
            <form onSubmit={onFormSubmit}>
              <div className={classes.search}>


                <InputField className={classes.searchInput} value={searchText} placeholder={translate('security', 'placeholder_search')} onChange={(e) => { setSearchText(e) }} />

                <div className={classes.searchActions}>
                  <Button type={'submit'} className={`${classes.searchButton}`} label={translate('security', 'button_search')} onClick={() => search(searchText)} />
                  <Button type={'reset'} className={`${classes.searchClearButton}`} label={translate('security', 'button_search_clear')} onClick={() => { setSearchText(); clearSearch(); }} />
                </div>
              </div>
            </form>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.newsList}>
              <Fragment>
                <Grid container className={classes.newsWrap}>
                  {rows.map(item =>
                    <Grid key={item.id} item xs={12} className='item'>
                      <ListItem viewType={'profile'} path='profile' item={item} onClick={handleNavigation} expanded={expanded} handleExpanded={handleExpanded} />
                    </Grid>
                  )}
                </Grid>
                <div className={clsx(classes.flexEnd, classes.paginatorLabel)}>
                  {/* {translate('profile-list', 'label_page_counter').replace('##page##', filters.page).replace('##total##', settings.totalPages)} */}
                </div>
                <div className={
                  filters.page !== 1 && filters.page !== settings.totalPages ? classes.spaceBetween :
                    filters.page === 1 ? classes.flexEnd : classes.flexStart
                }>
                  {filters.page !== 1 &&
                    <Button label={translate('profile-list', 'button_page_back')} fill onClick={(e) => handlePagination('back')} />
                  }
                  {!!settings.totalPages && filters.page !== settings.totalPages &&
                    <Button label={translate('profile-list', 'button_page_next')} fill onClick={(e) => handlePagination('next')} />
                  }
                </div>
              </Fragment>
            </div>
          </Grid>
        </Grid >
      </div>
    </>
  );
}

export default memo(ProfileList);