import { useState, useEffect } from 'react';
import useAPI from '../api/apiHandler';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../redux/states/statesActions';
import { useParams, useHistory } from 'react-router-dom';

const TranslationHook = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const loading = useSelector(state => state.states.loading);
  const [setRequest] = useAPI();
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);

  const fetchData = (filter = '') => {
    // dispatch(setLoading(true))
    // setRequest({
    //   url: `translations${filter}`,
    //   method: 'GET',
    // }).then(res => {
    //   if (res) {
    //     console.log(res);
    //     alert(1)
    //     setData(res.data || [])
    //     setCount(res.total_records)
    //   }
    //   dispatch(setLoading(false))
    // })
  }

  const handleActions = (type) => {
    switch (type) {
      default:
        break;
    }
  }
  return [data, count, loading, fetchData, handleActions];
}

export default TranslationHook;