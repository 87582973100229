import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    viewTypesWrap: {
      display: 'inline-flex',
      '& svg': {
        cursor: 'pointer',
        color: ColorSchemes.mediumBlue,
        fontSize: 26,
      },
      '& .selected': {
        color: ColorSchemes.orange,
      }
    },
  }));
  return styles();
}
export default theme;
