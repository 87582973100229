import React from 'react';
import theme from './style';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import clsx from 'clsx';
import translate from '../../../functions/applyTranslation'
const Button = (props) => {
  const classes = theme();
  const {
    id,
    onChange,
    fullWidth,
    invalid,
    data,
    disabledItems,
    selected,
    placeholder,
    className
  } = props;

  const handleChange = (e) => {
    e.stopPropagation()
    if (onChange) {
      onChange(e.target.value)
    }
  }
  return (
    <div className={
      clsx(
        classes.selectGroup,
        fullWidth ? classes.fullWidth : '',
        invalid ? classes.invalid : '',
        className || ''
      )}
    >
      {(!selected || selected.length === 0) &&
        <InputLabel className={classes.placeholder}>{placeholder || translate('', 'placeholder_select')}</InputLabel>
      }
      <Select
        id={`${id}`}
        className={`${classes.select}`}
        value={selected || []}
        multiple

        onChange={handleChange}
      >
        {/* <MenuItem value={null} disabled > <em> {placeholder || translate('', 'placeholder_select')}</em> </MenuItem> */}
        {data && data.map(item => {
          return <MenuItem disabled={(disabledItems || []).includes(item.id)} key={item.id} value={item.id}>{item.title}</MenuItem>
        })}
      </Select>
    </div >
  );
}

export default Button;