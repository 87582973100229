import React from 'react';
import theme from './style';
import clsx from 'clsx';
import ClearIcon from '@material-ui/icons/Clear';
import { Typography } from '@material-ui/core';
const DatePicker = (props) => {
  const classes = theme();
  const {
    id,
    onClick,
    fullWidth,
    label,
    className,
  } = props;

  const handleClick = () => {
    if (onClick) {
      onClick(id)
    }
  }
  return (
    <div  className={
      clsx(className,
        classes.chipGroup,
        fullWidth ? classes.fullWidth : '',
      )}
    >
      <Typography className={classes.chipText}>{label}</Typography>
      <ClearIcon className={classes.chipIcon} onClick={handleClick} />
    </div >
  );
}
DatePicker.propTypes = {
}


export default DatePicker;