import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const styles = makeStyles(theme => ({
    image: {
      maxHeight: 200,
      maxWidth: 200,
    },
    dialogTitle: {
      paddingBottom: 5
    },
    marginRight: {
      marginRight: 10
    },
    fileTooBigText: {
      color: "#ff3333 !important"
    }
  }));
  return styles();
}
export default theme;
