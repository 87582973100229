import React from 'react';
import theme from './style';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import clsx from 'clsx';
import translate from '../../../functions/applyTranslation'
const Button = (props) => {
  const classes = theme();
  const {
    onChange,
    fullWidth,
    invalid,
    data,
    disabledItems,
    selected,
    placeholder,
    className
  } = props;

  const handleChange = (e) => {
    e.stopPropagation()
    if (onChange) {
      onChange(e.target.value)
    }
  }
  return (
    <div className={
      clsx(
        classes.selectGroup,
        fullWidth ? classes.fullWidth : '',
        invalid ? classes.invalid : '',
        className || ''
      )}
    >
      {(!selected) &&
        <InputLabel className={classes.placeholder}>{placeholder || translate('', 'placeholder_select')}</InputLabel>
      }
      <Select
        className={`${classes.select}`}
        value={selected}
        onChange={handleChange}
      >
        <MenuItem value="null" disabled style={{ display: 'none' }}> <em> {placeholder || translate('', 'placeholder_select')}</em> </MenuItem>
        {data && data.map(item => {
          return <MenuItem disabled={item.id === selected || (disabledItems || []).includes(item.id)} key={item.id} value={item.id}>{item.title || item.name}</MenuItem>
        })}
      </Select>
    </div >
  );
}

export default Button;
