import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
    const styles = makeStyles(theme => ({
        main: {
            width: "100%",
            display: 'inline-block',
            cursor: 'pointer',
            maxWidth: "100%",
            '&.readOnly': {
                cursor: 'default',
            }
        },
        title: {
            paddingTop: 5,
            width: '100%',
            fontSize: '16px',
            lineHeight: '19px',
            color: ColorSchemes.black
        },
        content: {

            width: '100%',
            fontSize: '14px',
            lineHeight: '22px',
            color: ColorSchemes.black,
            '&.collapsed': {
                color: '#888',
                display: '-webkit-box',
                '-webkit-line-clamp': 3,
                '-webkit-box-orient': 'vertical',
                maxHeight: '66px',
                overflow: 'hidden',
            },
            '& p': {
                marginBlockStart: 0,
                marginBlockEnd: 0,
            },
            '& img': {
                maxWidth: "100%"
            }
        },
    }));
    return styles();
}
export default theme;
