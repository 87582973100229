import React from 'react';
import theme from './style';
import clsx from 'clsx';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Button from '../../Button';
import { Label, Select, } from '../../Inputs'
import translate from '../../../functions/applyTranslation';
import { Grid } from '@material-ui/core'
const compare = (a, b) => {
  const itemA = Number(a.page.sort_order);
  const itemB = Number(b.page.sort_order);
  let comparison = 0;
  if (itemA > itemB) {
    comparison = 1;
  } else if (itemA < itemB) {
    comparison = -1;
  }
  return comparison;
}

const TreeViewOrganizer = (props) => {
  const classes = theme();
  const {
    items,
    handleReorder, rasterizedMenu,
    newParentID, setNewParentId,
    onParentChange,
    selected, setSelected
  } = props;
  const ListItem = (item, level, path, length, first, last) => {
    JSX.push(
      <div key={item.page.id} className={clsx(classes.listItem,)}>
        <div onClick={() => handleSelect(path)}>
          {isSelected(path) ?
            <CheckBoxIcon className={classes.check} /> :
            <CheckBoxOutlineBlankIcon className={classes.check} />}
          <p
            style={{
              width: 'calc(100% -40px)',
              paddingLeft: 15 * (level - 1) + 30
            }}

          >
            {item.page.title}
          </p>
        </div>
        {length > 1 &&
          <div>
            {!first &&
              <ArrowDropUpIcon className={classes.arrowUp} onClick={() => handleReorder(path, item, 'up')} />
            }
            {!last &&
              <ArrowDropDownIcon className={classes.arrowDown} onClick={() => handleReorder(path, item, 'down')} />
            }
          </div>
        }
      </div>
    )
  }
  const handleSelect = (path) => {
    setNewParentId();
    setSelected(isSelected(path) ? [] : path)
  }

  const isSelected = (path) => {
    let matchArr = true;
    if (path.length !== selected.length) {
      matchArr = false;
    } else {
      path.forEach((item, index) => {
        if (selected[index] !== item) {
          matchArr = false;
        }
      })
    }
    return matchArr
  }
  let JSX = [];
  const generateMenu = (arr, level, path) => {
    level++;
    if (arr) {
      arr.sort(compare).forEach((item, index) => {
        ListItem(
          item,
          level,
          [...path, item.page.id],
          arr.length,
          index === 0,
          index === arr.length - 1
        );
        if (item.children.length > 0) {
          generateMenu(item.children, level, [...path, item.page.id])

        }
      })
    }
  }
  generateMenu(items, 0, []);

  const returnChildrenIds = (arr, matchedIds) => {
    let newMatchIds = []
    let _arr = JSON.parse(JSON.stringify(arr));
    let newArr = _arr.filter(item => {
      if (!matchedIds.includes(item.parent_id) && !matchedIds.includes(item.id)) {
        return true;
      } else {
        newMatchIds.push(item.id)
        return false;
      }
    })
    if (newArr.length === arr.length) {
      return false;
    } else {
      return {
        array: newArr,
        children: newMatchIds,
      }
    }
  }
  const removeChidlrenFromSelectList = (arr) => {
    let childrenIds = [selected[selected.length - 1]];
    let incomplete = true;
    let clearedArr = arr;
    do {
      let res = returnChildrenIds(clearedArr, childrenIds);
      if (res) {
        childrenIds = [...childrenIds, ...res.children];
        clearedArr = res.array;
      } else {
        incomplete = false;
      }
    } while (incomplete);
    return clearedArr;
  }
  return <div className={classes.wrapItem}>
    <Grid container>
      <Grid item xs={12} sm={6} >
        {JSX}
      </Grid>
      {selected && selected.length > 0 &&
        <Grid item xs={12} sm={6} style={{ padding: '0px 10px' }}>
          <div>
            <Label text={translate('information', 'label_change_parent')}>
              <Select icon='save'
                fullWidth
                data={removeChidlrenFromSelectList(rasterizedMenu)}
                onChange={setNewParentId}
                selected={
                  newParentID || rasterizedMenu.find(item => item.id === selected[selected.length - 1]).parent_id
                } />
            </Label>
          </div>
          <div style={{ marginTop: 10 }}>
            <Button fullWidth disabled={!newParentID} label={translate('', 'button_confirm')} fill onClick={
              () => {
                onParentChange(selected)
              }
            } />
          </div>

        </Grid>
      }
    </Grid>
  </div>
}

export default TreeViewOrganizer;