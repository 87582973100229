import React from 'react';
import theme from './style';
import parse from 'html-react-parser';
import {useSelector} from "react-redux";

const BlogBody = (props) => {
    const classes = theme();
    const { access_token } = useSelector(state => state.auth);
    const {
        id,
        title,
        content,
        collapsed,
        path,
        item,
        onClick
    } = props;
    const handleClick = () => {
        if (onClick) {
            onClick({ path, item, action: 'detail', value: id })
        }
    }

    const tryParse=(value)=>{
        let result = value;

        if(value) {
            result = value.replace(/token=(.*?)"/g, `token=${access_token}"`);
            result = result.replace(/token=(.*?)&quot;/g, `token=${access_token}&quot;`);
        }

        let body = parse(result || '');
        try {
            body = parse(body)
        }
        catch (e) {

        }
        return body;
    }

    return (

        <div className={`${classes.main} ${!onClick ? 'readOnly' : ''}`} onClick={handleClick}>
            <div className={classes.title}>
                {title}
            </div>
            <div className={`${classes.content} ${collapsed ? 'collapsed' : ''}`}>
                {tryParse(content)}
            </div>
        </div >
    );
}

export default BlogBody;