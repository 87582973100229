
import React, { createContext, useEffect, useState } from "react";
import useAPI from '../../api/apiHandler';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/states/statesActions';
import { useParams, useHistory } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import translate from "../../functions/applyTranslation";
export const BookingContext = createContext({});
const stripePromise = loadStripe("pk_test_51HziLZK7JTNQ6NQUAUxEi4rJJTofvNWeHzBDKONJ8sUU6gkLlNg2GFTD7g6lZm2K0n6UoUI8icmPhJ1WB7NT2Yji00AYgSrfFJ");

export const Provider = props => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.userData);
  const params = useParams();
  const [setRequest] = useAPI();
  const { id } = params;
  const history = useHistory();
  const [ticketsMember, setTicketsMember] = useState()
  const [ticketsNonMember, setTicketsNonMember] = useState()
  const [data, setData] = useState()
  const [products, setProcuts] = useState([])
  const [comments, setComments] = useState([]);
  const [agreementTC, setAgreementTC] = useState(false);
  const [ticketsSold, setTicketsSold] = useState([]);
  const [ticketUsersMember, setTicketUsersMember] = useState({
    adult: [],
    child: [],
    group: [],
  })
  const [ticketUsersNonMember, setTicketUsersNonMember] = useState({
    adult: [],
    child: [],
    group: [],
  })

  const [modal, setModal] = useState({ key: '', users: [] })
  const [guides, setGuides] = useState([]);
  const [mailLogs, setMailLogs] = useState([]);
  const [guide, setGuide] = useState("");
  const [guideSaveSuccess, setGuideSaveSuccess] = useState(false);
  const [guideData, setGuideData] = useState({});
  const [eventTypes, setEventTypes] = useState([]);

  const closeModal = () => {
    setModal();
  }
  const openAddManual = () => {
    setModal({ key: 'add'});
  }

  const openEmailSelected = (options) => {
    setModal({ key: 'email', users: options.users });
  }

  const submitAddManual= (submitData) => {
    dispatch(setLoading(true))
    var body = new FormData();
    body.append('event_id', id);
    body.append('users[0][name]', submitData.name);
    body.append('users[0][ssn]', submitData.ssn);
    body.append('users[0][message]', submitData.comment);
    setRequest({
      url: `event/user`,
      method: 'POST',
      data: body,
    }).then(res => {
      if (res) {
        fetchSoldTickets();
      }
      setModal()
      dispatch(setLoading(false))
    })
  }

  const submitEmailSelected = (submitData) => {
    dispatch(setLoading(true))
    var body = new FormData();
    body.append('notification_type', 'event_info');
    body.append('content_type', 'event');
    body.append('content_id', id);
    body.append('extra[custom_msg]', submitData.text);
    submitData.users.forEach((user, index) => {
      body.append(`users[${index}][id]`, user.id);
      body.append(`users[${index}][mail]`, user.email);
    })
    setRequest({
      url: `notifications/send`,
      method: 'POST',
      data: body,
    }).then(res => {
      if (res) {
        setModal({ key: 'email_sended'})
      }
      else
        setModal()
      dispatch(setLoading(false))
    })
  }

  const fetchEventDetail = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `events?limit=1&page=1&event_id=${id}&cal=1`,
      method: 'GET',
    }).then(res => {
      if (res && res.data) {
        let _data = res.data.content[0];
        setProcuts(_data.products.map(item => { return { ...item, title: item.name } }))
        setData(_data)
        setGuide(_data.jfm_event.tour_guide || "0")
      }
      dispatch(setLoading(false))
    })
  }
  const fetchSoldTickets = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `event/users?event_id=${id}`,
      
      method: 'GET',
    }).then(res => {
      if (res && res.data) {
        let keys = Object.keys(res.data);
        let data = res.data;
        let _data = keys.map(key => {
          return {
            id: key,
            ...data[key],
          }
        })

        setTicketsSold(_data);
      }
      dispatch(setLoading(false))
    })
  }
  const updateMemberTickets = () => {
    let userticket = JSON.parse(JSON.stringify(ticketUsersMember));
    ticketsMember && Object.keys(ticketsMember).map(item => {
      let { id, quantity } = ticketsMember[item];
      let product = products.find(item => item.id === id);
      if (quantity > userticket[item].length) {
        userticket[item].push({
          productName: product.name,
          name: '',
          ssn: '',
          ticket: id,
          member: 1,
        })
      } else if (quantity < userticket[item].length) {
        userticket[item] = userticket[item].slice(0, quantity);
      }
    })
    setTicketUsersMember(userticket);
  };
  const updateNonMemberTickets = () => {
    let userticket = JSON.parse(JSON.stringify(ticketUsersNonMember));
    ticketsNonMember && Object.keys(ticketsNonMember).map(item => {
      let { id, quantity } = ticketsNonMember[item];
      let product = products.find(item => item.id === id);
      if (quantity > userticket[item].length) {
        userticket[item].push({
          productName: product.name,
          name: '',
          ssn: '',
          ticket: id,
          member: 0,
        })
      } else if (quantity < userticket[item].length) {
        userticket[item] = userticket[item].slice(0, quantity);
      }
    })
    setTicketUsersNonMember(userticket);
  };

  const fetchAllGuides = () => {
    dispatch(setLoading(true))
    setRequest({
      url: 'users/tour_guides',
      method: 'GET'
    })
      .then(res => {
        if (res) {
          let unhandled = {id: "0", name: translate('booking', 'select_guide_unhandled')}
          let _guides = res.data;
          _guides.unshift(unhandled);
          setGuides(_guides);
        }
        dispatch(setLoading(false))
      })
      .catch(() => dispatch(setLoading(false)))
  };

  const fetchMailLogs = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `mail/mailLogs?event_id=${id}`,
      method: 'GET',
    })
      .then(res => {
        if (res) {
          let _mailLogs = res.data;
          setMailLogs(_mailLogs);
        }
        dispatch(setLoading(false))
      })
      .catch(() => dispatch(setLoading(false)))
  };

  const handleSendMail = (mailContent) => {
    dispatch(setLoading(true));
    var formData = new FormData();
    formData.append("event_id", id);
    formData.append('email', data.jfm_event.alt_email);
    formData.append("tour_guide", guide === "0" ? null : guide);
    formData.append("mail_content", mailContent);

    setRequest({
      url: "mail/send",
      method: "POST",
      data: formData,
    })
      .then(res => {
        dispatch(setLoading(false));
        fetchMailLogs();
      })
      .catch(err => {
        dispatch(setLoading(false));
        console.log(err);
      })
  }

  const submitGuideChange = (mailContent) => {
    dispatch(setLoading());
    let formData = new FormData();
    formData.append("event_id", id);
    formData.append("user_id", guide === "0" ? "0" : guide);
    formData.append("update_origin", "bookingPageDropdown");
    formData.append("send_mail", true);
    formData.append("mail_content", mailContent);

    setRequest({
      url: "event/tour_guide",
      method: "POST",
      data: formData,
    })
      .then(res => {
        setGuideSaveSuccess(true);
        dispatch(setLoading(false));
        fetchEventDetail();
      })
      .catch(err => {
        dispatch(setLoading(false))
        console.log(err)
      })
  }

  useEffect(() => { updateMemberTickets() }, [ticketsMember])
  useEffect(() => { updateNonMemberTickets() }, [ticketsNonMember])
  useEffect(() => { fetchEventDetail() }, [])
  useEffect(() => { fetchSoldTickets() }, [])
  useEffect(() => { fetchAllGuides() }, [])
  useEffect(() => { fetchMailLogs() }, [])
  useEffect(() => { fetchEventTypes() }, [])

  const handleChangeMember = (type, index, value) => {
    let ticketUsers = JSON.parse(JSON.stringify(ticketUsersMember));
    ticketUsers[type][index].name = value;
    setTicketUsersNonMember(ticketUsers);
  }
  const handleChangeNonMember = (type, index, value, key) => {
    let ticketUsers = JSON.parse(JSON.stringify(ticketUsersNonMember));
    ticketUsers[type][index][key] = value;
    setTicketUsersNonMember(ticketUsers);
  }

  const removeUser = (registerId) => {
    dispatch(setLoading(true))
    setRequest({
      url: `event/user?event_id=${id}&register_id=${registerId}`,
      method: 'DELETE',
    }).then(res => {
      if (res) {
        fetchSoldTickets();
      }
      dispatch(setLoading(false))
    })
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      // setMessage("Order placed! You will receive an email confirmation.");
      alert('success')
    }
    if (query.get("canceled")) {
      alert('cancel')
      // setMessage(
      //   "Order canceled -- continue to shop around and checkout when you're ready."
      // );
    }
  }, []);

  const handleBack = () => {
    history.goBack();
  }

  const handleSecurity = () => {
    history.push(`/activities/${id}/check-list`)
  }

  const handleCheckOut = async (event) => {
    const stripe = await stripePromise;
    var formdata = new FormData();
    formdata.append("event_id", id);
    let count = 0;
    ['adult', 'child', 'group'].forEach(key => {
      ticketUsersMember[key].forEach(item => {
        count++
        formdata.append(`items[${count}][product_id]`, item.ticket);
        formdata.append(`items[${count}][name]`, item.name);
      })
      ticketUsersNonMember[key].forEach(item => {
        count++
        formdata.append(`items[${count}][product_id]`, item.ticket);
        formdata.append(`items[${count}][name]`, item.name);
        formdata.append(`items[${count}][ssn]`, item.ssn);
      })
    })
    const session = await setRequest({
      url: 'checkout/order',
      method: 'POST',
      data: formdata
    }).then(res => {
      if (res) {
        stripe.redirectToCheckout({
          sessionId: res.data.stripe_id,
        });
      }
    })
  };

  const fetchGuideData = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `user/profile?user_id=${data.jfm_event.tour_guide}`,
      method: 'GET',
    }).then(res => {
      if (res && res.data) {
        setGuideData({
          name: res.data.name,
          phoneNr: res.data.phone,
          email: res.data.mail,
        });
      }
      dispatch(setLoading(false))
    })
  };

  const fetchEventTypes = () => {
    dispatch(setLoading(true));
    setRequest({
      url: 'events/event_types',
      method: 'get'
    })
      .then(res => {
        if(res && res.data) {
          setEventTypes(res.data)
        }
        setLoading(false);
      })
      .catch(e => setLoading(false))
  }

  const _bookingContext = {
    data,
    ticketsMember,
    ticketsNonMember,
    ticketsSold,
    products,
    comments,
    setComments,
    setTicketsMember,
    setTicketsNonMember,
    ticketUsersMember,
    ticketUsersNonMember,
    handleChangeMember,
    handleChangeNonMember,
    agreementTC, setAgreementTC,
    removeUser,
    handleCheckOut,
    handleBack,
    handleSecurity,
    fetchSoldTickets,
    modal,
    closeModal,
    openAddManual,
    openEmailSelected,
    submitAddManual,
    submitEmailSelected,
    userData,
    guides,
    guide,
    setGuide,
    submitGuideChange,
    handleSendMail,
    guideSaveSuccess,
    fetchGuideData,
    guideData,
    fetchAllGuides,
    fetchMailLogs,
    mailLogs,
    eventTypes
  };
  return <BookingContext.Provider value={_bookingContext}>{props.children}</BookingContext.Provider>;
};

export const { Consumer } = BookingContext;