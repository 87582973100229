import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme'
const theme = (props) => {
  const styles = makeStyles(theme => ({
    viewContainer: {
      position: 'relative',
      minHeight: '100vh',
      backgroundColor:ColorSchemes.white,
    },
    contentWrap: {
      padding: 0,
      paddingTop: 75,
    },
    gridContainer: {
      justifyContent: 'center',
      border: 'none !important'
    },
    paperContainer: {
      padding: '20px 28px',
      boxShadow: 'none !important'
    }
  }));
  return styles();
}
export default theme;

