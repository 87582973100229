import translate from '../functions/applyTranslation';
const Status = () => {
    const list = [
        {
            id: 1,
            title: translate('', 'label_status_unhandled') //'Ohanterad'
        },
        {
            id: 2,
            title: translate('', 'label_status_approved') //'Godkänd för publicering'
        },
        {
            id: 3,
            title: translate('', 'label_status_denied') //'Nekad'
        },
        {
            id: 4,
            title: translate('', 'label_status_unpublished') //'Avpublicerad'
        },
    ]

    const filter = [
        {
            id: -1,
            title: translate('', 'label_status_filter_all') //'Samtliga'
        },
        {
            id: 2,
            title: translate('', 'label_status_filter_published') //'Endast publicerade'
        },
        {
            id: 1,
            title: translate('', 'label_status_unhandled') //'Alla ohanterade'
        },
        {
            id: 3,
            title: translate('', 'label_status_denied') //'Nekad'
        },
    ]

    const applyFilter = (status) => {
        switch (status) {
            case -1:
                return null;
            default:
                return status;
        }
    }

    return [list, filter, applyFilter];
}

export default Status;