import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    selectGroup: {
      display: 'inline-flex',
      height: 35,
      // paddingLeft: 6,
      position: 'relative',
      '& ::after': {
        border: 'none !important'
      },
      '& ::before': {
        border: 'none !important'
      },
      '& .MuiInput-root': {
        borderColor: ColorSchemes.highDarkGrey,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 3,
        flexGrow: 1,
      },
      '& .MuiSelect-select': {
        //paddingLeft: 6,
        background: 'none',
        // fontSize: 14,
        // paddingTop: 8,
        // paddingBottom: 8,
        // color: ColorSchemes.lowDarkGrey,
        fontSize: 14,
        padding: '2px 10px',
        color: ColorSchemes.darkGrey,
      },
      '& .MuiSelect-icon': {
        top: 'calc(50% - 13px)'
      },

    },
    invalid: {
      '& .MuiInput-root': {
        borderColor: `${ColorSchemes.darkOrange} !important`,
        borderWidth: '3px'
      },
    },
    fullWidth: {
      width: '100%'
    },
    select: {
      maxWidth: '100%',
    },
    placeholder: {
      position: 'absolute',
      color: '#C3C3C3',
      padding: '10px',
      fontSize: '14px'
    }
  }));
  return styles();
}
export default theme;
