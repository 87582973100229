import {useState, memo, useEffect} from 'react';
import useSettingsHook from '../../../functions/settings';
import usePermissionsHook from "../../../functions/permissions";

const FiltersHook = () => {
  const [role, hasSetting] = useSettingsHook();
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const [filter, setFilter] = useState({
    page: !!sessionStorage.getItem("aLP") ? Number(JSON.parse(sessionStorage.getItem("aLP"))) : 1,
    limit: 6,
    status_id: !!sessionStorage.getItem('aLS') ? Number(JSON.parse(sessionStorage.getItem('aLS'))) : (hasPermission(permissions.APPLICANT_ACCESS) ? -1 : -1),
    viewSynagogue: !!sessionStorage.getItem("aLChckSynagogue") ? Number(JSON.parse(sessionStorage.getItem("aLChckSynagogue"))) : 1,
    viewMeeting: !!sessionStorage.getItem("aLChckMeeting") ? Number(JSON.parse(sessionStorage.getItem("aLChckMeeting"))) : 1,
    viewEvent: !!sessionStorage.getItem("aLChckEvent") ? Number(JSON.parse(sessionStorage.getItem("aLChckEvent"))) : 1,
    viewOld: !!sessionStorage.getItem("aLChckOld") ? Number(JSON.parse(sessionStorage.getItem("aLChckOld"))) : 0,
  })

  const setFilters = (key, value) => {
    if (key === "status_id") {
      setFilter({...filter, "page": 1, "status_id": value});
      sessionStorage.setItem("aLP", JSON.parse(1));
      sessionStorage.setItem('aLS', JSON.parse(value));
    } else {
      setFilter({...filter, [key]: value});
    }
  }

  useEffect(() => {
    sessionStorage.setItem("aLP", JSON.parse(filter.page));
    sessionStorage.setItem('aLS', JSON.parse(filter.status_id));
    sessionStorage.setItem("aLChckSynagogue", JSON.parse(filter.viewSynagogue));
    sessionStorage.setItem("aLChckMeeting", JSON.parse(filter.viewMeeting));
    sessionStorage.setItem("aLChckEvent", JSON.parse(filter.viewEvent));
    sessionStorage.setItem("aLChckOldP", JSON.parse(filter.viewOld));
  }, []);

  return [filter, setFilters];
}

export default FiltersHook;
