export const validatorForm1 = (data) => {
  const {
    //nulls
    booking_organization_type,
    booking_type,
    room_time_from,
    room_time_to,
    event_time_from,
    event_time_to,
    room_id,
    //string
    categorize,
    other_room
  } = data;

  let formValid = [];
  if ((Number(booking_organization_type) === 1 && (!booking_type || !booking_type.length))) {
    formValid.push('booking_type')
  }
  if (!room_time_from) {
    formValid.push('room_time_from')
  }
  if (!room_time_to) {
    formValid.push('room_time_to')
  }
  if (!event_time_from) {
    formValid.push('event_time_from')
  }
  if (!event_time_to) {
    formValid.push('event_time_to')
  }
  if (!room_id) {
    formValid.push('room_id')
  }
  if (Number(room_id) === -1 && (!other_room || !other_room.length)) {
    formValid.push('other_room')
  }
  if (!categorize) {
    formValid.push('categorize')
  }

  return formValid;
}
export const validatorForm2 = (data) => {
  const {
    //nulls
    activity_type,
    image,
    //string
    title,
    content,
  } = data;

  let formValid = [];
  if (Number(activity_type) === 1 && !image) {
    formValid.push('image')
  }
  if (!title.length) {
    formValid.push('title')
  }
  if (!content.length) {
    formValid.push('content')
  }
  return formValid;
}
export const validatorForm3 = (data) => {
  const {
    //nulls
    activity_type,
    registration_required,
    participants_number,
    registration_start_date,
    registration_end_members,
    registration_end_non_members,
    payment_required,
    prices_adult_member,
    prices_adult_non_member,
    prices_children_member,
    prices_children_non_member,
    prices_children_age_from,
    prices_children_age_to,
    amount_member,
    amount_non_member,
  } = data;

  let formValid = [];
  if (!participants_number) {
    formValid.push('participants_number')
  }
  if (Number(activity_type) === 1 && registration_required) {
    if (!registration_start_date) {
      formValid.push('registration_start_date')
    }
    if (!registration_end_members) {
      formValid.push('registration_end_members')
    }
    if (!registration_end_non_members) {
      formValid.push('registration_end_non_members')
    }
  }
  if (Number(activity_type) === 1 && payment_required) {
    if (!prices_adult_member) {
      formValid.push('prices_adult_member')
    }
    if (!prices_adult_non_member) {
      formValid.push('prices_adult_non_member')
    }

    if (!prices_children_member) {
      formValid.push('prices_children_member')
    }
    if (!prices_children_non_member) {
      formValid.push('prices_children_non_member')
    }
    if (!prices_children_age_from) {
      formValid.push('prices_children_age_from')
    }
    if (!prices_children_age_to) {
      formValid.push('prices_children_age_to')
    }

    if (!amount_member) {
      formValid.push('amount_member')
    }
    if (!amount_non_member) {
      formValid.push('amount_non_member')
    }
  }
  return formValid;
}
export const validatorForm4 = (data) => {
  const {
    //arrays
    visibility,
    keywords,
    //string
  } = data;

  let formValid = [];
  if (!visibility.length) {
    formValid.push('visibility')
  }
  // if (!keywords.length) {
  //   formValid.push('keywords')
  // }
  return formValid;
}