
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import { InputField } from '../../../components/Inputs';
import Button from '../../../components/Button';
import translate from '../../../functions/applyTranslation';
import theme from './style';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function EmailSended(props) {
  const { modal, closeModal } = props;
  const classes = theme();
  const handleClose = () => {
    closeModal();
  };
  return (
    <div>
      <Dialog
        open={modal === 'email_sended'}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>
          {translate('booking', 'title_email_sended')}
          <small>{translate('booking', 'subtitle_email_sended')}</small>
        </DialogTitle>
        <DialogActions>
          <Button fill onClick={handleClose} label={translate('', 'button_cancel')} />
        </DialogActions>
      </Dialog>
    </div>
  );
}
