import {makeStyles} from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme'

const theme = (props) => {
  const styles = makeStyles(theme => ({
    buttonWrap: {
      paddingBottom: 10,
      marginBottom: 10,
      borderBottom: `1px solid ${ColorSchemes.highDarkGrey}`,
      '& >:not(:last-child)': {
        marginRight: 20,
      }
    },
    headerWrap: {
      fontSize: 13,
      marginBottom: 20,
      '& svg': {
        height: 14,
        width: 14,
        marginRight: 7,
        fill: ColorSchemes.mediumBlue
      },
      '& .marginNoIcon': {
        paddingLeft: 20,
        paddingBottom: 5,
      },
      '& .marginGroup': {
        paddingTop: 5,
        paddingBottom: 5,
      },
      '& .customMargin': {
        paddingTop: 5,
      },
      '& .button': {
        cursor: 'pointer',
        marginTop: 5,
        marginBottom: 5,
        background: ColorSchemes.green,
        color: ColorSchemes.white,
        borderRadius: 3,
        paddingTop: 10,
        paddingBottom: 10,
        fontWeight: 'bold',
        textAlign: 'center'
      }
    },
    subHeaderWrap: {
      marginBottom: 20,
    },
    soldTicketsWrap: {
      marginBottom: 20,
    },
    productsWrap: {
      '& .singleline': {
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
      },
      '& .circle': {
        height: 15,
        width: 15,
        backgroundColor: ColorSchemes.orange,
        borderRadius: '50%',
        marginRight: 5,
      },
      '& p': {
        margin: 0,
        padding: 0
      },
      '& .circleText': {
        fontWeight: 'bold',
        fontSize: 12,
      }
    },
    cartWrap: {
      margin: '0px 10px',
      padding: 15,
      backgroundColor: '#F2F5FC',
      border: `1px solid #8B8B8B`,
      '& h4': {
        marginTop: 0,
        marginBottom: 20,
        fontSize: 14,
        fontWeight: 'bold'
      },
      '& .row': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 13,
      },
      '& p': {
        margin: '5px 0px',
        fontSize: 13,
      },
      '& b': {
        margin: '15px 0px 5px 0px',
      },
    },
    triangleWrap: {
      marginTop: -1,
      height: 30,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center'
    },
    triangle: {
      // position:'absolute',
      marginTop: '-20px',
      backgroundColor: '#F2F5FC',
      borderBottom: `1px solid #8B8B8B`,
      borderRight: `1px solid #8B8B8B`,
      height: 40,
      width: 40,
      transform: 'rotate(45deg)'
    },
    usersTicketWrap: {
      paddingTop: 10,
      '& .MuiGrid-item': {
        padding: 3
      }
    },
    alignFlexEnd: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    checkText: {
      '& p': {
        color: ColorSchemes.darkBlue
      }
    },
    checkUnderText: {
      fontSize: 12,
      color: ColorSchemes.darkBlue
    },
    error: {
      backgroundColor: ColorSchemes.red,
      padding: 10,
      marginBottom: 10,
    },
    disclaimer: {
      backgroundColor: ColorSchemes.mediumBlue,
      padding: 15,
      marginBottom: 49,
      marginTop: 66
    },
    whiteText: {
      '& .title': {
        color: `${ColorSchemes.white} !important`,
      },
      color: ColorSchemes.white,
      fontSize: 14,
    },
    statistcsWrap: {
      marginBottom: 10
    },
    actionsContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center'
    },
    printWrap: {
      width: '50%',
      alignSelf: 'flex-start'
    },
    addWrap: {
      width: '50%',
      alignSelf: 'flex-end',
      textAlign: 'right'
    },
    printWapper: {
      padding: 10,
    },
    printBlock: {
      padding: 0,
      fontSize: '13px',
      paddingBottom: 15,
      marginBottom: 15,
      borderBottom: `1px dashed ${ColorSchemes.black}`,
      '& div': {
        paddingBottom: 5,
      }
    },
    selectGroup: {
      display: 'inline-flex',
      height: 35,
      position: 'relative',
      width: "100%",

      '& ::after': {
        border: 'none !important'
      },
      '& ::before': {
        border: 'none !important'
      },
      '& .MuiInput-root': {
        borderColor: ColorSchemes.highDarkGrey,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 3,
        flexGrow: 1,
      },
      '& .MuiSelect-select': {
        background: 'none',

        fontSize: 14,
        padding: '2px 10px',
        color: ColorSchemes.darkGrey,
      },
      '& .MuiSelect-icon': {
        top: 'calc(50% - 13px)'
      },
    },
    guideSelectorWrap: {
      '@media (max-width: 540px)': {
        alignItems: "start"
      }
    },
    saveGuideButtonWrapper: {
      '@media (max-width: 540px)': {
        marginTop: 34
      },
      '@media (min-width: 541px)': {
        marginTop: 31
      }
    },
    topSectionWrap: {
      '@media (max-width: 540px)': {
        marginBottom: 20
      },
    },
    success: {
      color: '#07A717 !important',
      fontSize: '12px !important',
      lineHeight: '14px',
      fontWeight: 'bold',
      justifyContent: 'flex-start',
      marginTop: 4,
      marginBottom: 3
    },
    groupTourInfoWrapper: {
      marginBottom: 10
    },
    emailButtonWrap: {
      marginTop: 20,
      marginBottom: 10,
    },
    copyToClipboardIcon: {
      position: "absolute",
      top: 15,
      right: 15,
      cursor: "pointer",
      fill: "#cccccc"
    },
    selectWrap: {
      marginBottom: "20px",
      '@media (min-width: 541px)': {
        marginTop: "-30px"
      },
    },
    modalTopBar: {
      backgroundColor: '#f5f5f5',
      boxShadow: '0 2px 2px rgba(0, 0, 0, 0.2)'
    },
    modalTopBarMailSent: {
      backgroundColor: '#fed2d2',
      boxShadow: '0 2px 2px rgba(0, 0, 0, 0.2)'
    },
    mailLogSuccess: {
      color: 'green'
    },
    mailLogError: {
      color: 'red'
    },
    customSelect: {
      width: '100%',
      padding: 12,
      border: '1px solid #ccc',
      borderRadius: 4,
      fontSize: 14,
      fontWeight: 500,
      boxShadow: 'none',
      transition: 'all 0.3s ease'
    }
  }));
  return styles();
}
export default theme;

