import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme'
const theme = (props) => {
  const styles = makeStyles(theme => ({
    headerWrap: {
      borderBottom: `1px solid ${ColorSchemes.highDarkGrey}`,
      display: 'inline-flex',
      width: '100%',
      marginBottom: 10,
      padding: '10px 0px',
      alignItems: 'center'
    },
    buttonGroup: {
      flexGrow: 1,
      '& >:not(:last-child)': {
        marginRight: 10,
      }
    },
    date: {
      fontSize: 12,
      color: ColorSchemes.highDarkGrey,
      margin: 0,
    },
    titleWrap: {
      marginBottom: 20,
    },
    contentWrap: {
      fontSize: 14,
      marginTop: 10,
      paddingBottom: 20,
      '& p': {
        margin: '5px 0px',
      }
    },
    borderBottom: {
      borderBottom: `1px solid ${ColorSchemes.highDarkGrey}`
    },
    detailsWrap: {
      fontSize: 13,
      '& svg': {
        height: 14,
        width: 14,
        marginRight: 7,
        fill: ColorSchemes.mediumBlue
      },
      '& .marginNoIcon': {
        paddingLeft: 20,
        paddingBottom: 5,
      },
      '& .marginGroup': {
        paddingTop: 5,
        paddingBottom: 5,
      },
      '& .customMargin': {
        paddingTop: 5,
      },
      '& .button': {
        cursor: 'pointer',
        marginTop: 5,
        marginBottom: 5,
        background: ColorSchemes.green,
        color: ColorSchemes.white,
        borderRadius: 3,
        paddingTop: 10,
        paddingBottom: 10,
        fontWeight: 'bold',
        textAlign: 'center',

      },
    },
    detailedInfoWrap: {
      fontSize: 14,
      marginTop: 10,
      paddingBottom: 13,
      '& p': {
        margin: '5px 0px',
      }
    },
    organizerContactWrap: {
      fontSize: 14,
      marginTop: 10,
      paddingBottom: 30,
      '& p': {
        margin: '5px 0px',
      }
    },
    warningWrap: {
      fontSize: 14,
      marginTop: 10,
      marginBottom: 30,
      padding: 10,
      borderRadius: 3,
      backgroundColor: ColorSchemes.mediumBlue,
      color: ColorSchemes.white,
      '& p': {
        margin: '5px 0px',
      }
    },
    availabilityWrap: {
      display: 'inline-flex',
      alignItems: 'center',
      paddingTop: 5,
      '& .circle': {
        height: 11,
        width: 11,
        borderRadius: '50%',
        marginRight: 10,
      },
      '& .available': {
        backgroundColor: ColorSchemes.green
      },
      '& .noAvailable': {
        backgroundColor: ColorSchemes.red
      },
      '& .lowAvailable': {
        backgroundColor: ColorSchemes.orange
      },
    },
    scheduleDisclaimer: {
      color: ColorSchemes.highDarkGrey,
      fontSize: 11,
      marginBottom: 10,
    },
    booking: {
      '& .MuiSvgIcon-root': {
        width: 0,
        paddingLeft: '0 !important'
      }
    },
    organizer: {
      float: 'left',
      marginRight: '20px'
    },
    htmlContent: {
      display: 'inline-block',
      width: '100%'
    }
  }));
  return styles();
}
export default theme;

