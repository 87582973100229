import React, {Fragment, useEffect} from 'react';
import theme from './style';
import Button from '../../components/Button';
import {SmallTitle, Accordion, Text, Chip} from '../../components/DataDisplay';
import {Select, Label, Check, LabelInput, InputField, TextEditor} from '../../components/Inputs';
import useHook from './hook';
import translate from '../../functions/applyTranslation';
import toLongDate from '../../functions/formatLongDate';
import StatusList from '../../settings/articlesStatusList';
import {Grid} from '@material-ui/core'
import clsx from 'clsx';
import PublishInfo from '../../components/PublishInfo';
import DeleteModal from '../delete-modal';
import decode from 'decode-html';

const NewsEdit = (props) => {
  const classes = theme();
  // eslint-disable-next-line
  const [data, validator, modal, closeModal, handleDelete, handleChange, handleActions] = useHook(props);

  const handleVisibilityChange = (id, value) => {
    let newArr = [];
    if ((data.visibility.includes(1) && id !== 1) || (id === 1 && value)) {
      newArr.push(1)
    }
    if ((data.visibility.includes(2) && id !== 2) || (id === 2 && value)) {
      newArr.push(2)
    }
    handleChange('visibility', newArr)
  }
  const handleKeywords = (value) => {
    let _keywords = JSON.parse(JSON.stringify(data.keywords));
    _keywords.push(value);
    handleChange('keywords', _keywords);
  }
  const handleKeywordRemove = (index) => {
    let _keywords = JSON.parse(JSON.stringify(data.keywords));
    _keywords.splice(index, 1);
    handleChange('keywords', _keywords);
  }


  return (

    <>
      <DeleteModal title={translate('information-edit', 'label_delete_page_title')}
                   subTitle={translate('blog', 'label_delete_page_subtitle')} modal={modal ? modal.key : null}
                   closeModal={closeModal} callBack={handleDelete}/>
      <div className={classes.pageWrap}>
        <div className={classes.headerWrap}>
          <div className={classes.titleWrap}>
            <SmallTitle text={translate('information-edit', 'title_news_form')}/>
            <div className={classes.buttonGroup}>
              <Button label={translate('', 'button_delete')} icon='delete' onClick={() => {
                handleActions('delete')
              }}/>
              <Button label={translate('', 'button_back')} fill icon='back' onClick={() => {
                handleActions('back')
              }}/>
              <Button label={translate('', 'button_save')} icon='save' fill onClick={() => {
                handleActions('save')
              }}/>
            </div>
          </div>
          <Text text={translate('information-edit', 'sub_title_news_form')}/>
        </div>
        {data.new && <div>
          <Check value={data.published == '1'} onChange={(e) => handleChange('published', e ? '1' : '0')}
                 label={translate('information-edit', 'form_check_published')} className={classes.margin}/>
        </div>
        }
        <Grid container className={classes.header}>
          {!data.new &&
          <Fragment>
            <Grid item mdUp md={8}>
              <Check value={data.published == '1'} onChange={(e) => handleChange('published', e ? '1' : '0')}
              label={translate('information-edit', 'form_check_published')} className={classes.margin}/>
            </Grid>
            <Grid item sm={12} md={4} className={classes.headerSelectWrap}>
              <PublishInfo area={'information-edit'}
                           createdDate={data.created_date}
                           createdBy={data.created_by}
                           managedBy={data.managed_by}
                           publishedBy={data.published_by}
                           unpublishedBy={data.unpublished_by}/>
            </Grid>
          </Fragment>
          }
        </Grid>
        <div className={classes.accordionBodyWrap}>
          <Label fullWidth text={translate('information-edit', 'label_visibility')}>
            <Text text={translate('information-edit', 'sub_label_visibility')}/>
          </Label>
          <div className={classes.inputGroup}>
            <Check invalid={validator.includes('visibility')} value={data.visibility.includes(2)}
                   onChange={(e) => handleVisibilityChange(2, e)}
                   label={translate('information-edit', 'form_check_label_pay_member')} className={classes.margin}/>
            <Check invalid={validator.includes('visibility')} value={data.visibility.includes(1)}
                   onChange={(e) => handleVisibilityChange(1, e)}
                   label={translate('information-edit', 'form_check_label_non_pay_member')} className={classes.margin}/>
          </div>
          <Label fullWidth text={translate('information-edit', 'label_applicants')}>
            <Text text={translate('information-edit', 'sub_label_applicants')}/>
          </Label>
          <div className={classes.inputGroup}>
            {data.keywords && data.keywords.map((item, index) => (
                <Fragment key={index}>
                    {item !== "" &&
                        <Chip 
                            id={index} 
                            label={item} 
                            className={clsx(classes.margin, classes.verticalMargin)}
                            onClick={() => handleKeywordRemove(index)}
                        />
                    }
                </Fragment>
            ))}
          </div>
          <LabelInput fullWidth onClick={handleKeywords}/>
        </div>

        <div className={classes.section}>
          <SmallTitle text={translate('information-edit', 'title_page_information')}/>
          <Text text={translate('information-edit', 'sub_title_page_information')}/>
        </div>
        <Label fullWidth text={translate('information-edit', 'form_label_title')} className={classes.verticalMargin}>
          <InputField invalid={validator.includes('title')} fullWidth value={data.title || ''}
                      onChange={(e) => handleChange('title', e)}/>
        </Label>
        <Label fullWidth text={translate('information-edit', 'form_label_content')} className={classes.verticalMargin}>
          {(data.new || data.subpage || (!data.new && data.id)) &&
          <TextEditor invalid={validator.includes('content')} value={data.content || ''}
                      onChange={(e) => handleChange('content', e)}/>
          }
        </Label>
      </div>
    </>
  );
}
export default NewsEdit;