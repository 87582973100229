import React, { useState, useEffect } from 'react';
import theme from './style';
import translate from '../../../functions/applyTranslation';
import { BigTitle } from '../../../components/DataDisplay';
import { Grid } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from 'moment';

const Header = (props) => {
  const classes = theme();
  const { className, title, booked_start_time, booked_end_time, event_start_time, event_end_time } = props;
  const [dateTime, setDateTime] = useState(moment());

  const clearTimeout = (timer) => {
    timer = null;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setDateTime(moment());
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  return (
    <div className={className}>
      <div className={classes.headerWrap}>

        <Grid container className={classes.borderBottom}>
          <Grid item xs={12} sm={8} className={`${classes.mainGrid}`}>
            <Grid container className={classes.alignLeft}>
              <Grid item xs={12} className={classes.titleWrap}>
                <BigTitle text={title} />
              </Grid>
              <Grid item xs={12} className={'marginGroup'}>
                <span>{`${translate('security', 'label_ongoing')} ${moment.unix(event_start_time).format('HH:mm')} - ${moment.unix(event_end_time).format('HH:mm')}`}</span>
                <span>{`${translate('security', 'label_room_booked')} ${moment.unix(booked_start_time).format('HH:mm')} - ${moment.unix(booked_end_time).format('HH:mm')}`}</span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container   >
              <Grid item xs={12} className={classes.alignRight}>
                <BigTitle text={dateTime.format('dddd, D MMMM')} />
              </Grid>
              <Grid item xs={12} className={classes.alignRight}>
                <div className={classes.clockWrap}>
                  <AccessTimeIcon className={classes.clock} />
                  <BigTitle text={dateTime.format('HH:mm')} />
                </div>
              </Grid>
            </Grid>




          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default Header;