import React from 'react';
import theme from './style';
import Button from '../../../components/Button';
import { SmallTitle, Accordion, Text, Tabs } from '../../../components/DataDisplay';
import { Select, Label, Check, LabelInput, Dropzone, InputField, TextEditor } from '../../../components/Inputs';
// import useTabsHook from './tabsHook';
import translate from '../../../functions/applyTranslation';
import StatusList from '../../../settings/articlesStatusList';
import clsx from 'clsx';
import { Grid } from '@material-ui/core'
const ActivitiesTab2 = (props) => {
  const classes = theme();
  return (
    <div className={classes.pageWrap}>
      <Label text={translate('activities-edit', 'tabReview_booking_title')}>
        <Text text={translate('activities-edit', 'tabReview_booking_subtitle')} />
      </Label>
    </div >
  );
}
export default ActivitiesTab2;