import { useEffect, useState } from 'react';
import useAPI from '../../api/apiHandler';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/states/statesActions';
import { formValidator } from './validation';
const InformationEditHook = (props) => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.userData);
  const [setRequest] = useAPI();
  const [modal, setModal] = useState({ key: '' })
  const closeModal = () => {
    setModal();
  }

  const {
    history,
    match
  } = props;
  let newForm = match.url.search('/new') > 0;
  let editForm = match.url.search('/edit/') > 0;
  let subpage = match.url.search('/subpage') > 0;

  let workingId = match.params.id;
  const [validator, setValidator] = useState([]);
  const [data, setData] = useState({
    status: null,
    visibility: [1, 2],
    keywords: [],
    title: '',
    content: '',
    created_date: null,
    created_by: null,
    managed_by: null,
    published_by: null,
    unpublished_by: null,
    parent_id: 0,
    sort_order: null,
    new: newForm,
    subpage: subpage,
    published: '0'
  })

  const defaultData = {
    status: null,
    visibility: [1, 2],
    keywords: [],
    title: '',
    content: '',
    created_date: null,
    created_by: null,
    managed_by: null,
    published_by: null,
    unpublished_by: null,
    parent_id: 0,
    sort_order: null,
    new: false,
    subpage: false,
    published: '0'
};

  const handleChange = (key, value) => {
    setData({ ...data, [key]: value })
  }
  const handleActions = (action) => {
    switch (action) {
      case 'save':
        handleSubmitPage();
        break;
      case 'delete':
        setModal({ key: 'delete' });
        break;
      case 'back':
        history.goBack();
        break;

      default:
        break;
    }
  }

  const findAvailableSortOrder = (data) => {
    let sortOrder = 1;
    data.forEach(item => {
      let itemSortOrder = Number(item.page.sort_order);
      if (itemSortOrder >= sortOrder) {
        sortOrder = itemSortOrder + 1;
      }
    })
    return sortOrder;
  }
  const fetchPageData = (id) => {
    dispatch(setLoading(true));
    setRequest({
      url: `page/?page_id=${id}`,
      method: 'GET',
    }).then(res => {
      if (res) {
        if (editForm) {
          setData(prevState => ({
            ...defaultData,
            ...res.data.page,
            keywords: (res.data.page.keywords || '').split(',').map(kw => kw.trim()), // Ensure new array
            visibility: (res.data.page.visibility || '').split(',').map(Number), // Ensure new array
            status: 2,//FIXME: update with real ID after API gets fixed
            created_date: res.data.page.date_created,
            created_by: res.data.created_by?.name,
          }));
        } else {
          setData({
            ...defaultData,
            ...data,
            parent_id: res.data.page.id,
            sort_order: findAvailableSortOrder(res.data.children)
          })
        }
      }
      dispatch(setLoading(false));
    })
    .catch(() => {
      dispatch(setLoading(false));
    });
  }
  const fetchTreeViewItems = () => {
    dispatch(setLoading(true))
    setRequest({
      url: 'pages/getsorted',
      method: 'GET',
    }).then(res => {
      if (res) {
        let sortOrder = 1;
        res.data.forEach(item => {
          let _order = Number(item.page.sort_order);
          if (_order >= sortOrder) {
            sortOrder = _order + 1;
          }
        })
        setData({
          ...data,
          sort_order: sortOrder,
        })
      }
      dispatch(setLoading(false))
    })
      .catch(dispatch(setLoading(false)))
  }
  const handleSubmitPage = () => {
    let valid = true;
    const validated = formValidator(data);
    setValidator(validated)
    valid = validated.length === 0;

    if (!valid) {
      dispatch(setLoading(false))
      return;
    }

    let _data = {
      title: data.title,
      content: data.content,
      keywords: data.keywords.join(),
      visibility: data.visibility.join(),
      parent_id: data.parent_id,
      sort_order: data.sort_order,
      published: data.published
    }
    if (editForm) {
      _data.page_id = data.id;
    }
    // else {
    //   _data.author_id = userData.id
    // }
    dispatch(setLoading(true))
    setRequest({
      url: 'page',// editForm ? `page/update` : `createpage`,
      method: editForm ? 'PATCH' : 'POST',
      data: _data,
    }).then(res => {
      if (res) {
        history.push(`/information/${res.data.id}`);
      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  }

  const handleDelete = () => {
      dispatch(setLoading(true))
      setRequest({
        url: `page?page_id=${data.id}`,
        method: 'DELETE',
      }).then(res => {
        if (res) {
          history.push('/information');
        }
        dispatch(setLoading(false))
      })
        .catch(() => dispatch(setLoading(false)))
  }

  useEffect(() => {
    if (!newForm) {
      fetchPageData(workingId)
    } else {
      fetchTreeViewItems();
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    console.log('Data:', data);
  }, [data]);

  return [data, validator, modal, closeModal, handleDelete, handleChange, handleActions];
}

export default InformationEditHook;