import React, {Fragment} from 'react';
import translate from '../../../functions/applyTranslation';
import canByNewTickets from '../../../functions/canByNewTickets';
import theme from './style';
import moment from 'moment';

const Cart = (props) => {
  const classes = theme();

  const {event, role, osa_end, osa_pay_end, adultMemberTicket, childMemberTicket, handleNavigation} = props;

  const calculateAvailability = () => {
    //0 = Full, 1 = Low Availability, 2 = Big Availability;
    const maxPpl = Number(event.max_ppl);
    const registredUsers = Number(event.registered_users);
    const avaibality = maxPpl - registredUsers;
    if (avaibality > 0) {
      if (registredUsers / maxPpl > 0.79) {
        return 1;
      } else {
        return 2;
      }
    } else {
      return 0;
    }
  }

  const canByTickets = canByNewTickets(event, role)
  const handleNavigationCart = () => {
    if (handleNavigation && canByTickets)
      handleNavigation('cart');
  }
  const availability = calculateAvailability();

  return (
    <Fragment>
      {(adultMemberTicket || childMemberTicket) &&
      <Fragment>
        <div className={'customMargin'}>
          <span>{translate('activities-edit', 'label_pricing')}</span>
        </div>
        <div>
          <b>
            {`${adultMemberTicket ? `${translate('activities-edit', 'label_from')} ${adultMemberTicket.amount} ${translate('', 'label_currency_abbreviation')} ${translate('activities-edit', 'label_adults')}` : ''}
                ${(adultMemberTicket && childMemberTicket) ? `, ` : ''}
                ${(!adultMemberTicket && childMemberTicket) ? `${translate('activities-edit', 'label_from')} ` : ''}
                ${childMemberTicket ? `${childMemberTicket.amount} ${translate('', 'label_currency_abbreviation')} ${translate('activities-edit', 'label_children')}` : ''}`}
          </b>
        </div>
      </Fragment>
      }
      <div className={classes.availabilityWrap}>
        <div
          className={`circle ${availability === 1 ? 'lowAvailable' : availability === 2 ? 'available' : 'noAvailable'}`}/>
        {availability === 0 && translate('activities-edit', 'label_no_availability')}
        {availability === 1 && translate('activities-edit', 'label_low_availability')}
        {availability === 2 && translate('activities-edit', 'label_high_availability')}
      </div>
      <div className={`button ${!canByTickets ? 'disabled' : ''}`} onClick={() => {
        handleNavigationCart()
      }}>
        {translate('activities-edit', 'label_book_for_place')}
      </div>
      <div className={classes.scheduleDisclaimer}>
        {role === "member" ?
          // translate('activities-edit', 'label_book_date_limit').replace('##date##', moment.unix(osa_end).format('D MMMM YYYY'))
          translate('activities-edit', 'label_book_date_limit') + " " + moment.unix(osa_end).format('D MMMM YYYY')
          :
          // translate('activities-edit', 'label_book_date_limit').replace('##date##', moment.unix(osa_pay_end).format('D MMMM YYYY'))
          translate('activities-edit', 'label_book_date_limit') + " " + moment.unix(osa_pay_end).format('D MMMM YYYY')
        }
      </div>
    </Fragment>
  );
}

export default Cart;