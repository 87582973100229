import React, {Fragment, useState, useCallback, useEffect} from 'react';
import theme from './style';
import useHook from './hook';
import translate from '../../functions/applyTranslation';
import {InputField} from '../Inputs'
import {Tooltip, Grid} from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import Button from '../Button';
import {Check} from '../Inputs';
import moment from 'moment';
import {v4 as uuidv4} from 'uuid';


const EventList = (props) => {
  const classes = theme();
  const {openEmailSelected, className, tablet, tickets} = props;
  const [rows, selected, toogleSelected, selectAll, search, clearSearch, selectedUsers, data, pageNumber, pageSize, setPageNumber, setPageSize, removeUser, checkinUser, checkoutUser] = useHook(props);
  const [searchText, setSearchText] = useState('')

  const onFormSubmit = (e) => {
    e.preventDefault();
    search(searchText);
  }

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setSearchText('');
      clearSearch();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const getTooltipText = (message) => {
    try {
      let o = JSON.parse(message);
      if (o && typeof o === "object") {
        return o.message;
      }
      else {
        return message
      }
    }
    catch (e) {
      return message;
    }
  }

  return (
    <>
      {tablet &&
      <form onSubmit={onFormSubmit}>
        <Grid container className={classes.search}>

          <Grid item xs={7}>
            <InputField className={classes.searchInput} value={searchText}
                        placeholder={translate('security', 'placeholder_search')} fullWidth onChange={(e) => {
              setSearchText(e)
            }}/>
          </Grid>
          <Grid item xs={5} className={classes.searchActions}>
            <Button type={'submit'} className={`${classes.searchButton}`} label={translate('security', 'button_search')}
                    onClick={() => search(searchText)}/>
            <Button type={'reset'} className={`${classes.searchClearButton}`}
                    label={translate('security', 'button_search_clear')} onClick={() => {
              setSearchText('');
              clearSearch();
            }}/>

          </Grid>
        </Grid>
      </form>
      }
      <div className={`${classes.main} ${tablet ? 'tablet' : ''} ${className || ''}`}>
        <div className={`${classes.header} ${tablet ? 'tablet' : ''}`}>

          <div className={`${classes.name} ${tablet ? 'tablet' : ''}`}>
            {!tablet &&
            <Check className={classes.checkbox}
                   value={selected && selected.length > 0 && selected.length === rows.length}
                   onChange={(e) => selectAll()}
            />
            }
            {translate('booking', 'label_header_name')}
          </div>
          <div
            className={`${classes.ticket} ${tablet ? 'tablet' : ''}`}>{translate('booking', 'label_header_ticket')}</div>
          <div className={`${classes.options} ${tablet ? 'tablet' : ''}`}>&nbsp;</div>
        </div>
        {rows.map((item, index) => (
            <Fragment key={uuidv4()}>
              {tickets[index].deleted === '0' &&
              <div>
                {item.title && !tablet &&
                <div className={`${classes.group}`}>
                  <div
                    className={classes.groupItem}>{`${moment.unix(item.cdate).format('YYYY-MM-DD HH:mm')} - ${item.user.name}`}</div>
                  {item.comments &&
                  <Tooltip title={getTooltipText(item.comments)}>
                    <div className={classes.labelComments}>
                      <CommentIcon className={classes.iconComments}/>
                      <div>{translate('booking', 'label_show_comment')}</div>
                    </div>
                  </Tooltip>
                  }
                </div>
                }
                {item.title && tablet &&
                <div
                  className={`${classes.group} tablet`}>{`${translate('booking', 'label_booked_by')} ${item.user.name}`}</div>
                }
                <div key={item.id}
                     className={`${classes.row} ${tablet ? 'tablet' : ''} ${item.title ? 'borderNone' : ''} ${(item.attendance.checkin && item.attendance.checkout) ? 'closed' : ''} `}>
                  <div className={`${classes.element} ${classes.name} ${tablet ? 'tablet' : ''}`}>
                    {!tablet &&
                    <Check className={classes.checkbox}
                           value={selected && selected.find(element => Number(element) === Number(item.id))}
                           onChange={(e) => toogleSelected(item.id)}
                    />
                    }
                    {`${item.name}${item.ssn ? ` (${item.ssn})` : ''}`}
                  </div>
                  <div
                    className={`${classes.element} ${classes.ticket} ${tablet ? 'tablet' : ''}`}>{`${item.product.name || (Number(item.product.id) === 0 ? translate('booking', 'label_manually_entered') : '')}${Number(item.product.id) > 0 ? ` (${item.member === 1 ? translate('booking', 'label_member') : translate('booking', 'label_non_member')})` : ''}`}</div>
                  <div className={`${classes.element} ${classes.options} ${tablet ? 'tablet' : ''}`}>
                    {!tablet &&
                    <Button className={classes.unsubscribe} label={translate('booking', 'button_unsubscribe')}
                            onClick={() => removeUser(item.id)}/>
                    }
                    {tablet &&
                    <Fragment>
                      <Button disabled={!item.attendance.checkin || item.attendance.checkout}
                              className={`${classes.checkout} ${!item.attendance.checkin ? 'in' : ''} ${item.attendance.checkout ? 'out' : ''}`}
                              label={item.attendance.checkout || translate('booking', 'button_checkout')}
                              onClick={() => checkoutUser(item.id)}/>
                      <Button disabled={item.attendance.checkin}
                              className={`${classes.checkin} ${item.attendance.checkin ? 'in' : ''} ${item.attendance.checkout ? 'out' : ''}`}
                              label={item.attendance.checkin || translate('booking', 'button_checkin')}
                              onClick={() => checkinUser(item.id)}/>
                    </Fragment>
                    }
                  </div>
                </div>
              </div>
              }</Fragment>
          )
        )}
      </div>
      {!tablet &&
      <div className={classes.emailWrap}>
        <Button disabled={!selected || selected.length === 0} fill label={translate('booking', 'button_email_selected')}
                onClick={(e) => {
                  openEmailSelected({users: selectedUsers})
                }}/>
      </div>
      }
    </>
  );
}


<EventList/>

export default EventList;