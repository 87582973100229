import React from 'react';
import theme from './style';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
const SmallTitle = (props) => {
  const classes = theme();
  const {
    fullWidth,
    text,
    alt,
    className
  } = props;

  return (
    <div className={
      clsx(
        className,
        classes.smallTitleWrap,
        fullWidth ? classes.fullWidth : '',
      )}
    >
      <Typography className={classes.smallTitleText}>{text}
        {alt &&
          <span className={classes.altText}>{alt}</span>
        }
      </Typography>
    </div >
  );
}
SmallTitle.propTypes = {
}


export default SmallTitle;