import React, {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import useAuth from "../authBankId/hook";

const AuthBankIdHosted = props => {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const grandidsession = params.get("grandidsession");

    const {access_token} = useSelector(state => state.auth);

    const [data, initialize, handleNavigation, handleApplyAccount, handleSubmit, handleChange, handleCancel, invalidList, loading, checkStatus, startHostedGrandId, setData] = useAuth(props);

    useEffect(() => {
        if(grandidsession) {
            setData({...data, session_id: grandidsession});
            checkStatus({session_id: grandidsession});
        }
    }, []);

    useEffect(() => {
        if(access_token) {
            history.push("/");
        }
    }, [access_token]);

    return <div></div>
};

export default AuthBankIdHosted;