import React from 'react';
import theme from './style';
import { Typography } from '@material-ui/core';
import translate from '../../functions/applyTranslation'
import { useHistory } from 'react-router-dom';

const Footer = (props) => {
  const classes = theme();
  const history = useHistory();
  const handleClick= (page)=> {
    history.push(`/information/${page}`);
  }

  return (
    <div className={classes.footer}>
      <div className={classes.groupLabels}>
        <Typography className={'footerItem link'} onClick={()=> handleClick(93)}>{translate('', 'footer_privacy_policy')}</Typography>
        <Typography className={'footerItem link'} onClick={()=> handleClick(90)}>{translate('', 'footer_contacts')}</Typography>
      </div>
      <Typography className={'footerItem'}>{translate('', 'footer_copyright')}</Typography>
    </div>
  );
}
export default Footer;