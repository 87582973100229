import React, {memo, useContext, useEffect, useState} from 'react';
import theme from './style';
import Button from '../../../components/Button';
import Link from '../../../components/Link';
import {Text,} from '../../../components/DataDisplay';
import {
  Select,
  MultiSelect,
  Label,
  InputField,
  DateTimePicker as DatePicker,
  RadioGroup
} from '../../../components/Inputs';
import translate from '../../../functions/applyTranslation';
import {Grid} from '@material-ui/core';
import {ActivitiesContext,} from "../actitiesContext";

const ActivitiesTab1 = (props) => {
  const classes = theme();
  const activitiesContext = useContext(ActivitiesContext);
  const {
    tab1Data,
    setTab1Data,
    validator,
    locations,
    associations,
    categories,
    setSelectedTab,
    validatorForm1,
    setValidator,
    userData,
    user
  } = activitiesContext;
  const [ssn, setSsn] = useState("");
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const isActivityType = (id) => {
    return tab1Data.activity_type === id;
  }
  const validateForm = () => {
    let res = validatorForm1(tab1Data);
    if (!res.length) {
      setValidator({...validator, tab1: []})
      setSelectedTab(2)
    } else {
      setValidator({...validator, tab1: res})
    }
  }

  useEffect(() => {
    if(user) {
      if (tab1Data.alt_email !== user.email) {
        setEmail(tab1Data.alt_email);
      } else {
        setEmail('')
      }

      if (tab1Data.alt_phone !== user.phone) {
        setPhone(tab1Data.alt_phone);
      } else {
        setPhone('')
      }
    }
  }, [user, tab1Data.alt_email, tab1Data.alt_phone]);

  const _validator = validator.tab1;
  return (
    <div className={classes.pageWrap}>
      <div>
        <Label text={translate('activities-edit', 'tab1_contact_person_title')}>
          <Text text={translate('activities-edit', 'tab1_contact_person_subtitle')}/>
        </Label>
        <Grid container>
          <Grid item xs={12} className={classes.inlineContact}>
            <Text className={classes.bold} text={translate('activities-edit', 'tab1_label_contact_name')}/>
            <Text text={tab1Data.owner_name || userData.name}/>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.responsableContainer}>
            <Label className={classes.label} text={translate('activities-edit', 'tab1_label_alt_phone')}>
              <InputField fullWidth placeholder={translate('activities-edit', 'tab1_placeholder_alt_phone')}
                          value={phone}
                          onChange={(e) => {
                            setTab1Data('alt_phone', e);
                            setPhone(e);
                          }}
              />
            </Label>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.responsableContainer}>
            <Label className={classes.label} text={translate('activities-edit', 'tab1_label_alt_email')}>
              <InputField fullWidth placeholder={translate('activities-edit', 'tab1_placeholder_alt_email')}
                          value={email}
                          onChange={(e) => {
                            setTab1Data('alt_email', e);
                            setEmail(e)
                          }}
              />
            </Label>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.responsableContainer}>
            <Label className={classes.label} text={translate('activities-edit', 'tab1_label_owner_ssn')}>
              <InputField fullWidth placeholder={translate('activities-edit', 'tab1_placeholder_owner_ssn')}
                          value={ssn}
                          onChange={(e) => {
                            setTab1Data('owner_ssn', e)
                            setSsn(e)
                          }}
              />
            </Label>
          </Grid>
          <Grid item xs={12} sm={7}>
            <div className={classes.inputContainer}>
              <Label className={classes.paddingFix} text={translate('activities-edit', 'tab1_booking_type_title')}>
                <RadioGroup
                  selected={tab1Data.booking_organization_type}
                  onChange={val => setTab1Data('booking_organization_type', val)}
                  items={
                    [
                      {
                        id: 1,
                        text: translate('activities-edit', 'tab1_booking_type_option_organization'),
                      },
                      {
                        id: 2,
                        text: translate('activities-edit', 'tab1_booking_type_option_individual'),
                      },
                    ]
                  }
                />
              </Label>
            </div>
            {Number(tab1Data.booking_organization_type) === 1 &&
            <MultiSelect fullWidth placeholder={translate('activities-edit', 'tab1_booking_type_select_placeholder')}
                         invalid={_validator.includes('booking_type')}
                         data={[...associations]}
                         selected={tab1Data.booking_type}
                         fullWidth
                         onChange={(e) => setTab1Data('booking_type', e)}
            />
            }
          </Grid>
        </Grid>

        <Label className={classes.paddingFix} text={translate('activities-edit', 'tab1_create_activity_title')}>
          <Text text={translate('activities-edit', 'tab1_create_activity_subtitle')}/>
        </Label>
        <Grid container className={classes.activityContainer}>
          <Grid item xs={12} sm={4}>
            <Button className={classes.activityType} fill fullWidth selected={isActivityType(1)}
                    label={translate('activities-edit', 'tab1_create_activity_button_activity')}
                    onClick={() => setTab1Data('activity_type', 1)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button className={classes.activityType} fill fullWidth selected={isActivityType(2)}
                    label={translate('activities-edit', 'tab1_create_activity_button_meeting')}
                    onClick={() => setTab1Data('activity_type', 2)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button className={classes.activityType} fill fullWidth selected={isActivityType(3)}
                    label={translate('activities-edit', 'tab1_create_activity_button_visit')}
                    onClick={() => setTab1Data('activity_type', 3)}
            />
          </Grid>
        </Grid>
        <Label className={classes.paddingFix} text={translate('activities-edit', 'tab1_categorize_title')}>
          <Select fullWidth
                  data={[...categories, {id: '-1', title: translate('activities-edit', 'tab1_categorize_placeholder')}]}
                  selected={tab1Data.categorize}
                  invalid={_validator.includes('categorize')}
                  onChange={(e) => {
                    setTab1Data('categorize', e)
                  }}
          />
        </Label>
        <Label className={classes.paddingFix} text={translate('activities-edit', 'tab1_room_time_booking_title')}>
          <Text text={translate('activities-edit', 'tab1_room_time_booking_subtitle')}/>
        </Label>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <DatePicker placeholder={translate('', 'placeholder_from')}
                        value={tab1Data.room_time_from}
                        invalid={_validator.includes('room_time_from')}
                        onChange={(e) => setTab1Data('room_time_from', e)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DatePicker placeholder={translate('', 'placeholder_to')}
                        value={tab1Data.room_time_to}
                        invalid={_validator.includes('room_time_to')}
                        onChange={(e) => setTab1Data('room_time_to', e)}/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Link style={{marginTop: 5}} label={translate('activities-edit', 'action_go_to_activities')}
                  target={'_blank'} url={'/activities?view=calendar'}/>
          </Grid>
        </Grid>
        <Label className={classes.paddingFix} text={translate('activities-edit', 'tab1_event_time_title')}>
          <Text text={translate('activities-edit', 'tab1_event_time_subtitle')}/>
        </Label>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <DatePicker placeholder={translate('', 'placeholder_from')}
                        invalid={_validator.includes('event_time_from')}
                        value={tab1Data.event_time_from}
                        onChange={(e) => setTab1Data('event_time_from', e)}/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <DatePicker placeholder={translate('', 'placeholder_to')}
                        invalid={_validator.includes('event_time_to')}
                        value={tab1Data.event_time_to}
                        onChange={(e) => setTab1Data('event_time_to', e)}/>
          </Grid>
        </Grid>
        <Label className={classes.paddingFix} text={translate('activities-edit', 'tab1_room_picker_title')}/>
        <Select fullWidth
                data={[...locations, {id: '-1', title: translate('activities-edit', 'tab1_room_picker_other')}]}
                selected={tab1Data.room_id}
                invalid={_validator.includes('room_id')}
                onChange={(e) => setTab1Data('room_id', e)}
        />
        {Number(tab1Data.room_id) === -1 &&
        <InputField className={classes.otherRoom} fullWidth
                    placeholder={translate('activities-edit', 'tab1_room_picker_placeholder')}
                    value={tab1Data.other_room}
                    invalid={_validator.includes('other_room')}
                    onChange={(e) => setTab1Data('other_room', e)}
        />
        }
      </div>
      <div className={classes.formFooter}>
        <Button label={translate('activities-edit', 'tab1_button_next')} fill onClick={validateForm}/>
      </div>
    </div>
  );
}

export default memo(ActivitiesTab1);
