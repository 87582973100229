import { makeStyles } from "@material-ui/core/styles";
import ColorScheme from '../../../settings/colorScheme'
const theme = () => {
  const styles = makeStyles(theme => ({
    pageWrap: {

    },
    formFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
      paddingTop: 10,
      borderTop: `1px solid ${ColorScheme.highDarkGrey}`,
      '& >:nth-child(1)': {
        marginRight: 10,
      }
    },
    inputContainer: {
      marginBottom: 10,
    },
    alignBottom: {
      position: 'relative',
      '& p': {
        position: 'absolute !important',
        bottom: 0
      }
    },
    leftContainer: {
      [theme.breakpoints.up('sm')]: {
        paddingRight: 10
      }
    },
    paddingFix: {
      paddingTop: 13,
    },
    label: {
      '& .title': {
        fontWeight: '500 !important',
        color: `${ColorScheme.darkBlue} !important`,
        fontSize: '14px !important'
      }
    }
  }));
  return styles();
}
export default theme;

