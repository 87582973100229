import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Switch, Route, Redirect, useLocation} from "react-router-dom";
import {useSelector} from 'react-redux';
import moment from "moment";
import TemplateDashboard from '../templates/dashboard';
import TemplateAuth from '../templates/auth';
import TemplateTablet from '../templates/tablet';
import LoadingComponent from '../components/Loading';
import ErrorSnackBar from '../components/ErrorSnackBar';
import useRedux from './hook';
import {createBrowserHistory} from "history";
import {
  Samples,
  Home,
  NewsList,
  AuthBankId,
  AuthPassword,
  RecoverPassword,
  ResetPassword,
  Information,
  MenuOrder,
  NewsDetail,
  NewsForm,
  InformationEdit,
  ActivitiesEdit,
  ActivitiesDetail,
  GalleryList,
  ActivitiesList,
  CheckOut,
  ActivitiesCart,
  NotificationList,
  Application,
  Profile,
  ProfileList,
  Booking,
  CheckList,
  Translation,
  TranslationEdit,
  BlogList,
  BlogEdit,
  BlogDetail,
  ApplicationList,
  SearchResults,
  GuideBooking,
  GuideIframe,
  GuideBookingList,
    AuthBankIdHosted
} from './viewsList';

import {useDispatch} from 'react-redux';
import {setLogout, setUserData} from '../redux/auth/authActions';
import useAPI from '../api/apiHandler';

const Temp = () => {
  return (<div>Incomplete</div>)
}

const RouterLayout = ({component: Component, layout: Layout, ...props}) => {
  return (
    <Route
      {...props}
      render={routeProps => (
        <>
          {
            Layout ?
              (<Layout {...routeProps}>
                <Component {...routeProps}/>
              </Layout>)
              :
              <Component {...routeProps}/>
          }
        </>
      )}
    />
  )
};

const history = createBrowserHistory();
const RouterComp = (props) => {
  const tabletPaths = ['check-list']
  const {role, expires_in, access_token} = useSelector(state => state.auth);
  const actualPath = useSelector(state => state.states.location);
  const translations = useSelector(state => state.settings.translations);
  const [setRequest] = useAPI();
  const {fetchTranslations} = useRedux();
  const dispatch = useDispatch();

  const [timer, setTimer] = useState(null);

  useEffect(() => {
    fetchTranslations()
  }, [])
  useEffect(() => {
  }, [actualPath])

  function getQueryStringValue(key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
  }

  let redirectUrl = window.location.pathname;
  if (!!role) {
    redirectUrl = getQueryStringValue('redirectUrl');
  }

  const fetchUserData = () => {
    setRequest({
      url: 'user/profile',
      method: 'GET',
    }).then(res => {
      if (res) {
        dispatch(setUserData(res.data))
      }
    })
  };

/*  const tokenCheckInterval = () => {
    clearInterval(timer);

    if (access_token) {
      setTimer(setInterval(checkLogout, 360000));
    } else {
      clearInterval(timer);
    }

    function checkLogout() {
      let now = moment().unix();
      if (now > expires_in) {
        dispatch(setLogout());
        clearInterval(timer);
      }
    };
  }*/

  useEffect(() => {
    if (!!role)
      fetchUserData();
  }, [role]);

/*  useEffect(() => {
    tokenCheckInterval();
  }, [access_token, expires_in]);*/

  return (
    <Router history={history}>
      <LoadingComponent/>
      <ErrorSnackBar/>
      {/* Template Auth */}
      {!role &&
      <Switch>
        <RouterLayout layout={TemplateAuth} exact path="/" component={AuthBankId}/>
        <RouterLayout layout={TemplateAuth} exact path="/login" component={AuthPassword}/>
        <RouterLayout layout={TemplateAuth} exact path="/recover-password" component={RecoverPassword}/>
        <RouterLayout layout={TemplateAuth} exact path="/reset-password/:token" component={ResetPassword}/>
        <RouterLayout layout={TemplateAuth} exact path="/samples" component={Samples}/>
        <RouterLayout layout={TemplateAuth} exact path="/application" bigForm={true} component={Application}/>
        {/*<RouterLayout exact path="/guide/booking/iframe" component={GuideIframe}/>*/}
        <RouterLayout exact path="/guide/booking" component={GuideBooking}/>
        <RouterLayout layout={TemplateAuth} exact path="/get-bank-id-session" component={AuthBankIdHosted}/>
        <Redirect push to={`${redirectUrl ? `/?redirectUrl=${redirectUrl}` : '/'}`}/>
      </Switch>
      }

      {!!role && tabletPaths.includes((actualPath || '').split('/').pop()) &&
      <TemplateTablet>
        <Switch>
          <Route exact path="/activities/:id/check-list" component={CheckList}/>
          <Redirect push to="/"/>
        </Switch>
      </TemplateTablet>
      }

      {/* Template Dashboard */}
      {!!role && !tabletPaths.includes((actualPath || '').split('/').pop()) &&
      <>
        {redirectUrl ?
          (
            <Switch>
              <Redirect push to={`${redirectUrl ? `${redirectUrl}` : '/'}`}/>
            </Switch>
          )
          :
          (
            <Switch>
              <RouterLayout layout={TemplateDashboard} exact path="/" component={Home}/>
              <RouterLayout layout={TemplateDashboard} exact path="/article" component={NewsList}/>
              <RouterLayout layout={TemplateDashboard} exact path="/article/new" component={NewsForm}/>
              <RouterLayout layout={TemplateDashboard} exact path="/article/:id" component={NewsDetail}/>
              <RouterLayout layout={TemplateDashboard} exact path="/article/:id/edit" component={NewsForm}/>
              <RouterLayout layout={TemplateDashboard} exact path="/information" component={Information}/>
              <RouterLayout layout={TemplateDashboard} exact path="/information/menu-order" component={MenuOrder}/>
              <RouterLayout layout={TemplateDashboard} exact path="/information/new" component={InformationEdit}/>
              <RouterLayout layout={TemplateDashboard} exact path="/information/:id" component={Information}/>
              <RouterLayout layout={TemplateDashboard} exact path="/information/edit/:id" component={InformationEdit}/>
              <RouterLayout layout={TemplateDashboard} exact path="/information/subpage/:id"
                            component={InformationEdit}/>
              <RouterLayout layout={TemplateDashboard} exact path="/activities" component={ActivitiesList}/>
              <RouterLayout layout={TemplateDashboard} exact path="/activities/new" component={ActivitiesEdit}/>
              <RouterLayout layout={TemplateDashboard} exact path="/activities/:id" component={ActivitiesDetail}/>
              <RouterLayout layout={TemplateDashboard} exact path="/activities/:id/edit" component={ActivitiesEdit}/>

              <RouterLayout layout={TemplateDashboard} exact path="/activities/:id/cart" component={ActivitiesCart}/>
              <RouterLayout layout={TemplateDashboard} exact path="/activities/:id/cart-retry"
                            component={ActivitiesCart}/>

              <RouterLayout layout={TemplateDashboard} exact path="/blog" component={BlogList}/>
              <RouterLayout layout={TemplateDashboard} exact path="/blog/:id" component={BlogDetail}/>
              <RouterLayout layout={TemplateDashboard} exact path="/blog/:id/edit" component={BlogEdit}/>
              <RouterLayout layout={TemplateDashboard} exact path="/contacts" component={Temp}/>
              <RouterLayout layout={TemplateDashboard} exact path="/gallery" component={GalleryList}/>
              <RouterLayout layout={TemplateDashboard} exact path="/notifications" component={NotificationList}/>
              <RouterLayout layout={TemplateDashboard} exact path="/profile/:id" component={Profile}/>
              <RouterLayout layout={TemplateDashboard} exact path="/profile" component={ProfileList}/>
              <RouterLayout layout={TemplateDashboard} exact path='/activities/:id/booking' component={Booking}/>
              <RouterLayout layout={TemplateDashboard} exact path="/checkout/:message" component={CheckOut}/>
              <RouterLayout layout={TemplateDashboard} exact path="/applications" component={ApplicationList}/>
              <RouterLayout layout={TemplateDashboard} exact path='/translations' component={Translation}/>
              <RouterLayout layout={TemplateDashboard} exact path='/translations/:id' component={TranslationEdit}/>
              <RouterLayout layout={TemplateDashboard} exact path='/search' component={SearchResults}/>
              <RouterLayout layout={TemplateDashboard} exact path="/samples" component={Samples}/>

              <RouterLayout exact path="/guide/booking" component={GuideBooking}/>
              {/*<RouterLayout layout={TemplateDashboard} exact path="/guide/booking/iframe" component={GuideIframe}/>*/}
              <RouterLayout exact path="/guide/booking/reports" component={GuideBookingList}/>
              <RouterLayout layout={TemplateDashboard} exact path="/get-bank-id-session" component={AuthBankIdHosted}/>

              <Redirect push to={`${redirectUrl ? `${redirectUrl}` : '/'}`}/>
            </Switch>
          )}
      </>
      }
    </Router>
  );
}
export default RouterComp;
