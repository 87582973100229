import React, {useEffect, useState} from 'react';
import theme from './style';
import Button from '../../components/Button';
import ToTopWrap from '../../components/ToTopWrap';
import {SmallTitle} from '../../components/DataDisplay';
import EventHeader from '../../components/EventHeader';
import translate, {tryParseToHtml} from '../../functions/applyTranslation';
import {useSelector, useDispatch} from 'react-redux';

import useInformationHook from './hook';
import moment from 'moment';
import {Grid, Typography} from '@material-ui/core';
import clsx from 'clsx';
import Logo from '../../components/Header/Logo';
import useAPI, {useImage} from '../../api/apiHandler';
import Cart from './cart';
import usePermissionsHook from '../../functions/permissions';
import useSettingsHook from '../../functions/settings';
import {setLoading} from "../../redux/states/statesActions";

const ActivitiesDetail = () => {
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const classes = theme();
  const loading = useSelector(state => state.states.loading);
  const access_token = useSelector(state => state.auth.access_token);
  const [role, hasSetting] = useSettingsHook();
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const [getImageURL] = useImage();
  const [data, handleNavigation, notFound] = useInformationHook();
  const [tourManager, setTourManager] = useState({});
  const {
    jfm_event,
    event,
    asset,
    products,
    associations,
    user,
    program
  } = data;
  const {
    event_start,
    event_end,
    event_type,
    event_status
  } = jfm_event;
  const {
    title,
    cdate,
    image,
    osa_end,
    osa_pay_end,
    type,
    location
  } = event;
  const {
    name,
    description
  } = asset;

  let _products = [];

  if (products)
    _products = products.reverse();

  let adultMemberTicket = _products ? _products.find(item => item.pay === '1' && item.type === '1') : null
  let adultNonMemberTicket = _products ? _products.find(item => item.pay === '0' && item.type === '1') : null
  let childMemberTicket = _products ? _products.find(item => item.pay === '1' && item.type === '2') : null
  let childNonMemberTicket = _products ? _products.find(item => item.pay === '0' && item.type === '2') : null
  let familyMemberTicket = _products ? _products.find(item => item.pay === '1' && item.type === '3') : null
  let familyNonMemberTicket = _products ? _products.find(item => item.pay === '0' && item.type === '3') : null

  let existMemberTicket = !!adultMemberTicket || !!childMemberTicket || !!familyMemberTicket;
  let existNonMemberTicket = !!adultNonMemberTicket || !!childNonMemberTicket || !!familyNonMemberTicket;
  const currency = translate('', 'label_currency_abbreviation');

  const fetchTourManager = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `user/profile?user_id=39`,
      method: 'GET',
    }).then(res => {
      if (res && res.data) {
        setTourManager({
          name: res.data.name,
          phone: res.data.phone,
          email: res.data.mail,
        });
      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  };

  useEffect(() => {
    fetchTourManager();
  }, [])
  return (
    <div className={classes.pageWrap}>
      {(!notFound && !loading) &&
      (<>
          <div className={classes.headerWrap}>
            <div className={classes.buttonGroup}>
              <Button label={translate('', 'button_back')} icon='back' fill onClick={() => {
                handleNavigation('back')
              }}/>
              {hasPermission(permissions.ACTIVITIES_EDIT) &&
              <Button label={translate('activities-edit', 'button_edit_page')} icon='edit' fill onClick={() => {
                handleNavigation('edit')
              }}/>
              }
              {hasPermission(permissions.ACTIVITIES_BOOKING_ACCESS) &&
              <Button className={classes.booking} label={translate('activities-edit', 'button_booking_management')}
                      icon='edit' fill onClick={() => {
                handleNavigation('booking')
              }}/>
              }
            </div>
            <p
              className={classes.date}>{`${translate('', 'label_published')} ${event_status === "2" ? moment.unix(cdate).format('D MMMM YYYY') : "-"}`}
            </p>
          </div>
          <EventHeader className={classes.borderBottom} simple={false}
                       title={title}
                       date_time={event_start}
                       end_time={event_end}
                       location={{name: name || '', description: description || '', location: location}}
                       image={image}
          >
            {products && products.length > 0 &&
            <Cart event={event}
                  role={role}
                  osa_end={osa_end}
                  osa_pay_end={osa_pay_end}
                  adultMemberTicket={adultMemberTicket}
                  childMemberTicket={childMemberTicket}
                  handleNavigation={handleNavigation}/>
            }
          </EventHeader>
          <div className={classes.contentWrap}>
            <SmallTitle text={translate('activities-edit', 'label_about_title')}/>
            <div className={classes.htmlContent}>
              {tryParseToHtml(event.description, access_token)}
            </div>
            {event_type === "14" &&
            <>
              <div>{`${translate('activities-edit', 'label_program')}: ${program.name}`}</div>
              <div>{`${translate('activities-edit', 'label_time')}: ${moment.unix(event_start).format('HH:mm')} - ${moment.unix(event_end).format('HH:mm')}`}</div>
            </>
            }
          </div>
          {type === "event" &&
          <div className={clsx(classes.detailedInfoWrap, classes.borderBottom)}>
            <SmallTitle text={translate('activities-edit', 'label_detailed_info_title')}/>
            <p>{translate('activities-edit', 'label_detailed_info')}</p>
            <p>&nbsp;</p>
            <p>{translate('activities-edit', 'label_price_list')}</p>
            {existMemberTicket && adultMemberTicket &&
            <p>{`${adultMemberTicket.name}: ${adultMemberTicket.amount} ${currency} ${existNonMemberTicket && adultMemberTicket ? `(${adultNonMemberTicket.amount} ${currency})` : ''} `}</p>
            }
            {existMemberTicket && childMemberTicket &&
            <p>{`${childMemberTicket.name}: ${childMemberTicket.amount} ${currency} ${existNonMemberTicket && childNonMemberTicket ? `(${childNonMemberTicket.amount} ${currency})` : ''} `}</p>
            }
            {familyMemberTicket && familyMemberTicket &&
            <p>{`${familyMemberTicket.name} (${translate('', 'label_family_ticket_info')}): ${familyMemberTicket.amount} ${currency} ${existNonMemberTicket && familyNonMemberTicket ? `(${familyNonMemberTicket.amount} ${currency})` : ''} `}</p>
            }
          </div>
          }
          <div className={classes.organizerContactWrap}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <SmallTitle text={translate('activities-edit', 'label_contact_person')}/>
                <p>{event_type === "14" ? tourManager.name : (jfm_event.alt_contact && jfm_event.alt_contact !== " ") ? jfm_event.alt_contact : (user) ? user.name : ' '}</p>
                <p>{event_type === "14" ? tourManager.phone : (jfm_event.alt_phone && jfm_event.alt_phone !== " ") ? jfm_event.alt_phone : (user) ? user.phone : ' '}</p>
                <p>{event_type === "14" ? tourManager.email : (jfm_event.alt_email && jfm_event.alt_email !== " ") ? jfm_event.alt_email : (user) ? user.email : ' '}</p>
              </Grid>
              {Number(jfm_event.grp_type) === 1 &&
              <Grid item xs={12}>
                <SmallTitle text={translate('activities-edit', 'label_organizer')}/>
                {associations && associations.map(x => {
                  return <div className={classes.organizer}>{x.name}</div>
                })}
                {/*
                  <Logo isBlue />
                  <p>TODO: Judiska Församlingen i Malmö</p> */}
              </Grid>
              }
            </Grid>
          </div>
          <div className={classes.warningWrap}>
            <b>{translate('activities-edit', 'label_warning_title')}</b>
            <p>
              {translate('activities-edit', 'label_warning_text')}
            </p>
          </div>
          <ToTopWrap/>
        </>
      )}
      {(notFound && !loading) && (<div style={{textAlign: "center"}}>
        <Typography variant="h2">{translate('activities_edit', 'activity_not_found')}</Typography>
      </div>)}
    </div>
  );
}
export default ActivitiesDetail;
