import moment from 'moment';

const formatLongDate = (date) => {
  if (date) {
    return moment.unix(date).format('D MMMM YYYY')
  } else {
    return '-'
  }
}

export default formatLongDate;