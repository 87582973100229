import {
  SET_CART
} from './cartTypes';
const initialState = {
  session_id: null,
  order_id: null,
}
const CartReducer = function (state = initialState, action) {
  const { type, payload, } = action;
  switch (type) {
    case SET_CART:
      return {
        session_id: payload.session_id,
        order_id: payload.order_id,
      }
    default:
      return state;
  }
}
export default CartReducer;