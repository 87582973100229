import React, { useState } from 'react';
import theme from './style';
import { TextField, } from '@material-ui/core';
import clsx from 'clsx';
import translate from '../../../functions/applyTranslation';
const LabelInput = (props) => {
  const classes = theme();
  const [data, setData] = useState('')
  const {
    onClick,
    fullWidth,
  } = props;

  const handleChange = (e) => {
    setData(e.target.value)
  }
  const handleClick = (e) => {
    try { e.preventDefault(); } catch (error) { }
    if (onClick && !!data.length && !!data.trim().length) {
      onClick(data);
      setData('');
    }
  }
  return (
    <form className={
      clsx(
        classes.labelInputGroup,
        fullWidth ? classes.fullWidth : '',
      )}
      onSubmit={handleClick}
    >
      <TextField
        value={data}
        onChange={handleChange} />
      <button type='submit' className={'actionButton'}
      >{translate('', 'button_add')}</button>
    </form >
  );
}
export default LabelInput;