import { makeStyles } from "@material-ui/core/styles";
import ColorScheme from '../../../settings/colorScheme'
const theme = () => {
  const styles = makeStyles(theme => ({
    pageWrap: {

    },
    formFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 10,
      paddingTop: 10,
      borderTop: `1px solid ${ColorScheme.highDarkGrey}`,
      '& >:nth-child(1)': {
        marginRight: 10,
      }
    },
    activityType: {
      marginTop: 9
    },
    otherRoom: {
      marginTop: 8
    },
    activityContainer: {
      [theme.breakpoints.up('sm')]: {
        '& >:not(:first-child)': {
          paddingLeft: 10,
        }
      },
    },
    responsableContainer: {
      [theme.breakpoints.up('sm')]: {
        paddingRight: 10
      }
    },
    inputContainer: {
      marginBottom: 10
    },
    inlineContact: {
      marginTop: 10,
      display: 'inline-flex',
      '& p': {
        width: 'auto',
        marginRight: 11,
      }
    },
    bold: {
      fontWeight: '500',
      color: `${ColorScheme.darkBlue} !important`,
      fontSize: '14px !important'
    },
    label: {
      '& .title': {
        fontWeight: '500 !important',
        color: `${ColorScheme.darkBlue} !important`,
        fontSize: '14px !important'
      }
    },
    paddingFix: {
      paddingTop: 13,
    }
  }));
  return styles();
}
export default theme;

