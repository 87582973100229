import {makeStyles} from "@material-ui/core/styles";

const theme = () => {
  const styles = makeStyles(theme => ({
    text: {
      color: "#000000 !important"
    },
    title: {
      marginBottom: 5
    },
    bold: {
      fontWeight: "bold"
    }
  }));
  return styles();
}
export default theme;