import React, { useState, useEffect } from "react";
import useAPI from '../../api/apiHandler';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import "./App.css";
import translate from "../../functions/applyTranslation";

const Message = ({ message }) => (
  <section>
    <p className={`checkout-${message.type}`}>{message.message}</p>
  </section>
);

const Waiting = ({ }) => (
  <section>
    <p className={'checkout-waiting'}>{translate('checkout', 'label_waiting')}</p>
  </section>
);

export default function App() {
  const history = useHistory();
  const [setRequest] = useAPI();
  const cart = useSelector(state => state.cart);
  const params = useParams();
  const { message } = params;
  const [data, setData] = useState({ data: {}, message: null, type: null })

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery()
  useEffect(() => {
    if (message === 'success') {
      const session_id = query.get('session_id');
      if (session_id !== cart.session_id) {
        setData({
          ...data,
          message: translate('checkout', 'label_invalid_session_id'),
          type: 'error'
        })
        return;
      }
      setRequest({
        url: `checkout/order?order_id=${cart.order_id}`,
        method: 'GET',
      }).then(res => {
        if (res && res.data) {
          setData({
            data: res.data,
            message: translate('checkout', 'label_payment_succeeded'),
            type: 'success'
          })
        }
      })
    }
    else if (message === 'cancel') {
      setRequest({
        url: `checkout/order?order_id=${cart.order_id}`,
        method: 'GET',
      }).then(res => {
        if (res && res.data) {
          if (res.data.status === 'unpaid')
            history.push(`/activities/${res.data.event_id}/cart-retry`)
          else
            setData({
              data: res.data,
              message: translate('checkout', 'label_payment_succeeded'),
              type: 'success'
            })
        }
      })
    }
    else {
      setData({
        ...data,
        message: translate('checkout', 'label_payment_error'),
        type: 'error'
      })
    }
  }, [])

  return data.message ? <Message message={data} /> : <Waiting />
}