import {useState, useEffect} from "react";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import useAPI from "../../api/apiHandler";
import {setError, setLoading} from "../../redux/states/statesActions";
import NOT_BOOKABLE_DATES from "../../functions/notBookableDates";

const GuideBookingHook = () => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const [setRequest] = useAPI();
  const dispatch = useDispatch();

  const [displayedText, setDisplayedText] = useState({});
  const [invalidList, setInvalidList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [data, setData] = useState({selectedProgram: {}});
  const [errorCalendar, setErrorCalendar] = useState(false);
  const [bookingComplete, setBookingComplete] = useState(false);
  const [tempEvent, setTempEvent] = useState();
  const [programs, setPrograms] = useState([]);
  const [checkedGDPR, setCheckGDPR] = useState(false);
  const [freeSlot, setFreeSlot] = useState(true);
  const [datesRange, setDatesRange] = useState([]);

  const handleSelectChange = (value) => {
    setData({...data, ["selectedProgram"]: value});
    setDisplayedText(value)
  }

  const handleDateChange = (value) => {
    const startDate = value;
    const endDate = moment(startDate).add(Number(data.selectedProgram.duration), 'seconds');
    setData({
      ...data,
      startDate: startDate,
      endDate: endDate
    });

    let monday = moment(value).startOf('isoWeek').toDate();
    let friday = moment(value).endOf('week').subtract(2, 'days').toDate();
    setDatesRange([monday, friday])
    setDialogOpen(true);
  }

  const handleTextChange = (value, id) => {
    setData({...data, [id]: value})
  }

  const createEvent = () => {
    let event = {
      title: "Vald tid",
      start: moment(data.startDate).toDate(),
      end: moment(data.endDate).toDate(),
      notBookable: false,
      tempEvent: true
    };

    setTempEvent(event)
  }

  const validator = () => {
    let invalid = [];

    if (!data.organisation || data.organisation.length === 0) {
      invalid.push("organisation");
    }
    if (!data.name || data.name.length === 0) {
      invalid.push("name");
    }
    if (!data.adress || data.adress.length === 0) {
      invalid.push("adress");
    }
    if (!data.phone || data.phone.length === 0) {
      invalid.push("phone");
    }
    if (!data.nr_visitors || data.nr_visitors.length === 0) {
      invalid.push("nr_visitors");
    }
    if (!data.email || data.email.length === 0 || !emailRegex.test(data.email)) {
      invalid.push("email");
    }
    if (!data.confirmEmail || data.confirmEmail.length === 0 || data.confirmEmail !== data.email || !emailRegex.test(data.confirmEmail)) {
      invalid.push("confirmEmail");
    }
    if (!data.zip || data.zip.length === 0) {
      invalid.push("zip");
    }
    if (!data.city || data.city.length === 0) {
      invalid.push("city");
    }
    if (!data.startDate) {
      invalid.push("start_time");
      setErrorCalendar(true);
    }
    if (!checkedGDPR) {
      invalid.push("gdpr");
    }

    if (Number(data.nr_visitors) > 35) {
      invalid.push("nr_visitors_to_high")
    }

    setInvalidList(invalid);
    return invalid.length === 0
  }

  const submit = () => {
    if (validator()) {
      let formData = new FormData();
      formData.append('organisation', data.organisation);
      formData.append('name', data.name);
      formData.append('adress', data.adress);
      formData.append('zip', data.zip);
      formData.append('city', data.city);
      formData.append('phone', data.phone);
      formData.append('nr_visitors', data.nr_visitors);
      formData.append('email', data.email);
      formData.append('start_time', moment(data.startDate).unix())
      formData.append('program_id', data.selectedProgram.id)

      if (data.grade && data.grade.length > 0)
        formData.append('grade', data.grade);

      if (data.message && data.message.length > 0)
        formData.append('message', data.message);

      dispatch(setLoading(true));
      setRequest({
        url: "event/group",
        method: "POST",
        data: formData
      })
        .then(res => {
          if (res) {
            setBookingComplete(true)
          } else {
            setTempEvent(null);
          }
          dispatch(setLoading(false));
          // window.scrollTo({
          //   top: 0,
          //   behavior: 'smooth'
          // });
        })
        .catch(() => {
          dispatch(setLoading(false));
          setTempEvent(null);
        });
    }
  }

  const fetchEvents = () => {
    dispatch(setLoading(true));
    let start_time = moment(datesRange[0]).unix();
    let end_time = moment(datesRange[1]).unix();
    setRequest({
      url: `events/public?start_time=${start_time}&end_time=${end_time}`,
      method: 'GET'
    })
      .then(res => {
        if (res) {
          const _events = [...res.data];
          let eventsArray = [];
  
          _events.forEach(item => {
            if (item.type === 'event') {
              let event = {
                title: "Upptaget",
                start: moment.unix(item.start_time).toDate(),
                end: moment.unix(item.end_time).toDate(),
                notBookable: false
              }
              eventsArray.push(event);
            }
            if (item.type === 'block') {
              let startDate = moment.unix(item.start_time).startOf('day');
              let endDate = moment.unix(item.end_time).endOf('day');
  
              while (startDate.isBefore(endDate)) {
                let blockStart = startDate.clone().hour(8).minute(30).second(0);
                let blockEnd = startDate.clone().hour(16).minute(30).second(0);
  
                // Adjust for the first day of the block
                if (startDate.isSame(moment.unix(item.start_time), 'day')) {
                  if (moment.unix(item.start_time).isAfter(startDate.clone().hour(16).minute(30).second(0))) {
                    startDate.add(1, 'day');
                    continue;
                  }
                  blockStart = moment.unix(item.start_time);
                }
  
                // Adjust for the last day of the block
                if (startDate.isSame(moment.unix(item.end_time), 'day')) {
                  blockEnd = moment.unix(item.end_time);
                }
  
                let event = {
                  title: item.description || 'Stängd',
                  start: blockStart.toDate(),
                  end: blockEnd.toDate(),
                  notBookable: true,
                  halfDay: false
                }
  
                eventsArray.push(event);
                startDate.add(1, 'day');
              }
            }
          });
  
          setEvents(eventsArray);
        }
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  }
  
  
//   const generateBlockForOctober = () => {
//     const blockEvents = [];
//     const startOfMonth = moment("2024-07-08");
//     const endOfMonth = moment("2024-07-27");

//     let currentDay = startOfMonth;

//     while (currentDay <= endOfMonth) {
//       const startOfBlock = currentDay.clone().hour(8).minute(30).second(0);
//       const endOfBlock = currentDay.clone().hour(16).minute(30).second(0);
      
//       blockEvents.push({
//         type: 'block',
//         start_time: startOfBlock.unix(),
//         end_time: endOfBlock.unix(),
//         notBookable: true,
//         halfDay: false,
//         description: 'Ej bokningsbar',
//       });
      
//       currentDay.add(1, 'day');
//     }
    
//     return blockEvents;
//   }
  

  const fetchPrograms = () => {
    dispatch(setLoading(true))
    setRequest({
      url: 'event/tour_programs',
      method: 'GET'
    })
      .then(res => {
        if (res) {
          setPrograms(res.data)
        }
        dispatch(setLoading(false))
      })
      .catch(e => dispatch(setLoading(false)))
  }

  const checkSlot = value => {
    const startDate = moment(value);
    const endDate = moment(startDate).add(Number(data.selectedProgram.duration), 'seconds');
  
    let isFreeSlot = true;
  
    if (startDate.isoWeekday() > 5) { 
      isFreeSlot = false;
    }
  
    if (startDate.isoWeekday() <= 5) {
      if (
        (startDate.hour() < 8 || (startDate.hour() === 8 && startDate.minute() < 30)) ||
        (endDate.hour() > 16 || (endDate.hour() === 16 && endDate.minute() > 30))
      ) {
        isFreeSlot = false;
      }
  
      if (startDate.isoWeekday() === 5) {
        if (
          (endDate.hour() > 13 || (endDate.hour() === 13 && endDate.minute() > 30))
        ) {
          isFreeSlot = false;
        }
      }
    }
  
    events.forEach(event => {
      if (
        (startDate.isBetween(event.start, event.end, undefined, '[)') || 
        endDate.isBetween(event.start, event.end, undefined, '(]')) ||
        (moment(event.start).isBetween(startDate, endDate, undefined, '[)') ||
        moment(event.end).isBetween(startDate, endDate, undefined, '(]'))
      ) {
        isFreeSlot = false;
      }
    });
  
    setFreeSlot(isFreeSlot);
  }
  

//   useEffect(() => {
//     generateBlockForOctober();
//   }, []);

  useEffect(() => {
    fetchPrograms();
    let monday = moment(new Date()).startOf('isoWeek').toDate();
    let friday = moment(new Date()).endOf('week').subtract(2, 'days').toDate();
    setDatesRange([monday, friday]);
  }, []);

  useEffect(() => {
    if (datesRange.length > 0) {
      fetchEvents();
    }
  }, [datesRange]);

  return [programs, data, displayedText, setDisplayedText, handleSelectChange, handleTextChange, invalidList, handleDateChange, dialogOpen, setDialogOpen, events, submit, createEvent, errorCalendar, bookingComplete, tempEvent, checkedGDPR, setCheckGDPR, checkSlot, freeSlot, datesRange, setDatesRange];
}

export default GuideBookingHook;
