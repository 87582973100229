import {makeStyles} from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme';

const theme = (props) => {
  const styles = makeStyles(theme => ({
    subHeader: {
      backgroundColor: ColorSchemes.lowLightGrey,
      height: 31,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      position: 'fixed',
      top: 66,
      left: 0,
      right: 0,
      zIndex: 100,
      padding: '0px 40px',
      boxShadow: `3px 3px 6px ${ColorSchemes.highLightGrey}`,
      '@media (max-width: 540px)': {
        top: 105
      }
    },
    menuWrapper: {
      maxHeight: 31,
      display: 'flex',
      alignItems: 'center',
      '& .menuItem': {
        cursor: 'pointer',
        color: ColorSchemes.highDarkGrey,
        fontSize: 14,
        lineHeight: '17px',
        padding: '0px 10px'
      },
      '& .menuActive': {
        color: ColorSchemes.orange
      },
      'scrollbar-width': 'none', /* Firefox */
      '-ms-overflow-style': 'none',  /* IE 10+ */
      '&::-webkit-scrollbar': {
        width: 0,
        background: 'transparent', /* Chrome/Safari/Webkit */
      }
    },
    targetBlankLink: {
      color: "#636363",
      cursor: "pointer",
      padding: "0px 10px",
      fontSize: 14,
      textDecoration: "none",
      boxShadow: "none"
    }
  }));
  return styles();
}
export default theme;
