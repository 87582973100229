import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    categoriesWrap: {
      display: 'inline-flex',
      marginRight: 10,
      fontSize: '0.8em',
      '& span': {
        marginLeft: 3,
        color: ColorSchemes.highDarkGrey,
      },
      '& p': {
        display: 'inline',
        margin: 0,
        color: ColorSchemes.highDarkGrey
      },
      '& a': {
        cursor: 'pointer',
        color: ColorSchemes.mediumBlue,
        '&:hover': {
          color: ColorSchemes.orange,
        }
      },
    },
    selected: {
      '& a': {
        color: ColorSchemes.orange,
        textDecoration: 'underline'
      }
    },
  }));
  return styles();
}
export default theme;
