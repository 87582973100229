import {
  LOGIN,
  LOGOUT,
  SET_USER_DATA,
  SET_USER_SETTINGS
} from './authTypes';
export const setLogin = (payload) => async (dispatch) => {
  dispatch({type: LOGIN, payload: payload})
  return Promise.resolve();

}
export const setUserData = (payload) => {
  return {
    type: SET_USER_DATA,
    payload: payload
  }
}
export const setLogout = () => {
  sessionStorage.removeItem('aLP');
  sessionStorage.removeItem('aLS');
  sessionStorage.removeItem('nLP');
  sessionStorage.removeItem('nLS');
  return {
    type: LOGOUT
  }
}
export const setUserSettings = (payload) => {
  return {
    type: SET_USER_SETTINGS,
    payload: payload
  }
}
