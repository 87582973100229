import { useState, useEffect } from 'react';
import useAPI from '../../api/apiHandler';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../redux/states/statesActions';

const NotificationsHook = (props) => {
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const userData = useSelector(state => state.auth.userData);
  const [notifications, setNotifications] = useState([])
  const [notificationsInfo, setNotificationsInfo] = useState({
    totalItems: 0,
    totalPages: 1,
  })
  const [categories, setCategories] = useState([])
  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
  })

  const fetchNotifications = () => {
    dispatch(setLoading(true))
    const { page, limit, } = filter;
    //FIXME: remove hardcoded id
    let url = `notifications?user_id=${2 || userData.id}&page=${page}&limit=${limit}`;
    setRequest({
      url: url,//FIXME: fix the url
      method: 'GET',
    }).then(res => {
      if (res) {
        setNotifications(res.data.notifications)
        setNotificationsInfo({
          totalPages: Number(res.data.pages),
          totalItems: Number(res.data.total)
        })
      }
      dispatch(setLoading(false))
    })
  }
  const handlePagination = (action) => {
    setFilter({ ...filter, page: (action === 'next' ? filter.page += 1 : filter.page -= 1) })
  }
  useEffect(() => {
    if (setRequest) {
      fetchNotifications()
    }
    // eslint-disable-next-line
  }, [filter])
  return [notifications, notificationsInfo, filter, handlePagination];
}

export default NotificationsHook;