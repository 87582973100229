import {
  SET_TRANSLATIONS,
  SET_ACCEPT_BLOG_RULES,
  SET_WIDE_CARD
} from './settingsTypes';
const initialState = {
  translations: {},
  acceptedBlogRules: false,
  wideCard: false
}
const SettingsReducer = function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_TRANSLATIONS:
      return {
        ...state,
        translations: payload,
      }
    case SET_ACCEPT_BLOG_RULES:
      return {
        ...state,
        acceptedBlogRules: payload,
      }
    case SET_WIDE_CARD:
      return {
        ...state,
        wideCard: payload
      }

    default:
      return state;
  }
}
export default SettingsReducer;
