import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    labelInputGroup: {
      display: 'inline-flex',
      height: 35,
      '& ::after': {
        border: 'none !important'
      },
      '& ::before': {
        border: 'none !important'
      },
      '& .MuiFormControl-root': {
        flexGrow: 1,
      },
      '& .MuiInput-root': {
        borderColor: ColorSchemes.highDarkGrey,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: '3px 0px 0px 3px',
        flexGrow: 1,
        borderRight: 'none !important',
      },
      '& .MuiInputBase-input': {
        //paddingLeft: 6,
        background: 'none',
        padding: '2px 10px',
        background: 'none',
        fontSize: 14,
      },
      '& .actionButton': {
        border:'none',
        outline:'none',
        fontWeight: 500,
        cursor: 'pointer',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 12,
        padding: '0px 10px',
        borderRadius: '0px 3px 3px 0px',
        backgroundColor: ColorSchemes.mediumBlue,
        color: ColorSchemes.white,
        '&:hover': {
          backgroundColor: ColorSchemes.orange,
        }
      }
    },
    fullWidth: {
      width: '100%'
    },
  }));
  return styles();
}
export default theme;
