import React from 'react';
import theme from './style';
import ApplicationHeader from '../ApplicationHeader';
import ApplicationBody from '../ApplicationBody';
import { useHistory } from 'react-router-dom';

const ApplicationView = (props) => {
  const history = useHistory();

  const {
    item,
    width,
    path,
    expanded,
    handleExpanded,
    onClick
  } = props;

  const classes = theme({ width: width });

  const {
    id,
    cdate,
    status,
    data
  } = item;

  let application = {};
  try {
    application = JSON.parse(data);
  }
  catch (e) {
    application = {};
  }

  return (
    <div className={`${classes.listViewWrap} block`}>
      <ApplicationHeader
        id={id}
        date_created={cdate}
        status={status}
        path={path}
        expanded={expanded}
        handleExpanded={handleExpanded}
        onClick={onClick} />
      <ApplicationBody application={application} path={path} onClick={onClick} />
    </div>

  );
}
export default ApplicationView;