import React, {useState, useEffect} from 'react';
import theme from './style';
import {Paper} from '@material-ui/core';
import {Text} from '../../../components/DataDisplay';
import {Check} from '../../../components/Inputs';
import Button from '../../../components/Button';
import translate from '../../../functions/applyTranslation';
import {useDispatch, useSelector} from 'react-redux';
import {
  setLoading
} from '../../../redux/states/statesActions';
import useAPI from '../../../api/apiHandler';

const Notifications = (props) => {
  const classes = theme();
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const [setRequest] = useAPI();
  const loading = useSelector(state => state.states.loading);
  const [invalidList, setInvalidList] = useState([]);
  const [data, setData] = useState({
    articles: false,
    events: false,
    newsletters: false,
    blogs: false,
    success: false,
    notity_article: false,
    notity_activity: false,

  })

  const {saveMode, personal, settings} = props;

  const handleChange = (value, id) => {
    console.log(value, id)
    setData({...data, success: false, [id]: value})
  }

  const handleSubmit = (e) => {
    try {
      e.preventDefault()
    } catch (err) {
    }
    ;
    if (validator()) {
      dispatch(setLoading(true))
      setRequest({
        url: `user/settings?user_id=${data.id}`,
        method: 'PATCH',
        data: {
          user_id: data.id,
          event_certifier: (data.role === 'editor' || data.role === "admin" || data.role === "superadmin" || isNaN(props.id)) ? (data.notity_activity ? 1 : 0) : 0,
          articles_certifier: (data.role === 'editor' || data.role === "admin" || data.role === "superadmin" || isNaN(props.id)) ? (data.notity_article ? 1 : 0) : 0,
          newsletter_subscribed: data.newsletters ? "1" : "0",
          blog_published_subscription: data.blogs ? "1" : "0",
          article_published_notification: data.articles ? "1" : "0",
          event_published_notification: data.events ? "1" : "0",
        },
      }).then(res => {
        if (res) {
          setData({
            ...data,
            success: true
          })
        }
        dispatch(setLoading(false))
      })
        .catch(() => dispatch(setLoading(false)))
    }
  }

  const fetchData = () => {
    if (props.data && settings) {
      setData({
        id: props.data.id,
        articles: props.data.articles,
        events: props.data.events,
        newsletters: props.data.newsletters,
        blogs: props.data.blogs,
        notity_article: settings.articles_certifier === "1",
        notity_activity: settings.event_certifier === "1",
        success: false
      });
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [props.data, settings])

  const validator = () => {
    let invalid = [];
    Object.keys(data).forEach((key) => {
      var value = data[key];
      switch (key) {
        default:
          return true;
      }
    });
    setInvalidList(invalid);
    return invalid.length === 0
  }

  return (
    <Paper elevation={0} className={classes.formWrap}>
      <div className={classes.titleWrap}>
        <Text className={classes.title} text={translate('notifications', 'title_change_notifications')}/>
      </div>
      <form onSubmit={handleSubmit}>
        <Check className={classes.input} fullWidth
               label={translate('profile', 'placeholder_article_published_notification')} value={data.articles}
               onChange={handleChange} id='articles'/>
        <Check className={classes.input} fullWidth
               label={translate('profile', 'placeholder_event_published_notification')} value={data.events}
               onChange={handleChange} id='events'/>
        <Check className={classes.input} fullWidth
               label={translate('profile', 'placeholder_blog_published_subscription')} value={data.blogs}
               onChange={handleChange} id='blogs'/>
        <Check className={classes.input} fullWidth label={translate('profile', 'placeholder_newsletter_subscribed')}
               value={data.newsletters} onChange={handleChange} id='newsletters'/>
        {(personal.role === 'editor' || personal.role === "admin" || personal.role === "superadmin") &&
        <>
          <Check className={classes.input} fullWidth label={translate('profile', 'placeholder_notity_article_approval')}
                 value={data.notity_article} onChange={handleChange} id='notity_article'/>
          <Check className={classes.input} fullWidth
                 label={translate('profile', 'placeholder_notity_activity_approval')} value={data.notity_activity}
                 onChange={handleChange} id='notity_activity'/>
        </>
        }
        <div className={classes.loginButtonWrap}>
          <Button disabled={loading || !saveMode} icon='save' fill label={translate('profile', 'button_save')}
                  type='submit'/>
        </div>
        {data && data.success &&
        <div className={classes.loginButtonWrap}>
          <Text text={translate('profile', 'label_set_notifications_success')} className={classes.success}/>
        </div>
        }
      </form>
    </Paper>
  )
}
export default Notifications;
