import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const { height } = props;
  const styles = makeStyles(theme => ({
    logo: {
      maxHeight: height || 42,
      // width:'auto'
      '&.pointer': {
        cursor: 'pointer',
      }
    }
  }));
  return styles();
}
export default theme;

