import {
  SET_LOADING,
  SET_ERROR,
  SET_LOCATION,
  INCREASE_COUNT
} from './statesTypes';
const initialState = {
  error: null,
  loading: null,
  location: null,
  count: 0
}
const StatesReducer = function (state = initialState, action) {
  const { type, payload, } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case SET_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case SET_LOCATION:
      return {
        ...state,
        location: payload,
      }
    case INCREASE_COUNT:
      return {
        ...state,
        count: state.count + 1,
      }
    default:
      return state;
  }
}
export default StatesReducer;