import moment from "moment";

const NOT_BOOKABLE_DATES = [
  {
    startDate: moment(new Date(2021, 11, 1)).startOf('day'),
    endDate: moment(new Date(2021, 11, 1)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 2)).startOf('day'),
    endDate: moment(new Date(2021, 11, 2)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 3)).startOf('day'),
    endDate: moment(new Date(2021, 11, 3)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 4)).startOf('day'),
    endDate: moment(new Date(2021, 11, 4)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 5)).startOf('day'),
    endDate: moment(new Date(2021, 11, 5)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 6)).startOf('day'),
    endDate: moment(new Date(2021, 11, 6)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 7)).startOf('day'),
    endDate: moment(new Date(2021, 11, 7)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 8)).startOf('day'),
    endDate: moment(new Date(2021, 11, 8)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 9)).startOf('day'),
    endDate: moment(new Date(2021, 11, 9)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 10)).startOf('day'),
    endDate: moment(new Date(2021, 11, 10)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 11)).startOf('day'),
    endDate: moment(new Date(2021, 11, 11)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 12)).startOf('day'),
    endDate: moment(new Date(2021, 11, 12)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 13)).startOf('day'),
    endDate: moment(new Date(2021, 11, 13)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 14)).startOf('day'),
    endDate: moment(new Date(2021, 11, 14)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 15)).startOf('day'),
    endDate: moment(new Date(2021, 11, 15)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 16)).startOf('day'),
    endDate: moment(new Date(2021, 11, 16)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 17)).startOf('day'),
    endDate: moment(new Date(2021, 11, 17)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 18)).startOf('day'),
    endDate: moment(new Date(2021, 11, 18)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 19)).startOf('day'),
    endDate: moment(new Date(2021, 11, 19)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 20)).startOf('day'),
    endDate: moment(new Date(2021, 11, 20)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 21)).startOf('day'),
    endDate: moment(new Date(2021, 11, 21)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 22)).startOf('day'),
    endDate: moment(new Date(2021, 11, 22)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 23)).startOf('day'),
    endDate: moment(new Date(2021, 11, 23)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 24)).startOf('day'),
    endDate: moment(new Date(2021, 11, 24)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 25)).startOf('day'),
    endDate: moment(new Date(2021, 11, 25)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 26)).startOf('day'),
    endDate: moment(new Date(2021, 11, 26)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 27)).startOf('day'),
    endDate: moment(new Date(2021, 11, 27)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 28)).startOf('day'),
    endDate: moment(new Date(2021, 11, 28)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 29)).startOf('day'),
    endDate: moment(new Date(2021, 11, 29)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 30)).startOf('day'),
    endDate: moment(new Date(2021, 11, 30)).endOf('day')
  },
  {
    startDate: moment(new Date(2021, 11, 31)).startOf('day'),
    endDate: moment(new Date(2021, 11, 31)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 0, 26, 12, 0, 0, 0)),
    endDate: moment(new Date(2022, 0, 26)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 0, 27)).startOf('day'),
    endDate: moment(new Date(2022, 0, 27)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 1, 16)).startOf('day'),
    endDate: moment(new Date(2022, 1, 16, 12, 0 , 0 , 0))
  },
  {
    startDate: moment(new Date(2022, 2, 16, 12, 0, 0, 0)),
    endDate: moment(new Date(2022, 2, 16)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 2, 17)).startOf('day'),
    endDate: moment(new Date(2022, 2, 17)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 3, 14)).startOf('day'),
    endDate: moment(new Date(2022, 3, 14)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 3, 15)).startOf('day'),
    endDate: moment(new Date(2022, 3, 15)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 3, 16)).startOf('day'),
    endDate: moment(new Date(2022, 3, 16)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 3, 17)).startOf('day'),
    endDate: moment(new Date(2022, 3, 17)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 3, 22)).startOf('day'),
    endDate: moment(new Date(2022, 3, 22)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 3, 28)).startOf('day'),
    endDate: moment(new Date(2022, 3, 28)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 4, 5)).startOf('day'),
    endDate: moment(new Date(2022, 4, 5)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 5, 6)).startOf('day'),
    endDate: moment(new Date(2022, 5, 6)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 5, 24)).startOf('day'),
    endDate: moment(new Date(2022, 5, 24)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 8, 23)).startOf('day'),
    endDate: moment(new Date(2022, 8, 23)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 8, 24)).startOf('day'),
    endDate: moment(new Date(2022, 8, 24)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 8, 25)).startOf('day'),
    endDate: moment(new Date(2022, 8, 25)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 8, 26)).startOf('day'),
    endDate: moment(new Date(2022, 8, 26)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 8, 27)).startOf('day'),
    endDate: moment(new Date(2022, 8, 27)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 8, 28)).startOf('day'),
    endDate: moment(new Date(2022, 8, 28)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 9, 4)).startOf('day'),
    endDate: moment(new Date(2022, 9, 4)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 9, 5)).startOf('day'),
    endDate: moment(new Date(2022, 9, 5)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 9, 9)).startOf('day'),
    endDate: moment(new Date(2022, 9, 9)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 9, 10)).startOf('day'),
    endDate: moment(new Date(2022, 9, 10)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 9, 11)).startOf('day'),
    endDate: moment(new Date(2022, 9, 11)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 9, 17)).startOf('day'),
    endDate: moment(new Date(2022, 9, 17)).endOf('day')
  },
  {
    startDate: moment(new Date(2022, 9, 18)).startOf('day'),
    endDate: moment(new Date(2022, 9, 18)).endOf('day')
  },
  {
    startDate: moment(new Date(2023, 0, 2)).startOf('day'),
    endDate: moment(new Date(2023, 0, 2)).endOf('day')
  },
  {
    startDate: moment(new Date(2023, 0, 3)).startOf('day'),
    endDate: moment(new Date(2023, 0, 3)).endOf('day')
  },
  {
    startDate: moment(new Date(2023, 0, 4)).startOf('day'),
    endDate: moment(new Date(2023, 0, 4)).endOf('day')
  },
  {
    startDate: moment(new Date(2023, 0, 5)).startOf('day'),
    endDate: moment(new Date(2023, 0, 5)).endOf('day')
  },
  {
    startDate: moment(new Date(2023, 0, 6)).startOf('day'),
    endDate: moment(new Date(2023, 0, 6)).endOf('day')
  },
  {
    startDate: moment(new Date(2023, 0, 7)).startOf('day'),
    endDate: moment(new Date(2023, 0, 7)).endOf('day')
  },
  {
    startDate: moment(new Date(2023, 0, 8)).startOf('day'),
    endDate: moment(new Date(2023, 0, 8)).endOf('day')
  },
];

export default NOT_BOOKABLE_DATES;