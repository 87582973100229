import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme';
const theme = (props) => {
  const styles = makeStyles(theme => ({
    userInfo: {
      display: 'flex',
      maxHeight: 42,
      alignItems: 'center'
    },
    badgeWrapper: {
      cursor: 'pointer',

      padding: 10,
      '& svg': {
        fill: ColorSchemes.white,
      }
    },
    textWrapper: {
      margin: '10px 0px',
      paddingLeft: 10,
      color: ColorSchemes.white,
      // borderLeft: `1px solid ${ColorSchemes.white}`,
      '& .user': {
        fontSize: 14,
      },
      '& .actions': {
        fontSize: 12,
        cursor: 'pointer'
      },
    },
    textWrapperTablet: {
      margin: '25px 20px 25px 10px',
      color: ColorSchemes.white,
      '& .user': {
        fontSize: 16,
        lineHeight: '19px',
        '& span': {
          cursor: 'pointer',
        }
      },
      '& .actions': {
        cursor: 'pointer',
        '& span': {
          padding: '4px 20px',
          fontSize: 14,
          height: '17px',
          lineHeight: '17px',
          backgroundColor: ColorSchemes.red,
          borderRadius: 13,
          color: ColorSchemes.white
        }
      },
    },
    popover: {
      marginTop: 15,
      '& .MuiPaper-root': {
        minWidth: 130,
        borderRadius: 5,
        borderColor: ColorSchemes.orange,
        borderWidth: 1,
        borderStyle: 'solid',
      },
    },
    popoverTitle: {
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 5,
      padding: 10,
    },
    popoverItemContainer: {
      '& .popoverItem:not(:last-child)': {
        borderBottom: `1px solid #DCDCDC`,
      },
      '& .popoverItem': {
        cursor: 'pointer',
        padding: '5px 10px',
        width: '100%',
      }
    },
    popoverDate: {
      fontSize: 10,
    },
    popoverType: {
      fontSize: 12,
      color: ColorSchemes.mediumBlue
    },
    popoverAction: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: ColorSchemes.white,
      background: ColorSchemes.lowDarkGrey,
      width: '100%',
      fontSize: 10,
      height: 19,
      marginTop: 5,
    },
    mobileMenuIcon: {
      color: "#FFFFFF",
      padding: "10px 0 10px 10px",
      cursor: "pointer"
    }
  }));
  return styles();
}
export default theme;

