import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../../settings/colorScheme'
const theme = (props) => {
  const styles = makeStyles(theme => ({
    title: {
      fontWeight: 'bold',
      fontSize: 12,
      lineHeight: '14px',
      color: '#000000 !important',
      marginBottom: 3
    },
    detail: {
      fontSize: 12,
      lineHeight: '14px',
      color: '#000000 !important'
    }
  }));
  return styles();
}
export default theme;

