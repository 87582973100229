import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const {
    mobile
  } = props;
  const styles = makeStyles(theme => ({
    headerWrap: {
      display: 'inline-flex',
      justifyContent: 'space-between',
      marginBottom: 10,
      width: '100%',
    },
    titleGroup: {
      order: !mobile ? 1 : 2,
    },
    buttonGroup: {
      order: mobile ? 1 : 2,
      justifyContent: 'flex-end',
      display: 'inline-flex',
      '& >:first-child': {
        marginRight: 10,
      }
    },
  }));
  return styles();
}
export default theme;

