import React from 'react';
import theme from './style';
import FaceIcon from '@material-ui/icons/Face';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import translate from '../../../functions/applyTranslation';
import clsx from 'clsx';
import {Grid} from "@material-ui/core";

export default function CartItems(props) {
  const classes = theme();
  const { members, data, onChange, childrenStartAt, childrenEndAt } = props;
  let adultTicket = data ? data.find(item => item.type === '1') : null
  let childTicket = data ? data.find(item => item.type === '2') : null
  let groupTicket = data ? data.find(item => item.type === '3') : null

  // const counterCreation = {
  // };
  // if (adultTicket) { counterCreation['adult'] = { quantity: 0, id: adultTicket.id } }
  // if (childTicket) { counterCreation['child'] = { quantity: 0, id: childTicket.id } }
  // if (groupTicket) { counterCreation['group'] = { quantity: 0, id: groupTicket.id } }

  // const [counter, setCounter] = useState(counterCreation);
  const reduceCounter = (type) => {
    let _counter = JSON.parse(JSON.stringify(members))
    if (_counter[type].quantity > 0) {
      _counter[type].quantity -= 1;
      if (onChange) {
        onChange(_counter);
      }
    }
  }
  const increaseCounter = (type) => {
    let _counter = JSON.parse(JSON.stringify(members))
    _counter[type].quantity += 1;
    if (onChange) {
      onChange(_counter);
    }
  }
  // useEffect(() => {
  //   if (onChange) {
  //     onChange(counter);
  //   }
  // }, [counter])
  const currencyByUnit = translate('', 'label_currency_abbreviation_by_unit');
  return (
    <div className={classes.groupItemWrap}>
      {adultTicket &&
        <div className={classes.itemWrap}>
          <Grid container className={classes.item}>
            <Grid item xs={4} className={classes.iconAndNameWrap}>
              <FaceIcon className='icon' />
              <div>
                <p>
                  {adultTicket.name}
                </p>
                {childrenEndAt() &&
                  <small>
                    {`${Number(childrenEndAt()) + 1}+ ${translate('', 'label_age')}`}
                  </small>
                }
              </div>
            </Grid>
            <Grid item xs={5} className={classes.priceWrap}>
              <p>
                {`${Number(adultTicket.amount)} ${currencyByUnit}`}
              </p>
            </Grid>
            <Grid item xs={3} className={classes.actionWrap}>
              <RemoveCircleIcon onClick={() => reduceCounter('adult')} className={clsx(classes.actionIcon, classes[members.adult.quantity === 0 ? 'inactive' : 'active'])} />
              <span>{members.adult.quantity}</span>
              <AddCircleIcon onClick={() => increaseCounter('adult')} className={clsx(classes.actionIcon, classes.active)} />
            </Grid>
          </Grid>
        </div>
      }
      {childTicket &&
        <div className={classes.itemWrap}>
          <Grid container className={classes.item}>
            <Grid item xs={4} className={classes.iconAndNameWrap}>
              <ChildCareIcon className='icon' />
              <div>
                <p>
                  {childTicket.name}
                </p>
                {childrenStartAt() && childrenEndAt() &&
                  <small>
                    {`${childrenStartAt()} - ${childrenEndAt()} ${translate('', 'label_age')}`}
                  </small>
                }
              </div>
            </Grid>
            <Grid item xs={5} className={classes.priceWrap}>
              <p>
                {`${Number(childTicket.amount)} ${currencyByUnit}`}
              </p>
            </Grid>
            <Grid item xs={3} className={classes.actionWrap}>
              <RemoveCircleIcon onClick={() => reduceCounter('child')} className={clsx(classes.actionIcon, classes[members.child.quantity === 0 ? 'inactive' : 'active'])} />
              <span>{members.child.quantity}</span>
              <AddCircleIcon onClick={() => increaseCounter('child')} className={clsx(classes.actionIcon, classes.active)} />
            </Grid>
          </Grid>
        </div>
      }
      {groupTicket &&
        <div className={classes.itemWrap}>
          <Grid container className={classes.item}>
            <Grid item xs={4} className={classes.iconAndNameWrap}>
              <GroupWorkIcon className='icon' />
              <div>
                <p>
                  {groupTicket.name}
                </p>
                <small>
                  {translate('', 'label_family_ticket_info')}
                </small>
              </div>
            </Grid>
            <Grid item xs={5} className={classes.priceWrap}>
              <p>
                {`${Number(groupTicket.amount)} ${currencyByUnit}`}
              </p>
            </Grid>
            <Grid item xs={3} className={classes.actionWrap}>
              <RemoveCircleIcon onClick={() => reduceCounter('group')} className={clsx(classes.actionIcon, classes[members.group.quantity === 0 ? 'inactive' : 'active'])} />
              <span>{members.group.quantity}</span>
              <AddCircleIcon onClick={() => increaseCounter('group')} className={clsx(classes.actionIcon, classes.active)} />
            </Grid>
          </Grid>
        </div>
      }

    </div>
  );
}
