import React, { memo, } from 'react';
import theme from './style';
import { Grid, withWidth } from '@material-ui/core';
import Button from '../../components/Button';
import { ListItem, SmallTitle } from '../../components/DataDisplay';
import translations from '../../functions/applyTranslation';
import useHomeHook from './hook';
import usePermissionsHook from '../../functions/permissions';

const HomeView = (props) => {
  const { width, } = props;
  let responsiveTest = (width === 'xs' || width === 'sm');
  const [permissions, roles, hasPermission] = usePermissionsHook();
  const classes = theme({ mobile: responsiveTest });
  const [news, activities, blogs, handleNewsNavigation, handleActivitiesNavigation, handleBlogNavigation] = useHomeHook(props);
  const onBlogClick = (option) => {
    handleBlogNavigation(option.value)
  }
  return (
    <Grid container>
      <Grid item xs={12} sm={8} >
        <div className={classes.newsList}>
          <div className={classes.titleWrapper}>
            <SmallTitle text={translations('home', 'title_latest_news')} />
            <Button label={translations('home', 'action_see_more_news') + ' >'} onClick={handleNewsNavigation} />
          </div>
          {news && news.map(item => <ListItem key={item.id}
            viewType='list' path={'/article'} item={{
              id: item.id,
              date: item.publish_date,
              title: item.title,
              intro: item.intro,
              image: item.lead_image_id,
              status: item.status,
              highlight: item.highlight
            }} />
          )}
          {news.length === 3 &&
            <div className={classes.buttonContainer}>
              <Button label={translations('home', 'action_see_more_news')} fill onClick={handleNewsNavigation} />
            </div>
          }
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <div className={classes.activitiesList}>
          <div className={classes.titleWrapper}>
            <SmallTitle text={translations('home', 'title_latest_activities')} />
            <Button label={translations('home', 'action_see_more_activities') + ' >'} onClick={handleActivitiesNavigation} />
          </div>
          {activities && activities.map((item, index) =>
            <ListItem key={index} path={'/activities'} viewType={index === 0 ? 'grid' : 'hybrid'} firstPage={true} item={{
              id: item.jfm_event.id,
              date: item.jfm_event.event_start,
              title: item.event.title,
              intro: item.event.description,
              image: item.event.image,
              status: item.jfm_event.event_status,
            }} />
          )}
          {/* <ListItem viewType='hybrid' item={{}} />
          <ListItem viewType='hybrid' item={{}} /> */}
          {news.length === 3 &&
            <div className={classes.buttonContainer}>
              <Button label={translations('home', 'action_see_more_activities')} fill onClick={handleActivitiesNavigation} />
            </div>
          }
        </div>
      </Grid>
      {hasPermission(permissions.BLOG_ACCESS) &&
        <Grid item xs={12} >
          <div className={classes.blogsList}>
            <div className={classes.titleWrapper}>
              <SmallTitle text={translations('home', 'title_latest_blogs')} />
              <Button label={translations('home', 'action_see_more_blogs') + ' >'} onClick={handleBlogNavigation} />
            </div>
            {blogs && blogs.map(item => <ListItem key={item.id} readOnly={true}
              viewType='blog' path={'/blog'} item={item} onClick={onBlogClick} />
            )}
            {blogs.length === 3 &&
              <div className={classes.buttonContainer}>
                <Button label={translations('home', 'action_see_more_blogs')} fill onClick={handleBlogNavigation} />
              </div>
            }
          </div>
        </Grid>
      }
    </Grid >
  );
}
export default withWidth()(memo(HomeView));