import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const styles = makeStyles(theme => ({
    backdrop: { zIndex: 1300 },
    circle: {
      color: '#0C1B4D'
    },
  }));
  return styles();
}
export default theme;

