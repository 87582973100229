import { useState, useEffect, useCallback } from 'react';
import useAPI from '../../../api/apiHandler';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/states/statesActions';
import useFilters from './filter';
import { useHistory } from 'react-router-dom';

const ProfileHook = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [setRequest] = useAPI();
  const [profiles, setProfiles] = useState([])
  const [filters, setFilters] = useFilters();
  const [data, setData] = useState([])
  const [rows, setRows] = useState([])
  const [navigationData, setSavigationData] = useState({
    totalItems: 0,
    totalPages: 1,
  })
  const [searchText, setSearchText] = useState('')
  const onFormSubmit = (e) => {
    e.preventDefault();
    search(searchText);
  }

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setSearchText('');
      clearSearch();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);


  const clearSearch = () => {
    setSearchText('');
  }

  const search = (text) => {
    setSearchText(text.toUpperCase());
  }

  useEffect(() => {
    if (rows) {
      applyFilter();
    }
  }, [data, searchText])

  const applyFilter = () => {
    let _data = JSON.parse(JSON.stringify(data));
    if(searchText && searchText.length > 0)
      _data = _data.filter(item => item.name.toUpperCase().search(searchText) >= 0 || item.mail.toUpperCase().search(searchText) >= 0 || item.ssn.toUpperCase().search(searchText) >= 0);
    setRows(_data);
  }

  const fetchProfiles = () => {
    dispatch(setLoading(true))
    const { limit, page } = filters;
    let url = `users?limit=${limit}&page=${page}`;
    setRequest({
      url: url,
    }).then(res => {
      if (res) {
        setData(res.data.content);
        setSavigationData({
          totalPages: Number(res.data.pages),
          totalItems: Number(res.data.total)
        })
        dispatch(setLoading(false))
      }
    })
  }

  useEffect(() => {
    fetchProfiles();
  }, [filters])

  const handlePagination = (action) => {
    setFilters('page', (action === 'next' ? filters.page += 1 : filters.page -= 1))
  }

  const handleNavigation = (id) => {
    history.push(`/profile/${id}`);
  }

  return [{
    rows: rows,
    filters: filters,
    settings: navigationData
  }, setFilters, handleNavigation, handlePagination, search, clearSearch, onFormSubmit];
}

export default ProfileHook;