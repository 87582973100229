import React from 'react';
import theme from './style';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import translate from '../../functions/applyTranslation'
const Button = (props) => {
  const classes = theme();
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  return (
    <div className={classes.toTopWrap}>
      <div className={classes.button} onClick={handleClick}>
        < ExpandLessIcon />
        <span>{translate('', 'label_to_top')}</span>
      </div>
    </div>
  );
}
export default Button;