import { store } from '../redux/store';
import parse from 'html-react-parser';

export const tryParseToHtml = (value, token) => {
  let result = value;

  if(value) {
    result = value.replace(/token=(.*?)"/g, `token=${token}"`);
    result = result.replace(/<img(.*?)src/g, '<img style="max-width:100%" src');
  }

  let body = parse(result || '');

  try {
    body = parse(body)
  }
  catch (e) {

  }
  return body;
}

const translate = (area, key) => {
  const state = store.getState();
  let translations = state.settings.translations;
  try {
    return tryParseToHtml(translations[`/jfm${area.length > 0 ? '/' + area : ''}`].find(item => item.hash === key).text);
  } catch (error) {

    return `${area}..${key}`;
  }
}
export default translate;