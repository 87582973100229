import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme'
const theme = () => {
  const styles = makeStyles(theme => ({
    galleryList: {
      '&.test:not(:last-child)': {
        marginBottom: 15,
      }
    },
    spaceBetween: {
      width: `100%`,
      display: 'inline-flex',
      justifyContent: 'space-between',
      marginBottom: 5,
    },
    searchTextWrap: {
      width: `100%`,
      display: 'inline-flex',
      justifyContent: 'start',
      marginBottom: 5,
      '& p': {
        width: 'auto !important'
      }
    },
    searchText: {
      marginLeft: 3,
      fontWeight: 'bold'
    },
    flexEnd: {
      width: `100%`,
      display: 'inline-flex',
      justifyContent: 'flex-end',
      marginBottom: 5,
    },
    flexStart: {
      width: `100%`,
      display: 'inline-flex',
      justifyContent: 'flex-start',
      marginBottom: 5,
    },
    paginatorLabel: {
      fontSize: 11,
      color: ColorSchemes.highDarkGrey,
    },
    actionsWrapper: {
      borderBottom: `1px solid ${ColorSchemes.lowDarkGrey}`,
      paddingBottom: 10,
      marginBottom: 5,
      '& .item': {
        padding: 3,
        display: 'flex',
        alignItems: 'center'
      },
      '& .executeHolder, .deleteHolder': {
        justifyContent: 'flex-end'
      }
    }
  }));
  return styles();
}
export default theme;

