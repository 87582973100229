import { makeStyles } from "@material-ui/core/styles";
const theme = (props) => {
  const styles = makeStyles(theme => ({
    formWrap: {
      padding: 15,
      marginBottom: 15,
    },
    titleWrap: {
      marginBottom: 20,
    },
    loginButtonWrap: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 10
    },
    alternativeWrap: {
      padding: 15,
    },
    paddingFix: {
      paddingBottom: 5
    },
    help: {
      lineHeight: '20px'
    }
  }));
  return styles();
}
export default theme;

