import React  from 'react';
import theme from './style';
import clsx from 'clsx';
import { Link } from '@material-ui/core';
const DatePicker = (props) => {
  const classes = theme();
  const {
    onClick,
    id,
    text,
    quantity,
    selected,
  } = props;

  const handleChange = () => {
    if (onClick) {
      onClick(id)
    }
  }
  return (
    <div className={clsx(classes.categoriesWrap, selected ? classes.selected : '')} onClick={handleChange} >
      <Link>{text}</Link>
      <span>{`(${quantity})`}</span>
    </div >
  );
}
DatePicker.propTypes = {
}


export default DatePicker;