import React from 'react';
import theme from './style';
import {Typography} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import clsx from 'clsx';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';

const Check = (props) => {
  const classes = theme();
  const {
    id,
    invalid,
    disabled,
    value,
    label,
    onChange,
    className,
    position,
    rounded,
    iconClass,
    labelClass,
    extraIcon,
    onExtraClick,
    extraClass
  } = props;

  const handleChange = () => {
    if (!disabled && onChange) {
      onChange(!value, id)
    }
  }
  if (position && position === 'right') {
    return (
      <div tabIndex={0} className={clsx(className, classes.checkGroup, invalid ? classes.invalid : '')}
           onClick={handleChange}
      >
        <Typography className={clsx(labelClass, classes.text)}>
          {extraIcon && <OpenInNewIcon onClick={onExtraClick} className={extraClass}/>}
          {label}
        </Typography>
        {rounded ?
          <>
            {value ? <CheckCircleRoundedIcon className="checkIcon"/> :
              <RadioButtonUncheckedRoundedIcon className="checkIcon"/>}
          </>
          :
          <>
            {value ? <CheckBoxIcon className="checkIcon"/> : <CheckBoxOutlineBlankIcon className="checkIcon"/>}
          </>
        }
      </div>
    );
  } else {
    return (
      <div tabIndex={0}
           className={clsx(className, classes.checkGroup, invalid ? classes.invalid : '', disabled ? classes.disabled : '')}
           onClick={handleChange}
      >
        {rounded ?
          <>
            {value ? <CheckCircleRoundedIcon className={clsx(iconClass, "checkIcon")}/> :
              <RadioButtonUncheckedRoundedIcon className={clsx(iconClass, "checkIcon")}/>}
          </>
          :
          <>
            {value ? <CheckBoxIcon className="checkIcon"/> : <CheckBoxOutlineBlankIcon className="checkIcon"/>}
          </>
        }
        <Typography className={clsx(labelClass, classes.text)}>
          {label}
          {extraIcon && <OpenInNewIcon onClick={onExtraClick} className={extraClass}/>}
        </Typography>
      </div>
    );
  }
}
export default Check;