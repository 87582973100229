import { makeStyles } from "@material-ui/core/styles";
import ColorSchemes from '../../settings/colorScheme';
const theme = () => {
  const styles = makeStyles(theme => ({
    headerWrap: {
      marginBottom: 10,
      padding: '10px 0px',
    },
    titleWrap: {
      display: 'inline-flex',
      width: '100%',
      alignItems: 'center',
      '& >:nth-child(1)': {
        flexGrow: 1,
      }
    },
    buttonGroup: {
      '& >:not(:last-child)': {
        marginRight: 10,
      }
    },
    newsList: {
      '&.test:not(:last-child)': {
        marginBottom: 15,
      }
    },
    spaceBetween: {
      width: `100%`,
      display: 'inline-flex',
      justifyContent: 'space-between',
      marginBottom: 5,
    },
    flexEnd: {
      width: `100%`,
      display: 'inline-flex',
      justifyContent: 'flex-end',
      marginBottom: 5,
    },
    flexStart: {
      width: `100%`,
      display: 'inline-flex',
      justifyContent: 'flex-start',
      marginBottom: 5,
    },
    selectLabel: {
      marginRight: 10,
    },
    selectGroup: {
      marginRight: 15,
      alignItems: 'center',
      display: 'inline-flex',
    },
    newsWrap: {
      width: 'calc(100% + 10px)',
      marginLeft: -5,
      '& .item': {
        padding: '0px 5px'
      }
    },
    paginatorLabel: {
      fontSize: 11,
      color: ColorSchemes.highDarkGrey,
    },
    search: {
      marginBottom: 16,
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end'
    },
    searchButton: {
      marginLeft: 8,
      // fontSize: '16px !important',
      // lineHeight: '21px !important',
      color: '#fff !important',
      backgroundColor: '#07A717 !important',
      padding: '6px !important',
      height: '35px !important',
      maxHeight: '35px !important',
      borderRadius: '5px !important',
    },
    searchClearButton: {
      marginLeft: 8,
      marginRight: 2
      // fontSize: '16px !important',
      // lineHeight: '21px !important'
    },
    searchInput: {
      width: '30%',
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      }
      // '& input': {
      //   lineHeight: '21px !important',
      //   padding: '13px 14px 11px 14px !important'
      // }
    },
    searchActions: {
      textAlign: 'right',
    }
  }));
  return styles();
}
export default theme;

