import React  from 'react';
import theme from './style';
import { Grid, Paper } from '@material-ui/core';
import Header from '../../components/Header';
import SubHeader from '../../components/SubHeader';
import Footer from '../../components/Footer';
import MyPages from '../../components/MyPages';
import useHook from './hook';
import { withRouter } from 'react-router-dom';
import {useSelector} from "react-redux";

const TemplateDashboard = (props) => {
  const [handleLogout, updateLocation] = useHook(props);
  const [mobileMyPages, toggleMobileMyPages] = React.useState(false);
  const classes = theme();
  const {
    children,
    history
  } = props;

  const {wideCard} = useSelector(state => state.settings);

  history.listen((location, action) => {
    updateLocation();
  });

  const handleToggleMobileMyPages = (newState) => {
    toggleMobileMyPages(newState);
  }

  return (
    <div className={classes.root}>
      <Header handleLogout={handleLogout} toggleMobileMyPages={handleToggleMobileMyPages}/>
      <SubHeader />
      <MyPages handleLogout={handleLogout} mobileMyPages={mobileMyPages} handleToggleMobileMyPages={handleToggleMobileMyPages}/>
      <div className={classes.viewContainer}>
        <div className={classes.contentWrap}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} sm={10} md={wideCard ? 10 : 8}>
              <Paper className={classes.paperContainer}>
                {children}
              </Paper>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    </div >
  );
}
export default withRouter(TemplateDashboard);
