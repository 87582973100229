import React, {createContext, useEffect, useState} from "react";
import useAPI from '../../api/apiHandler';
import {useDispatch, useSelector} from 'react-redux';
import {setLoading} from '../../redux/states/statesActions';
import {setCart} from '../../redux/cart/cartActions';
import {useParams, useHistory} from 'react-router-dom';
import {loadStripe} from "@stripe/stripe-js";
import {priceFromDb} from '../../functions/applyPrices';

export const ActivitiesContext = createContext({});
const stripePromise = loadStripe("pk_test_51HziLZK7JTNQ6NQUAUxEi4rJJTofvNWeHzBDKONJ8sUU6gkLlNg2GFTD7g6lZm2K0n6UoUI8icmPhJ1WB7NT2Yji00AYgSrfFJ");

export const Provider = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.userData);
  const cart = useSelector(state => state.cart);

  const params = useParams();
  const [setRequest, multipleRequests] = useAPI();
  const {id} = params;
  const [error, setError] = useState(false);
  const [modal, setModal] = useState()
  const [data, setData] = useState()

  const [products, setProcuts] = useState([])
  const [comments, setComments] = useState('');
  const [agreementTC, setAgreementTC] = useState(false);

  const [ticketsMember, setTicketsMember] = useState()
  const [ticketsNonMember, setTicketsNonMember] = useState()

  const [ticketsSold, setTicketsSold] = useState([]);
  const [ticketUsersMember, setTicketUsersMember] = useState({
    adult: [],
    child: [],
    group: [],
  })
  const [ticketUsersNonMember, setTicketUsersNonMember] = useState({
    adult: [],
    child: [],
    group: [],
  })

  const closeModal = () => {
    setModal();
  }
  const openModal = (options) => {
    setModal(options);
  }

  const fetchEventDetail = async () => {
    dispatch(setLoading(true))

    let options = [
      {
        url: `events?limit=1&page=1&event_id=${id}`,
        method: 'GET',
      },
      {
        url: `event/tickets?event_id=${id}`,
        method: 'GET',
      }
    ];

    if (window.location.pathname.endsWith('cart-retry')) {
      options.push({
        url: `checkout/order?order_id=${cart.order_id}`,
        method: 'GET',
      });
    }

    await multipleRequests(options)
      .then(responses => {
        if (responses && responses.length >= 2) {
          let eventData = {};
          if (responses[0].data) {
            eventData = responses[0].data.content[0] || {};
            if (eventData && eventData.products)
              eventData.products = eventData.products.map(x => ({...x, title: x.name, amount: priceFromDb(x.amount)}));
            setProcuts(eventData.products)
            setData(eventData)
          }
          if (responses[1].data) {
            let data = responses[1].data;
            if (userData)
              data = data.filter(item => Number(item.user_id) === Number(userData.id));
            let keys = Object.keys(data);
            let _data = keys.map(key => {
              const ticket = (eventData.products || []).find(item => Number(item.id) === Number(data[key].ticket));
              return {
                //id: key,
                ticket_name: ticket ? ticket.name : null,
                ticket_price: ticket ? ticket.amount : null,
                ...data[key],
              }
            })
            setTicketsSold(_data);
          }
          if (window.location.pathname.endsWith('cart-retry') && responses.length === 3 && responses[2].data) {
            let data = JSON.parse(responses[2].data.user_info);
            let tickets = data.map(entrance => {
              const ticket = (eventData.products || []).find(item => Number(item.id) === Number(entrance.ticket));
              return {
                comments: entrance.message,
                member: ticket.pay,
                name: entrance.name,
                productName: ticket.name,
                ssn: entrance.ssn,
                ticket: entrance.ticket,
                type: ticket.type
              }
            })
            let member = {
              adult: tickets.filter(x => Number(x.type) === 1 && Number(x.member) === 1),
              child: tickets.filter(x => Number(x.type) === 2 && Number(x.member) === 1),
              group: tickets.filter(x => Number(x.type) === 3 && Number(x.member) === 1),
            }
            let nonMember = {
              adult: tickets.filter(x => Number(x.type) === 1 && Number(x.member) === 0),
              child: tickets.filter(x => Number(x.type) === 2 && Number(x.member) === 0),
              group: tickets.filter(x => Number(x.type) === 3 && Number(x.member) === 0),
            }
            let memberResume = {
              adult: {
                quantity: (member.adult || []).length,
                id: ((eventData.products || []).find(x => Number(x.type) === 1 && Number(x.pay) === 1) || {}).id
              },
              child: {
                quantity: (member.child || []).length,
                id: ((eventData.products || []).find(x => Number(x.type) === 2 && Number(x.pay) === 1) || {}).id
              },
              group: {
                quantity: (member.group || []).length,
                id: ((eventData.products || []).find(x => Number(x.type) === 3 && Number(x.pay) === 1) || {}).id
              },
            }
            let nonMemberResume = {
              adult: {
                quantity: (nonMember.adult || []).length,
                id: ((eventData.products || []).find(x => Number(x.type) === 1 && Number(x.pay) === 0) || {}).id
              },
              child: {
                quantity: (nonMember.child || []).length,
                id: ((eventData.products || []).find(x => Number(x.type) === 2 && Number(x.pay) === 0) || {}).id
              },
              group: {
                quantity: (nonMember.group || []).length,
                id: ((eventData.products || []).find(x => Number(x.type) === 3 && Number(x.pay) === 0) || {}).id
              },
            }

            setTicketsMember(memberResume);
            setTicketsNonMember(nonMemberResume);
            setTicketUsersMember(member);
            setTicketUsersNonMember(nonMember);
          } else {
            let member = {
              adult: [],
              child: [],
              group: [],
            }
            let nonMember = {
              adult: [],
              child: [],
              group: [],
            }
            let memberResume = {
              adult: {
                quantity: (member.adult || []).length,
                id: ((eventData.products || []).find(x => Number(x.type) === 1 && Number(x.pay) === 1) || {}).id
              },
              child: {
                quantity: (member.child || []).length,
                id: ((eventData.products || []).find(x => Number(x.type) === 2 && Number(x.pay) === 1) || {}).id
              },
              group: {
                quantity: (member.group || []).length,
                id: ((eventData.products || []).find(x => Number(x.type) === 3 && Number(x.pay) === 1) || {}).id
              },
            }
            let nonMemberResume = {
              adult: {
                quantity: (nonMember.adult || []).length,
                id: ((eventData.products || []).find(x => Number(x.type) === 1 && Number(x.pay) === 0) || {}).id
              },
              child: {
                quantity: (nonMember.child || []).length,
                id: ((eventData.products || []).find(x => Number(x.type) === 2 && Number(x.pay) === 0) || {}).id
              },
              group: {
                quantity: (nonMember.group || []).length,
                id: ((eventData.products || []).find(x => Number(x.type) === 3 && Number(x.pay) === 0) || {}).id
              },
            }

            setTicketsMember(memberResume);
            setTicketsNonMember(nonMemberResume);
            setTicketUsersMember(member);
            setTicketUsersNonMember(nonMember);
          }
          dispatch(setLoading(false))
        }
      })
      .catch(() => dispatch(setLoading(false)));
  }

  const fetchSoldTickets = () => {
    dispatch(setLoading(true))
    setRequest({
      url: `event/tickets?event_id=${id}`,
      method: 'GET',
    }).then(res => {
      if (res && res.data) {
        let data = res.data;
        if (userData)
          data = data.filter(item => Number(item.user_id) === Number(userData.id));
        let keys = Object.keys(data);
        let _data = keys.map(key => {
          const ticket = (products || []).find(item => Number(item.id) === Number(data[key].ticket));
          return {
            //id: key,
            ticket_name: ticket ? ticket.name : null,
            ticket_price: ticket ? ticket.amount : null,
            ...data[key],
          }
        })
        setTicketsSold(_data);
      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  }

  const updateMemberTickets = () => {
    let userticket = JSON.parse(JSON.stringify(ticketUsersMember));
    ticketsMember && Object.keys(ticketsMember).map(item => {
      let {id, quantity} = ticketsMember[item];
      let product = products.find(item => item.id === id);
      if (quantity > userticket[item].length) {
        userticket[item].push({
          productName: product.name,
          name: '',
          ssn: '',
          ticket: id,
          member: 1,
        })
      } else if (quantity < userticket[item].length) {
        userticket[item] = userticket[item].slice(0, quantity);
      }
    })
    setTicketUsersMember(userticket);
  };

  const updateNonMemberTickets = () => {
    let userticket = JSON.parse(JSON.stringify(ticketUsersNonMember));
    ticketsNonMember && Object.keys(ticketsNonMember).map(item => {
      let {id, quantity} = ticketsNonMember[item];
      let product = products.find(item => item.id === id);
      if (quantity > userticket[item].length) {
        userticket[item].push({
          productName: product.name,
          name: '',
          ssn: '',
          ticket: id,
          member: 0,
        })
      } else if (quantity < userticket[item].length) {
        userticket[item] = userticket[item].slice(0, quantity);
      }
    })
    setTicketUsersNonMember(userticket);
  };

  useEffect(() => {
    updateMemberTickets()
  }, [ticketsMember])
  useEffect(() => {
    updateNonMemberTickets()
  }, [ticketsNonMember])
  useEffect(() => {
    fetchEventDetail()
  }, [])
  // useEffect(() => { if (products) fetchSoldTickets() }, [products])

  const handleChangeMember = (type, index, value, key) => {
    let ticketUsers = JSON.parse(JSON.stringify(ticketUsersMember));
    ticketUsers[type][index][key] = value;
    setTicketUsersMember(ticketUsers);
  }

  const handleChangeNonMember = (type, index, value, key) => {
    let ticketUsers = JSON.parse(JSON.stringify(ticketUsersNonMember));
    ticketUsers[type][index][key] = value;
    setTicketUsersNonMember(ticketUsers);
  }

  const removeUser = (registerId) => {
    dispatch(setLoading(true))
    setRequest({
      url: `event/user?calendar_user_id=${registerId}`,
      method: 'DELETE',
    }).then(res => {
      if (res) {
        fetchSoldTickets();
      }
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  };

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      // setMessage("Order placed! You will receive an email confirmation.");
      alert('success')
    }
    if (query.get("canceled")) {
      alert('cancel')
      // setMessage(
      //   "Order canceled -- continue to shop around and checkout when you're ready."
      // );
    }
  }, []);

  const handleCheckOut = async (event) => {
    dispatch(setLoading(true))
    const stripe = await stripePromise;
    setError(false)
    var formdata = new FormData();
    formdata.append("event_id", id);
    let count = 0;
    ['adult', 'child', 'group'].forEach(key => {
      ticketUsersMember[key].forEach(item => {
        count++
        formdata.append(`items[${count}][product_id]`, item.ticket);
        formdata.append(`items[${count}][name]`, item.name);
        formdata.append(`items[${count}][message]`, item.comments || '');
      })
      ticketUsersNonMember[key].forEach(item => {
        count++
        formdata.append(`items[${count}][product_id]`, item.ticket);
        formdata.append(`items[${count}][name]`, item.name);
        formdata.append(`items[${count}][ssn]`, item.ssn);
        formdata.append(`items[${count}][message]`, item.comments || '');
      })
    })
    const session = await setRequest({
      url: 'checkout/order',
      method: 'POST',
      data: formdata
    }).then(async res => {
      if (res) {
        dispatch(setCart({session_id: res.data.stripe_id, order_id: res.data.id}))
        const result = await stripe.redirectToCheckout({
          sessionId: res.data.stripe_id,
        });
        if (result.error) {
          alert(error)
        }
      } else
        setError(true)
      dispatch(setLoading(false))
    })
      .catch(() => dispatch(setLoading(false)))
  };

  const handleBack = () => {
    history.goBack();
  }

  const childrenStartAt = () => {
    return extractChildAge(products, 1);
  }

  const childrenEndAt = () => {
    return extractChildAge(products, 2);
  }

  const extractChildAge = (data, pos) => {
    var numberPattern = /\d+/g;
    let _data = data.find(item => item.type === '2');
    if (_data) {
      let matchData = _data.name.match(numberPattern);
      if (matchData.length >= pos) {
        return matchData[pos - 1];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const _activitiesContext = {
    data,
    ticketsMember,
    ticketsNonMember,
    ticketsSold,
    products,
    comments,
    setComments,
    setTicketsMember,
    setTicketsNonMember,
    ticketUsersMember,
    ticketUsersNonMember,
    handleChangeMember,
    handleChangeNonMember,
    agreementTC, setAgreementTC,
    removeUser,
    handleCheckOut,
    handleBack,
    childrenStartAt,
    childrenEndAt,
    error,
    modal,
    openModal,
    closeModal
  };
  return <ActivitiesContext.Provider value={_activitiesContext}>{props.children}</ActivitiesContext.Provider>;
};

export const {Consumer} = ActivitiesContext;