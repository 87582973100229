
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import { InputField } from '../../../components/Inputs';
import Button from '../../../components/Button';
import translate from '../../../functions/applyTranslation';
import theme from './style';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function EmailSelected(props) {
  const { users, modal, closeModal, submitModal } = props;
  const classes = theme();
  const [data, setData] = useState({
    users: [],
    text: '',
  })
  const handleClose = () => {
    closeModal();
  };
  const formValid = () => {
    return !data.text.length
  }
  const handleSubmit = () => {
    submitModal(data);
    setData({
      users: [],
      text: '',
    })
  }
  return (
    <div>
      <Dialog
        open={modal === 'email'}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>
          {translate('booking', 'title_email_selected')}
          <small>{translate('booking', 'subtitle_email_selected')}</small>
        </DialogTitle>
        <DialogContent>
          <InputField value={data.text}
            className={classes.content}
            multiline
            placeholder={translate('booking', 'label_message_to_participants')} fullWidth
            onChange={(e) => {
              setData({
                users: users,
                text: e,
              })
            }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} label={translate('', 'button_cancel')} />
          <Button disabled={formValid()} onClick={handleSubmit} label={translate('', 'button_send')} fill icon='email' />
        </DialogActions>
      </Dialog>
    </div>
  );
}
