import { useState } from 'react';
import useAPI from '../../../api/apiHandler';
import { useDispatch } from 'react-redux';
import { setLoading, increaseCount } from '../../../redux/states/statesActions';
import { acceptBlogRules } from '../../../redux/settings/settingsActions';
import setTargetLink from '../../../functions/setTargetLink'
import { useHistory } from 'react-router-dom';
import translate from "../../../functions/applyTranslation"

const BlogHook = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [setRequest] = useAPI();
  const [comments, setComments] = useState()
  const [blog, setBlog] = useState();
  const [notFound, setNotFound] = useState(false);

  const fetchBlog = (id) => {
    dispatch(setLoading(true))
    let url = `blog?blog_id=${id}`;
    setRequest({
      url: url,
    }).then(res => {
      if (res) {
        let data = res.data;

        url = `blog/comments?blog_id=${id}`;
        setRequest({
          url: url,
        }).then(res => {
          if (res) {
            data.num_of_comments = res.data || 0;
          }
          url = `user/profile?user_id=${data.author_id}`;
          setRequest({
            url: url,
          }).then(res => {
            if (res) {
              data.author = res.data ? res.data.name : 0;
              setBlog(data);
            }
            dispatch(setLoading(false))
          })
          dispatch(setLoading(false))
        })
      }
      else {
        dispatch(setLoading(false));
        setNotFound(true);
      }
    })
      .catch(err => history.push("/blog"))
  }

  const fetchComments = (id) => {
    dispatch(setLoading(true))
    let url = `comments?blog_id=${id}`;
    setRequest({
      url: url,
    }).then(res => {
      if (res) {
        let data = res.data;
        data.forEach(item => {
          item.comment.content = setTargetLink(item.comment.content);
        });
        setComments(data);
      }
      dispatch(setLoading(false))
    })
  }

  const deleteBlog = (id) => {
    dispatch(setLoading(true))
    setRequest({
      url: `blog/delete/?blog_id=${id}`,
      method: 'DELETE',
    }).then(res => {
      if (res) {
        history.push('/blog');
      }
      dispatch(setLoading(false))
    })
  };

  const deleteComment = (id) => {
    dispatch(setLoading(true))
    setRequest({
      url: `comment/delete/?comment_id=${id}`,
      method: 'DELETE',
    }).then(res => {
      if (res) {
        dispatch(increaseCount())
      }
      dispatch(setLoading(false))
    })
  };

  const handleAcceptBlogRules = () => {
    dispatch(acceptBlogRules(true));
  }

  return [dispatch, setLoading, setRequest, blog, comments, fetchBlog, fetchComments, deleteBlog, deleteComment, handleAcceptBlogRules, notFound];
}

export default BlogHook;